import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import { format, parseISO } from "date-fns";

import CardContainer from "../../common/atoms/CardContainer";
import { PaymentService } from "../services/PaymentService";
import {
  CURRENCY_SYMBOLS,
} from "../../common/helpers/constants";

const TablePayment = styled.table`
  width: 100%;
  tr {
    th,
    td {
      padding: 0.5rem 0px;
      text-align: left;
    }
  }
`;

const PersonalPaymentHistory = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [payments, setPayments] = useState([]);

  useEffect(() => {
      PaymentService.getPayments()
        .then(
          (response) => {
            setPayments(response.data || []);
          },
          () => {
            setPayments([]);
          }
        )
        .finally(() => {
          setIsLoading(false);
        });
  }, []);

  return (
    <CardContainer headerTitle="Historial de Pagos" headerTextAlign="start">
      {isLoading && <CircularProgress />}
      {!isLoading && !!payments.length && (
        <div className="table-responsive">
          <TablePayment className="table table-borderless">
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Plan</th>
                <th>Costo</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {payments.map((payment) => (
                <tr key={payment.id}>
                  <td>{format(parseISO(payment.createdAt), "dd/MM/yyyy")}</td>
                  <td>{payment.name}</td>
                  <td>
                    {CURRENCY_SYMBOLS[payment.currency] || "$"}
                    {payment.price.toFixed(2)}
                  </td>
                  <td>
                    <span style={{ color: "green" }}>Pagado</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </TablePayment>
        </div>
      )}
      {!isLoading && !payments.length && (
        <span>No se encontraron resultados.</span>
      )}
    </CardContainer>
  );
};

export default PersonalPaymentHistory;
