import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { BrowserRouter } from "react-router-dom";
import { IconsProvider } from "./business-card-management/context/IconsContext";

import { Provider } from "react-redux";

import { store, persistor } from "./redux/store/index";
import { PersistGate } from "redux-persist/integration/react";

const apiUrl = process.env.REACT_APP_API_DEV_BASE_URL;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <IconsProvider>
            <App apiUrl={apiUrl} />
          </IconsProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// if ("serviceWorker" in navigator) {
//   window.addEventListener("load", () => {
//     navigator.serviceWorker
//       .register("/service-worker.js")
//       .then((registration) => {
//         console.log("Service Worker registrado con éxito:", registration);
//       })
//       .catch((error) => {
//         console.log("Error al registrar el Service Worker:", error);
//       });
//   });
// }
