import styled from "@emotion/styled";
import { useState } from "react";
import Typography from "../../common/atoms/Typography";

const ColorContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 8px 4px;
`;

const ColorPlaceholder = styled.p`
  margin-bottom: 8px;
  font-weight: 500;
  text-align: center;
  font-style: italic;
  font-size: 0.9rem;
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const ColorBox = styled.div`
  display: flex;
  align-items: center;
  input {
    width: 24px;
    height: 24px;
    border: none;
    margin-right: 8px;
  }
`;

const ColorText = styled(Typography)`
  font-weight: 500;
  font-size: 0.9rem;
  text-transform: uppercase;
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

export const ColorSelector = ({
  name,
  placeholder,
  initialColor,
  getColor,
}) => {
  const [color, setColor] = useState(initialColor);
  const handleSetColor = (color) => {
    setColor(color);
    getColor(name, color);
  };

  return (
    <ColorContainer>
      <ColorPlaceholder>{placeholder}</ColorPlaceholder>
      <ColorBox>
        <input
          type="color"
          value={color}
          onChange={(e) => handleSetColor(e.target.value)}
        />
        <ColorText variant="xs">{color}</ColorText>
      </ColorBox>
    </ColorContainer>
  );
};
