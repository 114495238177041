import axiosInstance from "../../common/helpers/axios";

let tokenString = null;

const AuthService = {
  setToken: (token) => {
    tokenString = `Bearer ${token}`;
  },

  login: (employeeCredentials) => {
    return axiosInstance.post("/auth/login", employeeCredentials);
  },
  getUser: () => {
    return axiosInstance.get("/auth/user");
  },
  verifyToken: async (code) => {
    const response = await axiosInstance.post("/auth/verify-token", { code });
    return response.data;
  },
  changePassword: async (code, newPassword) => {
    return axiosInstance.post("/auth/change-password", { code, newPassword });
  },
  resetPassword: async (data) => {
    return axiosInstance.post("/auth/reset-password", data);
  },

  getCompanyById: async (id) => {
    const config = {
      headers: {
        Authorization: tokenString,
      },
    };
    try {
      let result = await axiosInstance.get(`/company/${id}`);
      if (result.status === 200) {
        return result.data;
      } else {
        return {};
      }
    } catch (error) {
      console.log(error);
    }
  },

  getOwnCompanies: async (id) => {
    const date = new Date();
    return await axiosInstance.get(
      `/employee/${id}/companies?date=${date.toString()}`
    );
  },

  registerPersonalAccount: async (
    accountBody,
    defaultPhoto,
    defaultCompanyPhoto
  ) => {
    const response = await axiosInstance.post("/employee/personal-register", {
      ...accountBody,
      defaultPhoto,
      defaultCompanyPhoto,
    });
    return response.data;
  },

  changeAccount: async (employeeId) => {
    return await axiosInstance.get(`/employee/change-account/${employeeId}`);
  },
};

export default AuthService;
