import axiosInstance from "../../common/helpers/axios";

const EmployeeService = {
  editEmployee: async (employeeId, newEmployee) => {
    return axiosInstance.put("/employee/" + employeeId, newEmployee);
  },
  linkAccount: (code) => {
    return axiosInstance.post("employee/link", { code });
  },
  confirmLinking: (accountEmail) => {
    return axiosInstance.post("employee/confirm-linking", { accountEmail });
  },
  getLinkedAccounts: () => {
    return axiosInstance.get("employee/linked");
  },
  deleteLinkedAccount: (employeeId) => {
    return axiosInstance.delete("employee/linked", {
      params: {
        employeeId,
      },
    });
  },
};

export default EmployeeService;
