import { CircularProgress } from "@mui/material";
import React from "react";
import CardContainer from "../../common/atoms/CardContainer";
import styled from "@emotion/styled";
import { format, parseISO } from "date-fns";
import { CURRENCY_SYMBOLS } from "../../common/helpers/constants";
import Typography from "../../common/atoms/Typography";
const TablePayment = styled.table`
  width: 100%;
  tr {
    th,
    td {
      padding: 0.5rem 2rem;
      text-align: left;
    }
  }
`;

const StyledTableRow = styled.tr`
  td {
    vertical-align: middle;
  }
`;

const Container = styled.div`
  min-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const AdditionalPaymentsList = ({ additionalPayments }) => {

  return (
    <Container>
      {!!additionalPayments.length && (
        <div className='table-responsive'>
          <TablePayment className='table table-borderless'>
            <thead>
              <tr>
                <th>Fecha de registro</th>
                <th>Cantidad Tarjetas</th>
                <th>Precio total</th>
                <th>Duración en días</th>
              </tr>
            </thead>
            <tbody>
              {additionalPayments.map((payment) => (
                <StyledTableRow key={payment._id}>
                  <td>{format(parseISO(payment.createdAt), "dd/MM/yyyy")}</td>
                  <td>{payment.quantity}</td>
                  <td>
                    {CURRENCY_SYMBOLS[payment.currency] || "$"}
                    {payment.totalAmount.toFixed(2)}
                  </td>
                  <td>{payment.duration}</td>
                </StyledTableRow>
              ))}
            </tbody>
          </TablePayment>
        </div>
      )}
      {!additionalPayments.length && <span>No se encontraron resultados.</span>}
    </Container>
  );
};
