import DialogWithHeader from "./DialogWithHeader";
import PropTypes from "prop-types";
import Typography from "../atoms/Typography";
import styled from "@emotion/styled";
const ConfirmationQuestion = styled(Typography)`
  margin: 16px 8px;
  text-align: start;
  font-weight: bold;
`;

const ConfirmationNote = styled(Typography)`
  margin: 8px;
  text-align: start;
`;

const ConfirmationDialog = ({
  open,
  headerTitle,
  closeDialog,
  question,
  note = "",
  children,
}) => {
  const handleCloseDialog = () => {
    closeDialog();
  };
  return (
    <DialogWithHeader
      open={open}
      headerTitle={headerTitle}
      closeDialog={handleCloseDialog}
    >
      <div style={{ width: "100%" }}>
        <ConfirmationQuestion variant="small">{question}</ConfirmationQuestion>
        {note !== "" && (
          <ConfirmationNote variant="xs">{note}</ConfirmationNote>
        )}

        <>{children}</>
      </div>
    </DialogWithHeader>
  );
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  headerTitle: PropTypes.string,
  closeDialog: PropTypes.func,
  question: PropTypes.string,
  note: PropTypes.string,
};

export default ConfirmationDialog;
