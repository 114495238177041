import PropTypes from "prop-types";
import { Pagination as MUIPagination } from "@mui/material";
import styled from "@emotion/styled";
import { blue_main, graytwo } from "./variables";

const PaginationStyled = styled(MUIPagination)`
  display: flex;
  margin-top: 20px;
  justify-content: center;
  ul {
    gap: 6px;
    li {
      button {
        border-radius: 6px;
        border: 1px solid ${graytwo};
        color: #acacac;
        &.Mui-selected {
          background-color: ${blue_main};
          color: white;
          border: none;
          &:hover {
            background-color: ${blue_main};
            opacity: 0.8;
          }
        }
        &.MuiPaginationItem-previousNext {
          background-color: ${blue_main};
          color: white;
          border: none;
          &:hover {
            background-color: ${blue_main};
            opacity: 0.8;
          }
        }
      }
      .MuiPaginationItem-ellipsis {
        border-radius: 6px;
        border: 1px solid ${graytwo};
        color: #acacac;
        height: 32px;
      }
    }
  }
`;

const Pagination = ({ total, pageSize, currentPage, onPageChange }) => {
  const handleChange = (_event, page) => {
    onPageChange(page);
  };

  return (
    <PaginationStyled
      count={Math.ceil(total / pageSize)}
      page={currentPage}
      onChange={handleChange}
      variant="outlined"
      shape="rounded"
    />
  );
};

Pagination.propTypes = {
  total: PropTypes.number,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
};

export default Pagination;
