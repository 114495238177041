import { put } from "redux-saga/effects";
import { logout } from "../../accounts/actions/authActions";
import { clearAccounts } from "../../accounts/actions/linkedAccountsActions";
import { clearQuantity } from "../../business-cards/actions/cardQuantityActions";
import { clearCurrentCompany } from "../../companies/actions/currentCompanyActions";

export function* clearStoreHandler() {
  try {
    yield put(logout());
    yield put(clearAccounts());
    yield put(clearQuantity());
    yield put(clearCurrentCompany());
  } catch (error) {
    if (error.response) {
      console.log(error.response.data);
      return;
    }
    console.log(error);
  }
}
