import { getCardQuantity } from "../requests/cardQuantityRequests";
import { call, put } from "redux-saga/effects";
import { setCardQuantity } from "../actions/cardQuantityActions";

export function* handleInitCardQuantity(action) {
  try {
    const response = yield call(getCardQuantity, action.companyId);
    const data = response.data.cardsQuantity;
    yield put(setCardQuantity(data));
  } catch (error) {
    console.log(error);
  }
}
