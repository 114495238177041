import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import Link from "./Link";
import styled from "@emotion/styled";

const LabelContainer = styled.span`
  display: flex;
  align-items: center;
`;

const LinkStyled = styled(Link)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const BackButton = ({ label, backUrl, replace, onBack }) => {
  const navigate = useNavigate();
  const handleBack = () => {
    if (!backUrl) {
      navigate(-1, { replace });
    } else {
      navigate(backUrl, { replace });
    }
    onBack();
  };
  return (
    <LinkStyled size="medium" variant="secondary" onClick={handleBack}>
      <ArrowBackIcon />
      <LabelContainer>{label}</LabelContainer>
    </LinkStyled>
  );
};

BackButton.propTypes = {
  label: PropTypes.string,
  backUrl: PropTypes.string,
  replace: PropTypes.bool,
  onBack: PropTypes.func,
};

BackButton.defaultProps = {
  label: "Retroceder",
  backUrl: "",
  replace: false,
  onBack: () => {},
};

export default BackButton;
