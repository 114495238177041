import styled from "@emotion/styled";
import { DialogActions, DialogContent, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Alert from "../../common/atoms/Alert";
import Button from "../../common/atoms/Button";
import CounterTextStyled from "../../common/atoms/CounterTextStyled";
import Flex from "../../common/atoms/Flex";
import Typography from "../../common/atoms/Typography";
import RoleSelector from "./RoleSelector";

const CustomTextField = styled(TextField)`
  width: 48%;
  margin: 6px 0px;
  @media (max-width: 425px) {
    width: 100%;
  }
`;

const CustomDialogContent = styled(DialogContent)`
  padding: 8px 24px 8px 24px;
  overflow: auto;
  max-height: 640px;
  position: relative;
`;

const CustomDialogActions = styled(DialogActions)`
  margin-top: 4px;
  border-top: 1px solid #eaeaea;
`;

const ManualAddUserForm = ({
  companyId,
  closeDialog,
  saveEmployees,
  serverErrorMessage,
  refreshServerError,
  refreshUsersCreated,
  roleNames,
  usersCreated,
}) => {
  const initialEmployee = {
    name: "",
    lastname: "",
    email: "",
    phone: "",
    role: "",
    job: "",
    company: companyId,
  };

  const [newEmployee, setNewEmployee] = useState(initialEmployee);
  const [employeesArray, setEmployeesArray] = useState([]);
  const [employeesFormatted, setEmployeesFormatted] = useState([]);
  const [errors, setErrors] = useState({});
  const [selectorError, setSelectorError] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const refreshAlertErrors = () => {
    setErrorMessage("");
    refreshServerError();
  };

  const refreshAddUserFormErrors = () => {
    setErrorMessage("");
    setNewEmployee(initialEmployee);
    setErrors({});
    setSelectorError("");
  };

  const handleCloseDialog = () => {
    refreshAddUserFormErrors();
    refreshAlertErrors();
    closeDialog();
  };

  const columns = [
    {
      name: "#",
      maxWidth: "30px",
      selector: (row) => row.counter,
      sortable: true,
      cell: (row) => <CounterTextStyled>{row.counter}</CounterTextStyled>,
    },

    {
      name: "Nombre",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Apellido",
      selector: (row) => row.lastname,
      sortable: true,
    },
    {
      name: "Correo",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Telefono",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Rol",
      selector: (row) => row.role,
      sortable: true,
    },
    {
      name: "Puesto de trabajo",
      selector: (row) => row.job,
      sortable: true,
    },
    {
      name: "",
      button: true,
      cell: (row) => (
        <Button
          variant="secondary"
          size="small"
          onClick={() => {
            onDelete(row);
          }}
        >
          Borrar
        </Button>
      ),
    },
  ];

  const handleChange = (name, value) => {
    if (errors[name] !== "") {
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
    setNewEmployee((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleValidation = () => {
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/;
    const nameRegex =
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '.'-]+$/;
    const errors = {};
    let isValid = true;

    if (!newEmployee.name.trim()) {
      errors.name = "Ingrese un nombre";
      isValid = false;
    } else if (!nameRegex.test(newEmployee.name)) {
      errors.name = "Ingrese un nombre válido";
      isValid = false;
    }
    if (!newEmployee.lastname.trim()) {
      errors.lastname = "Ingrese un apellido";
      isValid = false;
    } else if (!nameRegex.test(newEmployee.lastname)) {
      errors.lastname = "Ingrese un apellido válido";
      isValid = false;
    }

    if (!newEmployee.email) {
      errors.email = "Ingrese un correo";
      isValid = false;
    } else if (!emailRegex.test(newEmployee.email)) {
      errors.email = "Ingrese un correo válido";
      isValid = false;
    }
    if (!newEmployee.phone) {
      errors.phone = "Ingrese un teléfono";
      isValid = false;
    } else {
      let phoneText = newEmployee.phone.toString();
      if (!phoneText.startsWith("9")) {
        errors.phone = "El teléfono debe comenzar con 9";
        isValid = false;
      } else if (newEmployee.phone.length !== 9) {
        errors.phone = "El teléfono debe tener 9 dígitos";
        isValid = false;
      }
    }
    if (!newEmployee.job.trim()) {
      errors.job = "Ingrese el puesto de trabajo";
      isValid = false;
    }
    if (!newEmployee.role) {
      let error = "Debe seleccionar un rol";
      setSelectorError(error);
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSelection = (roleValue) => {
    if (roleValue) {
      setSelectorError("");
    }
    setNewEmployee((prevState) => ({
      ...prevState,
      role: roleValue,
    }));
  };

  const addEmployee = () => {
    setErrorMessage("");
    const isValid = handleValidation();
    if (!isValid) return;
    const array = [...employeesArray];
    array.push(newEmployee);
    setEmployeesArray(array);
    setNewEmployee(initialEmployee);
  };

  const onDelete = (data) => {
    let newEmployeesArray = [...employeesArray];
    newEmployeesArray.splice(data.id - 1, 1);

    setEmployeesArray(newEmployeesArray);
    refreshAlertErrors();
  };

  const save = async () => {
    if (employeesArray.length === 0) {
      setErrorMessage("Debe añadir al menos un empleado.");
      return;
    }
    refreshAlertErrors();
    await saveEmployees(employeesArray, "manual");
    if (usersCreated) {
      setEmployeesArray([]);
      refreshUsersCreated();
    }
  };

  useEffect(() => {
    let newList = employeesArray.map((employee, index) => {
      const formattedRole = roleNames[employee.role];
      return {
        ...employee,
        counter: index + 1,
        role: formattedRole,
        id: index + 1,
      };
    });
    setEmployeesFormatted(newList);
  }, [employeesArray]);
  return (
    <div>
      <CustomDialogContent>
        <Typography variant="xs" weight="light">
          Ingresa la dirección de correo electrónico de las personas para
          invitarlas a unirse a tu negocio. Luego asígnales el acceso,
          automáticamente envíaremos un email a cada uno de los correos
          agregados con un link de acceso.
        </Typography>

        <div style={{ height: "16px" }}></div>
        <form id="user-form">
          <Typography variant="small" weight="bold">
            Ingresar nuevo usuario
          </Typography>
          <Flex flexWrap="wrap" justifyContent="space-between">
            <CustomTextField
              type="text"
              name="name"
              fullWidth
              value={newEmployee.name}
              label="Nombre"
              error={!!errors.name}
              helperText={errors.name}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              variant="outlined"
            />

            <CustomTextField
              type="text"
              value={newEmployee.lastname}
              fullWidth
              name="lastname"
              label="Apellido"
              error={!!errors.lastname}
              helperText={errors.lastname}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              variant="outlined"
            />

            <CustomTextField
              type="email"
              name="email"
              fullWidth
              value={newEmployee.email}
              label="Email"
              error={!!errors.email}
              helperText={errors.email}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              variant="outlined"
            />

            <CustomTextField
              type="number"
              name="phone"
              fullWidth
              value={newEmployee.phone}
              label="Teléfono"
              error={!!errors.phone}
              helperText={errors.phone}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              variant="outlined"
            />

            <CustomTextField
              type="text"
              fullWidth
              value={newEmployee.job}
              name="job"
              label="Puesto de trabajo"
              error={!!errors.job}
              helperText={errors.job}
              style={{ width: "100%" }}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              variant="outlined"
            />
          </Flex>

          <div style={{ height: "16px" }}></div>
          <div>
            <RoleSelector
              currentValue={newEmployee.role}
              customError={selectorError}
              onChange={handleSelection}
            />

            <Flex alignItems="center" justifyContent="center" my="4">
              <Button
                type="button"
                variant="primary"
                size="medium"
                onClick={addEmployee}
              >
                Añadir Usuario
              </Button>
            </Flex>
          </div>

          {errorMessage && <Alert type={"error"}>{errorMessage}</Alert>}
          {serverErrorMessage && (
            <Alert type={"error"}>{serverErrorMessage}</Alert>
          )}
          <DataTable
            noDataComponent="No se han añadido empleados."
            columns={columns}
            data={employeesFormatted}
          />
        </form>
      </CustomDialogContent>

      <CustomDialogActions>
        <Button
          onClick={handleCloseDialog}
          type="button"
          size="medium"
          variant="cancel"
        >
          Cancelar
        </Button>
        <Button onClick={save} type="button" variant="primary" size="medium">
          Guardar
        </Button>
      </CustomDialogActions>
    </div>
  );
};

export default ManualAddUserForm;
