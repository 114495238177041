import styled from "@emotion/styled";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../auth-management/context/AuthContext";
import ProfileSelector from "./ui/ProfileSelector";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Typography from "../common/atoms/Typography";
import { IconButton } from "@material-ui/core";
import MenuIcon from "@mui/icons-material/Menu";
import { setMobileMenuOpen } from "../redux/store/companies/actions/mobileMenuOpenActions";
import { clearStore } from "../redux/store/common/actions/commonActions";

const MOBILE_BREAKPOINT_WIDTH = 425;

const NavBarContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  align-items: center;
  background: white;
`;

const MenuButtonStyled = styled(IconButton)`
  &.MuiButtonBase-root {
    display: block;
    @media (min-width: 425px) {
      display: none;
    }
  }
`;

export const TopNavBar = ({ user }) => {
  const { navbarTitle } = useContext(AuthContext);
  const [windowSize, setWindowSize] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const detectSize = () => {
    setWindowSize({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    if (windowSize && windowSize.winWidth >= MOBILE_BREAKPOINT_WIDTH) {
      dispatch(setMobileMenuOpen(false));
    }
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowSize]);

  const handleLogout = () => {
    dispatch(clearStore());
    localStorage.clear();
    navigate("/");
  };

  const goToProfile = () => {
    navigate("/configuration");
  };

  const onMenuClicked = () => {
    dispatch(setMobileMenuOpen(true));
  };

  return (
    <NavBarContainer>
      <MenuButtonStyled
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={onMenuClicked}
      >
        <MenuIcon />
      </MenuButtonStyled>
      <Typography variant="h5" weight="bold">
        <i>{navbarTitle}</i>
      </Typography>
      <ProfileSelector
        user={user}
        logout={handleLogout}
        goToProfile={goToProfile}
      />
    </NavBarContainer>
  );
};
