import axiosInstance from "../../common/helpers/axios";

let tokenString = null;

export const CompanyService = {
  setToken: (token) => {
    tokenString = `Bearer ${token}`;
  },
  saveCompany: async (newBody) => {
    return axiosInstance.post(`/company`, newBody);
  },
  editCompany: async (companyId, body) => {
    return axiosInstance.put(`/company/${companyId}`, body);
  },
  editUrl: async (companyId, newUrl) => {
    return axiosInstance.put(`/company/${companyId}/path-key`, {
      newUrl,
    });
  },
  updateColors: async (companyId, colors) => {
    return axiosInstance.put(`/company/${companyId}/colors`, { colors });
  },
};
