import {
  LOG_IN,
  LOG_OUT,
  SET_LOGGED_USER,
  SET_AUTH_USER,
  UPDATE_LOGGED_USER,
} from "../action-types/authActionTypes";

export const login = ({ email, password }) => {
  return {
    type: LOG_IN,
    payload: {
      email,
      password,
    },
  };
};

export const setAuthUser = ({ token, employee }) => {
  return {
    type: SET_AUTH_USER,
    payload: {
      token,
      loggedUser: employee,
    },
  };
};

export const setLoggedUser = (employee) => {
  return {
    type: SET_LOGGED_USER,
    payload: employee,
  };
};

export const logout = () => {
  return {
    type: LOG_OUT,
  };
};

export const updateLoggedUser = (employee) => {
  return {
    type: UPDATE_LOGGED_USER,
    payload: employee,
  };
};
