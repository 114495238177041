import { MAX_PHONE_DIGITS } from "../../common/helpers/form.utils";

export const phoneValidationWithCountryExtension = (v, country) => {
  if (country === "PE") {
    return v.length > MAX_PHONE_DIGITS[country].max ||
      v.length < MAX_PHONE_DIGITS[country].min
      ? `El teléfono debe contener: Mínimo ${MAX_PHONE_DIGITS[country].min} dígitos, Máximo ${MAX_PHONE_DIGITS[country].max} dígitos`
      : "";
  } else {
    return v.length > 15 || v.length < 6
      ? `El teléfono debe contener: Mínimo 6 dígitos, Máximo 15 dígitos`
      : "";
  }
};

export const CATEGORIES = {
  BASE_INFORMATION: "baseInformation",
  GENERAL_CONTACTS: "generalContacts",
  SOCIAL_NETWORKS: "socialNetworks",
  INSTITUTIONAL_DATA: "institutionalData",
};

export const DEFAULT_COUNTRY = "PE";

export const COUNTRIES = {
  AR: {
    primary: "Argentina",
    secondary: "+54",
  },
  BO: {
    primary: "Bolivia",
    secondary: "+591",
  },
  CO: {
    primary: "Colombia",
    secondary: "+57",
  },
  EC: {
    primary: "Ecuador",
    secondary: "+593",
  },
  MX: {
    primary: "México",
    secondary: "+52",
  },
  CR: {
    primary: "Costa Rica",
    secondary: "+506",
  },
  CL: {
    primary: "Chile",
    secondary: "+56",
  },
  PE: {
    primary: "Perú",
    secondary: "+51",
  },
  SV: {
    primary: "El Salvador",
    secondary: "+503",
  },
  PY: {
    primary: "Paraguay",
    secondary: "+595",
  },
  UY: {
    primary: "Uruguay",
    secondary: "+598",
  },
  VE: {
    primary: "Venezuela",
    secondary: "+58",
  },
  US: {
    primary: "Estados Unidos",
    secondary: "+1",
  },
};

export const simpleFieldSchema = {
  placeholder: "",
  type: "",
  value: "",
  textarea: false,
  validation: (value) => {
    if (!value) {
      return "Este campo es requerido";
    } else {
      return "";
    }
  },
  extra: false,
  disabled: false,
};

export const socialNetworkFieldSchema = {
  placeholder: "",
  type: "",
  value: "",
  validation: (value) => {
    if (!value) {
      return "El enlace es requerido";
    } else {
      return "";
    }
  },
  extra: false,
  disabled: false,
  baseUrl: "",
};

export const fileFieldSchema = {
  placeholder: "",
  type: "file",
  acceptedType: "application/pdf",
  value: "",
  externalUrl: "",
  validation: () => {},
  extra: false,
  disabled: false,
};

export const multipleFieldSchema = {
  valuePlaceholder: "",
  tagPlaceholder: "",
  type: "",
  value: "",
  tag: "",
  valueDisabled: false,
  tagDisabled: false,
  valueValidation: () => {},
  tagValidation: () => {},
  extra: false,
};

export function getFieldSchema(category, name) {
  if (category === CATEGORIES.INSTITUTIONAL_DATA) {
    const field = { ...simpleFieldSchema };

    field.placeholder = "Nº Colegiatura";
    field.validation = (v) => {
      if (v || v !== "") {
        if (v.length < 4) {
          return `Se requieren más de 3 dígitos.`;
        }
        if (v.length > 6) {
          return `Se permiten hasta 6 dígitos.`;
        }
        return "";
      }
    };
    field.extra = false;
    return field;
  }
  if (category === CATEGORIES.GENERAL_CONTACTS) {
    if (name === "phones") {
      const field = { ...multipleFieldSchema };
      field.type = "number";
      field.valuePlaceholder = "Llamadas";
      field.tagPlaceholder = "Etiqueta";
      field.extra = true;
      field.extension = "+51";
      field.country = "PE";
      (field.valueValidation = (v, country) => {
        if (!v) {
          return "El teléfono es requerido";
        } else if (country !== "") {
          return phoneValidationWithCountryExtension(v, country);
        } else {
          return "";
        }
      }),
        (field.tagValidation = (v) => {
          return v ? "" : "El teléfono debe tener una etiqueta";
        });
      return field;
    }
    if (name === "email") {
      const field = { ...simpleFieldSchema };
      field.type = "text";
      field.placeholder = "Contacto por correo";
      field.validation = (v) => {
        if (!v) {
          return "El correo es requerido";
        } else {
          return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/.test(v)
            ? ""
            : "El correo es inválido";
        }
      };
      return field;
    }
    if (name === "address") {
      const field = { ...simpleFieldSchema };
      field.placeholder = "Ubicación de dirección";
      field.validation = (v) => {
        if (!v) {
          return "La dirección es requerida";
        } else {
          return "";
        }
      };
      field.extra = true;
      return field;
    }
    if (name === "webpage") {
      const field = { ...simpleFieldSchema };
      field.placeholder = "Visita a la web empresa";
      field.validation = (v) => {
        if (!v) {
          return "El enlace a su página web es requerido";
        } else {
          return /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
            v
          )
            ? ""
            : "El enlace es inválido.";
        }
      };
      field.extra = true;
      return field;
    }
    if (name === "webpages") {
      const field = { ...fileFieldSchema };
      field.placeholder = "+ Webs";
      field.extra = true;
      return field;
    }
    if (name === "pdf") {
      const field = { ...fileFieldSchema };
      field.placeholder = "Visualización de PDF";
      field.validation = (v, url) => {
        if (!v) {
          return url === "" ? "La presentación de empresa es requerida" : "";
        } else {
          if (!v.type) return "";
          return v.type.localeCompare("application/pdf") !== 0
            ? "El archivo debe tener formato .pdf"
            : "";
        }
      };
      field.extra = true;
      return field;
    }
    if (name === "businesspresentations") {
      const field = { ...fileFieldSchema };
      field.placeholder = "Visualización de vídeo";
      field.extra = true;
      return field;
    }
    if (name === "addcontact") {
      const field = { ...fileFieldSchema };
      field.placeholder = "Contacto añadido";
      field.extra = true;
      return field;
    }
  }

  if (category === CATEGORIES.SOCIAL_NETWORKS) {
    const field = { ...socialNetworkFieldSchema };
    field.extra = true;
    const socialNetworkInfo = getSocialNetworkInfoByName(name);
    field.placeholder = socialNetworkInfo.placeholder;
    field.baseUrl = socialNetworkInfo.baseUrl;
    field.validation = (v) => {
      if (!v) {
        return `El enlace a ${socialNetworkInfo.placeholder} es requerido`;
      }
      return "";
    };
    return field;
  }
}

export function isEmptyCardFormControl(formControl) {
  const type = formControl.type;
  if (!type) {
    return !formControl.value;
  }
  if (type === "text") {
    return !formControl.value;
  }
  if (type === "file") {
    return !(formControl.url || formControl.value || formControl.externalUrl);
  }
}

export function getEventNameByKey(key) {
  if (!key) return "";

  return `CLICK_${key.toUpperCase()}_BUTTON`;
}

export function getSocialNetworkInfoByName(name) {
  switch (name) {
    case "whatsapp":
      return {
        baseUrl: "wa.me/",
        placeholder: "WhatsApp",
      };
    case "linkedin":
      return {
        baseUrl: "linkedin.com/in/",
        placeholder: "Linkedin",
      };
    case "whatsappBusiness":
      return {
        baseUrl: "wa.me/message/",
        placeholder: "WhatsApp Business",
      };
    case "linkedinBusiness":
      return {
        baseUrl: "linkedin.com/company/",
        placeholder: "Linkedin Business",
      };
    case "facebook":
      return {
        baseUrl: "facebook.com/",
        placeholder: "Facebook",
      };

    case "instagram":
      return {
        baseUrl: "instagram.com/",
        placeholder: "Instagram",
      };

    case "twitter":
      return {
        baseUrl: "twitter.com/",
        placeholder: "Twitter",
      };
    case "behance":
      return {
        baseUrl: "behance.net/",
        placeholder: "Behance",
      };
    case "snapchat":
      return {
        baseUrl: "snapchat.com/add/",
        placeholder: "Snapchat",
      };
    case "youtube":
      return {
        baseUrl: "youtube.com/channel/",
        placeholder: "Youtube",
      };
    case "vimeo":
      return {
        baseUrl: "vimeo.com/",
        placeholder: "Vimeo",
      };
    case "tiktok":
      return {
        baseUrl: "tiktok.com/@",
        placeholder: "Tik tok",
      };

    case "twitch":
      return {
        baseUrl: "twitch.tv/",
        placeholder: "Twitch",
      };

    case "telegram":
      return {
        baseUrl: "t.me/",
        placeholder: "Telegram",
      };
    case "wechat":
      return {
        baseUrl: "wechat.com/es/",
        placeholder: "Wechat",
      };
    case "signal":
      return {
        baseUrl: "signal.org/es/download/",
        placeholder: "Signal",
      };
    case "github":
      return {
        baseUrl: "github.com/",
        placeholder: "Github",
      };
    case "paypal":
      return {
        baseUrl: "paypal.com/",
        placeholder: "Paypal",
      };

    default: {
      return {
        baseUrl: "",
        placeholder: "",
      };
    }
  }
}
