import styled from "@emotion/styled";
import theme from "../../common/theme";
import Button from "../../common/atoms/Button";

const QrButton = styled(Button)`
  display: flex;
  gap: 10px;
  align-items: center;
  border-radius: 50%;
  border: 3px solid white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 8px;
  background-color: white;
  color: ${theme.colors.white};

  padding-inline: 15px;
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.3s;
  transition-property: transform;
  &:hover,
  &:active,
  &:focus {
    transform: scale(0.9);
  }
`;

const VcfQrButton = ({ openQR, setOpenQR }) => {
  const handleOpenQR = () => {
    setOpenQR(!openQR);
  };

  return (
    <QrButton openQR={openQR} setOpenQR={setOpenQR} onClick={handleOpenQR}>
      <i
        className="bi bi-qr-code"
        style={{
          fontSize: "1.6rem",
          color: "#000",
        }}
      />
    </QrButton>
  );
};

export default VcfQrButton;
