import styled from "@emotion/styled";
import BaseCard from "../../common/atoms/BaseCard";
import Typography from "../../common/atoms/Typography";

const AddCardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto auto;
`;

export default function AddCardButton({ onClick }) {
  const handleClick = () => {
    onClick();
  };

  return (
    <BaseCard onClick={handleClick}>
      <AddCardBody>
        <img
          style={{ width: "70px", height: "56px", marginBottom: "12px" }}
          src={require("../assets/img/create-card-icon.png")}
        />
        <Typography variant="tiny" weight="bold">
          Añadir tarjeta
        </Typography>
      </AddCardBody>
    </BaseCard>
  );
}
