import { RatingService } from "../../business-card-management/services/RatingService";
import { AsyncPaginate } from "react-select-async-paginate";
import theme from "../../common/theme";

const CustomStyles = {
  control: (provided) => ({
    ...provided,
    border: "none",
    borderRadius: "10px",
    boxShadow: "none",
    width: "100%",
    padding: "0px 10px",
    minHeight: "50px",
    "&:hover": {
      border: "none",
    },
  }),
  input: (provided) => ({
    ...provided,
    margin: "0px",
    fontSize: "1rem",
    fontFamily: "Lato",
    "@media (max-width: 768px)": {
      fontSize: "0.8rem",
    },
    "&:placeholder": {
      color: theme.colors.whiteLilac,
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    background: theme.colors.azureRadiance,
    borderRadius: "10px",
    padding: "0px 5px",
    color: "white",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "white",
    paddingBottom: "0.4rem",
  }),
};

const ReferralsMultiSelect = ({ companyId, setSelectedReferralCards }) => {
  const loadOptions = async (searchQuery, prevLoadedOptions, { page }) => {
    try {
      const response = await RatingService.getReferralsByCompanyId(
        companyId,
        page,
        searchQuery
      );
      const referralNames = response.data;
      const formattedOptions = referralNames.referralNames.map((nameObj) => {
        return {
          label: `${nameObj.name} ${nameObj.lastname}`,
          value: nameObj._id,
        };
      });
      const hasMore = formattedOptions.length > prevLoadedOptions.length;
      return {
        options: formattedOptions,
        hasMore: hasMore,
        additional: {
          page: searchQuery ? 2 : page + 1,
        },
      };
    } catch (error) {
      console.error("Error al obtener los datos:", error);
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <AsyncPaginate
        onChange={setSelectedReferralCards}
        styles={CustomStyles}
        placeholder="Selecciona usuarios"
        isSearchable
        loadOptions={loadOptions}
        isMulti
        additional={{
          page: 1,
        }}
      />
    </div>
  );
};

export default ReferralsMultiSelect;
