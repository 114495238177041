import styled from "@emotion/styled";
import { TextField } from "@mui/material";
import { useEffect, useState } from "react";

import CardContainer from "../../common/atoms/CardContainer";
import { CompanyService } from "../services/CompanyServices";
import { LogoUploadInput } from "../../common/molecules/LogoUploadInput";
import { FileServices } from "../../common/services/FileServices";
import * as React from "react";

import { EditIcon } from "../../common/atoms/SvgIcons";
import { updateCurrentCompany } from "../../redux/store/companies/actions/currentCompanyActions";
import { useDispatch, useSelector } from "react-redux";
import EditFieldDialog from "../../common/molecules/EditFieldDialog";
import Typography from "../../common/atoms/Typography";

const CompanyContainer = styled(CardContainer)``;

const EditIconContainer = styled.div`
  margin: 8px 0px 0px 4px;
  cursor: pointer;
`;

const LogoInformation = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 480px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const UploadLogoContainer = styled.div``;

const LogoTipText = styled(Typography)`
  padding-left: 40px;
  text-align: left;
  @media (max-width: 480px) {
    padding: 0;
    text-align: center;
    line-height: 20px;
  }
`;

const FormContainer = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 24px 0px 0px 0px;
  @media (max-width: 480px) {
    & > div {
      width: 100%;
    }
  }
`;

const TextFieldContainer = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

export function CompanyProfileForm({ loggedUser, token }) {
  const dispatch = useDispatch();
  const currentCompany = useSelector((state) => state.currentCompany);
  useEffect(() => {
    CompanyService.setToken(token);
  });

  const [formFields, setFormFields] = useState({
    name: {
      value: currentCompany.name,
      placeholder: "Nombre de la empresa",
      validation: (v) => {
        if (!v) return "Debe ingresar el nombre de la empresa";
        return "";
      },
      description: "Escribe el nombre de tu empresa",
      open: false,
      type: "text",
    },
    mainEmail: {
      value: currentCompany.mainEmail,
      placeholder: "Correo",
      description: "Escribe el correo de tu empresa",
      validation: (v) => {
        if (!v) return "Debe ingresar el email";
        return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/.test(v)
          ? ""
          : "El correo es inválido";
      },
      open: false,
      type: "text",
    },
    phone: {
      value: currentCompany.phone,
      placeholder: "Teléfono",
      description: "Escribe el teléfono de tu empresa",
      validation: (v) => {
        if (!v) return "El teléfono es requerido";
        let phoneText = v.toString();
        if (phoneText.length > 18 || phoneText.length < 12) {
          return "El número no és válido";
        }
        return "";
      },
      open: false,
      type: "text",
    },
    address: {
      value: currentCompany.address,
      placeholder: "Dirección",
      description: "Escribe la dirección de tu empresa",
      validation: (v) => {
        if (!v) return "La dirección es requerida";
        return "";
      },
      open: false,
      type: "text",
    },
    webUrl: {
      value: currentCompany.webUrl,
      placeholder: "Página web",
      description: "Escribe el enlace a la página web de tu empresa",
      validation: (v) => {
        if (!v) {
          return "El enlace a su página web es requerido";
        } else {
          return /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
            v
          )
            ? ""
            : "El enlace es inválido.";
        }
      },
      open: false,
      type: "text",
    },
  });

  const logoUpload = async (logoFile) => {
    if (currentCompany.logoUrl !== undefined || currentCompany.logoUrl !== "") {
      if (logoFile) {
        let url = "";
        try {
          let filename = `${logoFile.name}-${new Date().getTime()}`;
          let reference = FileServices.getReference("image/*", filename);
          url = await FileServices.uploadFile(logoFile, reference);
          await updateCompany("logoUrl", url);
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const updateCompany = async (name, value) => {
    const body = {
      [name]: value,
    };
    try {
      const response = await CompanyService.editCompany(
        currentCompany.id,
        body
      );

      if (response.status === 200 && response.data.updatedCompany) {
        dispatch(updateCurrentCompany(response.data.updatedCompany));
        if (name !== "logoUrl") {
          const newObj = { ...formFields[name], value: value };
          setFormFields((prev) => ({
            ...prev,
            [name]: newObj,
          }));
          closeEdition(name);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const openEdition = (name) => {
    setFormFields((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        open: true,
      },
    }));
  };

  const closeEdition = (name) => {
    setFormFields((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        open: false,
      },
    }));
  };

  return (
    <CompanyContainer
      headerTitle="Configura tu negocio"
      headerTextAlign="start"
    >
      <LogoInformation>
        <UploadLogoContainer>
          <LogoUploadInput
            onHandleImage={logoUpload}
            currentLogoUrl={currentCompany.logoUrl}
            formType="company"
          />
        </UploadLogoContainer>
        <LogoTipText variant="small" weight="light">
          Sube la foto de Perfil de tu empresa
          <br /> 512*512 px
        </LogoTipText>
      </LogoInformation>
      <FormContainer>
        {Object.keys(formFields).map((fieldName) => {
          const field = formFields[fieldName];
          return (
            <div key={fieldName}>
              <TextFieldContainer>
                <TextField
                  autoFocus={true}
                  fullWidth
                  name={fieldName}
                  value={field.value}
                  variant="standard"
                  type={field.type}
                  label={field.placeholder}
                  disabled={true}
                />
                {(loggedUser.role === "ADMIN_FOUNDER" ||
                  (fieldName !== "company" && loggedUser.role === "ADMIN")) && (
                  <EditIconContainer onClick={() => openEdition(fieldName)}>
                    <EditIcon />
                  </EditIconContainer>
                )}
              </TextFieldContainer>
              <EditFieldDialog
                fieldName={fieldName}
                fieldType={field.type}
                initialValue={field.value}
                fieldLabel={field.placeholder}
                headerTitle={field.placeholder}
                descriptionText={field.description}
                validationFunction={field.validation}
                open={field.open}
                onClose={closeEdition}
                save={updateCompany}
              />
            </div>
          );
        })}
      </FormContainer>
    </CompanyContainer>
  );
}
