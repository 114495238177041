import axiosInstance from "../../common/helpers/axios";

export const RatingService = {
  saveRating: async (ratingBody) => {
    return axiosInstance.post(`/rating`, ratingBody);
  },

  getPersonalCollection: (businessCardId, search) => {
    return axiosInstance.get(`/rating/personal/collection`, {
      params: {
        businessCardId,
        search,
      },
    });
  },

  getPersonalCollectionReport: (businessCardId, search) => {
    return axiosInstance.get(`/rating/personal/collection/report`, {
      params: {
        businessCardId,
        search,
      },
      responseType: "text",
    });
  },

  getGeneralCollectionByCompany: (
    companyId,
    search,
    referralCardsIds = [],
    startDate,
    endDate
  ) => {
    return axiosInstance.post(
      `/rating/general/collection`,
      { referralCardsIds, startDate, endDate },
      {
        params: {
          companyId,
          search,
        },
      }
    );
  },

  getGeneralCollectionByCompanyReport: (
    companyId,
    search,
    referralCardsIds = [],
    startDate,
    endDate
  ) => {
    return axiosInstance.post(
      `/rating/general/collection/report`,
      { referralCardsIds, startDate, endDate },
      {
        params: {
          companyId,
          search,
        },
        responseType: "text",
      }
    );
  },

  getGeneralCollectionByCard: (businessCardId, search) => {
    return axiosInstance.post(`/rating/general/collection`, {}, {
      params: {
        businessCardId,
        search,
      },
    });
  },

  getGeneralCollectionByCardReport: (businessCardId, search) => {
    return axiosInstance.post(`/rating/general/collection/report`, {}, {
      params: {
        businessCardId,
        search,
      },
      responseType: "text",
    });
  },

  getReferralsByCompanyId: (companyId, page = 1, search = "") => {
    return axiosInstance.get(`/rating/general/collection/referral-names`, {
      params: {
        companyId,
        page,
        search,
      },
    });
  },
};
