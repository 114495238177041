import mobileMenuOpenActionTypes from "../action-types/mobileMenuOpenActionTypes";

const { SET_MOBILE_MENU_OPEN } = mobileMenuOpenActionTypes;
const initialState = false;

const mobileMenuOpenReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MOBILE_MENU_OPEN:
      return action.payload;

    default:
      return state;
  }
};

export default mobileMenuOpenReducer;
