import React, { useState, useEffect } from "react";
import Button from "../../common/atoms/Button";
import { MonitoringService } from "../../statistics/services/MonitoringService";
import AuthService from "../../auth-management/services/AuthService";
import { CustomDialog } from "../../common/atoms/Dialog";
import Typography from "../../common/atoms/Typography";
import { toast } from "react-toastify";

export const ReplaceStatisticsDialog = ({
  currentCardId,
  currentCardData,
  selectedCard,
  showDialog,
  closeDialog,
}) => {
  const [transferEventCount, setTransferEventCount] = useState(0);
  const [companyId, setCompanyId] = useState(
    currentCardData.baseInformation.company.id
  );
  //const companyId = currentCardData.baseInformation.company.id;

  const getTransferEventCount = async (companyId) => {
    let company = await AuthService.getCompanyById(companyId);
    return company.company._doc.transferEventCount || 0;
  };
  const getSimpleName = (name = "", lastname = "") => {
    const names = name.split(" ");
    const lastnames = lastname.split(" ");
    return `${names[0]} ${lastnames[0]}`;
  };
  const formattedCurrentCardData = getSimpleName(
    currentCardData.baseInformation.name,
    currentCardData.baseInformation.lastname
  );
  const formattedSelectedCardData = getSimpleName(
    selectedCard.baseInformation.name,
    selectedCard.baseInformation.lastname
  );

  useEffect(() => {
    const fetchData = async () => {
      const count = await getTransferEventCount(companyId);
      setTransferEventCount(count);
    };
    fetchData();
  }, [companyId]);

  const transferStaticalData = async () => {
    try {
      const response = await MonitoringService.transferEvents(
        companyId,
        selectedCard.id,
        currentCardId
      );
      toast.success(response.data.message, {
        position: "bottom-center",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "bottom-center",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } finally {
      closeDialog();
    }
  };

  return (
    <CustomDialog
      showDialog={showDialog}
      closeDialog={closeDialog}
      sx={{
        ".MuiPaper-root": {
          borderRadius: "15px",
        },
      }}
    >
      <div>
        <Typography variant="h5">
          Intentos disponibles ({transferEventCount}/2)
        </Typography>
        <br />
        <Typography variant="small">
          Los datos estadísticos de {formattedCurrentCardData} serán
          reemplazados por los de {formattedSelectedCardData}
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Button
            size="medium"
            variant="primary"
            onClick={transferStaticalData}
            style={{ marginRight: "10px" }}
          >
            Aceptar
          </Button>
          <Button
            size="medium"
            variant="secondary"
            onClick={closeDialog}
            style={{ marginLeft: "10px" }}
          >
            Declinar
          </Button>
        </div>
      </div>
    </CustomDialog>
  );
};

export default ReplaceStatisticsDialog;
