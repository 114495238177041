import styled from "@emotion/styled";
import theme from "../theme";
import { css } from "@emotion/react";
import { useState, useEffect } from "react";

const errorStyled = css`
  input {
    border-color: ${theme.colors.pastelRed};
    &:focus {
      border-color: ${theme.colors.pastelRed};
    }
  }
  p {
    color: ${theme.colors.pastelRed} !important;
  }
`;

const disabledStyled = css`
  input {
    background-color: white;
    border-color: ${theme.colors.alto};
    color: ${theme.colors.silverChalice};
    cursor: not-allowed;
  }
  p {
    color: ${theme.colors.silverChalice} !important;
    cursor: not-allowed;
  }
`;

const inputNotFocus = css`
  color: ${theme.colors.mineShaft};
`;

const removeMargin = css`
  margin: 0;
`;

const fullWidth = css`
  width: 100%;
`;

const Input = styled.div`
  position: relative;
  margin: 20px 0;
  ${(props) => props.removeMargin && removeMargin}
  label {
    position: relative;
    ${(props) => props.fullWidth && fullWidth}
    input {
      font-family: "Lato", sans-serif;
      font-size: 16px;
      color: ${theme.colors.mineShaft};
      background: transparent;
      padding: 0.7rem 1.2rem 0.7rem 1.2rem;
      border-radius: 10px;
      border: 1px solid ${theme.colors.mineShaft};
      transition: border-color 0.5s;
      ${(props) => props.fullWidth && fullWidth}
      &:focus {
        border: 1px solid ${theme.colors.azureRadiance};
        border-radius: 10px;
      }
      &:not(:focus) {
        & + p {
          color: #7a7a7a56;
        }
      }
    }
    p {
      font-family: "Lato", sans-serif;
      color: ${theme.colors.silverChalice};
      font-size: 1rem;
      font-weight: 500;
      user-select: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin-left: 0.8rem;
      padding: 0 0.4rem;
      background: white;
      transition: top 0.2s, font-size 0.2s, color 0.2s;
    }
    ${(props) => props.isDisabled && disabledStyled}
    ${(props) => props.error && errorStyled}
  }
  > i {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 35px;
    border-radius: 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: transparent;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.5s;
  }
  .icon-red {
    color: ${theme.colors.pastelRed};
    transition: color 0.5s;
    &:hover {
      color: #b20101;
    }
  }
  label input:focus + p,
  label input:not(:placeholder-shown) + p {
    top: 0;
    font-size: 0.9rem;
    color: ${theme.colors.azureRadiance};
    font-weight: 600;
  }
  label input:not(:focus) + p {
    cursor: text;
    color: ${theme.colors.silverChalice};
    ${(props) => props.haveValue && inputNotFocus}
  }
  input:focus-visible {
    outline: none;
  }
  @media screen and (max-width: 480px) {
    label {
      input {
        font-size: 14px;
      }
      p {
        font-size: 0.8rem;
      }
    }
    label input:focus + p,
    label input:not(:placeholder-shown) + p {
      font-size: 0.8rem;
    }
  }
`;

const ErrorMessage = styled.p`
  color: ${theme.colors.pastelRed};
  font-weight: 600;
  font-size: 0.9rem;
  padding-left: 0.5rem;
  margin: 0;
`;

const InputFull = (props) => {
  const handleChange = (e) => {
    props.onChange(e, props.type);
  };

  const deleteField = (e) => {
    props.deleteInputField(e, props.type, props.name);
  };

  return (
    <Input
      error={props.error}
      isDisabled={props.isDisabled}
      fullWidth={props.fullWidth}
      removeMargin={props.removeMargin}
      deleteField={props.deleteField}
      haveValue={props.value.length > 0}
      onKeyDown={props.onKeyDown}
    >
      <label>
        <input
          type={props.type}
          name={props.name}
          placeholder=" "
          disabled={props.isDisabled}
          value={props.value}
          onChange={handleChange}
          autoFocus={props.autoFocus}
        />
        <p>{props.label}</p>
      </label>
      {props.extra && (
        <i className="bi bi-trash icon-red" onClick={(e) => deleteField(e)}></i>
      )}
      {props.error && <ErrorMessage>{props.errorMessage}</ErrorMessage>}
    </Input>
  );
};

InputFull.defaultProps = {
  type: "",
  label: "",
  value: "",
  name: "",
  isDisabled: false,
  fullWidth: false,
  removeMargin: false,
  errorMessage: "",
  autoFocus: false,
};

export default InputFull;
