import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import "../assets/css/styles.css";
import { IconsContext } from "../context/IconsContext";
import { CATEGORIES, getEventNameByKey } from "./form.utils";
import { MonitoringService } from "../../statistics/services/MonitoringService";
import {
  getContactLabel,
  getContactValue,
  getSocialValue,
} from "./template.utils";
import Flex from "../../common/atoms/Flex";
import {
  CardPhotoArea,
  CardTemplateBase,
  Photo,
  LogoIcon,
  CardContent,
  ContactButtonWrapper,
  ContactButton,
  ContactText,
  StyledIcon,
  StyledTypography,
  SocialButtonWrapper,
  SocialButton,
} from "./CardTemplateBase";
import ModalQR from "./ModalQr";

const cardDefaultInfo = {
  baseInformation: {
    company: {
      name: "Empresa",
      logoUrl: require("../assets/img/default-company-logo.png"),
    },
    photo: {
      url: "",
      filename: "",
    },
    name: "Nombre",
    lastname: "",
    job: "Puesto de trabajo",
    introduction:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In lacus dui, blandit pulvinar aliquam vel, euismod ac nisi. Vivamus vitae.",
  },
  generalContacts: {
    phones: [{ value: "999999999", tag: "Teléfono" }],
    email: "test@gmail.com",
    webpage: "Página web",
    pdf: {
      url: "",
      filename: "Presentación de Empresa",
    },
  },
  socialNetworks: {
    facebook: "test",
    twitter: "test",
    instagram: "test",
    linkedin: "test",
  },
  templateId: 1,
};

const PhotoOne = styled(Photo)`
  @media (max-width: 375px) {
    height: 154px;
  }
`;

const ContactButtonOne = styled(ContactButton)`
  &:hover {
    p {
      color: white;
    }
  }
`;

function CardTemplateOne({
  mode,
  interactive,
  selectable,
  selected,
  cardInput,
  fullWidth,
  colors,
  isPreview,
  cardPathKeys,
  openQR,
  handleClose,
  phoneExtension,
}) {
  const { icons } = useContext(IconsContext);
  const [cardInfo, setCardInfo] = useState(
    mode === "static" ? cardDefaultInfo : cardInput
  );

  const getBusinessCardType = (type) => {
    return type === "personal" ? "p" : "b";
  };

  const [pathKey, setPathKey] = useState("");

  const handlePathKey = () => {
    if (!cardPathKeys) return;
    setPathKey(
      cardPathKeys.currentBusinessKey &&
        cardPathKeys.currentEmployeeKey &&
        `/${cardPathKeys.currentBusinessKey}/${cardPathKeys.currentEmployeeKey}`
    );
  };

  const baseUrl =
    process.env.REACT_APP_DOMAIN + `/${getBusinessCardType(cardInfo?.type)}`;

  useEffect(() => {
    if (mode === "dynamic") {
      setCardInfo(cardInput);
    }
    handlePathKey();
  }, [cardInput, mode, setCardInfo]);

  const getSimpleName = (name = "", lastname = "") => {
    const names = name.split(" ");
    const lastnames = lastname.split(" ");
    return `${names[0]} ${lastnames[0]}`;
  };

  const handleSocialButtonClick = (key) => {
    if (isPreview) return;

    const eventName = getEventNameByKey(key);
    MonitoringService.saveEvent(
      eventName,
      "click",
      cardInfo,
      CATEGORIES.SOCIAL_NETWORKS
    );
  };

  const handleContactButtonClick = (key) => {
    if (isPreview) return;

    const eventName = getEventNameByKey(key);
    MonitoringService.saveEvent(
      eventName,
      "click",
      cardInfo,
      CATEGORIES.GENERAL_CONTACTS
    );
  };

  return (
    <CardTemplateBase
      selectable={selectable}
      selected={selected}
      fullWidth={fullWidth}
      background={colors.primary}
    >
      <CardPhotoArea fullWidth={fullWidth} background={colors.primary}>
        <PhotoOne fullWidth={fullWidth}>
          <Flex>
            {cardInfo.baseInformation.photo.url ? (
              <img src={cardInfo.baseInformation.photo.url} />
            ) : (
              <img style={{ backgroundColor: "rgb(167, 167, 167)" }} />
            )}
          </Flex>
          <LogoIcon>
            <img src={cardInfo.baseInformation.company.logoUrl} />
          </LogoIcon>
        </PhotoOne>
      </CardPhotoArea>
      <CardContent fullWidth={fullWidth}>
        <div className="template-main-content">
          <StyledTypography variant="h5" color={colors.primaryText}>
            {cardInfo.baseInformation.name +
              " " +
              cardInfo.baseInformation.lastname}
          </StyledTypography>
          <StyledTypography
            variant="small"
            weight="bold"
            color={colors.primaryText}
          >
            {cardInfo.baseInformation.job}
          </StyledTypography>
          <StyledTypography variant="small" color={colors.primaryText}>
            <i>{cardInfo.baseInformation.company.name}</i>
          </StyledTypography>
        </div>
        <div className="template-introduction">
          <StyledTypography variant="tiny" mb="2" color={colors.primaryText}>
            {cardInfo.baseInformation.introduction}
          </StyledTypography>
        </div>
        {cardInfo.institution !== undefined && cardInfo.institution !== "" && (
          <div className="template-introduction">
            <StyledTypography variant="tiny" mb="2" color={colors.primaryText}>
              {cardInfo.institutionalData.identifier && (
                <>
                  <em>
                    <strong>Nº COLEGIATURA</strong>{" "}
                  </em>
                  {cardInfo.institutionalData.identifier
                    .toString()
                    .padStart(6, "0")}
                </>
              )}
            </StyledTypography>
          </div>
        )}
        <ModalQR
          open={openQR}
          handleClose={handleClose}
          title={getSimpleName(
            cardInfo.baseInformation.name,
            cardInfo.baseInformation.lastname
          )}
          subtitle={cardInfo.baseInformation.job}
          description={cardInfo.baseInformation.company.name}
          baseUrl={baseUrl}
          cardPathKey={pathKey}
          logoImage={cardInfo.baseInformation.company.logoUrl}
          disabled={cardInfo && cardInfo.status === "PENDING"}
          colors={cardInfo.baseInformation.company.colors}
        />
        <ContactButtonWrapper>
          {Object.keys(cardInfo.generalContacts).map((key) => {
            const field = cardInfo.generalContacts[key];
            if (!field) return;
            if (!Array.isArray(field)) {
              const label = getContactLabel(field, key);
              return (
                label &&
                (interactive ? (
                  <ContactButtonOne
                    background={colors.generalContactButton}
                    target="_blank"
                    key={key}
                    href={getContactValue(field, key)}
                    onClick={() => handleContactButtonClick(key)}
                  >
                    <ContactText
                      variant="tiny"
                      weight="bold"
                      color={colors.generalContactText}
                    >
                      {label}
                    </ContactText>
                  </ContactButtonOne>
                ) : (
                  <ContactButtonOne
                    key={key}
                    background={colors.generalContactButton}
                  >
                    <ContactText
                      variant="tiny"
                      weight="bold"
                      color={colors.generalContactText}
                    >
                      {label}
                    </ContactText>
                  </ContactButtonOne>
                ))
              );
            } else {
              {
                return field.map((element, index) => {
                  const label = getContactLabel(element, key);
                  return (
                    label &&
                    (interactive ? (
                      <ContactButtonOne
                        background={colors.generalContactButton}
                        target="_blank"
                        key={`${key}${index}`}
                        href={getContactValue(element, key)}
                        onClick={() => handleContactButtonClick(key)}
                      >
                        <ContactText
                          variant="tiny"
                          weight="bold"
                          color={colors.generalContactText}
                        >
                          {label}
                        </ContactText>
                      </ContactButtonOne>
                    ) : (
                      <ContactButtonOne
                        key={`${key}${index}`}
                        background={colors.generalContactButton}
                      >
                        <ContactText
                          variant="tiny"
                          weight="bold"
                          color={colors.generalContactText}
                        >
                          {label}
                        </ContactText>
                      </ContactButtonOne>
                    ))
                  );
                });
              }
            }
          })}
        </ContactButtonWrapper>
        <SocialButtonWrapper>
          {cardInfo.socialNetworks &&
            Object.keys(cardInfo.socialNetworks).map((key, index) => {
              const field = cardInfo.socialNetworks[key];
              if (field !== "") {
                let socialValue = "";
                if (key === "whatsapp") {
                  socialValue = getSocialValue(
                    !field.includes("+") ? `${phoneExtension}${field}` : field,
                    key
                  );
                } else {
                  socialValue = getSocialValue(field, key);
                }
                return interactive ? (
                  <SocialButton
                    target="_blank"
                    key={key}
                    href={socialValue}
                    onClick={() => handleSocialButtonClick(key)}
                    background={colors.socialNetworkButton}
                  >
                    <StyledIcon
                      className={icons[key]}
                      color={colors.socialNetworkText}
                    ></StyledIcon>
                  </SocialButton>
                ) : (
                  <SocialButton
                    key={key}
                    background={colors.socialNetworkButton}
                  >
                    <StyledIcon
                      className={icons[key]}
                      color={colors.socialNetworkText}
                    ></StyledIcon>
                  </SocialButton>
                );
              }
            })}
        </SocialButtonWrapper>
      </CardContent>
    </CardTemplateBase>
  );
}

CardTemplateOne.propTypes = {
  mode: PropTypes.oneOf(["static", "dynamic"]),
  interactive: PropTypes.bool,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  cardInput: PropTypes.object,
  fullWidth: PropTypes.bool,
  isPreview: PropTypes.bool,
};

CardTemplateOne.defaultProps = {
  mode: "static",
  interactive: false,
  selectable: false,
  selected: false,
  cardInput: null,
  fullWidth: false,
  isPreview: false,
};
export default CardTemplateOne;
