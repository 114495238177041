import { useNavigate } from "react-router-dom";
import Button from "../atoms/Button";

export function NotFound() {
  const navigate = useNavigate();
  const goToCardsPage = () => {
    navigate("/cards");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <h1>404 No se encontró</h1>
      <span>
        La ruta que ha ingresado no existe o no tiene permisos para visualizarla
      </span>
      <Button
        type="button"
        size="medium"
        onClick={goToCardsPage}
        variant="primary"
        style={{ marginTop: "12px" }}
      >
        Ir a Mis Tarjetas
      </Button>
    </div>
  );
}
