import { Divider, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import { Link, useLocation, useNavigate } from "react-router-dom";
import ArticleIcon from "@mui/icons-material/Article";
import EditIcon from "@mui/icons-material/Edit";
import GroupIcon from "@mui/icons-material/Group";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import PaymentIcon from "@mui/icons-material/Payment";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { AuthContext } from "../auth-management/context/AuthContext";
import styled from "@emotion/styled";
import { SelectButton } from "../common/molecules/SelectButton";
import { useDispatch, useSelector } from "react-redux";
import { setMobileMenuOpen } from "../redux/store/companies/actions/mobileMenuOpenActions";
import { changeAccount } from "../redux/store/accounts/actions/linkedAccountsActions";
import theme from "../common/theme";
import { useFeature } from "flagged";
import { setNoPadding } from "../redux/store/common/actions/commonActions";
import { ACCOUNT_TYPE_PERSONAL } from "../common/helpers/constants";
import { setChangeAccountLoaded } from "../redux/store/accounts/actions/changeAccountLoadedActions";

const drawerWidth = 260;
const closedDrawerWidth = 65;

const DrawerMobile = styled(Drawer)`
  display: block;
  @media (min-width: 425px) {
    display: none;
  }
`;

const DrawerDesktop = styled(Drawer)`
  display: none;
  @media (min-width: 425px) {
    display: block;
  }
`;

const CollapseButton = styled.button`
  border-radius: 5px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  position: fixed;
  margin-left: ${(props) => (props.open ? "239px" : "43px")};
  margin-top: 42px;
  background-color: white;
  border: none;
  padding: 5px;
  color: ${theme.colors.azureRadiance};
  font-weight: bold;
`;

const LogoMobile = styled.div`
  text-align: center;
  cursor: pointer;
  display: block;
  @media (min-width: 425px) {
    display: none;
  }
`;
const LogoDesktop = styled.div`
  text-align: center;
  cursor: pointer;
  display: none;
  @media (min-width: 425px) {
    display: block;
  }
`;

const CompanySubtitleMobile = styled.div`
  display: block;
  @media (min-width: 425px) {
    display: none;
  }
`;
const CompanySubtitleDesktop = styled.div`
  display: none;
  @media (min-width: 425px) {
    display: block;
  }
`;

const SubTitle = styled.p`
  color: white;
  font-family: "Lato";
  font-weight: 700;
  font-size: 14px;
  line-height: 16.8px;
  padding-left: 22px;
  padding-right: 22px;
  margin-bottom: 10px;
  margin-top: 80px;
  white-space: nowrap;
`;

const ListItemTextStyled = styled(ListItemText)`
  font-family: "Lato";
  font-weight: 500;
  color: white;
  line-height: 34px;
  opacity: 0.5;
  span {
    font-size: 14px;
  }
  &.active {
    opacity: 1;
    opacity: ${(props) => !props.open && "0"};
  }
  opacity: ${(props) => !props.open && "0"};
  @media (max-width: 425px) {
    opacity: 0.5;
    &.active {
      opacity: 1;
    }
  }
`;

const ListItemStyled = styled(ListItem)`
  padding-left: ${(props) => props.open && "22px"};
  padding-right: ${(props) => props.open && "22px"};
  white-space: ${(props) => !props.open && "nowrap"};
  &.active {
    background: none;
  }
`;

const ListItemIconStyled = styled(ListItemIcon)`
  &.MuiListItemIcon-root {
    opacity: 0.5;
    min-width: 32px;
    justify-content: center;
    &.active {
      opacity: 1;
    }
  }
`;

const LinkedAccountsSelector = styled.div`
  margin-top: auto;
`;

const LinkedAccountsSelectorMobile = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    color: rgba(255, 255, 255, 0.54);
    font-size: 28px;
    cursor: pointer;
  }
`;

const useStyles = makeStyles((theme) => {
  return {
    page: {
      background: "#f9f9f9",
      width: "100%",
      padding: theme.spacing(3),
    },
    drawer: {
      width: drawerWidth,
    },
    closedDrawer: {
      width: closedDrawerWidth,
    },
    drawPaper: {
      background: "#0061F7",
      width: drawerWidth,
      paddingTop: "30px",
      transition:
        "width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    },
    closedDrawPaper: {
      background: "#0061F7",
      width: closedDrawerWidth,
      paddingTop: "30px",
      overflowX: "hidden",
      transition: "width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    },
    root: {
      display: "flex",
    },
    active: {
      background: "#f4f4f4",
    },
    title: {
      padding: theme,
    },
  };
});

export default function NavigationDrawer({ role, currentCompany, loggedUser }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const { setNavbarTitleOverride } = useContext(AuthContext);
  const mobileMenuOpen = useSelector((state) => state.mobileMenuOpen);
  const activeLinkedAccounts = useSelector((state) =>
    (state.linkedAccounts || []).filter((item) => !item.disabled)
  );
  const changeAccountLoaded = useSelector((state) => state.changeAccount);
  const hasReferrals = useFeature("referrals");
  const hasNoPersonalAccount = loggedUser.accountType !== ACCOUNT_TYPE_PERSONAL;
  const hasPersonalAccount = loggedUser.accountType === ACCOUNT_TYPE_PERSONAL;
  const hasPaidPackage = !loggedUser.unpaid;
  const isAdmin = role === "ADMIN" || role === "ADMIN_FOUNDER";

  const closeDrawer = () => {
    dispatch(setMobileMenuOpen(false));
  };

  const handleButtonClick = (event) => {
    event.stopPropagation();
    dispatch(setMobileMenuOpen(false));
  };
  
  const employeeItems = [
    {
      text: "Tarjetas",
      icon: <HomeIcon style={{ color: "white" }} />,
      path: "/cards",
      visible: hasPaidPackage,
    },
    {
      text: "Estadísticas Personales",
      icon: <ArticleIcon style={{ color: "white" }} />,
      path: "/statistics",
      className: "step-5",
      visible: hasPaidPackage,
    },
    {
      text: "Recolección de datos",
      icon: <SummarizeIcon style={{ color: "white" }} />,
      path: "/collection",
      visible: hasReferrals && hasNoPersonalAccount && hasPaidPackage,
    },
    {
      text: "Configuración",
      icon: <SettingsIcon style={{ color: "white" }} />,
      path: "/configuration",
      className: "step-2",
      visible: hasPaidPackage,
    },
    {
      text: "Pagos",
      icon: <PaymentIcon style={{ color: "white" }} />,
      path: "/payments",
      visible: hasPersonalAccount || isAdmin,
    },
  ];

  const adminItems = [
    {
      text: "Tarjetas equipo",
      icon: <EditIcon style={{ color: "white" }} />,
      path: "/company/cards",
      visible: hasNoPersonalAccount && hasPaidPackage,
    },
    {
      text: "Recolección Global",
      icon: <SummarizeIcon style={{ color: "white" }} />,
      path: "/company/collection",
      visible: hasReferrals && hasNoPersonalAccount && hasPaidPackage,
    },
    {
      text: "Estadísticas generales",
      icon: <QueryStatsIcon style={{ color: "white" }} />,
      path: "/company/statistics",
      visible: hasNoPersonalAccount && hasPaidPackage,
    },
    {
      text: "Usuarios",
      icon: <GroupIcon style={{ color: "white" }} />,
      path: "/company/users",
      visible: hasNoPersonalAccount && hasPaidPackage,
    },
    {
      text: "Configuración del negocio",
      icon: <SettingsIcon style={{ color: "white" }} />,
      path: "/company/configuration",
      visible: hasPaidPackage,
    },
  ];

  const handleLogoClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    let text = "";
    let item = {};
    calculateNoPadding(location.pathname);
    if (location.pathname.startsWith("/cards/")) {
      setOpen(false);
    }
    if (location.pathname === "/" || location.pathname === "") {
      text = "Tarjetas";
    } else {
      if (location.pathname.startsWith("/company")) {
        text = "Business: ";
        item = adminItems.filter(
          (element) => element.path === location.pathname
        )[0];
        if (item) {
          text = text + item.text + ` - ${currentCompany.name}`;
        } else {
          text = "";
        }
      } else {
        item = employeeItems.filter(
          (element) => element.path === location.pathname
        )[0];
        if (item) {
          text = item.text;
        } else {
          text = "";
        }
      }
    }

    if (text !== "") setNavbarTitleOverride(text);
  }, [location.pathname]);

  const calculateNoPadding = (pathname = "") => {
    if (
      pathname.endsWith("/edition-form") ||
      pathname.endsWith("/creation-form")
    ) {
      dispatch(setNoPadding(true));
    } else {
      dispatch(setNoPadding(false));
    }
  };

  const setAndRefresh = async (element) => {
    dispatch(changeAccount(element.id));
  };

  useEffect(() => {
    if (window.matchMedia("(max-width: 768px)").matches) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [window.matchMedia("(max-width: 768px)").matches]);

  useEffect(() => {
    if (changeAccountLoaded) {
      navigate("/cards");
      dispatch(setChangeAccountLoaded(false));
    }
  }, [changeAccountLoaded]);

  const drawer = (
    <>
      <LogoDesktop>
        {open ? <img src="/logo.png" /> : <img src="/logo-mini.png" />}
      </LogoDesktop>
      <LogoMobile>
        <img src="/logo.png" />
      </LogoMobile>
      <Divider
        style={{ backgroundColor: "white", margin: "23px 20px 27px 20px" }}
      ></Divider>

      <List>
        {employeeItems
          .filter((item) => item.visible)
          .map((item) => (
            <ListItemStyled
              button
              key={item.text}
              to={item.path}
              component={Link}
              className={`${item.className} ${
                location.pathname === item.path ? "active" : ""
              }`}
              open={open}
              onClick={handleButtonClick}
            >
              <ListItemIconStyled
                className={location.pathname == item.path ? "active" : ""}
                open={open}
              >
                {item.icon}
              </ListItemIconStyled>
              <ListItemTextStyled
                primary={item.text}
                className={location.pathname === item.path ? "active" : ""}
                open={open}
              />
            </ListItemStyled>
          ))}
      </List>
      {isAdmin && (
        <>
          {hasPaidPackage && (
            <>
              <CompanySubtitleDesktop>
                {open ? (
                  <SubTitle>Opciones del negocio</SubTitle>
                ) : (
                  <div className="my-5"></div>
                )}
              </CompanySubtitleDesktop>
              <CompanySubtitleMobile>
                <SubTitle>Opciones del negocio</SubTitle>
              </CompanySubtitleMobile>
            </>
          )}

          <List>
            {adminItems
              .filter((item) => item.visible)
              .map((item) => (
                <ListItemStyled
                  button
                  key={item.text}
                  to={item.path}
                  component={Link}
                  className={location.pathname === item.path ? "active" : ""}
                  open={open}
                  onClick={handleButtonClick}
                >
                  <ListItemIconStyled
                    className={location.pathname == item.path ? "active" : ""}
                    open={open}
                  >
                    {item.icon}
                  </ListItemIconStyled>
                  <ListItemTextStyled
                    primary={item.text}
                    className={location.pathname === item.path ? "active" : ""}
                    open={open}
                  />
                </ListItemStyled>
              ))}
          </List>
        </>
      )}

      {open
        ? activeLinkedAccounts.length > 1 && (
            <LinkedAccountsSelector>
              <SelectButton
                list={activeLinkedAccounts}
                selected={loggedUser}
                placeholder="Selecciona tu negocio"
                setItemSelected={setAndRefresh}
                onOpen={() => {}}
                onClose={() => {}}
                loadingText={"Cargando negocios"}
              />
            </LinkedAccountsSelector>
          )
        : activeLinkedAccounts.length > 1 && (
            <LinkedAccountsSelectorMobile>
              <i
                className="bi bi-menu-down pb-3"
                onClick={() => {
                  setOpen(!open);
                  setMobileMenuOpen(!mobileMenuOpen);
                }}
              ></i>
            </LinkedAccountsSelectorMobile>
          )}
    </>
  );

  return (
    <>
      <DrawerMobile
        className={classes.drawer}
        variant="temporary"
        anchor="left"
        onClose={closeDrawer}
        classes={{ paper: classes.drawPaper }}
        open={mobileMenuOpen}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {drawer}
      </DrawerMobile>
      <DrawerDesktop
        className={open ? classes.drawer : classes.closedDrawer}
        variant="permanent"
        anchor="left"
        classes={{ paper: open ? classes.drawPaper : classes.closedDrawPaper }}
        open={open}
        onClick={handleLogoClick}
      >
        <CollapseButton open={open} onClick={handleLogoClick}>
          {open ? <ArrowBackIcon /> : <ArrowForwardIcon />}
        </CollapseButton>
        {drawer}
      </DrawerDesktop>
    </>
  );
}
