import React, { useCallback, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import {
  Box,
  DialogActions,
  DialogContent,
  LinearProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";

import Alert from "../../common/atoms/Alert";
import Button from "../../common/atoms/Button";
import Typography from "../../common/atoms/Typography";
import {
  defaultCardPhoto,
  excelFormatFile,
} from "../../common/atoms/variables";
import EmployeeService from "../services/EmployeeService";
import theme from "../../common/theme";
import { setPageLoading } from "../../redux/store/companies/actions/generalPageLoadingActions";

export const CounterStyled = styled.span`
  font-weight: 600;
  font-size: 16px;
  display: block;
  text-align: left;
`;

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const ReasonText = styled(Typography)`
  color: ${theme.colors.carnation};
`;

const ImportExcelComponent = ({
  closeDialog,
  getEmployees,
  refreshUsersCreated,
}) => {
  const currentCompany = useSelector((state) => state.currentCompany);
  const [message, setMessage] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [file, setFile] = useState(null);
  const [loadingExcelUpload, setLoadingExcelUpload] = useState(false);
  const [hasError, setHasError] = useState(false);
  const dispatch = useDispatch();

  const onDrop = useCallback((newFiles) => {
    setEmployees([]);
    setMessage(null);
    setLoadingExcelUpload(true);
    handleFile(newFiles[0]);
  }, []);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: 35 * 1024,
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
      "application/vnd.ms-excel": [],
    },
  });

  const columns = [
    {
      name: "#",
      minWidth: "60px",
      maxWidth: "60px",
      selector: (row) => row.counter,
      sortable: true,
      cell: (row) => <CounterStyled>{row.counter}</CounterStyled>,
    },
    {
      name: "Nombre",
      minWidth: "auto",
      maxWidth: "120px",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Apellido",
      minWidth: "auto",
      maxWidth: "120px",
      selector: (row) => row.lastname,
      sortable: true,
    },
    {
      name: "Correo",
      minWidth: "auto",
      maxWidth: "120px",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Telefono",
      minWidth: "auto",
      maxWidth: "100px",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Puesto de trabajo",
      minWidth: "auto",
      maxWidth: "150px",
      selector: (row) => row.job,
      sortable: true,
    },
    {
      name: "Rol",
      minWidth: "auto",
      maxWidth: "180px",
      selector: (row) => row.role,
      sortable: true,
    },
    {
      name: "Obs.",
      minWidth: "120px",
      maxWidth: "120px",
      selector: (row) => row.reason,
      sortable: true,
      cell: (row) => (
        <ReasonText variant="xtiny" weight="regular">
          {row.reason}
        </ReasonText>
      ),
    },
  ];

  const handleFile = (newFile) => {
    if (!newFile) {
      setMessage({
        type: "error",
        text: "El archivo elegido es muy grande.",
      });
      setLoadingExcelUpload(false);
      return;
    }
    setFile(newFile);
    const formData = new FormData();
    formData.append("employees", newFile);
    EmployeeService.validateBulkUpload(formData)
      .then((data) => {
        setMessage(null);
        if (data.success) {
          setHasError(false);
          setMessage({
            type: "info",
            text: "Solo se mostrarán como máximo 200 usuarios en la tabla",
          });
        } else {
          setHasError(true);
          setMessage({
            type: "error",
            text: `Se encontraron ${data.totalErrors} errores en el archivo cargado. Verificar. Solo se muestran los primeros 25 errores.`,
          });
        }
        setEmployees(
          data.employees.map((item, index) => ({
            counter: index + 1,
            name: item["Nombre"],
            lastname: item["Apellido"],
            email: item["Correo"],
            phone: item["Teléfono"],
            job: item["Puesto de trabajo"],
            role: item["Rol"],
            reason: item["reason"],
          }))
        );
      })
      .finally(() => {
        setLoadingExcelUpload(false);
      });
  };

  const handleSave = async () => {
    if (hasError) {
      setMessage({
        type: "error",
        text: "Solucione los errores del archivo y vuelva a cargarlo para continuar.",
      });

      return;
    }
    if (employees.length === 0) {
      setMessage({ type: "error", text: "Debe haber al menos un empleado." });
      return;
    }
    dispatch(setPageLoading(true));
    const formData = new FormData();
    formData.append("employees", file);
    formData.append("companyId", currentCompany.id);
    formData.append("photo", defaultCardPhoto);
    EmployeeService.saveBulkUpload(formData)
      .then(() => {
        toast.success(`Carga completada`, {
          position: "top-center",
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        refreshUsersCreated();
        setEmployees([]);
        handleCloseDialog();
      })
      .catch((err) => {
        if (err.message === "Network Error") {
          toast.success(`Carga completada`, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          refreshUsersCreated();
          setEmployees([]);
          handleCloseDialog();
        } else {
          if (err.response && err.response.status === 400) {
            toast.error(
              err.response.data.message,
              {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              }
            );
            return;
          }
          toast.error(
            `Ocurrió un error en la carga. Revise la información de usuarios para validar que se hayan creado correctamente.`,
            {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        }
      })
      .finally(() => {
        dispatch(setPageLoading(false));
      });
  };

  const handleCloseDialog = () => {
    setMessage(null);
    getEmployees("", 0);
    closeDialog();
  };

  const downloadExcelFormat = () => {
    const link = document.createElement("a");
    link.download = "HolaLink - Formato de Registro de Empleados.xlsx";
    link.href = excelFormatFile;
    link.click();
  };

  return (
    <div>
      <DialogContent style={{ overflow: "auto", maxHeight: "640px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "10px 0px 12px 0px",
          }}
        >
          <label>
            <h5>Añade más de 50 usuarios al mismo tiempo</h5>
          </label>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "10px 0px 0px 0px",
          }}
        >
          Descarga nuestro formato
        </div>
        <div style={{ height: "20px" }}></div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0px",
          }}
        >
          <Button
            type="button"
            variant="primary"
            size="medium"
            onClick={downloadExcelFormat}
          >
            Descargar Formato
          </Button>
        </div>

        <label>
          <div
            style={{
              display: "flex",
              margin: "20px 10px 0px 10px",
            }}
          >
            En el formato .xlsx encontrarás una tabla para introducir nombre,
            apellido, teléfono, correo y seleccionar roles. Este documento te
            permitirá, de forma ordenada, establecer a aquellos trabajadores que
            desees que tengan una tarjeta.
          </div>
        </label>
        <div
          style={{
            display: "flex",
            margin: "24px 0px 0px 0px",
          }}
        >
          <label>
            <b>Sube el formato</b>
          </label>
        </div>
        <div {...getRootProps({ className: "Dropzone" })}>
          <Container>
            <input {...getInputProps()} />
            <p>Click aquí o arrastra el documento</p>
            <ul>
              {acceptedFiles.map((item) => (
                <li key={item.path}>
                  {item.path} - {(item.size / 1024).toFixed()} kilobytes
                </li>
              ))}
            </ul>
          </Container>
        </div>

        {loadingExcelUpload && (
          <div>
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          </div>
        )}

        <div
          style={{
            display: "flex",
            margin: "24px 0px 16px 0px",
          }}
        >
          <label>
            Por favor no cierres esta ventana hasta que termine de subirse el
            archivo
          </label>
        </div>

        {message && <Alert type={message.type}>{message.text}</Alert>}
        <div>
          <DataTable
            noDataComponent="No se han añadido empleados."
            columns={columns}
            data={employees}
            pagination
          />
        </div>
      </DialogContent>
      <DialogActions
        style={{ marginTop: "4px", borderTop: "1px solid #eaeaea" }}
      >
        <Button
          onClick={handleCloseDialog}
          type="button"
          size="medium"
          variant="cancel"
        >
          Cancelar
        </Button>
        <Button
          onClick={handleSave}
          type="button"
          size="medium"
          disabled={!file}
          variant={!file ? "disabled" : "primary"}
        >
          Guardar
        </Button>
      </DialogActions>
    </div>
  );
};

export default ImportExcelComponent;
