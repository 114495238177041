import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Typography from "../../common/atoms/Typography";
import theme from "../../common/theme";

const selectableStyle = ({ selectable }) => css`
  cursor: ${selectable && "pointer"};
  &:hover {
    box-shadow: ${selectable && "5px 5px 10px #d9d9d9"};
  }
`;
const selectedStyle = ({ selected }) =>
  css`
    border: ${selected && `5px solid ${theme.colors.azureRadiance}`};
    padding: ${selected && "0px"};
    overflow: ${selected && "hidden"};
    border-radius: ${selected && "15px"};
  `;
export const CardTemplateBase = styled.div`
  border-radius: 10px;
  box-shadow: 5px 5px 25px rgba(121, 121, 121, 0.1);
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: ${(props) => (props.fullWidth ? "none" : "350px")};
  min-height: 650px;
  box-sizing: border-box;
  position: relative;
  ${selectableStyle};
  ${selectedStyle};
  @media (min-width: 1920px) {
    max-width: ${(props) => (props.fullWidth ? "768px" : "467px")};
  }
  width: 100%;
  border-radius: ${(props) => "10px"};
  @media (min-width: 768px) {
    padding-top: ${(props) => props.fullWidth && "80px"};
  }
  background-color: ${(props) => props.background};
  padding-top: ${(props) => props.fullWidth && "20px"};
  margin: ${(props) => (props.fullWidth ? "auto" : "0")};
`;

export const CardPhotoArea = styled.div`
  background-color: ${(props) => props.background};
  height: 180px;
  border-radius: 10px 10px 0px 0px;
  padding: 8px;
  @media (min-width: 1920px) {
    height: 200px;
  }
  border-radius: ${(props) => props.fullWidth && "0"};
  @media (max-width: 375px) {
    height: 134px;
  }
`;

export const Photo = styled.div`
  max-width: 180px;
  height: 200px;
  margin: 22px auto 0px auto;
  position: relative;
  @media (min-width: 768px) {
    max-width: ${(props) => (props.fullWidth ? "262px" : "200px")};
    margin-top: ${(props) => (props.fullWidth ? "-40px" : "24px")};
    height: ${(props) => (props.fullWidth ? "272px" : "200px")};
  }
  @media (min-width: 1920px) {
    max-width: ${(props) => (props.fullWidth ? "262px" : "220px")};
    margin-top: ${(props) => (props.fullWidth ? "-40px" : "24px")};
    height: ${(props) => (props.fullWidth ? "272px" : "220px")};
  }
  > div:first-of-type {
    background-color: white;
    border: 5px solid #ffffff;
    height: 100%;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 2;
    }
  }
  @media (max-width: 375px) {
    max-width: 146px;
    height: 146px;
  }
`;

export const LogoIcon = styled.div`
  width: 72.44px;
  height: 72.44px;
  border-radius: 50%;
  border: 5px solid white;
  background-color: white;
  position: absolute;
  margin-top: -63px;
  overflow: hidden;
  z-index: 3;
  margin-right: -24px;
  right: 0;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 8px;
  > img {
    object-fit: cover;
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
`;

export const CardContent = styled.div`
  padding: 70px 20px 20px 20px;
  min-height: ${(props) => props.fullWidth && "40vh"};
  background-color: white;
  flex: 1;
  border-radius: ${(props) => "0 0 10px 10px"};
`;

export const ContactButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const ContactButton = styled.a`
  background-color: ${(props) => props.background};
  max-width: 180px;
  display: block;
  padding: 8px;
  width: 100%;
  text-decoration: none;
`;

export const SocialButtonWrapper = styled.div`
  display: flex;
  max-width: 190px;
  margin: 10px auto 0px auto;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
`;

export const SocialButton = styled.a`
  background-color: ${(props) => props.background};
  height: 30px;
  width: 30px;
  text-align: center;
  color: white;
  padding: 3px;
  text-decoration: none;
  &:hover {
    color: white;
  }
  i {
    font-size: 15px;
  }
`;

export const ContactText = styled(Typography)`
  color: ${(props) => props.color};
`;

export const StyledTypography = styled(Typography)`
  color: ${(props) => props.color};
`;

export const StyledIcon = styled.i`
  color: ${(props) => props.color};
`;
