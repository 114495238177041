import axiosInstance from "../../common/helpers/axios";

const NotificationService = {
  getUserNotifications: (timezone) => {
    return axiosInstance.get("/notifications", {
      params: {
        timezone,
      },
    });
  },
};

export default NotificationService;
