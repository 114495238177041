import InitialLayout from "../components/InitialLayout";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setAuthUser } from "../../redux/store/accounts/actions/authActions";
import AuthService from "../services/AuthService";
import Button from "../../common/atoms/Button";
import Link from "../../common/atoms/Link";
import { TextField } from "@material-ui/core";
import TextInputPassword from "../../common/atoms/TextInputPassword";
import { EMAIL_REGEX } from "../../common/helpers/form.utils";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const dispatch = useDispatch();
  const [pageLoading, setPageLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loginErrors, setLoginErrors] = useState({});
  const [loginForm, setLoginForm] = useState({ email: "", password: "" });
  const navigate = useNavigate();

  const redirectToForgotPassword = () => {
    setErrorMessage("");
    setSuccessMessage("");
    navigate("/change-password/set-email");
  };

  const handleLoginFormChange = (e) => {
    const { name, value } = e.target;

    setLoginForm((prevState) => ({
      ...prevState,
      [name]: value.replace(/\s/g, ""),
    }));
  };

  const handleLoginFormValidation = () => {
    const errors = {};
    let isValid = true;

    if (!loginForm.email) {
      errors.email = "Ingrese un correo";
      isValid = false;
    } else if (!EMAIL_REGEX.test(loginForm.email)) {
      errors.email = "Ingrese un correo válido";
      isValid = false;
    }
    if (!loginForm.password) {
      errors.password = "Ingrese una contraseña";
      isValid = false;
    }
    setLoginErrors(errors);

    return isValid;
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    const isValid = handleLoginFormValidation();
    if (!isValid) return;
    setPageLoading(true);
    AuthService.login(loginForm)
      .then((response) => {
        const user = response.data.employee;
        const currentToken = response.data.token;
        dispatch(setAuthUser({ employee: user, token: currentToken }));
      })
      .catch((error) => {
        setErrorMessage(
          error?.response?.data?.message || "Correo o contraseña inválido"
        );
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  const navigateToRegister = () => {
    navigate("/account-type");
  };

  return (
    <InitialLayout
      errorMessage={errorMessage}
      successMessage={successMessage}
      pageLoading={pageLoading}
    >
      <form onSubmit={handleLogin} method="post">
        <div className="form-group">
          <TextField
            fullWidth
            type="text"
            color="primary"
            margin="normal"
            variant="standard"
            label="Usuario"
            name="email"
            error={!!loginErrors.email}
            helperText={loginErrors.email}
            onChange={handleLoginFormChange}
          />
        </div>
        <div className="form-group">
          <div
            className="hideShowPassword-wrapper"
            style={{
              position: "relative",
              display: "block",
              verticalAlign: "baseline",
              margin: "0px",
            }}
          >
            <TextInputPassword
              fullWidth
              type="password"
              color="primary"
              margin="normal"
              variant="standard"
              label="Contraseña"
              name="password"
              error={!!loginErrors.password}
              helperText={loginErrors.password}
              onChange={handleLoginFormChange}
            />
          </div>
        </div>
        <div className="clearfix mb-3">
          <div className="text-end">
            <Link onClick={redirectToForgotPassword}>Olvidé mi contraseña</Link>
          </div>
        </div>
        <div className="mt-5">
          <Button
            variant="primary"
            size="medium"
            fullWidth={true}
            type="submit"
          >
            Iniciar sesión
          </Button>
          <span className="d-flex justify-content-center gap-1">
            ¿No tienes una cuenta?{" "}
            <Link onClick={navigateToRegister}>Regístrate</Link>
          </span>
        </div>
      </form>
    </InitialLayout>
  );
};

export default Login;
