import styled from "@emotion/styled";
import AccountCenter from "../components/AccountCenter";
import { PersonalProfileForm } from "../components/PersonalProfileForm";

const PageLayout = styled.div`
  width: 100%;
  @media (max-width: 480px) {
    padding: 0;
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  & > div:first-of-type {
    max-width: 550px;
  }
  @media screen and (max-width: 480px) {
    justify-content: center;
  }
`;

export const PersonalConfiguration = () => {
  return (
    <PageLayout>
      <MainContainer>
        <PersonalProfileForm />
        <AccountCenter />
      </MainContainer>
    </PageLayout>
  );
};
