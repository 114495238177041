import styled from "@emotion/styled";
import { css } from "@emotion/react";
import BaseCard from "../../common/atoms/BaseCard";
import { blue_main, danger } from "../../common/atoms/variables";
import Typography from "../../common/atoms/Typography";
import theme from "../../common/theme";
import { toast } from "react-toastify";
const DEFAULT_CARD_TYPE = "business";

const CardBody = styled.div`
  &:hover {
    img {
      -webkit-transform: scale(1.3);
      transform: scale(1.3);
    }
  }
`;

const primaryStyle = (props) => css`
  background-color: ${props.variant === "primary" && blue_main};
`;
const dangerStyle = (props) => css`
  background-color: ${props.variant === "danger" && danger};
`;

const CardLabel = styled.div`
  background-color: ${theme.colors.mineShaft};
  padding: 3px 20px;
  position: absolute;
  border-radius: 10px 0px;
  text-transform: capitalize;
  p {
    color: ${theme.colors.white};
  }
`;

const CardContent = styled.div`
  height: 96px;
  background-color: #eaeaea;
  border-radius: 10px 10px 0px 0px;
  padding-top: 61px;
  padding-left: 16px;
  padding-right: 16px;
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const CardImage = styled.div`
  width: 110px;
  height: 106px;
  margin: 35px auto -35px auto;
  z-index: 1;
  figure {
    border-radius: 10px;
    overflow: hidden;

    img {
      width: 110px;
      height: 120px;
      border-radius: 10px;
      object-fit: cover;
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transition: 0.3s ease-in-out;
      transition: 0.3s ease-in-out;
    }
  }
`;

const CardFooter = styled.div`
  ${primaryStyle}
  ${dangerStyle}
  text-align: center;
  height: 48px;
  padding: 5px;
  border-radius: 0px 0px 10px 10px;

  p {
    color: white;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const FooterText = styled(Typography)`
  @media (min-width: 1920px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export function CardItem({ card, goToCardDetail }) {
  const variantStyle =
    card.status && card.status === "PENDING" ? "danger" : "primary";
  const handleClick = () => {
    if (card.company.unpaid) {
      toast.error(
        `No se puede editar una tarjeta de una cuenta cuyo plan ha expirado.`,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
      return;
    }
    goToCardDetail(card.id);
  };

  return (
    <>
      <BaseCard onClick={handleClick}>
        <CardBody>
          <CardLabel>
            <Typography variant="xtiny" weight="bold">
              <i>{card.type || DEFAULT_CARD_TYPE}</i>
            </Typography>
          </CardLabel>
          <CardImage>
            <figure>
              <img src={card.photoUrl} />
            </figure>
          </CardImage>
          <CardContent>
            <Typography variant="tiny" weight="bold">
              {card.name} {card.lastname}
            </Typography>
          </CardContent>
          <CardFooter variant={variantStyle}>
            <FooterText variant="tiny">{card.company?.name}</FooterText>
            <FooterText variant="tiny">{card.email}</FooterText>
          </CardFooter>
        </CardBody>
      </BaseCard>
    </>
  );
}
