import styled from "@emotion/styled";
import { DialogActions, TextField } from "@mui/material";
import Button from "../../common/atoms/Button";

import { useState } from "react";
import { CompanyService } from "../services/CompanyServices";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentCompany } from "../../redux/store/companies/actions/currentCompanyActions";
import Typography from "../../common/atoms/Typography";
import { danger, secondaryGray } from "../../common/atoms/variables";
import { EditIcon } from "../../common/atoms/SvgIcons";
import { setPageLoading } from "../../redux/store/companies/actions/generalPageLoadingActions";
import ConfirmationDialog from "../../common/molecules/ConfirmationDialog";
import Flex from "../../common/atoms/Flex";

const UrlContainer = styled.div`
  background-color: white;
  border: 3px solid #0061f7;
  padding: 24px;
  border-radius: 10px;
`;

const EditButton = styled.div`
  cursor: pointer;
`;

const UrlBase = styled.span`
  color: #d1d1d1;
  font-size: 1rem;
  margin-right: 8px;
`;

const UrlTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
`;

const UrlField = styled(TextField)`
  display: inline;
  max-width: 174px;
`;

const WarningMessage = styled(Typography)`
  margin-bottom: 0px;
  margin-top: 8px;
  color: ${danger};
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const EditionMessage = styled(Typography)`
  margin-bottom: 8px;
  margin-top: 8px;
  color: ${secondaryGray};
`;

const MAX_INSTANT_UPDATES = 3;
const daysArray = [1, 7, 7];
export const BusinessUrlForm = ({ loggedUser }) => {
  const [showEditRequestDialog, setShowEditRequestDialog] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const currentCompany = useSelector((state) => state.currentCompany);
  const dispatch = useDispatch();

  const [url, setUrl] = useState(currentCompany.businessPathKey);
  const [error, setError] = useState(false);
  const [urlEditionDisabled, setUrlEditionDisabled] = useState(true);
  const [urlMessage, setUrlMessage] = useState("");
  const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);
  const [counter, setCounter] = useState(0);
  const [daysLeft, setDaysLeft] = useState(currentCompany.daysLeft || 0);
  const [confirmationNote, setConfirmationNote] = useState(
    "* Esto afectará a todas las tarjetas registradas."
  );

  const enableEdition = () => {
    if (loggedUser.role === "ADMIN_FOUNDER") {
      if (!urlEditionDisabled) {
        setUrl(currentCompany.businessPathKey);
        setUrlMessage("");
        setError(false);
      }
      setUrlEditionDisabled(!urlEditionDisabled);
    }
  };

  const updateUrl = (e) => {
    dispatch(setPageLoading(true));
    CompanyService.editUrl(currentCompany.id, url.trim())
      .then((res) => {
        if (res.status === 200 && res.data.updatedCompany !== undefined) {
          dispatch(updateCurrentCompany(res.data.updatedCompany));
          setDaysLeft(res.data.updatedCompany.daysLeft);
          setUrlEditionDisabled(true);
          setSaveButtonEnabled(false);
          setShowConfirmationDialog(false);
          setCounter(0);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch(setPageLoading(false));
      });
  };

  const handleChange = (e) => {
    if (counter === 0) {
      setCounter(1);
      setSaveButtonEnabled(true);
    }
    setError(false);
    setUrlMessage("");
    setUrl(e.target.value);
  };

  const closeDialog = (e) => {
    setShowEditRequestDialog(false);
  };
  const openConfirmationDialog = (e) => {
    setShowConfirmationDialog(true);
  };
  const closeConfirmationDialog = (e) => {
    setShowConfirmationDialog(false);
  };

  const chooseDialogToOpen = () => {
    if (urlEditionDisabled) {
      return;
    }

    if (!saveButtonEnabled) {
      setUrlMessage("No hubieron cambios.");
      return;
    }

    if (currentCompany.businessPathKey === url) {
      setUrlMessage("No hubieron cambios.");
      return;
    }
    if (url === "") {
      setError(true);
      setUrlMessage("El URL es requerido.");
      return;
    }

    if (currentCompany.urlUpdateCount >= MAX_INSTANT_UPDATES) {
      setConfirmationNote(
        "* Has alcanzado el límite máximo de cambios, contacte a soporte."
      ); 
      openConfirmationDialog();
    } else {
      const daysCount = daysArray[currentCompany.urlUpdateCount];
      const daysMessage = daysCount > 1 ? " días." : " día.";
      setConfirmationNote(
        "* Podrás editar de nuevo luego de " +
          daysCount.toString() +
          daysMessage
      );
      openConfirmationDialog();
    }
  };
  return (
    <UrlContainer>
      <UrlTitle>URL del negocio</UrlTitle>
      <form>
        <Flex gap="24px" flexWrap="wrap" justifyContent="center">
          <Flex alignItems="center">
            <UrlBase>https://hola.link/</UrlBase>
            <UrlField
              error={error}
              helperText={urlMessage !== "" && urlMessage}
              fullWidth
              value={url}
              onChange={(e) => handleChange(e)}
              variant="standard"
              disabled={urlEditionDisabled}
            />
            {loggedUser.role === "ADMIN_FOUNDER" && daysLeft === 0 && (
              <EditButton onClick={enableEdition}>
                <EditIcon />
              </EditButton>
            )}
          </Flex>
          <Button
            onClick={chooseDialogToOpen}
            type="button"
            size="small"
            variant={
              urlEditionDisabled ||
              loggedUser.role !== "ADMIN_FOUNDER" ||
              daysLeft !== 0
                ? "disabled"
                : "primary"
            }
            disabled={
              urlEditionDisabled ||
              loggedUser.role !== "ADMIN_FOUNDER" ||
              daysLeft !== 0
            }
            style={{ cursor: urlEditionDisabled ? "not-allowed" : "pointer" }}
          >
            Confirmar
          </Button>
        </Flex>
      </form>
      {daysLeft !== 0 && (
        <EditionMessage variant="tiny">
          Podrás editar luego de{" "}
          {daysLeft.toString() + (daysLeft > 1 ? " días." : " día.")}
        </EditionMessage>
      )}
      <WarningMessage variant="tiny">
        * La edición del URL afectará a todas las tarjetas registradas.{" "}
      </WarningMessage>

      <ConfirmationDialog
        open={showConfirmationDialog}
        headerTitle="Confirmar edición de URL"
        closeDialog={closeConfirmationDialog}
        question={"¿Está seguro de completar la edición del URL del negocio?"}
        note={confirmationNote}
      >
        <div style={{ width: "100%" }}>
          <DialogActions>
            <ButtonContainer>
              <Button
                variant="cancel"
                size="small"
                onClick={closeConfirmationDialog}
              >
                Cancelar
              </Button>
              <div style={{ width: "12px" }}></div>
              <Button variant="primary" size="small" onClick={updateUrl}>
                Guardar
              </Button>
            </ButtonContainer>
          </DialogActions>
        </div>
      </ConfirmationDialog>
      <ConfirmationDialog
        open={showEditRequestDialog}
        headerTitle="Solicitud de edición de URL"
        closeDialog={closeDialog}
        question={"¿Desea solicitar una edición del URL?"}
        note={"* La actualización puede tomar 24 hrs en realizarse."}
      >
        <div style={{ width: "100%" }}>
          <DialogActions>
            <ButtonContainer>
              <Button variant="cancel" size="small" onClick={closeDialog}>
                Cancelar
              </Button>
              <div style={{ width: "12px" }}></div>
              <Button variant="primary" size="small" onClick={closeDialog}>
                Confirmar
              </Button>
            </ButtonContainer>
          </DialogActions>
        </div>
      </ConfirmationDialog>
    </UrlContainer>
  );
};
