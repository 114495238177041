import React, { useState } from "react";

export const BusinessCardPublicContext = React.createContext({});

export const BusinessCardPublicProvider = ({ children }) => {
  const [ratingExists, setRatingExists] = useState(false);
  return (
    <BusinessCardPublicContext.Provider
      value={{
        ratingExists,
        setRatingExists,
      }}
    >
      {children}
    </BusinessCardPublicContext.Provider>
  );
};
