import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TemplateCardList from "../components/TemplateCardList";
import { BusinessCardsContext } from "../context/BusinessCardsContext";
import Button from "../../common/atoms/Button";
import { BusinessCardService } from "../services/BusinessCardService";
import PageLoader from "../../common/atoms/PageLoader";
import BackButton from "../../common/atoms/BackButton";
import Typography from "../../common/atoms/Typography";
import Flex from "../../common/atoms/Flex";

export default function TemplateEdition() {
  const navigate = useNavigate();
  const [pageLoading, setPageLoading] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const { cardId } = useParams();
  const { currentCard, setCurrentCard, formMode, setFormMode, isOwnCard } =
    useContext(BusinessCardsContext);

  useEffect(() => {
    if (!currentCard) {
      fetchCardInfo();
    } else {
      if (currentCard.status === "PENDING") {
        setFormMode("default-card-edition");
      }
      setSelectedTemplate(currentCard.templateId);
    }
  }, []);

  const fetchCardInfo = () => {
    setPageLoading(true);
    BusinessCardService.getBusinessCardById(cardId)
      .then((res) => {
        if (res.data.businessCard) {
          setCurrentCard(res.data.businessCard);
          setSelectedTemplate(res.data.businessCard.templateId);
          if (res.data.businessCard.status === "PENDING") {
            setFormMode("default-card-edition");
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          const status = error.response.status;
          if (status === 403 || status === 404) {
            navigate("/not-found");
            return;
          }
        }
        console.log(error);
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  const selectTemplate = (id) => {
    setSelectedTemplate(id);
  };

  const updateTemplate = () => {
    setPageLoading(true);
    BusinessCardService.updateTemplate(cardId, selectedTemplate)
      .then(() => {
        if (
          formMode === "default-card-edition" &&
          currentCard.status === "PENDING"
        ) {
          setFormMode("edition");
          navigate(`/cards/${cardId}/edition-form`);
        } else {
          navigate(`/cards/${cardId}`);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  return (
    <>
      {pageLoading && <PageLoader />}
      {currentCard && (
        <div className="container-fluid p-0">
          <BackButton />
          <Typography
            variant="h5"
            weight="bold"
            textAlign="center"
            mt="24px"
            mb="40px"
          >
            Selecciona tu tarjeta
          </Typography>
          <TemplateCardList
            defaultSelected={currentCard.templateId}
            selectTemplate={selectTemplate}
            colors={currentCard && currentCard.baseInformation.company.colors}
          ></TemplateCardList>
          <Flex m="4" justifyContent="center">
            <Button variant="primary" size="medium" onClick={updateTemplate}>
              Seleccionar
            </Button>
          </Flex>
        </div>
      )}
    </>
  );
}
