import axiosInstance from "../../common/helpers/axios";

const EmployeeService = {
  createEmployee: async (employee) => {
    let result = await axiosInstance.post(`/employee`, employee);
    return result.data;
  },
  createManyEmployees: async (employees, defaultPhoto) => {
    let result = {};
    try {
      result = await axiosInstance.post(`/employee/multiple-creation`, {
        employees,
        defaultPhoto,
      });
      return result.data;
    } catch (error) {
      console.log(error);
      if (error.response) {
        return error.response.data;
      }
    }
  },
  validateBulkUpload: async (data) => {
    const result = await axiosInstance.post(
      `/bulk-employees-upload/validate`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return result.data;
  },
  saveBulkUpload: async (data) => {
    const result = await axiosInstance.post(
      `/bulk-employees-upload/save`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return result.data;
  },
  getEmployeesByCompany: async (companyId, search, page) => {
    const config = {};
    config.params = {};
    if (search) {
      config.params.search = search;
    }
    if (page) {
      config.params.page = page;
    }
    const result = await axiosInstance.get(
      `/employee/company/${companyId}`,
      config
    ); 
    return result.data;
  },
  editEmployeeByAdmin: async (employeeId, employeeBody) => {
    return axiosInstance.put(
      "/employee/admin-edition/" + employeeId,
      employeeBody
    );
  },
  editManyEmployeesByAdmin: async (employees) => {
    return axiosInstance.put("/employee/massive/admin-edition", { employees });
  },
  deleteEmployees: async (employees) => {
    return axiosInstance.post("/employee/delete", { employees });
  },
  getCurrentCompany: () => {
    return axiosInstance.get(`/employee/company`);
  },
};

export default EmployeeService;
