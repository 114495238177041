import styled from "@emotion/styled";
import { Dialog, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState, lazy, Suspense } from "react";
import { MonitoringService } from "../../statistics/services/MonitoringService";
import TopCTA from "../../statistics/components/TopCTA";
import TopSocialMedia from "../../statistics/components/TopSocialMedia";
import { CATEGORIES, getFieldSchema } from "./form.utils";
import Typography from "../../common/atoms/Typography";
import theme from "./../../common/theme";
import SimpleCard from "../../common/atoms/SimpleCard";
import BaseFiltersSelector from "../../statistics/components/BaseFiltersSelector";
import Loader from "../../common/atoms/Loader";

const ChartReact = lazy(() => import("../../statistics/components/ChartReact"));

const renderLoader = () => <Loader />;

const DialogStyled = styled(Dialog)`
  .MuiDialog-paper {
    padding: 25px;
    border-radius: 15px;
    margin: 0;
    width: calc(100% - 16px);
  }
`;

const SimpleCardStyled = styled(SimpleCard)`
  padding: 15px 0;
  box-shadow: 0px 15px 50px rgba(160, 163, 189, 0.1);
`;

const ContactCardWrapper = styled.div`
  display: flex;
  gap: 25px;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 35px;
`;

const CardStatistics = ({ cardId, title, showDialog, closeDialog }) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [generalContactData, setGeneralContactData] = useState([]);
  const [socialMediaData, setSocialMediaData] = useState([]);
  const [socialMediaLoading, setSocialMediaLoading] = useState(false);
  const [generalContactLoading, setGeneralContactLoading] = useState(false);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [filter, setFilter] = useState("THIS_WEEK");

  const getVisitsByCardId = () => {
    setLoading(true);
    if (cardId) {
      MonitoringService.getVisitsByCardId(cardId, timezone, filter)
        .then((res) => {
          if (res.status === 200) {
            setData(res.data.visitsData);
          } else {
            console.log(res.data.message);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getSocialMediaEvents = () => {
    setSocialMediaLoading(true);
    MonitoringService.getEventsByCategoryAndBusinessCardId(
      cardId,
      CATEGORIES.SOCIAL_NETWORKS,
      filter
    ).then((res) => {
      if (res.status === 200) {
        const events = res.data.events.map((item) => {
          const fieldSchema = getFieldSchema(item.category, item.key);
          return {
            ...item,
            placeholder: fieldSchema
              ? fieldSchema.placeholder || fieldSchema.valuePlaceholder
              : item.key,
          };
        });
        setSocialMediaData(events);
      } else {
        console.log(res.data.message);
      }
      setSocialMediaLoading(false);
    });
  };

  const getGeneralContactEvents = () => {
    setGeneralContactLoading(true);
    MonitoringService.getEventsByCategoryAndBusinessCardId(
      cardId,
      CATEGORIES.GENERAL_CONTACTS,
      filter
    ).then((res) => {
      if (res.status === 200) {
        const events = res.data.events.map((item) => {
          const fieldSchema = getFieldSchema(item.category, item.key);
          return {
            ...item,
            placeholder: fieldSchema
              ? fieldSchema.placeholder || fieldSchema.valuePlaceholder
              : item.key,
          };
        });
        setGeneralContactData(events);
      } else {
        console.log(res.data.message);
      }
      setGeneralContactLoading(false);
    });
  };

  useEffect(() => {
    if (open) {
      setLoading(true);
      getVisitsByCardId();
      getSocialMediaEvents();
      getGeneralContactEvents();
    }
  }, [open, filter]);

  const onChangeFilter = (option) => {
    setFilter(option);
  };

  return (
    <DialogStyled
      open={showDialog}
      onClose={closeDialog}
      maxWidth={"lg"}
      fullWidth
    >
      <IconButton
        aria-label="close"
        onClick={closeDialog}
        sx={{
          position: "absolute",
          right: 12,
          top: 12,
          color: theme.colors.mineShaft,
          maxWidth: "40px",
        }}
      >
        <CloseIcon />
      </IconButton>
      <Typography variant="small" weight="bold" mb="3">
        <i>{title}</i>
      </Typography>
      <BaseFiltersSelector onChange={onChangeFilter} />
      <SimpleCardStyled>
        <Suspense fallback={renderLoader()}>
          <ChartReact data={data} filter={filter} loading={loading} />
        </Suspense>
      </SimpleCardStyled>
      <ContactCardWrapper>
        <TopCTA
          loading={generalContactLoading}
          data={generalContactData}
        ></TopCTA>
        <TopSocialMedia
          loading={socialMediaLoading}
          data={socialMediaData}
        ></TopSocialMedia>
      </ContactCardWrapper>
    </DialogStyled>
  );
};

export default CardStatistics;
