import React, { useState } from "react";
import { LocalStorageService } from "../../common/services/LocalStorageService";

export const AuthContext = React.createContext({});

export const AuthProvider = ({ children }) => {
  const getToken = () => {
    const sessionToken = LocalStorageService.get("token");
    return sessionToken;
  };

  const getLoggedUser = () => {
    const user = LocalStorageService.get("loggedUser");
    return user;
  };

  const getNavbarTitle = () => {
    return LocalStorageService.get("navbarTitle");
  };

  const [navbarTitle, setNavbarTitle] = useState(getNavbarTitle());

  const [loggedUser, setLoggedUser] = useState(getLoggedUser());
  const [token, setToken] = useState(getToken());

  const setNavbarTitleOverride = (text) => {
    LocalStorageService.set("navbarTitle", text);
    setNavbarTitle(text);
  };

  return (
    <AuthContext.Provider
      value={{
        loggedUser,
        setLoggedUser,
        token,
        setToken,
        navbarTitle,
        setNavbarTitleOverride,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
