import {
  LOG_OUT,
  SET_AUTH_USER,
  SET_LOGGED_USER,
} from "../action-types/authActionTypes";

const initialState = {
  loggedUser: null,
  token: "",
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH_USER:
      const auth = action.payload;
      return auth;
    case SET_LOGGED_USER:
      const loggedUser = action.payload;
      return {
        ...state,
        loggedUser,
      };
    case LOG_OUT:
      return initialState;
    default:
      return state;
  }
};

export default authReducer;
