import cardQuantityActionTypes from "../action-types/cardQuantityActionTypes";

const { INCREASE_QUANTITY, DECREASE_QUANTITY, SET_QUANTITY, CLEAR_QUANTITY } =
  cardQuantityActionTypes;

const initialState = 0;

const cardQuantityReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_QUANTITY:
      return action.payload;
    case INCREASE_QUANTITY:
      return state + action.payload;
    case DECREASE_QUANTITY:
      return state - action.payload;
    case CLEAR_QUANTITY:
      return initialState;
    default:
      return state;
  }
};

export default cardQuantityReducer;
