import { css } from "@emotion/react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { black_main, blue_main } from "./variables";

const primaryStyle = (props) =>
  css`
    color: ${props.variant === "primary" && blue_main};
  `;
const secondaryStyle = (props) =>
  css`
    color: ${props.variant === "secondary" && black_main};
  `;
const smallStyle = (props) => css`
  font-size: ${props.size === "small" && "14px"};
  padding: ${props.size === "small" && "7px"};
  line-height: ${props.size === "small" && "16px"};
`;
const mediumStyle = (props) => css`
  font-size: ${props.size === "medium" && "16px"};
  padding: ${props.size === "medium" && "7px"};
  font-weight: ${props.size === "medium" && "500"};
  line-height: ${props.size === "medium" && "19px"};
`;
const largeStyle = (props) =>
  css`
    font-size: ${props.size === "large" && "18px"};
    font-weight: ${props.size === "large" && "500"};
    padding: ${props.size === "large" && "14px"};
    line-height: ${props.size === "large" && "24px"};
  `;

const Link = styled.a`
  color: ${blue_main};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: ${black_main};
  }
  ${primaryStyle};
  ${secondaryStyle};
  ${smallStyle};
  ${mediumStyle};
  ${largeStyle};
`;

Link.propTypes = {
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["primary", "secondary"]),
};

export default Link;
