import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import styled from "@emotion/styled";

import SimpleTable from "../../common/atoms/SimpleTable";
import theme from "../../common/theme";

const CounterStyled = styled.span`
  font-weight: 700;
  font-family: Lato;
  font-style: italic;
  font-size: 18px;
  display: block;
  margin: 0 auto;
  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;

const Container = styled.div`
  margin-bottom: 25px;
  width: 100%;
`;

const EmployeeCellContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ProfileIcon = styled.div`
  border-radius: 50%;
  background-color: ${theme.colors.silver};
  height: 30px;
  width: 30px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  overflow: hidden;
`;

const DniRatingTable = ({ type, tableTitle, data }) => {
  const navigate = useNavigate();
  const generalColumns = [
    {
      field: "counter",
      headerName: "#",
      headerAlign: "center",
      width: "60",
      renderCell: (value) => <CounterStyled>{value}.</CounterStyled>,
    },
    {
      field: "names",
      headerName: "Nombre",
      width: "200",
      headerAlign: "center",
      renderCell: (value, row) => {
        return (
          <EmployeeCellContainer>
            <ProfileIcon>
              <img
                width="100%"
                src={
                  (row && row.cardPhoto) ||
                  require("../../common/assets/img/no-avatar-img.jpg")
                }
              />
            </ProfileIcon>
            {value}
          </EmployeeCellContainer>
        );
      },
    },
    {
      field: "uniqueViews",
      headerName: "Vistas únicas",
      headerAlign: "center",
      width: "130",
    },
    // {
    //   field: "averageRating",
    //   headerName: "Calificación",
    //   headerAlign: "center",
    //   width: "120",
    // },
    {
      field: "viewsWithDniRate",
      headerName: "CR",
      headerAlign: "center",
      width: "100",
    },
    {
      field: "collectedDniQuantity",
      headerName: "Data Recolectada",
      headerAlign: "center",
      width: "150",
    },
    {
      field: "actions",
      headerName: "",
      width: "100",
      headerAlign: "center",
      renderCell: (value, row) => {
        return (
          <IconButton
            aria-label="view"
            onClick={() => navigate(`/company/collection/${row.id}`)}
          >
            <ArrowCircleRightIcon />
          </IconButton>
        );
      },
    },
  ];

  const personalColumns = [
    {
      field: "uniqueViews",
      headerName: "Vistas únicas",
      headerAlign: "center",
      width: "150",
    },
    {
      field: "viewsWithDniRate",
      headerName: "CR",
      headerAlign: "center",
      width: "150",
    },
    {
      field: "collectedDniQuantity",
      headerName: "Data Recolectada",
      headerAlign: "center",
      width: "150",
    },
    {
      field: "actions",
      headerName: "",
      width: "100",
      headerAlign: "center",
      renderCell: () => {
        return (
          <IconButton aria-label="view" onClick={() => navigate(`/collection`)}>
            <ArrowCircleRightIcon />
          </IconButton>
        );
      },
    },
  ];

  const [columns, setColumns] = useState(
    type === "personal" ? personalColumns : generalColumns
  );

  return (
    <Container>
      <SimpleTable
        className="top-referrals-table"
        rows={data || []}
        columns={columns}
        noDataMessage="No hay eventos registrados"
        title={tableTitle}
        variant="primary"
      />
    </Container>
  );
};

DniRatingTable.propTypes = {
  filter: PropTypes.string,
  type: PropTypes.oneOf(["general", "personal"]),
  tableTitle: PropTypes.string,
  data: PropTypes.array,
};

export default DniRatingTable;
