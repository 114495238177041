import { call, put } from "redux-saga/effects";
import { setLoggedUser } from "../actions/authActions";
import { setAccounts } from "../actions/linkedAccountsActions";
import { getLinkedAccounts } from "../requests/linkedAccountsRequests";

export function* updateLoggedUserHandler(action) {
  try {
    const employee = action.payload;
    const response = yield call(getLinkedAccounts);
    const users = response.data.employees;
    yield put(setAccounts(users));
    yield put(setLoggedUser(employee));
  } catch (error) {
    if (error.response) {
      console.log(error.response.data);
      return;
    }
    console.log(error);
  }
}
