import noPaddingActionTypes from "../action-types/noPaddingActionTypes";

const { SET_NO_PADDING } = noPaddingActionTypes;
const initialState = false;

const noPaddingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NO_PADDING:
      return action.payload;

    default:
      return state;
  }
};

export default noPaddingReducer;
