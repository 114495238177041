import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "@redux-saga/core";
import cardQuantityReducer from "./business-cards/reducers/cardQuantityReducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import currentCompanyReducer from "./companies/reducers/currentCompanyReducer";
import rootSaga from "./rootSaga";
import generalPageLoadingReducer from "./companies/reducers/generalPageLoadingReducer";
import mobileMenuOpenReducer from "./companies/reducers/mobileMenuOpenReducer";
import authReducer from "./accounts/reducers/authReducer";
import linkedAccountsReducer from "./accounts/reducers/linkedAccountsReducer";
import noPaddingReducer from "./common/reducers/noPaddingReducer";
import changeAccountReducer from "./accounts/reducers/changeAccountReducer";

const persistConfig = {
  key: "root",
  storage,
};

const reducers = persistReducer(
  persistConfig,
  combineReducers({
    cardQuantity: cardQuantityReducer,
    authUser: authReducer,
    linkedAccounts: linkedAccountsReducer,
    changeAccount: changeAccountReducer,
    currentCompany: currentCompanyReducer,
    generalPageLoading: generalPageLoadingReducer,
    mobileMenuOpen: mobileMenuOpenReducer,
    noPadding: noPaddingReducer,
  })
);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware];

export const store = createStore(
  reducers,
  {},
  composeEnhancers(applyMiddleware(...middleware))
);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
