import styled from "@emotion/styled";

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  @media (max-width: 520px) {
    a,
    button {
      width: 100%;
    }
  }
`;

export default ButtonGroup;
