import styled from "@emotion/styled";
import { useState } from "react";
import CardContainer from "../../common/atoms/CardContainer";
import { TextField } from "@mui/material";
import { LogoUploadInput } from "../../common/molecules/LogoUploadInput";
import { FileServices } from "../../common/services/FileServices";
import EmployeeService from "../services/EmployeeService";
import { EditIcon } from "../../common/atoms/SvgIcons";
import EditFieldDialog from "../../common/molecules/EditFieldDialog";
import Typography from "../../common/atoms/Typography";
import { useDispatch, useSelector } from "react-redux";
import { updateLoggedUser } from "../../redux/store/accounts/actions/authActions";

const EditIconContainer = styled.div`
  margin: 8px 0px 0px 4px;
  cursor: pointer;
`;

const LogoInformation = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 480px) {
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    p {
      text-align: center;
    }
  }
`;

const UploadLogoContainer = styled.div``;

const LogoTipText = styled(Typography)`
  padding-left: 40px;
  @media (max-width: 480px) {
    padding: 0;
  }
`;

const FormContainer = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 24px 0px 0px 0px;
`;

const TextFieldContainer = styled.div`
  width: 45%;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const PersonalProfileForm = () => {
  const { loggedUser } = useSelector((state) => state.authUser);
  const dispatch = useDispatch();
  const [formFields, setFormFields] = useState({
    name: {
      value: loggedUser.name,
      placeholder: "Nombre",
      validation: (v) => {
        if (!v) {
          return "El nombre es requerido";
        } else {
          return /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '.'-]+$/.test(
            v
          )
            ? ""
            : "El nombre es inválido";
        }
      },
      description: "Escribe tu nombre",
      open: false,
      type: "text",
    },
    lastname: {
      value: loggedUser.lastname,
      placeholder: "Apellido",
      validation: (v) => {
        if (!v) {
          return "El apellido es requerido";
        } else {
          return /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/.test(
            v
          )
            ? ""
            : "El apellido es inválido";
        }
      },
      description: "Escribe tu apellido",
      open: false,
      type: "text",
    },
    email: {
      value: loggedUser.email,
      placeholder: "Correo",
      validation: (v) => {
        if (!v) return "Debe ingresar el email";
        return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/.test(v)
          ? ""
          : "El correo es inválido";
      },
      description: "Escribe tu correo",
      open: false,
      type: "text",
    },
    phone: {
      value: loggedUser.phone,
      placeholder: "Teléfono",
      validation: (v) => {
        if (!v) return "El teléfono es requerido";
        let phoneText = v.toString();
        if (phoneText.length > 18 || phoneText.length < 9) {
          return "El número no és válido";
        }
        return "";
      },
      description: "Escribe tu teléfono",
      open: false,
      type: "text",
    },
  });

  const logoUpload = async (logoFile) => {
    if (
      loggedUser.profileImage !== undefined ||
      loggedUser.profileImage !== ""
    ) {
      if (logoFile) {
        let url = "";
        try {
          let filename = `${logoFile.name}-${new Date().getTime()}`;
          let reference = FileServices.getReference("image/*", filename);
          url = await FileServices.uploadFile(logoFile, reference);
          await updateEmployee("profileImage", url);
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const updateEmployee = async (name, value) => {
    const body = {
      [name]: value,
    };

    const response = await EmployeeService.editEmployee(loggedUser.id, body);

    if (response.status === 200 && response.data.updatedEmployee) {
      dispatch(updateLoggedUser(response.data.updatedEmployee));
      if (name !== "profileImage") {
        const newObj = { ...formFields[name], value: value };
        setFormFields((prev) => ({
          ...prev,
          [name]: newObj,
        }));
        closeEdition(name);
      }
    }
  };

  const openEdition = (name) => {
    setFormFields((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        open: true,
      },
    }));
  };

  const closeEdition = (name) => {
    setFormFields((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        open: false,
      },
    }));
  };

  return (
    <CardContainer headerTitle="Configura tu perfil" headerTextAlign="start">
      <LogoInformation>
        <UploadLogoContainer>
          <LogoUploadInput
            onHandleImage={logoUpload}
            currentLogoUrl={loggedUser.profileImage}
            formType="user"
          />
        </UploadLogoContainer>
        <LogoTipText variant="small" weight="light" textAlign="left">
          Sube tu foto de perfil
          <br /> 512*512 px
        </LogoTipText>
      </LogoInformation>
      <FormContainer>
        {Object.keys(formFields).map((fieldName, index) => {
          const field = formFields[fieldName];
          return (
            <TextFieldContainer key={fieldName}>
              <TextField
                autoFocus={true}
                fullWidth
                name={fieldName}
                value={field.value}
                variant="standard"
                type={field.type}
                label={field.placeholder}
                disabled={true}
              />

              {fieldName !== "email" && (
                <EditIconContainer onClick={() => openEdition(fieldName)}>
                  <EditIcon />
                </EditIconContainer>
              )}
              <EditFieldDialog
                fieldName={fieldName}
                fieldType={field.type}
                initialValue={field.value}
                fieldLabel={field.placeholder}
                headerTitle={field.placeholder}
                descriptionText={field.description}
                validationFunction={field.validation}
                open={field.open}
                onClose={closeEdition}
                save={updateEmployee}
              />
            </TextFieldContainer>
          );
        })}
      </FormContainer>
    </CardContainer>
  );
};
