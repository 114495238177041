import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { COUNTRIES, DEFAULT_COUNTRY } from "../form.utils";
import theme from "../../../common/theme";
import InputFull from "../../../common/atoms/InputFull";
import { css } from "@emotion/react";

const PhoneContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin: 10px 0;
  > div:first-of-type {
    flex: 30%;
  }
  > div:last-of-type {
    flex: 70%;
  }
`;

const ErrorSelect = css`
  border: 1px solid ${theme.colors.pastelRed};
  color: ${theme.colors.pastelRed};
`;

const ReactFlagsSelectStyled = styled(ReactFlagsSelect)`
  padding: 0;
  button {
    padding: 0.4rem;
    border: 1px solid ${theme.colors.mineShaft};
    border-radius: 10px;
    @media screen and (max-width: 768px) {
      padding: 0.5rem;
      font-size: 0.8rem !important;
    }
  }
`;

const SelectLabel = styled.select`
  font-family: "Lato", sans-serif;
  font-size: 16px;
  color: ${theme.colors.mineShaft};
  background: transparent;
  padding: 0.8rem 1.2rem 0.8rem 0.8rem;
  border-radius: 10px;
  border: 1px solid ${theme.colors.mineShaft};
  width: 100%;
  margin: 10px 0;
  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
    no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
  @media screen and (max-width: 768px) {
    padding: 0.7rem 0.8rem;
    font-size: 14px;
  }
  &:focus,
  &:focus-visible {
    outline: none;
    border: 1px solid ${theme.colors.azureRadiance};
    border-radius: 10px;
    border-left: 1px solid ${theme.colors.azureRadiance};
  }
  ${(props) => props.tagError && ErrorSelect}
`;

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-width: 100%;
  .icon-red {
    color: ${theme.colors.pastelRed};
    transition: color 0.5s;
    cursor: pointer;
    &:hover {
      color: #b20101;
    }
  }
`;

const PhoneFieldInput = ({
  extra,
  onChange,
  name,
  type,
  valueType,
  position,
  deleteInputField,
  valueError,
  valueHelperText,
  tagError,
  valueLabel,
  disabledValue,
  valueOfElement,
  tagOfElement,
  countryOfElement,
  onKeyDown = () => {},
}) => {
  const listLabels = [
    { label: "Seleccionar Etiqueta", hidden: true },
    { value: "Teléfono Oficina", label: "Teléfono Oficina" },
    { value: "Teléfono Principal", label: "Teléfono Principal" },
    { value: "Celular", label: "Celular" },
    { value: "Trabajo", label: "Trabajo" },
    { value: "Otro", label: "Otro" },
  ];
  const [countrySelected, setCountrySelected] = useState("");
  const [tagSelected, setTagSelected] = useState(listLabels[0]);

  useEffect(() => {
    handleCountryChange(DEFAULT_COUNTRY);
  }, []);

  useEffect(() => {
    if (countryOfElement) {
      setCountrySelected(countryOfElement);
    }
  }, [countryOfElement]);

  useEffect(() => {
    if (tagOfElement) {
      setTagSelected(tagOfElement);
    }
  }, [tagOfElement]);

  const handleChange = (e, category) => {
    onChange(category, position, e.target.value);
  };

  const handleCountryChange = (code) => {
    setCountrySelected(code);
    onChange("country", position, code);
    onChange("value", position, valueOfElement);
  };

  const handleTagChange = (e) => {
    setTagSelected(e.target.value);
    onChange("tag", position, e.target.value);
  };

  const deleteField = (e) => {
    deleteInputField(e, type, name, position);
  };

  const handleOnKeyDown = (e) => {
    onKeyDown(e);
  };
  return (
    <>
      <PhoneContainer>
        <ReactFlagsSelectStyled
          placeholder="País"
          searchPlaceholder="Buscar"
          showSelectedLabel={false}
          showSecondarySelectedLabel={true}
          showOptionLabel={false}
          showSecondaryOptionLabel={true}
          searchable={false}
          countries={Object.keys(COUNTRIES)}
          customLabels={COUNTRIES}
          selected={countrySelected}
          onSelect={(code) => handleCountryChange(code)}
        />
        <InputFull
          autoFocus={valueOfElement.length === 0 && position > 0 ? true : false}
          error={valueError}
          errorMessage={valueHelperText}
          label={valueLabel}
          name={name}
          variant="standard"
          disabled={disabledValue}
          fullWidth
          removeMargin
          value={valueOfElement}
          onChange={(e) => handleChange(e, "value")}
          type={valueType}
          onKeyDown={(e) => handleOnKeyDown(e)}
        />
      </PhoneContainer>
      <SelectContainer>
        <SelectLabel
          onChange={(e) => handleTagChange(e)}
          value={tagSelected}
          tagError={tagError}
        >
          {listLabels.map((option, index) => (
            <option
              key={index.toString()}
              value={option.value}
              hidden={option.hidden}
            >
              {option.label}
            </option>
          ))}
        </SelectLabel>
        {extra && (
          <i
            className="bi bi-trash icon-red"
            onClick={(e) => deleteField(e)}
          ></i>
        )}
      </SelectContainer>
    </>
  );
};

export default PhoneFieldInput;
