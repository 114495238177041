import styled from "@emotion/styled";
import { flexbox, space } from "styled-system";

const StyledFlex = styled.div`
  display: flex;
  gap: ${(props) => props.gap};
  ${flexbox};
  ${space};
`;

const Flex = (props) => {
  return <StyledFlex {...props}>{props.children}</StyledFlex>;
};

export default Flex;
