import { call, put, select } from "redux-saga/effects";
import { setLoggedUser } from "../../accounts/actions/authActions";
import { setAccounts } from "../../accounts/actions/linkedAccountsActions";
import { getLinkedAccounts } from "../../accounts/requests/linkedAccountsRequests";
import { setCurrentCompany } from "../actions/currentCompanyActions";
import { setPageLoading } from "../actions/generalPageLoadingActions";
import { getCurrentCompanyRequest } from "../requests/companiesRequests";

export function* handleUpdateCurrentCompany(action) {
  try {
    const response = yield call(getLinkedAccounts);
    const linkedAccounts = response.data.employees;
    const company = action.payload;
    yield put(setCurrentCompany(company));
    yield put(setAccounts(linkedAccounts));
    const loggedUser = yield select((state) => state.authUser.loggedUser);
    const currentAccount = linkedAccounts.find(
      (account) => account.id === loggedUser.id
    );
    yield put(
      setLoggedUser({ ...loggedUser, company: currentAccount.company })
    );
  } catch (error) {
    if (error.response) {
      console.log(error.response.data);
    }
  }
}

export function* handleInitCurrentCompany(action) {
  try {
    yield put(setPageLoading(true));
    const response = yield call(getCurrentCompanyRequest);
    yield put(setCurrentCompany(response.data.company));
  } catch (error) {
    if (error.response) {
      console.log(error.response.data);
      return;
    }
    console.log(error);
  } finally {
    yield put(setPageLoading(false));
  }
}
