import styled from "@emotion/styled";
import { Box, Popper } from "@material-ui/core";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { CircularProgress } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useState } from "react";
import Typography from "./../atoms/Typography";
import theme from "./../theme";
import { ROLE_NAMES } from "./../helpers/roles.utils";

const MainContainer = styled(Box)`
  min-width: 200px;
  max-width: 235px;
  width: 100%;
`;

const ImageContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-image: url(${(props) => props && props.url});
  background-size: cover;
  background-position: center;
  margin-right: 4px;
`;

const Container = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
  min-height: 54px;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${theme.colors.whiteLilac};
  }
`;

const SelectBox = styled.div`
  background-color: white;
  border-radius: 10px;
  border-radius: ${(props) => props.open && "0px 0px 10px 10px"};
  margin: 12px;
  cursor: pointer;
  overflow: hidden;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  max-width: 140px;
  text-overflow: ellipsis;
`;

const SelectTitle = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 15px;
`;

const SelectDescription = styled(Typography)`
  font-size: 11px;
  line-height: 12px;
  font-style: italic;
  max-width: 150px;
  text-overflow: ellipsis;
`;

const SelectedStyled = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 0px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: ${theme.colors.black};
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const Selector = styled(Popper)`
  border-radius: 10px 10px 0px 0px;
  z-index: 10000;
  background-color: white;
  max-width: 235px;
  width: 100%;
  overflow: hidden;
  left: 1px !important;
  border-bottom: 1px solid ${theme.colors.alto};
`;

// TODO: Refactor this component to be decoupled from business logic
export const SelectButton = ({
  list,
  selected,
  setItemSelected,
  placeholder,
  onOpen,
  onClose,
  loadingText,
}) => {
  const [anchor, setAnchor] = useState(null);

  const handleClick = (e) => {
    if (!list || !list.length || list.length === 1) {
      return;
    }
    setAnchor(anchor !== null ? null : e.currentTarget);
    onOpen();
  };

  const handleClose = () => {
    setAnchor(null);
    onClose();
  };

  const selectItem = (element) => {
    setItemSelected(element);
    handleClose();
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <MainContainer>
        {(!selected || !list || list.length === 0) && (
          <SelectBox>
            <Container>
              <CircularProgress size={20} />
              <TextContainer style={{ marginLeft: "8px" }}>
                <Typography variant="tiny" weight="bold">
                  {loadingText}
                </Typography>
              </TextContainer>
            </Container>
          </SelectBox>
        )}
        {selected && list && list.length > 0 && (
          <>
            <SelectBox onClick={handleClick} open={Boolean(anchor)}>
              <Container>
                <ImageContainer url={selected.company.logoUrl} />
                <TextContainer>
                  <Typography variant="xtiny" weight="bold">
                    {placeholder}
                  </Typography>
                  <SelectedStyled variant="xtiny" weight="regular">
                    {selected.company.name}
                  </SelectedStyled>
                </TextContainer>
                {Boolean(anchor) ? (
                  <ArrowDropDownIcon style={{ marginLeft: "auto" }} />
                ) : (
                  <ArrowDropUpIcon style={{ marginLeft: "auto" }} />
                )}
              </Container>
            </SelectBox>
            <Selector open={Boolean(anchor)} anchorEl={anchor} placement="top">
              {list !== null &&
                list
                  .filter((item) => item !== selected)
                  .map((element, index) => {
                    return (
                      <Container
                        key={index.toString()}
                        onClick={(e) => selectItem(element)}
                      >
                        <ImageContainer url={element.company.logoUrl} />
                        <TextContainer>
                          <SelectTitle variant="xtiny" weight="bold">
                            {element.company.name}
                          </SelectTitle>
                          <SelectDescription variant="xtiny">
                            {ROLE_NAMES[element.role]}
                          </SelectDescription>
                        </TextContainer>
                      </Container>
                    );
                  })}
            </Selector>
          </>
        )}
      </MainContainer>
    </ClickAwayListener>
  );
};
