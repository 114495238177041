import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../common/atoms/Button";
import CardContainer from "../../common/atoms/CardContainer";
import Typography from "../../common/atoms/Typography";
import EditFieldDialog from "../../common/molecules/EditFieldDialog";
import theme from "../../common/theme";
import EmployeeService from "../services/EmployeeService";
import MessageDialog from "../../common/molecules/MessageDialog";
import { initAccounts } from "../../redux/store/accounts/actions/linkedAccountsActions";

const Wrapper = styled.div`
  max-width: 325px;
  width: 100%;
  div {
    div {
      &:last-child {
        padding: 26px;
      }
    }
  }
  @media (min-width: 1920px) {
    max-width: 400px;
  }
`;

const EmployeeRow = styled.div`
  border-bottom: 1px solid ${theme.colors.alto};
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  p {
    color: ${(props) => props.disabled && theme.colors.carnation};
    text-overflow: ellipsis;
    overflow: hidden;
  }
  @media (max-width: 480px) {
    p {
      width: 100%;
    }
    flex-wrap: wrap;
  }
`;

const TrashIcon = styled.i`
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  color: ${(props) =>
    props.disabled ? theme.colors.silver : theme.colors.carnation};
`;

const AccountCenter = () => {
  const [open, setOpen] = useState(false);
  const [linkedEmployees, setLinkedEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState("");
  const [initialEmail, setInitialEmail] = useState("");
  const [linkedEmployeeToDelete, setLinkedEmployeeToDelete] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [linkingRequestModalOpen, setLinkingRequestModalOpen] = useState(false);

  const { loggedUser } = useSelector((state) => state.authUser);
  const dispatch = useDispatch();

  useEffect(() => {
    getLinkedAccounts();
  }, []);

  const getLinkedAccounts = () => {
    try {
      setLoading(true);
      EmployeeService.getLinkedAccounts()
        .then((response) => {
          const { employees } = response.data;
          setLinkedEmployees(employees);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (ex) {
      console.log({ ex });
    }
  };

  const handleClose = () => {
    setServerErrorMessage("");
    setInitialEmail("");
    setOpen(false);
  };
  const handleLinkAccount = async (_fieldName, value) => {
    setServerErrorMessage("");
    try {
      let res = await EmployeeService.confirmLinking(value);
      if (res.status === 200) {
        handleClose();
        setLinkingRequestModalOpen(true);
      }
    } catch (ex) {
      if (ex.response) {
        if (ex.response.status === 409 || ex.response.status === 404) {
          setServerErrorMessage(ex.response.data.message);
        }
      }
      console.log({ ex });
    }
  };

  const accountValidation = (v) => {
    if (!v) return "Debe ingresar el email";
    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/.test(v)
      ? ""
      : "El correo es inválido";
  };

  const deleteAccount = () => {
    EmployeeService.deleteLinkedAccount(linkedEmployeeToDelete).then(
      () => {
        setLinkedEmployeeToDelete(null);
        dispatch(initAccounts());
        getLinkedAccounts();
      },
      (error) => {
        const message = error.response.data.message;
        toast.error(`Error. ${message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    );
  };

  return (
    <Wrapper>
      <CardContainer headerTextAlign="start" headerTitle="Centro de cuentas">
        {loading && <CircularProgress />}
        {!loading &&
          linkedEmployees.map((employee, index) => (
            <EmployeeRow key={index} disabled={employee.disabled}>
              <Typography variant="xs">{employee.email}</Typography>
              <TrashIcon
                disabled={employee.id === loggedUser.id}
                className="bi bi-trash"
                onClick={() => {
                  if (employee.id === loggedUser.id) return;
                  setLinkedEmployeeToDelete(employee.id);
                  setDeleteDialogOpen(true);
                }}
              />
            </EmployeeRow>
          ))}
        {!loading && linkedEmployees.length === 0 && (
          <Typography variant="xs">No hay cuentas vinculadas.</Typography>
        )}
        <br />
        {!loading && (
          <Button variant="primary" size="medium" onClick={() => setOpen(true)}>
            Añadir nueva cuenta
          </Button>
        )}
        <EditFieldDialog
          fieldName="accountEmail"
          fieldType="email"
          initialValue={initialEmail}
          fieldLabel="Correo"
          headerTitle="Añadir cuenta"
          descriptionText="Ingrese el correo de la cuenta a vincular"
          validationFunction={accountValidation}
          open={open}
          onClose={handleClose}
          serverErrorMessage={serverErrorMessage}
          save={handleLinkAccount}
        />
        <MessageDialog
          headerTitle="Eliminar cuenta vinculada"
          message="¿Estás seguro de eliminar la vinculación con esta cuenta?"
          open={deleteDialogOpen}
          setOpen={setDeleteDialogOpen}
          showCancel={true}
          showConfirm={true}
          onConfirm={deleteAccount}
        />
        <MessageDialog
          headerTitle="Solicitud de vinculación enviada"
          message="Se ha enviado un correo para confirmar la vinculación de la nueva cuenta"
          open={linkingRequestModalOpen}
          setOpen={setLinkingRequestModalOpen}
          showConfirm={true}
          confirmLabel="Aceptar"
        />
      </CardContainer>
    </Wrapper>
  );
};

export default AccountCenter;
