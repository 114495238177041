import { CircularProgress } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AddCardButton from "../components/AddCardButton";
import { CardItem } from "../components/CardItem";
import { BusinessCardsContext } from "../context/BusinessCardsContext";
import { BusinessCardService } from "../services/BusinessCardService";
import CreateCardInvitation from "../components/CreateCardInvitation";
import TextSearch from "../../common/atoms/TextSearch";
import { debounce } from "../../common/helpers/functions";
import styled from "@emotion/styled";
import { Roles } from "../../common/atoms/variables";
import Pagination from "../../common/atoms/Pagination";
import CardsLegend from "../../common/molecules/CardsLegend";

const ListContainer = styled.div`
  display: flex;
  gap: 22px;
  flex-wrap: wrap;
  @media (min-width: 1920px) {
    gap: 40px;
  }
  @media (max-width: 516px) {
    justify-content: center;
  }
`;

const MAX_LINKED_ACCOUNTS = 10;
const PAGE_SIZE = 15;

export default function BusinessCardList() {
  const [cards, setCards] = useState([]);
  const { loggedUser } = useSelector((state) => state.authUser);
  const [loading, setLoading] = useState(false);
  const { setFormMode, setIsOwnCard } = useContext(BusinessCardsContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const showAddButton =
    loggedUser &&
    loggedUser.role.startsWith("ADMIN") &&
    (location.pathname === "/company/cards" ||
      ((location.pathname === "/" || location.pathname === "/cards") &&
        !loggedUser.haveCard &&
        loggedUser.role === Roles.ADMIN_FOUNDER));
  const showInvitationButton =
    loggedUser &&
    (location.pathname === "/" || location.pathname === "/cards") &&
    loggedUser.role !== "EMPLOYEE";

  useEffect(() => {
    setCards([]);
    setLoading(true);
    setIsOwnCard(true);
    getOwnCards();
  }, [location.pathname, currentPage, search]);

  const getOwnCards = async () => {
    setLoading(true);
    BusinessCardService.getOwnBusinessCards(currentPage - 1, search)
      .then((response) => {
        const businessCards = response.data.businessCards || [];
        setCards(businessCards);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const goToTemplateSelection = () => {
    setFormMode("creation");
    navigate("/cards/templates");
  };

  const goToCardDetail = (cardId) => {
    setFormMode("edition");
    navigate(`/cards/${cardId}`);
  };

  const goToInvitationCardTemplateSelection = (cardId) => {
    setFormMode("default-card-edition");
    navigate(`/cards/${cardId}/template`);
  };

  const renderCardAddButtons = () => {
    if (showAddButton) {
      return <AddCardButton onClick={goToTemplateSelection} />;
    }
  };

  const handleSearch = (event) => {
    const value = event.nativeEvent.target.value;
    setCurrentPage(1);
    setSearch(value);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const debouncedChangeHandler = useCallback(debounce(handleSearch, 300), []);

  return (
    <div className="container-fluid p-0">
      <div style={{ marginBottom: "20px" }} className="step-3">
        <TextSearch onSearch={debouncedChangeHandler} />
      </div>
      <CardsLegend />
      <ListContainer className="step-4">
        {renderCardAddButtons()}
        {loading && (
          <div className="loading-container">
            <CircularProgress />
          </div>
        )}
        {cards &&
          cards.map((card, index) => {
            if (showInvitationButton && card.status === "PENDING") {
              return (
                <CreateCardInvitation
                  key={index}
                  onClick={() => {
                    goToInvitationCardTemplateSelection(card.id);
                  }}
                  currentCompany={card.company}
                  cardType={card.type}
                />
              );
            } else {
              return (
                <CardItem
                  key={index}
                  card={card}
                  goToCardDetail={goToCardDetail}
                />
              );
            }
          })}
        {!cards ||
          (cards.length <= 0 && <div style={{ height: "250px" }}></div>)}
      </ListContainer>
      <Pagination
        total={MAX_LINKED_ACCOUNTS}
        pageSize={PAGE_SIZE}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
}
