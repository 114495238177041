import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "@emotion/styled";

import "../assets/css/styles.css";
import CardFieldsList from "../components/CardFieldsList";
import CardPreview from "../components/CardPreview";
import { FileServices } from "../../common/services/FileServices";
import { BusinessCardService } from "../services/BusinessCardService";
import { BusinessCardsContext } from "../context/BusinessCardsContext";
import CardForm from "../components/CardForm";
import PageLoader from "../../common/atoms/PageLoader";
import { setLoggedUser } from "../../redux/store/accounts/actions/authActions";
import { CATEGORIES, COUNTRIES, DEFAULT_COUNTRY } from "./../components/form.utils";
import { increaseCardQuantity } from "../../redux/store/business-cards/actions/cardQuantityActions";
import { AuthContext } from "../../auth-management/context/AuthContext";

const Container = styled.div`
  display: flex;
  gap: 50px;
  flex-wrap: wrap;
  @media (min-width: 1920px) {
    gap: 67px;
  }
  @media screen and (max-width: 480px) {
    justify-content: center;
  }
`;

const CardColumn = styled.div`
  max-width: 350px;
  width: 100%;
  @media (min-width: 1920px) {
    max-width: 467px;
  }
`;

export default function EditionPage() {
  const { loggedUser, token } = useSelector((state) => state.authUser);

  const currentCompany = useSelector((state) => state.currentCompany);
  const { isOwnCard, setIsOwnCard, initialCard, currentTemplateId } =
    useContext(BusinessCardsContext);
  const { cardId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [card, setCard] = useState(null);
  const [formMode, setFormMode] = useState(cardId ? "edition" : "creation");
  const [pageLoading, setPageLoading] = useState(false);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setNavbarTitleOverride } = useContext(AuthContext);
  const [hasInstitution, setHasInstitution] = useState(false);
  const cardFormRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (cardId) {
        if (searchParams.get("redirected") === "true") {
          setNavbarTitleOverride("Tarjetas");
        }
        fetchCardInfo();
      } else {
        let currentEmail = "";
        if (location.pathname === "/cards/own-creation-form") {
          setIsOwnCard(true);
          currentEmail = loggedUser.email
        } else {
          setIsOwnCard(false);
        }
        /*
        TODO: 
        Se está inicializando de nuevo phones, 
        ya que se mantiene el valor cuando retrocedes durante el proceso de creación 
        */
        let cardToSet = {
          ...initialCard, templateId: currentTemplateId, generalContacts: {
            phones: [
              {
                value: "",
                tag: "",
                country: DEFAULT_COUNTRY,
                extension: COUNTRIES[DEFAULT_COUNTRY].secondary,
              },
            ],
          }
        };

        cardToSet.baseInformation.company = {
          id: currentCompany.id,
          name: currentCompany.name,
          logoUrl: currentCompany.logoUrl,
        };
        cardToSet.generalContacts.webpage = currentCompany.webUrl;
        cardToSet.generalContacts.email = currentEmail;
        setCard(cardToSet);
      }
    }, 500);
  }, []);

  const fetchCardInfo = () => {
    setPageLoading(true);
    BusinessCardService.getBusinessCardById(cardId)
      .then((res) => {
        if (res.data.businessCard) {
          let fetchedCard = res.data.businessCard;
          let cardFormatted = {
            ...fetchedCard,
            generalContacts: {
              ...fetchedCard["generalContacts"],
              email:
                fetchedCard.employee.customEmail || fetchedCard.employee.email,
            },
          };
          setHasInstitution(
            cardFormatted.institution !== undefined &&
            cardFormatted.institution !== ""
          );
          setCard(cardFormatted);
        }
      })
      .catch((error) => {
        if (error.response) {
          const status = error.response.status;
          if (status === 403 || status === 404) {
            navigate("/not-found");
            return;
          }
        }
        console.log(error);
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  const prepareFiles = (cardInfo) => {
    const files = [];
    const photo = cardInfo.baseInformation.photo;
    const pdf = cardInfo.generalContacts.pdf;
    const businessPresentations =
      cardInfo.generalContacts.businessPresentations;
    if (photo.value) {
      files.push({
        file: photo.value,
        filename: photo.filename,
        category: "baseInformation",
        acceptedType: "image/*",
        key: "photo",
      });
    }
    if (pdf && pdf.value) {
      files.push({
        file: pdf.value,
        filename: pdf.filename,
        category: "generalContacts",
        acceptedType: "application/pdf",
        key: "pdf",
      });
    }
    if (businessPresentations && businessPresentations.length > 0) {
      businessPresentations
        .filter((item) => item.value)
        .forEach((item) => {
          files.push({
            file: item.value,
            filename: item.filename,
            category: "generalContacts",
            acceptedType: "application/pdf",
            key: "businessPresentations",
          });
        });
    }

    return files;
  };

  const handleAddField = (name, category, field) => {
    cardFormRef.current.addField(name, category, field);
  };

  const formatOutput = (cardInfo) => {
    let newCard = {};
    const baseCategories = Object.values(CATEGORIES);
    Object.keys(cardInfo).forEach((category) => {
      if (category === "templateId" || category === "institution") {
        newCard[category] = cardInfo[category];
        return;
      }

      if (!baseCategories.includes(category)) {
        return;
      }
      newCard[category] = cardInfo[category];
    });
    return newCard;
  };

  const submitCard = async () => {
    const cardOutput = formatOutput(card);
    setPageLoading(true);

    const { data: { businessCard } } = await BusinessCardService.getBusinessCardById(cardId);
    const { baseInformation: oldBaseInformation, generalContacts: oldGeneralContacts } = businessCard;
    // update image
    if (cardOutput.baseInformation.photo.filename !== oldBaseInformation.photo.filename) {
        const ref = FileServices.getReference("image/*", oldBaseInformation.photo.filename);
        FileServices.deleteFile(ref);
    }
    // update pdf
    if (!cardOutput.generalContacts.pdf && oldGeneralContacts.pdf) {
      const ref = FileServices.getReference('application/pdf', oldGeneralContacts.pdf.filename)
      FileServices.deleteFile(ref)
    } 
    if (cardOutput.generalContacts.pdf && oldGeneralContacts.pdf) {
      if (cardOutput.generalContacts.pdf.filename !== oldGeneralContacts.pdf.filename) {
        const ref = FileServices.getReference('application/pdf', oldGeneralContacts.pdf.filename)
        FileServices.deleteFile(ref)
      }
    }
    // update video
    if (cardOutput.generalContacts.businessPresentations.length === 0 && oldGeneralContacts.businessPresentations.length > 0) {
      const ref = FileServices.getReference('application/pdf', oldGeneralContacts.businessPresentations[0].filename)
      FileServices.deleteFile(ref)
    } 
    if (cardOutput.generalContacts.businessPresentations.length > 0 && oldGeneralContacts.businessPresentations.length > 0) {
      if (cardOutput.generalContacts.businessPresentations[0].filename !== oldGeneralContacts.businessPresentations[0].filename) {
        const ref = FileServices.getReference('application/pdf', oldGeneralContacts.businessPresentations[0].filename)
        FileServices.deleteFile(ref)
      }
    }
    
    let auxCard = cardOutput;
    const files = prepareFiles(cardOutput);
    const fileReferences = [];
    try {
      if (files.length > 0) {
        for (let element of files) {
          const filename = `${cardId}-${new Date().getTime()}`;
          const reference = FileServices.getReference(
            element.acceptedType,
            filename
          );
          const url = await FileServices.uploadFile(element.file, reference);

          fileReferences.push({ filename, type: element.acceptedType });
          auxCard = {
            ...auxCard,
            [element.category]: {
              ...auxCard[element.category],
              [element.key]: {
                ["url"]: url,
                ["filename"]: filename,
                ["externalUrl"]: element.externalUrl,
              },
            },
          };
        }
      }
    } catch (error) {
      console.log(error.message);
      toast.error(`Error al subir las imágenes`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }

    try {
      if (formMode === "creation") {
        if (!isOwnCard) {
          await BusinessCardService.createBusinessCard(auxCard);
        } else {
          await BusinessCardService.createOwnBusinessCard(auxCard);
        }
        dispatch(increaseCardQuantity());
      } else if (formMode === "edition" && cardId !== undefined) {
        await BusinessCardService.editBusinessCard(auxCard, cardId);
      }
      if (formMode === "creation") {
        let user = { ...loggedUser };
        user.haveCard = true;
        dispatch(setLoggedUser(user));
      }

      if (!isOwnCard) {
        navigate("/company/cards");
      } else {
        navigate("/");
      }
    } catch (error) {
      if (fileReferences.length > 0) {
        for (let element of fileReferences) {
          const ref = FileServices.getReference(element.type, element.filename);
          FileServices.deleteFile(ref);
        }
      }
      if (error.response) {
        const message = error.response.data.message;
        toast.error(`Error. ${message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }

      console.log(error);
    } finally {
      setPageLoading(false);
    }
  };

  const handleBack = () => {
    if (!isOwnCard) {
      navigate("/company/cards");
      return;
    }
    navigate("/cards");
  };

  return (
    <>
      {pageLoading && <PageLoader />}
      {card && (
        <div className="container-fluid p-0 col-md-12">
          <div
            className="mb-3 d-flex align-items-center gap-2"
            style={{ cursor: "pointer", padding: "0 5px" }}
            onClick={handleBack}
          >
            <i className="bi bi-arrow-left"></i>
            <span className="h5 mb-1">
              {`Tarjeta de Negocio: ${card.baseInformation.name} ${card.baseInformation.lastname} ${currentCompany.name}`}
            </span>
          </div>
          <Container>
            <CardForm
              ref={cardFormRef}
              initialCardData={card}
              setCardValues={setCard}
              submitCard={submitCard}
              mode={formMode}
              isOwnCard={isOwnCard}
              hasInstitution={hasInstitution}
              currentCompany={currentCompany}
            />
            <div>
              <CardFieldsList addField={handleAddField} />
            </div>
            <CardColumn>
              <CardPreview
                cardValues={card}
                interactive={false}
                mode="dynamic"
                colors={
                  card.baseInformation.company.colors || currentCompany.colors
                }
              />
            </CardColumn>
          </Container>
        </div>
      )}
    </>
  );
}
