export const black_main = "#2B2B2B";
export const blue_main = "#007AFF";
export const blue_secondary = "#1a70ce";
export const white = "#FFFFFF";
export const grayone = "#EAEAEA";
export const graytwo = "#C7C7C7";
export const secondaryGray = "F0F2FA";
export const danger = "#F26464";
export const black = "#2B2B2B";
export const purple = "#5856D6";
export const light_green = "#01FF83";
export const middle_green = "#01A54A";
export const orange = "#FFC531";
export const science_blue = "#006CE0";

export const defaultCardPhoto =
  "https://firebasestorage.googleapis.com/v0/b/holalinktest.appspot.com/o/images%2Fno-avatar-img.jpg?alt=media&token=b26109fd-cbbe-4708-b271-26974a9d24ec";

export const excelFormatFile =
  "https://firebasestorage.googleapis.com/v0/b/holalinktest.appspot.com/o/formats%2FHolaLink%20-%20Formato%20de%20Registro%20de%20Empleados.xlsx?alt=media&token=fd353daa-78ec-46ca-b0cd-1ad8091bda94";

export const Roles = {
  ADMIN_FOUNDER: "ADMIN_FOUNDER",
  ADMIN: "ADMIN",
  EDITOR: "EDITOR",
  EMPLOYEE: "EMPLOYEE",
};

export const THIS_WEEK = "THIS_WEEK";
export const THIS_MONTH = "THIS_MONTH";
export const THIS_YEAR = "THIS_YEAR";

export const dayNames = {
  1: "Lunes",
  2: "Martes",
  3: "Miércoles",
  4: "Jueves",
  5: "Viernes",
  6: "Sábado",
  7: "Domingo",
};

export const monthNames = {
  1: "Enero",
  2: "Febrero",
  3: "Marzo",
  4: "Abril",
  5: "Mayo",
  6: "Junio",
  7: "Julio",
  8: "Agosto",
  9: "Setiembre",
  10: "Octubre",
  11: "Noviembre",
  12: "Diciembre",
};
