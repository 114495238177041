import styled from "@emotion/styled";
import { Dialog } from "@mui/material";
import PropTypes from "prop-types";

import Button from "../atoms/Button";
import CardContainer from "../atoms/CardContainer";

const Content = styled.div`
  padding: 10px 24px;
  position: relative;
`;
const Description = styled.p`
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 16px;
`;
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0px 8px 0px;
  gap: 8px;
`;

const ButtonContent = styled.p`
  margin-bottom: 0px;
`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: 10px;
  }
`;

const MessageDialog = ({
  open,
  setOpen,
  headerTitle,
  message,
  onCancel,
  onConfirm,
  showCancel,
  showConfirm,
  cancelLabel,
  confirmLabel
}) => {
  const closeDialog = () => {
    setOpen(false);
  };
  const accept = () => {
    onConfirm();
    closeDialog();
  };
  const cancel = () => {
    onCancel();
    closeDialog();
  };

  return (
    <StyledDialog open={open} maxWidth="xs" fullWidth>
      <CardContainer noGutters headerTitle={headerTitle}>
        <Content>
          <Description>{message}</Description>
          <ButtonsContainer>
            {showCancel && (
              <Button size="small" variant="cancel" onClick={cancel}>
                <ButtonContent>{cancelLabel}</ButtonContent>
              </Button>
            )}
            {showConfirm && (
              <Button size="small" variant="primary" onClick={accept}>
                <ButtonContent>{confirmLabel}</ButtonContent>
              </Button>
            )}
          </ButtonsContainer>
        </Content>
      </CardContainer>
    </StyledDialog>
  );
};

MessageDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  headerTitle: PropTypes.string,
  message: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  showCancel: PropTypes.bool,
  showConfirm: PropTypes.bool,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string
};

MessageDialog.defaultProps = {
  onCancel: () => { },
  onConfirm: () => { },
  cancelLabel: 'Cancelar',
  confirmLabel: 'Confirmar'
};
export default MessageDialog;
