import cardQuantityActionTypes from "../action-types/cardQuantityActionTypes";

const {
  INCREASE_QUANTITY,
  DECREASE_QUANTITY,
  INIT_QUANTITY,
  SET_QUANTITY,
  CLEAR_QUANTITY,
} = cardQuantityActionTypes;

export const increaseCardQuantity = () => {
  return {
    type: INCREASE_QUANTITY,
    payload: 1,
  };
};

export const decreaseCardQuantity = () => {
  return {
    type: DECREASE_QUANTITY,
    payload: 1,
  };
};

export const initCardQuantity = (companyId) => {
  return {
    type: INIT_QUANTITY,
    companyId,
  };
};

export const setCardQuantity = (quantity) => {
  return {
    type: SET_QUANTITY,
    payload: quantity,
  };
};

export const clearQuantity = () => {
  return {
    type: CLEAR_QUANTITY,
  };
};
