import cardQuantityActionTypes from "../business-cards/action-types/cardQuantityActionTypes";
import { handleInitCardQuantity } from "./handlers/cardQuantityHandlers";
import { takeLatest } from "redux-saga/effects";
const { INIT_QUANTITY } = cardQuantityActionTypes;

function* watchInitCardQuantitySaga() {
  yield takeLatest(INIT_QUANTITY, handleInitCardQuantity);
}

export default [watchInitCardQuantitySaga];
