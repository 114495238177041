import React from "react";
import Button from "../../common/atoms/Button";
import { CustomDialog } from "../../common/atoms/Dialog";
import Typography from "../../common/atoms/Typography";
import { WhatsApp } from "@mui/icons-material";
import styled from "@emotion/styled";

const StyledWhatsAppIcon = styled(WhatsApp)`
  margin-right: 6px;
  margin-bottom: 4px;
`;

export const AdditionalPaymentDialog = ({ showDialog, closeDialog, user }) => {
  const goToWhatsApp = () => {
    if (!user) return;
    const phone = process.env.REACT_APP_HOLALINK_PHONE;
    const message = `Hola, me llamo ${
      user.name + " " + user.lastname
    } y pertenezco a la empresa ${
      user.company.name
    }. Quisiera aquirir más tarjetas para mi plan actual de HolaLink`;
    const url = `https://wa.me/${phone.replace(/\s/g, "")}?text=${message}`;
    window.open(url, "_blank");
  };

  return (
    <CustomDialog showDialog={showDialog} closeDialog={closeDialog}>
      <div>
        <Typography variant='h5'>Consigue más tarjetas</Typography>
        <br />
        <ul>
          <li>
            Puedes adquirir más tarjetas en el transcurso de tu plan actual.
          </li>
          <li>
            Para realizar la compra y/o pedir más información, dirígete a
            nuestro chat de WhatsApp seleccionando el botón de abajo.
          </li>
        </ul>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button size='medium' variant='primary' onClick={goToWhatsApp}>
            <StyledWhatsAppIcon />
            Ir a WhatsApp
          </Button>
        </div>
      </div>
    </CustomDialog>
  );
};
