import styled from "@emotion/styled";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: 10px;
  }
`;

const StyleDialogTitle = styled(DialogTitle)`
  background-color: #eaeaea;
`;

const DialogWithHeader = ({ open, headerTitle, closeDialog, children }) => {
  return (
    <StyledDialog open={open} maxWidth="sm" fullWidth>
      <StyleDialogTitle textAlign="center">
        <div style={{ position: "relative", width: "100%" }}>
          <div
            style={{
              position: "absolute",
              zIndex: 500,
              cursor: "pointer",
              right: 0,
            }}
            onClick={() => {
              closeDialog();
            }}
          >
            <CloseIcon />
          </div>
          <div style={{ position: "relative", zIndex: 100 }}>
            <b>{headerTitle}</b>
          </div>
        </div>
      </StyleDialogTitle>
      <DialogContent>{children}</DialogContent>
    </StyledDialog>
  );
};

DialogWithHeader.propTypes = {
  open: PropTypes.bool,
  headerTitle: PropTypes.string,
  children: PropTypes.node,
};
export default DialogWithHeader;
