export default {
  typography: {
    fontFamily: ["Lato", "Helvetica", "Arial", "sans-serif"].join(","),
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  colors: {
    azureRadiance: "#007AFF",
    scienceBlue: "#006CE0",
    pattensBlue: "#d9ebff",
    springGreen: "#01FF83",
    springGreenHover: "#00F57E",
    indigo: "#5856D6",
    flushOrange: "#FF8201",
    mineShaft: "#2B2B2B",
    carnation: "#F26464",
    white: "#FFFFFF",
    silver: "#C4C4C4",
    alto: "#D1D1D1",
    whiteLilac: "#F0F2FA",
    gallery: "#EAEAEA",
    silverChalice: "#A1A1A1",
    sunglow: "#FFC531",
    pastelRed: "#F26464",
  },
};
