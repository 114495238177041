import React, { useEffect } from "react";
import { createTheme, MuiThemeProvider } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { FlagsProvider } from "flagged";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { AuthProvider } from "./auth-management/context/AuthContext";
import AppRoutes from "./routing/AppRoutes";
import theme from "./common/theme";

const THEME = createTheme(theme);
function App({ apiUrl }) {
  const { features } = useSelector((state) => state.currentCompany);

  useEffect(() => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const parts = url.pathname.split("/");
    let name = parts[parts.length - 1];
    if (parts.length === 4 && searchParams.size === 0) {
      const manifestUrl = `${apiUrl}/business-card/manifest.json?name=${name}&href=${url.href}&origin=${url.origin}`;
      const link = document.createElement("link");
      link.rel = "manifest";
      link.href = manifestUrl;
      document.head.appendChild(link);
    }
  }, []);

  return (
    <FlagsProvider features={features}>
      <MuiThemeProvider theme={THEME}>
        <AuthProvider>
          <div className="App" style={{ width: "100%" }}>
            <AppRoutes />
            <ToastContainer />
          </div>
        </AuthProvider>
      </MuiThemeProvider>
    </FlagsProvider>
  );
}

export default App;
