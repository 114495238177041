import PropTypes from "prop-types";
import styled from "@emotion/styled";
import theme from "../theme";
import Typography from "./Typography";

const MainHeader = styled.div`
  padding: 10px;
  margin-bottom: 1px;
  text-align: center;
  border-radius: 10px 10px 0 0;
  p {
    color: ${(props) =>
      !props.variant || props.variant === "primary"
        ? theme.colors.white
        : theme.colors.mineShaft};
  }
  background-color: ${(props) =>
    !props.variant || props.variant === "primary"
      ? theme.colors.azureRadiance
      : theme.colors.gallery};
`;

const SimpleTableStyled = styled.table`
  thead {
    background-color: ${(props) =>
      !props.variant || props.variant === "primary"
        ? theme.colors.azureRadiance
        : theme.colors.gallery};
  }
  tbody {
    background-color: white;
    tr {
      &:hover {
        background-color: ${theme.colors.pattensBlue};
      }
    }
  }
  th {
    font-weight: bold;
    font-size: 16px;
    font-family: Lato;
    padding: 14px;
    @media screen and (max-width: 480) {
      font-size: 14px;
      padding: 10px;
    }
    color: ${(props) =>
      !props.variant || props.variant === "primary"
        ? theme.colors.white
        : theme.colors.mineShaft};
  }
  td {
    font-size: 16px;
    font-family: Lato;
    font-weight: 400;
    padding: 14px;
    @media screen and (max-width: 768px) {
      padding: 10px;
      font-size: 14px;
    }
  }
  width: 100%;
  color: #3d3d3d;
  border-collapse: collapse;
  border-radius: ${(props) => (props.title ? "0 0 10px 10px" : "10px")};
  overflow: hidden;
`;

const NoDataMessage = styled.tr`
  text-align: center;
  width: 100%;
  height: 72px;
  background: white;
  font-weight: 700;
  font-size: 16px;
`;

const SimpleTable = ({
  className,
  columns = [],
  rows = [],
  noDataMessage = "",
  title = "",
  variant = "primary",
}) => {
  return (
    <>
      {title && (
        <MainHeader variant={variant}>
          <Typography variant="small" weight="bold">
            {title}
          </Typography>
        </MainHeader>
      )}
      <div className="table-responsive">
        <SimpleTableStyled
          className={className}
          variant={variant}
          title={title}
        >
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th key={index}>{column.headerName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.length > 0 &&
              rows.map((item, index) => (
                <tr key={index}>
                  {columns.map((c, cindex) => (
                    <td key={cindex} width={c.width}>
                      {c.renderCell
                        ? c.renderCell(item[c.field] || "", item)
                        : item[c.field]}
                    </td>
                  ))}
                </tr>
              ))}
            {rows.length <= 0 && (
              <NoDataMessage>
                <td colSpan={columns.length}>{noDataMessage}</td>
              </NoDataMessage>
            )}
          </tbody>
        </SimpleTableStyled>
      </div>
    </>
  );
};

SimpleTable.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.array,
  rows: PropTypes.array,
  noDataMessage: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.oneOf(["primary", "secondary"]),
};

export default SimpleTable;
