import axios from "axios";
import { clearStore } from "../../redux/store/common/actions/commonActions";
import { store } from "../../redux/store/index";

const baseURL = process.env.REACT_APP_API_DEV_BASE_URL;

const getToken = () => {
  const state = store.getState();
  return state.authUser.token;
};

const axiosInstance = axios.create({
  baseURL: baseURL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    config.headers = {
      Authorization: token ? `Bearer ${token}` : "",
      "X-Timezone": timezone || "",
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (res) => {
    return new Promise((resolve, reject) => {
      resolve(res);
    });
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch(clearStore());
      localStorage.clear();
      if (!window.location.href.includes("/login")) {
        window.location = "/login";
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
