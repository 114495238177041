import styled from "@emotion/styled";
import { FormControl, Select, InputBase, MenuItem } from "@mui/material";
import PropTypes from "prop-types";
import { useState } from "react";
import CheckIcon from "@mui/icons-material/Check";

import Button from "../atoms/Button";
import Typography from "../atoms/Typography";
import theme from "../theme";

const FormControlStyled = styled(FormControl)`
  width: 100%;
  height: 50px;
`;

const CustomInput = styled(InputBase)`
  .MuiInputBase-input {
    border-radius: 10px 0px 0px 10px;
    position: relative;
    background-color: white;
    width: 100%;
    font-size: 16px;
    padding: 13px 15px;
  }
  .MuiInputBase-input:focus {
    border-radius: 10px 0px 0px 10px;
    background-color: white;
  }
  margin-bottom: 12px;
`;

const SelectorLabel = styled.div`
  p {
    color: ${theme.colors.alto};
  }
`;

const ItemStyled = styled(Typography)`
  padding-left: 14px;
`;

const StyledItem = styled(MenuItem)`
  &.MuiButtonBase-root {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding: 7px;
    &:focus {
      background-color: white;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
    p {
      white-space: normal;
    }
  }
`;

const BatchButton = styled(Button)`
  border: none;
  border-radius: 0px 10px 10px 0px;
  width: 130px;
  height: 50px;
  p {
    color: ${theme.colors.white};
  }
  display: block;
  @media (max-width: 480px) {
    display: none;
  }
`;

const BatchButtonMobile = styled(BatchButton)`
  display: none;
  @media (max-width: 425px) {
    display: block;
  }
`;

const Description = styled(Typography)`
  display: block;
  @media (max-width: 425px) {
    display: none;
  }
`;
const DescriptionMobile = styled(Typography)`
  display: none;
  @media (max-width: 425px) {
    display: block;
  }
`;

const SelectorWithButton = ({
  options,
  setParentValue,
  buttonAction,
  textDescription,
  textDescriptionMobile,
}) => {
  const optionsKeys = Object.keys(options);
  const [value, setValue] = useState("");
  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    setParentValue(newValue);
  };

  const handleButtonAction = () => {
    buttonAction();
  };

  return (
    <div style={{ display: "flex", justifyContent: "start", flexGrow: "1" }}>
      <FormControlStyled variant="filled">
        <Select
          sx={{ minHeight: "50px" }}
          value={value}
          displayEmpty
          renderValue={(selected) => {
            if (!selected) {
              return (
                <SelectorLabel>
                  <Description variant="tiny">{textDescription}</Description>
                  <DescriptionMobile variant="tiny">
                    {textDescriptionMobile}
                  </DescriptionMobile>
                </SelectorLabel>
              );
            }
            return options[selected];
          }}
          onChange={handleChange}
          input={<CustomInput />}
        >
          {optionsKeys.map((key) => {
            return (
              <StyledItem key={key} value={key}>
                <ItemStyled variant="tiny" weight="light">
                  {options[key]}
                </ItemStyled>
              </StyledItem>
            );
          })}
        </Select>
      </FormControlStyled>
      <BatchButton variant="primary" onClick={handleButtonAction}>
        Aplicar
      </BatchButton>
      <BatchButtonMobile variant="primary" onClick={handleButtonAction}>
        <CheckIcon />
      </BatchButtonMobile>
    </div>
  );
};

SelectorWithButton.propTypes = {
  options: PropTypes.object,
};

export default SelectorWithButton;
