import AddCardIcon from '@mui/icons-material/AddCard';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import { format, parseISO } from "date-fns";

import CardContainer from "../../common/atoms/CardContainer";
import { PaymentService } from "../services/PaymentService";
import { CURRENCY_SYMBOLS } from "../../common/helpers/constants";
import { AdditionalPaymentDialog } from "./AdditionalPaymentDialog";
import Button from "../../common/atoms/Button";
import { useDialog } from "../../common/hooks/useDialog";
import { AdditionalPaymentsList } from "./AdditionalPaymentsList";
import { CustomDialog } from "../../common/atoms/Dialog";

const TablePayment = styled.table`
  width: 100%;
  tr {
    th,
    td {
      padding: 0.5rem 0px;
      text-align: left;
    }
  }
`;

const StyledTableRow = styled.tr`
  td {
    vertical-align: middle;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 8px;
`

const BusinessPaymentHistory = ({ user }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [payments, setPayments] = useState([]);
  const [additionalPayments, setAdditionalPayments] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const {
    showDialog: additionalPaymentDialog,
    openDialog: openAdditionalDialog,
    closeDialog: closeAdditionalDialog,
  } = useDialog();

  useEffect(() => {
    PaymentService.getBusinessPayments()
      .then(
        (response) => {
          const payments = response.data
          setPayments(payments || []);
        },
        () => {
          setPayments([]);
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const closeDialog = () => {
    setShowDialog(false);
  };

  const openDialog = () => {
    setShowDialog(true);
  };

  const openAdditionalPayments = (paymentId) => {
    if (!payments) return;
    const currentPayment = payments.find((payment) => payment._id === paymentId);
    if (!currentPayment) return;
    setAdditionalPayments(currentPayment.additionalPayments);
    openAdditionalDialog();
  };

  return (
    <>
      <CardContainer headerTitle='Historial de Pagos' headerTextAlign='start'>
        {isLoading && <CircularProgress />}
        {!isLoading && !!payments.length && (
          <div className='table-responsive'>
            <TablePayment className='table table-borderless'>
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>Plan</th>
                  <th>Precio Unidad</th>
                  <th>Nº Tarjetas Inicial</th>
                  <th>Nº Tarjetas Actual</th>
                  <th>Total</th>
                  <th>Estado</th>
                  <th colSpan={2}>Acciones</th>
                  {/* <th>Pagado por</th> */}
                </tr>
              </thead>
              <tbody>
                {payments.map((payment) => (
                  <StyledTableRow key={payment._id}>
                    <td>{format(parseISO(payment.createdAt), "dd/MM/yyyy")}</td>
                    <td>{payment.name}</td>
                    <td>
                      {CURRENCY_SYMBOLS[payment.currency] || "$"}
                      {parseFloat(payment.price).toFixed(2)}
                    </td>
                    <td>{payment.trial ? "-" : payment.quantity || 1}</td>
                    <td>{payment.trial ? "-" : payment.fullQuantity || 1}</td>
                    <td>
                      {CURRENCY_SYMBOLS[payment.currency] || "$"}
                      {(payment.quantity || 1) * payment.price}
                    </td>
                    <td>
                      <span style={{ 
                        backgroundColor: 'green', 
                        color: 'white', 
                        padding: '10px', 
                        borderRadius: '15px' 
                        }}
                      >Pagado</span>
                    </td>
                    <td>
                      <ButtonContainer>
                        <Button
                          title= "Agregar pago adicional"
                          variant='primary'
                          size='small'
                          onClick={openDialog}
                        >
                          <AddCardIcon />
                        </Button>
                        <Button
                          title= "Ver pagos adicionales"
                          variant='primary'
                          size='small'
                          onClick={() => openAdditionalPayments(payment._id)}
                        >
                          <RemoveRedEyeIcon />
                        </Button>
                      </ButtonContainer>
                    </td>
                  </StyledTableRow>
                ))}
              </tbody>
            </TablePayment>
          </div>
        )}
        {!isLoading && !payments.length && (
          <span>No se encontraron resultados.</span>
        )}
      </CardContainer>

      <AdditionalPaymentDialog
        showDialog={showDialog}
        closeDialog={closeDialog}
        user={user}
      />
      <CustomDialog
        title={"Pagos adicionales"}
        maxWidth={"500px"}
        showDialog={additionalPaymentDialog}
        closeDialog={closeAdditionalDialog}
      >
        <AdditionalPaymentsList additionalPayments={additionalPayments || []} />
      </CustomDialog>
    </>
  );
};

export default BusinessPaymentHistory;
