import styled from "@emotion/styled";
import React, { useState } from "react";
import Joyride, { STATUS } from "react-joyride";
import SimpleCard from "../../common/atoms/SimpleCard";
import Typography from "../../common/atoms/Typography";
import Link from "../../common/atoms/Link";
import theme from "../../common/theme";
import { useNavigate, useLocation } from "react-router-dom";
import { useFeature } from "flagged";

const Card = styled(SimpleCard)`
  width: 100%;
  margin-bottom: 20px;
  min-width: 220px;
  padding: 20px 15px;
  @media (min-width: 1920px) {
    min-width: 293px;
  }
`;

const ImageIcon = styled.img`
  width: 80px;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const BlueHighlightText = styled(Typography)`
  font-style: italic;
  text-align: center;
  color: ${theme.colors.azureRadiance};
`;

const FirstStepsCardStyled = styled(Card)`
  margin-top: auto;
`;

export const WelcomeCard = () => {
  return (
    <Card>
      <ImageContainer>
        <ImageIcon src="https://firebasestorage.googleapis.com/v0/b/holalinktest.appspot.com/o/images%2FHolaLinkIcon.PNG-1652083264389?alt=media&token=8a796147-56c9-46dc-ae54-1717b3276a61" />
      </ImageContainer>
      <Typography variant="tiny" weight="bold" textAlign="center" mb="2">
        Bienvenido a Hola.Link
      </Typography>
      <Typography variant="xtiny" textAlign="center">
        Hola.Link es una aplicación de tarjetas de presentación digital diseñada
        para ayudarlo a hacer crecer su red.
      </Typography>
    </Card>
  );
};

export const PlanTypeCard = ({ cardsQuantity }) => {
  return (
    <Card>
      <Typography variant="tiny" weight="bold" textAlign="center">
        Plan Business Standard
      </Typography>
      {cardsQuantity === undefined ? (
        <BlueHighlightText variant="small" weight="bold" textAlign="center">
          Calculando...
        </BlueHighlightText>
      ) : (
        <BlueHighlightText variant="h2" weight="bold" textAlign="center">
          {cardsQuantity}
        </BlueHighlightText>
      )}
      <Typography variant="xtiny" textAlign="center">
        Tarjetas en uso
      </Typography>
    </Card>
  );
};

export const UpdatePlanCard = () => {
  return (
    <Card>
      <Typography variant="tiny" weight="bold" textAlign="center">
        Deseas actualizar tu plan
      </Typography>
      <BlueHighlightText>Click aquí</BlueHighlightText>
    </Card>
  );
};

export const FirstStepsCard = () => {
  const hasReferrals = useFeature("referrals");
  const tourWelcome = {
    description: "Tour de Bienvenida",
    route: "/cards",
    steps: [
      {
        content: (
          <div>
            <Typography variant="h5" weight="bold" mb="2">
              {`¡Bienvenido(a) al Tour!`}
            </Typography>
            <Typography variant="xs">
              Te mostraremos paso a paso cómo utilizar Hola.Link para aprovechar
              todas sus funcionalidades.
            </Typography>
          </div>
        ),
        placement: "center",
        target: "body",
      },
      {
        content: (
          <div>
            <Typography variant="xs">
              Aquí podrás acceder a la configuración de tu perfil como también
              cerrar sesión.
            </Typography>
          </div>
        ),
        target: ".step-1",
      },
      {
        content: (
          <div>
            <Typography variant="xs">
              Igualmente desde aquí podrás ingresar a la configuración de tu
              perfil.
            </Typography>
          </div>
        ),
        target: ".step-2",
      },
      {
        content: (
          <div>
            <Typography variant="xs">
              Desde aquí podrás buscar tus tarjetas escribiendo el nombre,
              correo o teléfono.
            </Typography>
          </div>
        ),
        target: ".step-3",
      },
      {
        content: (
          <div>
            <Typography variant="xs">
              Tus tarjetas aparecerán aquí y puedes ver el detalle de cada una
              haciendo clic sobre ellas.
            </Typography>
          </div>
        ),
        target: ".step-4",
      },
      {
        content: (
          <div>
            <Typography variant="xs">
              Para finalizar aquí podrás ver tus estadísticas personales. Hay un
              tour disponible por si te interesa! 😃
            </Typography>
          </div>
        ),
        target: ".step-5",
      },
      {
        content: (
          <div>
            <Typography variant="h5" weight="bold" mb="2">
              ¡Felicidades! 🎉🥳 Terminaste el tour.
            </Typography>
            <Typography variant="xs">
              Si algún paso no te quedó claro no dudes en regresar. Tómalo las
              veces que quieras.
            </Typography>
          </div>
        ),
        placement: "center",
        target: "body",
      },
    ],
  };

  const tourStatistics = {
    description: "Tour de Estadísticas Personales",
    route: "/statistics",
    steps: [
      {
        content: (
          <div>
            <Typography variant="h5" weight="bold" mb="2">
              {`¡Bienvenido(a) al Tour!
              Estadísticas Personales`}
            </Typography>
            <Typography variant="xs">
              Te daremos un breve recorrido por tus estadísitcas personales.
            </Typography>
          </div>
        ),
        placement: "center",
        target: "body",
      },
      {
        content: (
          <div>
            <Typography variant="xs">
              En esta parte podrás filtrar por cada tarjeta que tengas asociada
              y por fecha.
            </Typography>
          </div>
        ),
        target: ".step-1-statistics",
      },
      {
        content: (
          <div>
            <Typography variant="xs">
              Según lo seleccionado anteriormente verás la cantidad de visitas
              que ha recibido tu tarjeta en una gráfica con una comparación con
              el periodo anterior.
            </Typography>
          </div>
        ),
        placement: "bottom-end",
        target: ".step-2-statistics",
      },
      {
        content: (
          <div>
            <Typography variant="xs">
              Finalmente verás tus Top CTA que se refiere a la cantidad de veces
              que han interactuado con los botones de contacto de tu tarjeta y
              de igual forma podrás ver tus Top Redes Sociales.
            </Typography>
          </div>
        ),
        target: ".step-4-statistics",
      },
      {
        content: (
          <div>
            <Typography variant="h5" weight="bold" mb="2">
              ¡Felicidades! 🎉🥳 Terminaste el tour.
            </Typography>
            <Typography variant="xs">
              Si algún paso no te quedo claro no dudes en regresar. Tómalo las
              veces que quieras.
            </Typography>
          </div>
        ),
        placement: "center",
        target: "body",
      },
    ],
  };
  const referralStep = {
    content: (
      <div>
        <Typography variant="xs">
          En esta sección verás a tus referidos por tarjeta como tu calificación
          personal.
        </Typography>
      </div>
    ),
    placement: "bottom-end",
    target: ".step-3-statistics",
  };
  if (hasReferrals) {
    tourStatistics.steps.splice(3, 0, referralStep);
  }

  return (
    <FirstStepsCardStyled>
      <Typography variant="tiny" weight="bold" textAlign="left" mb="2">
        Primeros pasos
      </Typography>
      <Typography variant="xtiny" textAlign="left" mb="2">
        Mira nuestras guías interactivas para aprender a utilizar todas las
        funcionalidades que ofrece HolaLink
      </Typography>
      <TourGenerator tourData={tourWelcome} />
      <TourGenerator tourData={tourStatistics} />
    </FirstStepsCardStyled>
  );
};

const TourGenerator = ({ tourData = {} }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [run, setRun] = useState(false);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
    }
  };

  const handleStart = () => {
    if (location.pathname === tourData.route) {
      setRun(true);
    } else {
      navigate(tourData.route);
      setTimeout(() => {
        setRun(true);
      }, 1000);
    }
  };

  return (
    <>
      <Link
        children={tourData.description}
        variant="primary"
        size="small"
        onClick={handleStart}
        style={{ fontSize: "12px" }}
      />
      <Joyride
        callback={handleJoyrideCallback}
        steps={tourData.steps}
        locale={{
          back: "Atrás",
          close: "Cerrar",
          last: "Finalizar",
          next: "Siguiente",
          open: "Abrir diálogo",
          skip: "Saltar",
        }}
        run={run}
        showSkipButton
        showProgress
        continuous
        styles={{
          options: {
            zIndex: 1300,
            primaryColor: theme.colors.azureRadiance,
          },
        }}
      />
    </>
  );
};

export default TourGenerator;
