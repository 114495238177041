import styled from "@emotion/styled";
import { CircularProgress } from "@mui/material";
import { useContext } from "react";
import { IconsContext } from "../../business-card-management/context/IconsContext";
import CardContainer from "../../common/atoms/CardContainer";
import Typography from "../../common/atoms/Typography";
import theme from "../../common/theme";

const ContactCard = styled.div`
  min-width: 220px;
  width: calc(50% - 12.5px);
  @media (max-width: 612px) {
    width: 100%;
  }
`;

const ContactCardItem = styled.div`
  color: #3d3d3d;
  font-size: 18px;
  font-family: Lato;
  font-weight: 400;
  display: flex;
  gap: 25px;
  margin-bottom: 28px;
  &:last-child {
    margin-bottom: 0px;
  }
  p {
    &:last-child {
      margin-left: auto;
    }
  }
  @media (max-width: 425px) {
    gap: 12px;
  }
`;

const NoDataMessage = styled.div`
  margin: auto;
  height: "200px";
  font-weight: 700;
  font-size: 18px;
  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;

const ContactIcon = styled.div`
  border-radius: 50%;
  background-color: ${theme.colors.mineShaft};
  height: 30px;
  width: 30px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
`;

const TopCTA = ({ loading, data }) => {
  const { icons } = useContext(IconsContext);
  return (
    <ContactCard>
      <CardContainer headerTitle="Top CTA" headerTextAlign="center">
        <div>
          {loading && <CircularProgress />}
          {!loading && data.length > 0
            ? data.map((item, index) => (
                <ContactCardItem key={index}>
                  <Typography variant="small" weight="bold">
                    <i>{item.counter}.</i>
                  </Typography>
                  <ContactIcon>
                    <i className={icons[item.key]}></i>
                  </ContactIcon>
                  <Typography variant="small">{item.placeholder}</Typography>{" "}
                  <Typography variant="small">{item.hits}</Typography>
                </ContactCardItem>
              ))
            : !loading && (
                <NoDataMessage>No hay eventos registrados</NoDataMessage>
              )}
        </div>
      </CardContainer>
    </ContactCard>
  );
};

export default TopCTA;
