import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Typography from "../atoms/Typography";
import theme from "../theme";

const DropDownContainer = styled.div`
  width: ${(props) => (props.fullWidth ? "100%" : "11.5em")};
  position: relative;
  @media (max-width: 425px) {
    width: ${(props) => props.responsive && "100%"};
  }
`;

const DropDownHeader = styled.div`
  padding: 12px 26px 12px 26px;
  color: ${theme.colors.mineShaft};
  border-radius: ${(props) => (props.isOpen ? "10px 10px 0px 0px" : "10px")};
  background-color: ${theme.colors.white};
  cursor: pointer;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  p {
    display: ${(props) => props.hasIcon && "inline-block"};
  }
`;

const DropdownPlaceholder = styled(Typography)`
  color: ${theme.colors.alto};
`;

const DropdownIcon = styled.div`
  position: absolute;
  right: 26px;
  top: 25%;
  color: ${theme.colors.silver};
`;
const DropDownListContainer = styled.div`
  position: absolute;
  z-index: 100;
  width: ${(props) => (props.fullWidth ? "100%" : "11.5em")};
  border-radius: 0px 0px 10px 10px;
  overflow: hidden;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  @media (max-width: 425px) {
    width: ${(props) => props.responsive && "100%"};
  }
`;

const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  background: ${theme.colors.white};
  box-sizing: border-box;
`;

const ListItem = styled.li`
  list-style: none;
  padding: 8px 26px 8px 26px;
  p {
    display: ${(props) => props.hasIcon && "inline-block"};
  }
  &:hover {
    cursor: pointer;
    p {
      font-weight: bold;
    }
  }
`;

const ListItemIcon = styled.div`
  width: 26px;
  height: 26px;
  display: inline-block;
  margin-right: 9px;
  background-color: ${theme.colors.alto};
  img {
    width: 100%;
    height: 100%;
    vertical-align: top;
    object-fit: cover;
  }
`;

function Dropdown({
  options,
  onClick,
  placeholder,
  fullWidth,
  hasIcon,
  defaultOption,
  loading,
  responsive,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (option) => () => {
    setSelectedOption(option);
    setIsOpen(false);
    onClick(option);
  };

  const renderTextValue = () => {
    if (loading) {
      return (
        <DropdownPlaceholder variant="small" weight="light">
          Cargando...
        </DropdownPlaceholder>
      );
    }
    const currentOption = selectedOption || defaultOption;
    if (currentOption) {
      return (
        <>
          {hasIcon && (
            <ListItemIcon>
              <img src={currentOption.icon} />
            </ListItemIcon>
          )}
          <Typography variant="small" weight="light">
            {currentOption.value}
          </Typography>
        </>
      );
    }

    return (
      <DropdownPlaceholder variant="small" weight="light">
        {placeholder || "Seleccionar"}
      </DropdownPlaceholder>
    );
  };

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <DropDownContainer fullWidth={fullWidth} responsive={responsive}>
        <DropDownHeader onClick={toggling} isOpen={isOpen} hasIcon={hasIcon}>
          {renderTextValue()}
          <DropdownIcon>
            {isOpen ? (
              <ArrowDropUpIcon style={{ marginLeft: "auto" }} />
            ) : (
              <ArrowDropDownIcon style={{ marginLeft: "auto" }} />
            )}
          </DropdownIcon>
        </DropDownHeader>
        {isOpen && (
          <DropDownListContainer fullWidth={fullWidth} responsive={responsive}>
            <DropDownList>
              {options.map((option, index) => {
                const isSelected =
                  selectedOption && selectedOption.key === option.key;
                return (
                  <ListItem
                    onClick={onOptionClicked(option)}
                    key={index}
                    active={isSelected}
                    hasIcon={hasIcon}
                  >
                    {hasIcon && (
                      <ListItemIcon>
                        <img src={option.icon} />
                      </ListItemIcon>
                    )}
                    <Typography
                      variant="small"
                      weight={isSelected ? "bold" : "light"}
                    >
                      {option.value || ""}
                    </Typography>
                  </ListItem>
                );
              })}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    </ClickAwayListener>
  );
}

Dropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any,
      value: PropTypes.string,
      icon: PropTypes.string,
    })
  ),
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  hasIcon: PropTypes.bool,
  defaultOption: PropTypes.shape({
    key: PropTypes.any,
    value: PropTypes.string,
    icon: PropTypes.string,
  }),
  loading: PropTypes.bool,
  responsive: PropTypes.bool,
};

export default Dropdown;
