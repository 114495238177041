import currentCompanyActionTypes from "../action-types/currentCompanyActionTypes";

const {
  SET_CURRENT_COMPANY,
  GET_CURRENT_COMPANY,
  UPDATE_CURRENT_COMPANY,
  CLEAR_CURRENT_COMPANY,
  INIT_CURRENT_COMPANY,
} = currentCompanyActionTypes;

export const setCurrentCompany = (company) => {
  return {
    type: SET_CURRENT_COMPANY,
    payload: company,
  };
};

export const getCurrentCompany = () => {
  return {
    type: GET_CURRENT_COMPANY,
  };
};

export const updateCurrentCompany = (editedCompany) => {
  return {
    type: UPDATE_CURRENT_COMPANY,
    payload: editedCompany,
  };
};

export const clearCurrentCompany = () => {
  return {
    type: CLEAR_CURRENT_COMPANY,
  };
};

export const initCurrentCompany = () => {
  return {
    type: INIT_CURRENT_COMPANY,
  };
};
