import styled from "@emotion/styled";

const BaseCard = styled.div`
  width: 200px;
  height: 250px;
  cursor: pointer;
  text-align: center;
  background-color: white;
  box-shadow: -3px 3px 10px rgba(121, 121, 121, 0.25);
  border-radius: 10px;
  box-shadow: -5px 5px 20px rgb(199, 199, 199);
  display: flex;
  flex-direction: column;
  @media (min-width: 1920px) {
    width: 210px;
  }
`;

export default BaseCard;
