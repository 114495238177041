import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import "../assets/css/styles.css";
import CardTemplateOne from "./CardTemplateOne";
import CardTemplateTwo from "./CardTemplateTwo";
import CardTemplateThree from "./CardTemplateThree";
import styled from "@emotion/styled";

import Flex from "../../common/atoms/Flex";

const Container = styled(Flex)`
  gap: 50px;
  @media (min-width: 1920px) {
    gap: 60px;
  }
`;

function TemplateCardList({ defaultSelected, selectTemplate, colors }) {
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  useEffect(() => {
    if (defaultSelected) {
      setSelectedTemplate(defaultSelected);
    }
  }, [defaultSelected]);

  const templates = [
    {
      id: 1,
      render: (selected) => {
        return (
          <CardTemplateOne
            mode="static"
            selectable={true}
            selected={selected}
            colors={colors}
          />
        );
      },
    },
    {
      id: 2,
      render: (selected) => {
        return (
          <CardTemplateTwo
            mode="static"
            selectable={true}
            selected={selected}
            colors={colors}
          />
        );
      },
    },
    {
      id: 3,
      render: (selected) => {
        return (
          <CardTemplateThree
            mode="static"
            selectable={true}
            selected={selected}
            colors={colors}
          />
        );
      },
    },
  ];
  const handleSelection = (id) => {
    setSelectedTemplate(id);
    selectTemplate(id);
  };

  return (
    <Container justifyContent="center" flexWrap="wrap">
      {templates.map((item) => {
        return (
          <div key={item.id} onClick={() => handleSelection(item.id)}>
            {item.render(selectedTemplate === item.id)}
          </div>
        );
      })}
    </Container>
  );
}

TemplateCardList.propTypes = {
  defaultSelected: PropTypes.number,
  selectTemplate: PropTypes.func,
};

export default TemplateCardList;
