import { Dialog, DialogContent, DialogTitle } from "@mui/material";

export const CustomDialog = ({
  title,
  showDialog,
  children,
  closeDialog,
  ...props
}) => {
  return (
    <Dialog open={showDialog} onClose={closeDialog} {...props}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};
