import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import Compressor from "compressorjs";

import RoundImageCropper from "../../../common/molecules/RoundImageCropper";
import theme from "../../../common/theme";

const Image = styled.div`
  margin-bottom: 12px;
  border: 2px solid #444;
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  background-position: center;
  border-radius: ${(props) => props.radius};
  height: 150px;
  width: 150px;
`;

const CustomFileButton = styled.div`
  max-width: 350px;
  width: 100%;
  input[type="file"] {
    margin-left: -2px !important;

    &::-webkit-file-upload-button {
      display: none;
    }
    &::file-selector-button {
      display: none;
    }
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }

  &:hover {
    label {
      background-color: #dde0e3;
      cursor: pointer;
    }
  }
  .input-group-text {
    border: 1px solid ${theme.colors.mineShaft};
    border-radius: 10px 0px 0px 10px;
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }
  .form-control {
    border: 1px solid ${theme.colors.mineShaft};
    border-radius: 0px 10px 10px 0px;
    &:focus {
      border: 1px solid ${theme.colors.mineShaft};
      box-shadow: none;
    }
  }
`;

export function ImageInput({
  error,
  field,
  helperText,
  type,
  name,
  handleImage,
  handleTouched,
  formMode,
  cropType,
}) {
  const [openCropper, setOpenCropper] = useState(false);

  const [selectedImage, setSelectedImage] = useState();
  const [preview, setPreview] = useState(
    require("../../assets/img/no-avatar-img.jpg")
  );

  const defaultImageUrl =
    field.url || require("../../assets/img/no-avatar-img.jpg");

  useEffect(() => {
    if (!selectedImage) {
      setSelectedImage(undefined);
      setTimeout(() => {
        setPreview(field.url);
      });
    }
  }, [selectedImage, field]);

  const handleSelectImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const image = e.target.files[0];
      setSelectedImage(image);
      const imageUrl = URL.createObjectURL(image);
      setPreview(imageUrl);
      setOpenCropper(true);
      return () => URL.revokeObjectURL(imageUrl);
    }

    setSelectedImage(undefined);
    setPreview(defaultImageUrl);
    handleImage(type, name, "", defaultImageUrl);
  };

  const onTouched = (e) => {
    const image =
      e.target.files && e.target.files.length > 0
        ? e.target.files[0]
        : undefined;
    handleTouched(name, image, type);
  };

  const handleCroppedImage = (image, url) => {
    new Compressor(image, {
      quality: 0.6,
      maxHeight: 1024,
      maxWidth: 1024,
      success(result) {
        setSelectedImage(result);
        setPreview(url);
        handleImage(type, name, result, url);
        onConfirm();
      },
      error(err) {
        console.log(err.message);
      },
    });
  };

  const clearFile = (e) => {
    e.target.value = null;
  };

  const onCancel = () => {
    setSelectedImage(undefined);
    setPreview(defaultImageUrl);
    setOpenCropper(false);
  };

  const onConfirm = () => {
    setOpenCropper(false);
  };

  return (
    <div
      className="image-input-container"
      style={{ textAlign: "center", width: "100%" }}
    >
      {formMode === "edition" && (
        <div>
          <h5>Foto actual</h5>
        </div>
      )}
      <Image imageUrl={preview} radius={cropType === "round" ? `50%` : `0%`} />

      <CustomFileButton className="input-group">
        <label className="input-group-text" htmlFor="inputGroupFile">
          Selecciona archivo
        </label>
        <input
          type="file"
          className="form-control"
          accept="image/*"
          id="inputGroupFile"
          onChange={handleSelectImage}
          onClick={clearFile}
          onBlur={onTouched}
        />
      </CustomFileButton>
      {error && <span className="input-error-message">{helperText}</span>}
      <RoundImageCropper
        imageSrc={preview}
        openCropper={openCropper}
        setCroppedImage={handleCroppedImage}
        closeCropper={onCancel}
        cropType={cropType}
      />
    </div>
  );
}
