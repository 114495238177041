import styled from "@emotion/styled";

const CounterStyled = styled.span`
  font-weight: 600;
  font-size: 16px;
  display: block;
  text-align: left;
`;

export default CounterStyled;
