import styled from "@emotion/styled";
import theme from "../../../common/theme";
import InputFull from "../../../common/atoms/InputFull";

const WebsiteContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin: 20px 0;
  > div:first-of-type {
    flex: 40%;
  }
  > div:last-of-type {
    flex: 60%;
  }
  > i {
    cursor: pointer;
    height: 100%;
    width: 35px;
  }
  .icon-red {
    color: ${theme.colors.pastelRed};
    transition: color 0.5s;
    &:hover {
      color: #b20101;
    }
  }
`;

const WebpageFieldInput = ({
  onChange,
  name,
  type,
  valueType,
  position,
  deleteInputField,
  tagError,
  tagHelperText,
  valueError,
  valueHelperText,
  valueLabel,
  disabledValue,
  valueOfElement,
  tagOfElement,
}) => {
  const handleChange = (e, category) => {
    onChange(category, position, e.target.value);
  };

  const deleteField = (e) => {
    deleteInputField(e, type, name, position);
  };

  return (
    <>
      <WebsiteContainer>
        <InputFull
          autoFocus={valueOfElement.length === 0 && position > 0 ? true : false}
          error={tagError}
          errorMessage={tagHelperText}
          label="Título"
          name={name}
          variant="standard"
          disabled={disabledValue}
          fullWidth
          removeMargin
          value={tagOfElement}
          onChange={(e) => handleChange(e, "tag")}
          type="text"
        />
        <InputFull
          autoFocus={valueOfElement.length === 0 && position > 0 ? true : false}
          error={valueError}
          errorMessage={valueHelperText}
          label={valueLabel}
          name={name}
          variant="standard"
          disabled={disabledValue}
          fullWidth
          removeMargin
          value={valueOfElement}
          onChange={(e) => handleChange(e, "value")}
          type="url"
        />
        <i className="bi bi-trash icon-red" onClick={(e) => deleteField(e)}></i>
      </WebsiteContainer>
    </>
  );
};

export default WebpageFieldInput;
