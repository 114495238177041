import styled from "@emotion/styled";
import PropTypes from "prop-types";
import Typography from "./Typography";

const StyledBackground = styled.div`
  background-color: #eaeaea;
  border-radius: 10px;
  padding: 4px 12px;
  margin: 12px 0px;
  width: 100%;
  overflow-x: auto;
`;

const UrlText = styled(Typography)`
  @media (max-width: 425px) {
    font-size: 12px;
  }
`;

const UrlBackground = ({ url }) => {
  return (
    <StyledBackground>
      <UrlText variant="tiny" weight="light">
        {url}
      </UrlText>
    </StyledBackground>
  );
};

UrlBackground.propTypes = {
  url: PropTypes.string,
};

export default UrlBackground;
