import styled from "@emotion/styled";
import theme from "../theme";
import { css } from "@emotion/react";

const errorStyled = css`
  label {
    color: ${theme.colors.pastelRed};
  }
  textarea {
    border-color: ${theme.colors.pastelRed};
  }
`;

const disabledStyled = css`
  textarea {
    background-color: white;
    border-color: ${theme.colors.alto};
    color: ${theme.colors.silverChalice};
    cursor: not-allowed;
  }
  p {
    color: ${theme.colors.silverChalice} !important;
    cursor: not-allowed;
  }
`;

const removeMargin = css`
  margin: 0;
`;

const fullWidth = css`
  width: 100%;
`;

const inputNotFocus = css`
  color: ${theme.colors.mineShaft};
`;

const TextArea = styled.div`
  .material-textfield {
    position: relative;
  }

  label {
    font: 500 16px/1.5 "Lato", sans-serif;
    position: absolute;
    font-size: 1rem;
    left: 0;
    top: 20%;
    transform: translateY(-50%);
    background-color: white;
    color: ${theme.colors.silverChalice};
    ${(props) => props.haveValue && inputNotFocus}
    padding: 0 0.3rem;
    margin: 0 0.8rem;
    transition: 0.1s ease-out;
    transform-origin: left top;
    pointer-events: none;
    @media screen and (max-width: 480px) {
      font-size: 0.8rem;
    }
  }
  textarea {
    font: 400 16px/1.5 "Lato", sans-serif;
    font-size: 1rem;
    outline: none;
    border: 1px solid ${theme.colors.mineShaft};
    border-radius: 10px;
    padding: 0.7rem 1rem 0.7rem 1rem;
    color: ${theme.colors.mineShaft};
    transition: 0.1s ease-out;
    resize: none;
    ${(props) => props.fullWidth && fullWidth}
    @media screen and (max-width: 480px) {
      font-size: 0.8rem;
    }
  }
  textarea:focus {
    border-color: ${theme.colors.azureRadiance};
  }
  textarea:focus + label {
    color: ${theme.colors.azureRadiance};
    font-weight: 600;
    top: 0;
    transform: translateY(-50%) scale(0.9);
  }
  textarea:not(:placeholder-shown) + label {
    top: 0;
    left: 0;
    transform: translateY(-50%) scale(0.9);
    font-weight: 600;
  }

  ${(props) => props.error && errorStyled}
`;

const ErrorMessage = styled.p`
  color: ${theme.colors.pastelRed};
  font-weight: 600;
  font-size: 0.9rem;
  padding-left: 0.5rem;
`;

const TextAreaFull = (props) => {
  const handleChange = (e) => {
    props.onChange(e, props.type);
  };

  const deleteField = (e) => {
    props.deleteField(e, props.type);
  };

  return (
    <TextArea
      error={props.error}
      isDisabled={props.isDisabled}
      fullWidth={props.fullWidth}
      removeMargin={props.removeMargin}
      deleteField={props.deleteField}
      haveValue={props.value.length > 0}
    >
      <div className="material-textfield">
        <textarea
          type={props.type}
          name={props.name}
          placeholder=" "
          disabled={props.isDisabled}
          value={props.value}
          onChange={handleChange}
          rows={props.rows}
        ></textarea>
        <label>{props.label}</label>
        {props.error && <ErrorMessage>{props.errorMessage}</ErrorMessage>}
        {props.extra && (
          <i
            className="bi bi-trash icon-red"
            onClick={(e) => deleteField(e)}
          ></i>
        )}
      </div>
    </TextArea>
  );
};

TextAreaFull.defaultProps = {
  type: "",
  label: "",
  value: "",
  name: "",
  rows: 3,
  isDisabled: false,
  fullWidth: false,
  removeMargin: false,
  errorMessage: "",
};

export default TextAreaFull;
