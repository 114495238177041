export const EditIcon = () => {
  return <img src={require("../assets/img/EditIcon.svg").default} />;
};

export const ImportExcelIcon = () => {
  return <img src={require("../assets/img/ImportExcelIcon.svg").default} />;
};

export const ExportExcelIcon = () => {
  return <img src={require("../assets/img/ExportExcelIcon.svg").default} />;
}

export const WeChatIcon = () => {
  return <img src={require("../assets/img/wechat.svg").default} />;
}

export const ContactIcon = () => {
  return <img src={require("../assets/img/ContactIcon.svg").default} />
}