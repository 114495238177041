import { useEffect, useState } from "react";
import { RatingService } from "../../business-card-management/services/RatingService";
import Table from "../../common/atoms/Table";
import { formatDate } from "../../common/helpers/dates.utils";

const columns = [
  {
    field: "id",
    headerName: "ID",
    width: 70,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "dateHour",
    headerName: "Fecha y Hora",
    width: 200,
    headerAlign: "center",
    align: "center",
  },
  // {
  //   field: "DNI",
  //   headerName: "DNI",
  //   width: 130,
  //   headerAlign: "center",
  //   align: "center",
  // },
  {
    field: "phoneNumber",
    headerName: "Teléfono",
    width: 130,
    headerAlign: "center",
    align: "center",
  },
];

export default function PersonalCollectionTable({ search, businessCardId }) {
  const [collection, setCollection] = useState([]);

  useEffect(() => {
    if (businessCardId) {
      RatingService.getPersonalCollection(businessCardId, search).then(
        (response) => {
          if (response.data.collection) {
            setCollection(
              response.data.collection.map((item, index) => ({
                id: index + 1,
                dateHour: formatDate(new Date(item.updatedAt)),
                DNI: item.contact.dni,
                phoneNumber: item.contact.phoneNumber || "",
              }))
            );
          }
        }
      );
    }
  }, [businessCardId, search]);

  return (
    <div style={{ width: "100%" }}>
      <Table
        autoHeight
        rows={collection}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
      />
    </div>
  );
}
