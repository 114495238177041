import { SET_CHANGE_ACCOUNT_LOADED } from "../action-types/changeAccountLoadedActionTypes";

const initialState = false;

const changeAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHANGE_ACCOUNT_LOADED:
      return action.payload;
    default:
      return state;
  }
};

export default changeAccountReducer;
