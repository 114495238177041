import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { space, typography } from "styled-system";
import theme from "./../theme";

const Base = css`
  font-family: Lato;
  padding: 0px;
  margin: 0px;
  color: ${theme.colors.mineShaft};
`;

const fontWeight = (weight) => {
  if (weight === "light") return "400";
  if (weight === "regular") return "500";
  if (weight === "bold") return "700";
};

const Heading1 = styled.h1`
  ${Base}
  ${space}
  ${typography}
  font-weight: 700;
  font-size: 90px;
  line-height: 100px;
  font-weight: ${(props) => fontWeight(props.weight)};
`;

const Heading2 = styled.h2`
  ${Base}
  ${space}
  ${typography}
  font-weight: 700;
  font-size: 52px;
  line-height: 60px;
  font-weight: ${(props) => fontWeight(props.weight)};
`;

const Heading3 = styled.h3`
  ${Base}
  ${space}
  ${typography}
  font-weight: 700;
  font-size: 70px;
  line-height: 78px;
  font-weight: ${(props) => fontWeight(props.weight)};
`;

const Heading4 = styled.h4`
  ${Base}
  ${space}
  ${typography}
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  font-weight: ${(props) => fontWeight(props.weight)};
`;

const Heading5 = styled.h5`
  ${Base}
  ${space}
  ${typography}
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  font-weight: ${(props) => fontWeight(props.weight)};
  @media (min-width: 1920px) {
    font-size: 28px;
    line-height: 36px;
  }
  @media screen and (max-width: 480px) {
    font-size: 20px;
    padding-right: 15px;
  }
`;

const Small = styled.p`
  ${Base}
  ${space}
  ${typography}
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  font-weight: ${(props) => fontWeight(props.weight)};
  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;

const XSmall = styled.p`
  ${Base}
  ${space}
  ${typography}
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-weight: ${(props) => fontWeight(props.weight)};
  @media (min-width: 1920px) {
    font-size: 18px;
    line-height: 28px;
  }
`;

const Tiny = styled.p`
  ${Base}
  ${space}
  ${typography}
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-weight: ${(props) => fontWeight(props.weight)};
  @media (min-width: 1920px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const XTiny = styled.p`
  ${Base}
  ${space}
  ${typography}
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  font-weight: ${(props) => fontWeight(props.weight)};
  @media (min-width: 1920px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const Typography = (props) => {
  if (props.variant === "h1")
    return <Heading1 {...props}>{props.children}</Heading1>;
  if (props.variant === "h2")
    return <Heading2 {...props}>{props.children}</Heading2>;
  if (props.variant === "h3")
    return <Heading3 {...props}>{props.children}</Heading3>;
  if (props.variant === "h4")
    return <Heading4 {...props}>{props.children}</Heading4>;
  if (props.variant === "h5")
    return <Heading5 {...props}>{props.children}</Heading5>;

  if (props.variant === "small")
    return <Small {...props}>{props.children}</Small>;
  if (props.variant === "xs")
    return <XSmall {...props}>{props.children}</XSmall>;
  if (props.variant === "tiny") return <Tiny {...props}>{props.children}</Tiny>;
  if (props.variant === "xtiny")
    return <XTiny {...props}>{props.children}</XTiny>;

  return <Small {...props}>{props.children}</Small>;
};

Typography.propTypes = {
  variant: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "small",
    "xs",
    "tiny",
    "xtiny",
  ]),
  weight: PropTypes.oneOf(["light", "regular", "bold"]),
  children: PropTypes.node,
};

export default Typography;
