import {
  CLEAR_ACCOUNTS,
  SET_ACCOUNTS,
  CHANGE_ACCOUNT,
  INIT_ACCOUNTS,
} from "../action-types/linkedAccountsActionTypes";

export const initAccounts = () => {
  return {
    type: INIT_ACCOUNTS,
  };
};

export const setAccounts = (accounts) => {
  return {
    type: SET_ACCOUNTS,
    payload: accounts,
  };
};

export const clearAccounts = () => {
  return {
    type: CLEAR_ACCOUNTS,
  };
};

export const changeAccount = (employeeId) => {
  return {
    type: CHANGE_ACCOUNT,
    payload: employeeId,
  };
};
