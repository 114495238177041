import styled from "@emotion/styled";
import { DialogActions } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../common/atoms/Button";
import CardContainer from "../../common/atoms/CardContainer";
import { updateCurrentCompany } from "../../redux/store/companies/actions/currentCompanyActions";
import { setPageLoading } from "../../redux/store/companies/actions/generalPageLoadingActions";
import { CompanyService } from "../services/CompanyServices";
import { ColorSelector } from "./ColorSelector";

const ColorGroup = styled.div`
  display: flex;
  border-bottom: 1px solid rgb(196, 196, 196);
  position: relative;
  > div {
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const GlobalColors = () => {
  const currentCompany = useSelector((state) => state.currentCompany);
  const dispatch = useDispatch();

  const [baseInformationPalettes, setBaseInformationPalettes] = useState({
    primary: {
      placeholder: "Color Primario",
      initialColor: currentCompany.colors.primary,
      currentColor: "",
      type: "primary",
    },
    primaryText: {
      placeholder: "Color Texto",
      initialColor: currentCompany.colors.primaryText,
      currentColor: "",
      type: "text",
    },
  });

  const [generalContactsPalettes, setGeneralContactsPalettes] = useState({
    generalContactButton: {
      placeholder: "Fondo botón",
      initialColor: currentCompany.colors.generalContactButton,
      currentColor: "",
      type: "button",
    },
    generalContactText: {
      placeholder: "Color Texto",
      initialColor: currentCompany.colors.generalContactText,
      currentColor: "",
      type: "text",
    },
  });

  const [socialNetworksPalettes, setSocialNetworksPalettes] = useState({
    socialNetworkButton: {
      placeholder: "Fondo botón",
      initialColor: currentCompany.colors.socialNetworkButton,
      currentColor: "",
      type: "button",
    },
    socialNetworkText: {
      placeholder: "Color ícono",
      initialColor: currentCompany.colors.socialNetworkText,
      currentColor: "",
      type: "text",
    },
  });

  const saveColor = (name, color, type) => {
    let createObj = (prevObj) => ({
      ...prevObj,
      [name]: {
        ...prevObj[name],
        currentColor: color,
      },
    });
    switch (type) {
      case "baseInformationPalettes":
        setBaseInformationPalettes((prev) => ({
          ...createObj(prev),
        }));
        break;
      case "generalContactsPalettes":
        setGeneralContactsPalettes((prev) => ({
          ...createObj(prev),
        }));
        break;
      case "socialNetworksPalettes":
        setSocialNetworksPalettes((prev) => ({
          ...createObj(prev),
        }));
        break;
    }
  };

  const updateColors = async () => {
    let newColors = {
      primary:
        baseInformationPalettes.primary.currentColor ||
        baseInformationPalettes.primary.initialColor,
      primaryText:
        baseInformationPalettes.primaryText.currentColor ||
        baseInformationPalettes.primaryText.initialColor,
      generalContactButton:
        generalContactsPalettes.generalContactButton.currentColor ||
        generalContactsPalettes.generalContactButton.initialColor,
      generalContactText:
        generalContactsPalettes.generalContactText.currentColor ||
        generalContactsPalettes.generalContactText.initialColor,
      socialNetworkButton:
        socialNetworksPalettes.socialNetworkButton.currentColor ||
        socialNetworksPalettes.socialNetworkButton.initialColor,
      socialNetworkText:
        socialNetworksPalettes.socialNetworkText.currentColor ||
        socialNetworksPalettes.socialNetworkText.initialColor,
    };
    dispatch(setPageLoading(true));
    CompanyService.updateColors(currentCompany.id, newColors)
      .then((response) => {
        if (response.status === 200 && response.data.updatedCompany) {
          let company = response.data.updatedCompany;
          setBaseInformationPalettes({
            primary: {
              ...baseInformationPalettes.primary,
              initialColor: company.colors.primary,
              currentColor: "",
            },
            primaryText: {
              ...baseInformationPalettes.primaryText,
              initialColor: company.colors.primaryText,
              currentColor: "",
            },
          });
          setGeneralContactsPalettes({
            generalContactButton: {
              ...generalContactsPalettes.generalContactButton,
              initialColor: company.colors.generalContactButton,
              currentColor: "",
            },
            generalContactText: {
              ...generalContactsPalettes.generalContactText,
              initialColor: company.colors.generalContactText,
              currentColor: "",
            },
          });
          setSocialNetworksPalettes({
            socialNetworkButton: {
              ...socialNetworksPalettes.socialNetworkButton,
              initialColor: company.colors.socialNetworkButton,
              currentColor: "",
            },
            socialNetworkText: {
              ...socialNetworksPalettes.socialNetworkText,
              initialColor: company.colors.socialNetworkText,
              currentColor: "",
            },
          });
          dispatch(updateCurrentCompany(company));
          dispatch(setPageLoading(false));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <CardContainer
      headerTitle="Colores Globales"
      headerTextAlign="center"
      noGutters={true}
    >
      <ColorGroup>
        {Object.keys(baseInformationPalettes).map((name, index) => {
          const element = baseInformationPalettes[name];
          return (
            <div key={index.toString()}>
              <ColorSelector
                type={element.type}
                name={name}
                placeholder={element.placeholder}
                initialColor={element.initialColor}
                getColor={(name, color) => {
                  saveColor(name, color, "baseInformationPalettes");
                }}
              />
            </div>
          );
        })}
      </ColorGroup>
      <div style={{ fontWeight: "600" }}>Botones de contacto</div>
      <ColorGroup>
        {Object.keys(generalContactsPalettes).map((name, index) => {
          const element = generalContactsPalettes[name];
          return (
            <div key={(index * 2).toString()}>
              <ColorSelector
                type={element.type}
                name={name}
                placeholder={element.placeholder}
                initialColor={element.initialColor}
                getColor={(name, color) => {
                  saveColor(name, color, "generalContactsPalettes");
                }}
              />
            </div>
          );
        })}
      </ColorGroup>
      <div style={{ fontWeight: "600" }}>Botones de redes sociales</div>
      <ColorGroup>
        {Object.keys(socialNetworksPalettes).map((name, index) => {
          const element = socialNetworksPalettes[name];
          return (
            <div key={(index * 3).toString()}>
              <ColorSelector
                type={element.type}
                name={name}
                placeholder={element.placeholder}
                initialColor={element.initialColor}
                getColor={(name, color) => {
                  saveColor(name, color, "socialNetworksPalettes");
                }}
              />
            </div>
          );
        })}
      </ColorGroup>
      <DialogActions
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button variant="primary" size="small" onClick={updateColors}>
          Guardar
        </Button>
      </DialogActions>
    </CardContainer>
  );
};
