import styled from "@emotion/styled";
import { DataGrid } from "@mui/x-data-grid";

export const CounterStyled = styled.span`
  font-weight: 700;
  font-size: 18px;
  display: block;
  margin: 0 auto;
`;

const NoRows = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 18px;
  font-weight: 700;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const DataGridStyled = styled(DataGrid)`
  background-color: white;
  border-radius: 10px;
  border: none;
  color: #3d3d3d;
  font-family: Lato;
  font-size: 16px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  .MuiTablePagination-displayedRows {
    margin: 0;
  }
  .MuiDataGrid-columnHeader {
    .MuiDataGrid-columnHeaderTitle {
      font-weight: 700;
    }
  }
  .MuiDataGrid-cellContent {
    white-space: break-spaces;
  }

  .MuiDataGrid-row {
    min-height: 60px;
    &.even {
      background-color: #f0f2fa;
    }
    &.MuiDataGrid-row.odd {
      background-color: white;
    }
    .MuiDataGrid-cell {
      padding: 5px;
    }
  }
`;

const Table = (props) => {
  return (
    <DataGridStyled
      {...props}
      rowHeight={60}
      disableColumnMenu
      components={{
        NoRowsOverlay: () => <NoRows>No hay datos</NoRows>,
      }}
    ></DataGridStyled>
  );
};
export default Table;
