import React from "react";
import BusinessCardList from "../business-card-management/views/BusinessCardList";
import EditionPage from "../business-card-management/views/EditionPage";
import TemplateSelection from "../business-card-management/views/TemplateSelection";
import Layout from "../navigation-components/Layout";
import TemplateEdition from "../business-card-management/views/TemplateEdition";
import BusinessCardTeamList from "../business-card-management/views/BusinessCardTeamList";
import PersonalStatistics from "../statistics/views/PersonalStatistics";
import CompanyStatistics from "../statistics/views/CompanyStatistics";
import GlobalCollection from "../recolection-users/views/GlobalCollection";
import PersonalCollection from "../recolection-users/views/PersonalCollection";
import EmployeeCollection from "../recolection-users/views/EmployeeCollection";
import Login from "../auth-management/views/Login";
import EmailToChangePasswordForm from "../auth-management/views/EmailToChangePasswordForm";
import PersonalRegister from "../auth-management/views/PersonalRegister";
import SelectAcount from "../auth-management/views/SelectAcount";
import Payment from "../payments/views/Payment";
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";
import { useFeature } from "flagged";
import { useSelector } from "react-redux";
import { BusinessCardsProvider } from "../business-card-management/context/BusinessCardsContext";
import { BusinessCardDetail } from "../business-card-management/views/BusinessCardDetail";
import { NotFound } from "../common/components/NotFound";
import { BusinessCardPublic } from "../business-card-management/views/BusinessCardPublic";
import { UsersManagementPage } from "../users-management/views/UsersManagementPage";
import { CompanyConfiguration } from "../company-profile/views/CompanyConfiguration";
import { ChangePassword } from "../auth-management/views/ChangePassword";
import { PersonalConfiguration } from "../user-profile/views/PersonalConfiguration";
import { EmployeeProvider } from "../users-management/context/EmployeesContext";
import { LinkAccount } from "../user-profile/views/LinkAccount";
import { BusinessCardPublicProvider } from "../business-card-management/context/BusinessCardPublicContext";
import { Redirect } from "../auth-management/views/Redirect";

function AppRoutes() {
  const { token, loggedUser } = useSelector((state) => state.authUser);
  const hasReferrals = useFeature("referrals");
  let isAuthenticated = loggedUser && token;
  const [searchParams, setSearchParams] = useSearchParams();

  const getUrlWithParams = () => {
    let url = "/";
    if (searchParams.get("page")) {
      url = "/" + searchParams.get("page")?.replaceAll('"', "");
    }
    if (url !== "/") {
      if (url.includes("?")) {
        url = `${url}&redirected=true`;
      } else {
        url = `${url}?redirected=true`;
      }
    }
    return url;
  };

  return (
    <Routes>
      <Route
        path="/login"
        element={
          !isAuthenticated ? <Login /> : <Navigate to={getUrlWithParams()} />
        }
      />
      <Route path="/register" element={<PersonalRegister />} />
      <Route path="/account-type" element={<SelectAcount />} />
      <Route
        path="/change-password/set-email"
        element={<EmailToChangePasswordForm />}
      />
      <Route path="/set-password/:code" element={<ChangePassword />} />
      <Route path="/redirect/:token" element={<Redirect />} />

      <Route
        path="/"
        element={
          <BusinessCardsProvider>
            <Layout isAuth={isAuthenticated} loggedUser={loggedUser} />
          </BusinessCardsProvider>
        }
      >
        <Route index element={<BusinessCardList />} />
        <Route path="/cards" element={<BusinessCardList />} />
        <Route path="/cards/:cardId" element={<BusinessCardDetail />} />
        <Route path="/cards/:cardId/edition-form" element={<EditionPage />} />
        <Route path="/cards/:cardId/template" element={<TemplateEdition />} />
        <Route path="/cards/templates" element={<TemplateSelection />} />
        <Route path="/cards/creation-form" element={<EditionPage />} />
        <Route path="/cards/own-creation-form" element={<EditionPage />} />
        <Route path="/statistics" element={<PersonalStatistics />} />
        <Route path="/configuration" element={<PersonalConfiguration />} />
        <Route path="/payments" element={<Payment />} />
        {hasReferrals && (
          <Route path="/collection" element={<PersonalCollection />} />
        )}

        <Route path="/company/statistics" element={<CompanyStatistics />} />
        <Route path="/company/cards" element={<BusinessCardTeamList />} />
        {hasReferrals && (
          <Route path="/company/collection" element={<GlobalCollection />} />
        )}
        {hasReferrals && (
          <Route
            path="/company/collection/:cardId"
            element={<EmployeeCollection />}
          />
        )}
        <Route
          path="/company/users"
          element={
            <EmployeeProvider>
              <UsersManagementPage />
            </EmployeeProvider>
          }
        />
        <Route
          path="/company/configuration"
          element={<CompanyConfiguration />}
        />

        <Route path="*" element={<NotFound />} />
        <Route path="/not-found" element={<NotFound />} />
      </Route>
      {["/b/:businessKey/:employeeKey", "/p/:businessKey/:employeeKey"].map(
        (path, index) => (
          <Route
            key={index}
            path={path}
            element={
              <BusinessCardPublicProvider>
                <BusinessCardPublic />
              </BusinessCardPublicProvider>
            }
          />
        )
      )}
      <Route path="/link-account/:code" element={<LinkAccount />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AppRoutes;
