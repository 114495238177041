import React, { useState, useEffect } from "react";
import { CircularProgress, IconButton } from "@mui/material";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { MonitoringService } from "../services/MonitoringService";
import SimpleTable from "../../common/atoms/SimpleTable";
import theme from "../../common/theme";
import { useNavigate } from "react-router-dom";
import "../assets/Tables.css";

const Container = styled.div`
  margin-bottom: 25px;
`;
const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
`;

const CounterStyled = styled.span`
  font-weight: 700;
  font-family: Lato;
  font-style: italic;
  font-size: 18px;
  display: block;
  margin: 0 auto;
  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;

const EmployeeCellContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  @media screen and (max-width: 480px) {
  }
`;

const ProfileIcon = styled.div`
  border-radius: 50%;
  background-color: ${theme.colors.silver};
  height: 30px;
  width: 30px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  overflow: hidden;
`;

const TopUsersTable = ({ filter }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const currentCompany = useSelector((state) => state.currentCompany);
  const navigate = useNavigate();
  const columns = [
    {
      field: "counter",
      headerName: "#",
      headerAlign: "center",
      width: "60",
      renderCell: (value) => <CounterStyled>{value}.</CounterStyled>,
    },
    {
      field: "names",
      headerName: "Nombre",
      width: "300",
      headerAlign: "center",
      renderCell: (value, row) => {
        return (
          <EmployeeCellContainer>
            <ProfileIcon>
              <img
                width="100%"
                height="100%"
                src={
                  (row && row.profileImage) ||
                  require("../../common/assets/img/no-avatar-img.jpg")
                }
              />
            </ProfileIcon>
            {value}
          </EmployeeCellContainer>
        );
      },
    },
    {
      field: "job",
      headerName: "Cargo",
      width: "200",
      headerAlign: "center",
    },
    {
      field: "phone",
      headerName: "Teléfono",
      width: "200",
      headerAlign: "center",
    },
    {
      field: "views",
      headerName: "Vistas",
      width: "250",
      headerAlign: "center",
    },
    {
      field: "actions",
      headerName: "",
      width: "100",
      headerAlign: "center",
      renderCell: (_value, row) => {
        return (
          <IconButton
            aria-label="view"
            onClick={() => navigate(`/cards/${row.id}`)}
          >
            <ArrowCircleRightIcon />
          </IconButton>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    if (currentCompany && currentCompany.id) {
      MonitoringService.getVisitsByEmployee(currentCompany.id, filter)
        .then((res) => {
          if (res.status === 200) {
            setData(res.data.visitsData);
          } else {
            console.log(res.data.message);
          }
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [filter]);

  return (
    <Container>
      {loading && (
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      )}
      {!loading && (
        <SimpleTable
          className="table-top-users"
          rows={data || []}
          columns={columns}
          noDataMessage="No hay eventos registrados"
          title="Top Visitas Colaboradores"
          variant="primary"
        ></SimpleTable>
      )}
    </Container>
  );
};

export default TopUsersTable;
