import styled from "@emotion/styled";
import Button from "../../common/atoms/Button";
import Typography from "../../common/atoms/Typography";
import { black, danger } from "../../common/atoms/variables";

const Card = styled.div`
  border: 5px solid ${black};
  border-radius: 10px;
  width: 100%;
  height: 320px;
  background: white;
  position: relative;
  margin-bottom: 16px;
  overflow: hidden;
`;

const CardHeader = styled.div`
  margin: 0;
  position: relative;
  height: 50%;
  width: 100%;
  overflow: hidden;
  background: ${danger};
`;

const CardFooter = styled.div`
  width: 100%;
  height: 50%;
  background: #eaeaea;
  text-align: center;
  display: flex;
  align-items: end;
  padding-bottom: 20px;
`;

const Container = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 200;
`;

const Image = styled.div`
  width: 45%;
  height: 160px;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;

  img {
    border-radius: 10px;
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  width: 45%;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const InactiveCard = ({ loggedUser, token }) => {
  const goToStartPage = () => {
    if (loggedUser && token) {
      window.location.replace(process.env.REACT_APP_DOMAIN + "/cards");
    } else {
      window.location.replace("https://hola.link");
    }
  };

  return (
    <Container className="container">
      <Card>
        <CardHeader />
        <Image>
          <img src={require("../../common/assets/img/no-avatar-img.jpg")} />
        </Image>
        <CardFooter>
          <Typography
            style={{ fontSize: "22px", lineHeight: "22px", fontWeight: "bold" }}
          >
            La tarjeta que busca no se encuentra activa
          </Typography>
        </CardFooter>
      </Card>
      <ButtonContainer onClick={goToStartPage}>
        <StyledButton variant="secondary">Ir al Inicio</StyledButton>
      </ButtonContainer>
    </Container>
  );
};

export default InactiveCard;
