import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import styled from "@emotion/styled";

import InputFull from "../../../common/atoms/InputFull";
import TextAreaFull from "../../../common/atoms/TextAreaFull";

const FormNameContainer = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  > div {
    flex: 1 1;
  }
`;

const FormInstitutionalContainer = styled.div`
  margin: 20px 0;
  display: flex;
  gap: 15px;
  align-items: center;
  > div {
    flex: 1 1;
  }
`;

const validations = {
  name: (v) => {
    if (!v) {
      return "El nombre es requerido";
    } else {
      return /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '.'-]+$/.test(
        v
      )
        ? ""
        : "El nombre es inválido";
    }
  },
  lastname: (v) => {
    if (!v) {
      return "El apellido es requerido";
    } else {
      return /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/.test(
        v
      )
        ? ""
        : "El apellido es inválido";
    }
  },
  job: (v) => {
    if (!v) {
      return "El puesto de trabajo es requerido";
    } else {
      return "";
    }
  },
  introduction: (v) => "",
  identifier: (v) => {
    if (v && !v.match(/^[0-9]*$/)) {
      return "El N° de Colegiatura es inválido";
    } else {
      return "";
    }
  },
};

const BasicInfoSection = forwardRef(
  (
    {
      loggedUser,
      initialData,
      readOnly,
      hasInstitution,
      mode,
      onChange,
      onError,
    },
    ref
  ) => {
    const [formData, setFormData] = useState({
      name: initialData.name,
      lastname: initialData.lastname,
      identifier: initialData.institutionalData?.identifier,
      job: initialData.job,
      introduction: initialData.introduction,
    });
    const [errors, setErrors] = useState({});
    const [enabledJobInput, setEnabledJobInput] = useState(false);

    const handleInputChange = (e, type) => {
      let { name, value } = e.target;

      if (name === "identifier") {
        if (!value.match(/^[0-9]*$/)) {
          return;
        }
        setFormData((prevState) => ({
          ...prevState,
          institutionalData: {
            [name]: value,
          },
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }

      validateInput(name, value);
    };

    const validateInput = (name, value) => {
      const error = validations[name](value);
      setErrors((prevState) => ({
        ...prevState,
        [name]: error,
      }));

      return error;
    };

    const validateAll = () => {
      let isValid = true;
      Object.keys(formData).forEach((name) => {
        const error = validateInput(name, formData[name]);
        if (error) {
          isValid = false;
        }
      });
      return isValid;
    };

    useEffect(() => {
      const isEnabled =
        loggedUser.role.startsWith("ADMIN") || mode === "creation";
      setEnabledJobInput(isEnabled);
    }, [loggedUser, mode]);

    useEffect(() => {
      const initialErrors = validateAll();
      onError(initialErrors);
    }, []);

    useEffect(() => {
      onError(errors);
    }, [errors]);

    useEffect(() => {
      onChange(formData);
    }, [formData]);

    useImperativeHandle(ref, () => ({
      validateAll,
    }));

    return (
      <>
        <FormNameContainer>
          <InputFull
            label="Nombres"
            value={formData.name}
            error={errors.name}
            fullWidth
            removeMargin
            errorMessage={errors.name}
            formMode={mode}
            onChange={handleInputChange}
            name="name"
            required={true}
            isDisabled={readOnly}
          />
          <InputFull
            label="Apellidos"
            value={formData.lastname}
            error={errors.lastname}
            fullWidth
            removeMargin
            errorMessage={errors.lastname}
            formMode={mode}
            onChange={handleInputChange}
            name="lastname"
            required={true}
            isDisabled={readOnly}
          />
        </FormNameContainer>
        {hasInstitution && (
          <FormInstitutionalContainer>
            <InputFull
              label="N° Colegiatura"
              value={formData.identifier}
              error={errors.identifier}
              fullWidth
              removeMargin
              errorMessage={errors.identifier}
              formMode={mode}
              onChange={handleInputChange}
              name="identifier"
              isDisabled={readOnly}
            />
          </FormInstitutionalContainer>
        )}
        <InputFull
          label="Ocupación / Puesto de trabajo"
          value={formData.job}
          error={errors.job}
          fullWidth
          errorMessage={errors.job}
          formMode={mode}
          onChange={handleInputChange}
          name="job"
          required={true}
          isDisabled={!enabledJobInput}
        />
        <InputFull
          label="Empresa"
          value={initialData.company.name}
          fullWidth
          name="company"
          required={true}
          isDisabled={true}
        />
        <TextAreaFull
          error={errors.introduction}
          helperText={errors.introduction}
          label="Presentación"
          deleteInputField={() => {}}
          name="introduction"
          fullWidth
          variant="standard"
          value={formData.introduction}
          onChange={handleInputChange}
          idDisabled={readOnly}
        />
      </>
    );
  }
);

export default BasicInfoSection;
