import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import "../../auth-management/assets/css/styles.css";
import { ReactComponent as Logo } from "../../auth-management/assets/img/logo-login.svg";
import Button from "../../common/atoms/Button";
import Alert from "../../common/atoms/Alert";
import PageLoader from "../../common/atoms/PageLoader";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";
import EmployeeService from "../services/EmployeeService";
import { logout } from "../../redux/store/accounts/actions/authActions";
import { clearCurrentCompany } from "../../redux/store/companies/actions/currentCompanyActions";
import Link from "../../common/atoms/Link";

const RowStyled = styled.div`
  margin: 0px;
  min-height: 100vh !important;
  height: 100vh;
`;

const ImageContainer = styled.div`
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #ededed;
  background-image: url(/greetings.jpg);
`;

const LeftWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  min-height: 100%;
  padding: 0 90px;
  color: #fff;
  text-align: center;
  position: relative;
  width: 100%;
  height: 100%;
  position: relative;
  left: 0;
  top: 0;
  z-index: 2;
  mix-blend-mode: multiply;
  background-color: rgba(0, 122, 255, 0.8);
  @media (max-width: 991px) {
    height: auto;
    padding: 95px 30px 60px 30px;
  }
  @media (max-width: 767px) {
    padding: 95px 15px 60px 15px;
  }
`;

export function LinkAccount() {
  const [errorMessage, setErrorMessage] = useState("");
  const [pageLoading, setPageLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const { code } = useParams();
  const { token, loggedUser } = useSelector((state) => state.authUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = token && loggedUser.id;

  const redirect = () => {
    if (isLoggedIn) {
      navigate("/configuration");
    } else {
      dispatch(logout());
      dispatch(clearCurrentCompany());
      navigate("/login");
    }
  };

  useEffect(() => {
    if (code) {
      setPageLoading(true);

      EmployeeService.linkAccount(code)
        .then((data) => {
          const loggedMessage = !isLoggedIn
            ? " Inicie sesión para continuar."
            : "";
          setSuccessMessage(
            "La vinculación se ha realizado correctamente." + loggedMessage
          );
        })
        .catch((error) => {
          const message = error.response?.data?.message;
          setErrorMessage(message);
        })
        .finally(() => {
          setPageLoading(false);
        });
    }
  }, [code]);

  return (
    <>
      {pageLoading && <PageLoader />}
      <div className="container-fluid p-0" style={{ height: "100%" }}>
        <RowStyled className="row">
          <ImageContainer className="col-lg-6 p-0">
            <LeftWrapper></LeftWrapper>
          </ImageContainer>
          <div className="col-lg-6 d-flex flex-column content-right">
            <div className="container my-auto py-5">
              <div className="row">
                <div className="col-lg-9 col-xl-7 mx-auto">
                  <div className="text-center">
                    <Logo width="218px" />
                  </div>
                  {errorMessage && <Alert type={"error"}>{errorMessage}</Alert>}
                  {successMessage && (
                    <Alert type={"success"}>{successMessage}</Alert>
                  )}
                  <div className="mt-5">
                    <Button
                      variant="primary"
                      size="medium"
                      fullWidth={true}
                      type="submit"
                      onClick={redirect}
                    >
                      Continuar
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="container social-networks">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex gap-2 justify-content-center">
                    <Link
                      className="social-networks__link"
                      href="https://www.facebook.com/Holalinklatam"
                      target="_blank"
                    >
                      <i className="bi bi-facebook"></i>
                    </Link>
                    <Link
                      className="social-networks__link"
                      href="https://www.instagram.com/holalinklatam/"
                      target="_blank"
                    >
                      <i className="bi bi-instagram"></i>
                    </Link>
                    <Link
                      className="social-networks__link"
                      href="https://www.linkedin.com/company/holalink/"
                      target="_blank"
                    >
                      <i className="bi bi-linkedin"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="container pb-3 copy text-center">
              © {new Date().getFullYear()} HolaLink - All Rights Reserved.
            </div>
          </div>
        </RowStyled>
      </div>
    </>
  );
}
