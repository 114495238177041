import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import Dropdown from "../../common/molecules/Dropdown";
import { BusinessCardService } from "../../business-card-management/services/BusinessCardService";

const OwnBusinessCardSelector = ({ onChange }) => {
  const [loading, setLoading] = useState(false);
  const [businessCards, setBusinessCards] = useState([]);
  const [cardOptions, setCardOptions] = useState([]);
  const [initialOption, setInitialOption] = useState(null);

  useEffect(() => {
    getOwnCards();
  }, []);

  const getOwnCards = async () => {
    setLoading(true);
    BusinessCardService.getOwnBusinessCards()
      .then((response) => {
        const businessCardList = response.data.businessCards || [];
        setBusinessCards(businessCardList);
        setCardOptions(
          businessCardList.map((item) => ({
            key: item.id,
            value: item.company?.name,
            icon: item.photoUrl,
          }))
        );
        setInitialOption({
          key: businessCardList[0].id,
          value: businessCardList[0].company?.name,
          icon: businessCardList[0].photoUrl,
        });
        onChange(businessCardList[0]);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClick = (option) => {
    const selected = businessCards.find((item) => item.id === option.key);
    onChange(selected);
  };

  return (
    <Dropdown
      options={cardOptions}
      fullWidth={true}
      hasIcon={true}
      onClick={handleClick}
      loading={loading}
      defaultOption={initialOption}
    />
  );
};

OwnBusinessCardSelector.propTypes = {
  loading: PropTypes.bool,
  onChange: PropTypes.func,
};

export default OwnBusinessCardSelector;
