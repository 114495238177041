import * as React from "react";
import Table from "../../common/atoms/Table";

const columns = [
  {
    field: "id",
    headerName: "ID",
    width: 70,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "dateHour",
    headerName: "Fecha y Hora",
    width: 200,
    headerAlign: "center",
    align: "center",
  },
  // {
  //   field: "DNI",
  //   headerName: "DNI",
  //   width: 130,
  //   headerAlign: "center",
  //   align: "center",
  // },
  {
    field: "phoneNumber",
    headerName: "Teléfono",
    width: 130,
    headerAlign: "center",
    align: "center",
  },
  // {
  //   field: "Calification",
  //   headerName: "Calificación",
  //   width: 130,
  //   headerAlign: "center",
  //   align: "center",
  // },
];

export default function EmployeeCollectionTable({ data }) {
  return (
    <div style={{ width: "100%" }}>
      <Table
        autoHeight
        rows={data}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
      />
    </div>
  );
}
