import axiosInstance from "../../common/helpers/axios";

export const PricingService = {
  getPricing: async () => {
    try {
      return await axiosInstance.get(`/pricing`);
    } catch (error) {
      return error.response;
    }
  },
};
