import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { useContext, useEffect } from "react";

import Button from "../../common/atoms/Button";
import Typography from "../../common/atoms/Typography";
import theme from "../../common/theme";
import { LocalStorageService } from "../../common/services/LocalStorageService";
import { VcfService } from "../../common/helpers/VcfService";
import { BusinessCardPublicContext } from "../context/BusinessCardPublicContext";
import { useSelector, useDispatch } from "react-redux";
import { setPageLoading } from "../../redux/store/companies/actions/generalPageLoadingActions";

const ButtonStyled = styled(Button)`
  display: flex;
  gap: 10px;
  align-items: center;
  border-radius: 40px;
  border: 3px solid white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 8px;
  background-color: ${theme.colors.white};
  color: ${theme.colors.white};

  transform: perspective(1px) translateZ(0);
  transition-duration: 0.3s;
  transition-property: transform;
  &:hover,
  &:active,
  &:focus {
    transform: scale(0.9);
  }
  &:disabled {
    background-color: ${theme.colors.alto};
    color: ${theme.colors.mineShaft};
    border: 1px solid ${theme.colors.mineShaft};
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 8px;
    cursor: not-allowed;
    &:hover,
    &:active {
      transform: none;
    }
  }
`;

const VcfContactButton = ({ businessCardId, onDownload, disabled }) => {
  const { ratingExists, setRatingExists } = useContext(
    BusinessCardPublicContext
  );
  const generalPageLoading = useSelector((state) => state.generalPageLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    if (businessCardId) {
      setRatingExists(
        LocalStorageService.get("CARDS_RATED") &&
          LocalStorageService.get("CARDS_RATED")[businessCardId]
      );
    }
  }, [businessCardId]);

  const handleClick = () => {
    if (disabled) return;
    dispatch(setPageLoading(true));
    onDownload();
    VcfService.downloadVcf().finally(() => {
      dispatch(setPageLoading(false));
    });
  };

  return (
    <ButtonStyled onClick={handleClick} size="large" disabled={disabled}>
      {generalPageLoading ? (
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        <>
          <i
            className="bi bi-person-fill-add"
            style={{
              fontSize: "1.5rem",
              color: "#000",
            }}
          />
          <Typography variant="xs" weight="bold">
            {generalPageLoading ? "Descargando..." : "Añadir"}
          </Typography>
        </>
      )}
    </ButtonStyled>
  );
};

VcfContactButton.propTypes = {
  businessCardId: PropTypes.string,
};

export default VcfContactButton;
