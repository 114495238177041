import React, { useEffect, useState } from "react";
import InitialLayout from "../components/InitialLayout";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LogoUploadInput } from "../../common/molecules/LogoUploadInput";
import { TextField } from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "../../common/atoms/Button";
import AuthService from "../services/AuthService";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import theme from "../../common/theme";
import { useNavigate } from "react-router-dom";
import { FileServices } from "../../common/services/FileServices";
import NativeSelect from "@mui/material/NativeSelect";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import InstitutionService from "../services/InstitutionService";

const PersonalRegister = () => {
  const [companyForm, setCompanyForm] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [institutions, setInstitutions] = useState([]);

  const navigate = useNavigate();

  const getInstitutions = async () => {
    InstitutionService.getInstitutions()
      .then((res) => {
        setInstitutions(res.institutions);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const uploadImage = async (images) => {
    let responseImagesObj = {};
    try {
      for (const fileObj of images) {
        const name = fileObj.name;
        const imageFile = fileObj.file;
        const filename = `${imageFile.name}-${new Date().getTime()}`;
        let reference = FileServices.getReference("image/*", filename);
        const url = await FileServices.uploadFile(imageFile, reference);
        responseImagesObj[name] = { filename, url };
      }
    } catch (error) {
      console.log(error);
    }
    return responseImagesObj;
  };

  const savePersonalAccount = (body) => {
    const { personalData, brandData, institutionalData } = body;
    setPageLoading(true);

    const filesArray = [];
    if (personalData.profileImage)
      filesArray.push({
        name: "profileImage",
        file: personalData.profileImage,
      });
    if (brandData.logoUrl)
      filesArray.push({ name: "logoUrl", file: brandData.logoUrl });
    uploadImage(filesArray)
      .then((responseImages) => {
        const formattedBody = {
          personalData: {
            ...personalData,
            profileImage: responseImages.profileImage,
          },
          brandData: {
            ...brandData,
            logoUrl: responseImages.logoUrl,
          },
          institutionalData: {
            ...institutionalData,
            institution:
              institutionalData.institution === "0"
                ? ""
                : institutionalData.institution,
          },
        };
        AuthService.registerPersonalAccount(
          formattedBody,
          {
            url: "https://firebasestorage.googleapis.com/v0/b/holalinktest.appspot.com/o/default-images%2Favatar-default.webp?alt=media&token=1db16d0b-a86d-4820-9c0f-107fdd0de36e",
            filename: "avatar.jpg",
          },
          {
            url: "https://firebasestorage.googleapis.com/v0/b/holalinktest.appspot.com/o/default-images%2Fcompany-default.webp?alt=media&token=ca0e1308-952b-4455-8bb1-26adf0021882",
            filename: "company-default.webp",
          }
        )
          .then((data) => {
            setErrorMessage("");
            setSuccessMessage(
              "Cuenta creada correctamente, se ha enviado un email. Se redireccionará en 5 segundos a la página de login."
            );
            setTimeout(() => {
              navigate("/login");
            }, 5000);
          })
          .catch((error) => {
            if (error.response.status === 409) {
              setErrorMessage(error.response.data.message);
            } else {
              setErrorMessage("Error al crear la cuenta");
            }
          })
          .finally(() => {
            setPageLoading(false);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formik = useFormik({
    initialValues: {
      personalData: {
        phone: "",
        name: "",
        email: "",
        lastname: "",
        profileImage: undefined,
      },
      brandData: {
        brandName: "",
        address: "",
        contactEmail: "",
        contactPhone: "",
        webUrl: "",
        logoUrl: undefined,
      },
      institutionalData: {
        institution: "0",
        identifier: "",
      },
    },
    validationSchema: Yup.object({
      personalData: Yup.object({
        name: Yup.string()
          .min(2, "Ingrese un nombre válido")
          .required("Ingrese un nombre")
          .matches(
            /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]+( [a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]+)*$/,
            "Ingrese un nombre válido"
          ),
        lastname: Yup.string()
          .min(2, "Ingrese un apellido válido")
          .required("Ingrese un apellido")
          .matches(
            /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]+( [a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]+)*$/,
            "Ingrese un apellido válido"
          ),
        email: Yup.string()
          .email("Ingrese un correo válido")
          .required("Ingrese un correo"),
        phone: Yup.string()
          .required("Ingrese un teléfono")
          .matches(/^\d{9}$/, "Ingrese un número válido"),
      }),
      brandData: Yup.object({
        brandName: Yup.string().min(2, "Ingrese un nombre válido"),
        address: Yup.string().min(2, "Ingrese una dirección válida"),
        contactEmail: Yup.string().email("Ingrese un correo válido"),
        contactPhone: Yup.string()
          .matches(/^\+\d+$/, "Ingrese un número válido")
          .min(12, "Ingrese un número válido")
          .max(18, "Ingrese un número válido"),
      }),
      institutionalData: Yup.object({
        institution: Yup.string(),
        identifier: Yup.string()
          .matches(/^[0-9]+$/, "Ingrese un número válido")
          .min(4, "Se requieren más de 3 dígitos.")
          .max(6, "Se permiten hasta 6 dígitos"),
      }),
    }),
    onSubmit: (values) => {
      savePersonalAccount(values);
    },
  });

  const handleCompanyForm = () => {
    setCompanyForm(!companyForm);
    formik.setFieldValue("brandData", {
      brandName: "",
      address: "",
      contactEmail: "",
      contactPhone: "",
      webUrl: "",
      logoUrl: "",
    });
  };

  const handleImage = async (imageFile, name, setFieldValue) => {
    setFieldValue(name, imageFile);
  };

  const handleInstitutionChange = (e) => {
    formik.setFieldValue("institutionalData.institution", e.target.value);
    formik.setFieldValue("institutionalData.identifier", "");
  };

  useEffect(() => {
    getInstitutions();
  }, []);

  return (
    <InitialLayout
      errorMessage={""}
      successMessage={""}
      pageLoading={pageLoading}
      customWidth="col-lg-9 col-xl-9 mx-auto"
    >
      <div>
        <label
          onClick={() => navigate("/account-type")}
          style={{
            cursor: "pointer",
          }}
        >
          <ArrowBackIcon
            sx={{
              fontSize: "1rem",
              marginRight: "0.5rem",
            }}
          />
          Volver
        </label>
      </div>
      <div className="row">
        <div className="d-flex justify-content-center mb-3">
          <LogoUploadInput
            onHandleImage={(imageFile) =>
              handleImage(
                imageFile,
                "personalData.profileImage",
                formik.setFieldValue
              )
            }
            formType="personal-account"
          />
        </div>
        <div className="col-md-6 mb-3">
          <TextField
            id="name"
            type="text"
            name="personalData.name"
            label="Nombre"
            variant="standard"
            fullWidth
            value={formik.values.personalData.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.personalData?.name &&
              Boolean(formik.errors.personalData?.name)
            }
            helperText={
              formik.touched.personalData?.name &&
              formik.errors.personalData?.name
            }
            required
          />
        </div>
        <div className="col-md-6 mb-3">
          <TextField
            id="lastname"
            type="text"
            name="personalData.lastname"
            label="Apellido"
            variant="standard"
            fullWidth
            value={formik.values.personalData.lastname}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.personalData?.lastname &&
              Boolean(formik.errors.personalData?.lastname)
            }
            helperText={
              formik.touched.personalData?.lastname &&
              formik.errors.personalData?.lastname
            }
            required
          />
        </div>
        <div className="col-md-6 mb-3">
          <TextField
            id="email"
            type="email"
            name="personalData.email"
            label="Correo"
            variant="standard"
            fullWidth
            value={formik.values.personalData.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.personalData?.email &&
              Boolean(formik.errors.personalData?.email)
            }
            helperText={
              formik.touched.personalData?.email &&
              formik.errors.personalData?.email
            }
            required
          />
        </div>
        <div className="col-md-6 mb-3">
          <TextField
            id="phone"
            type="text"
            name="personalData.phone"
            label="Teléfono"
            variant="standard"
            fullWidth
            value={formik.values.personalData.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.personalData?.phone &&
              Boolean(formik.errors.personalData?.phone)
            }
            helperText={
              formik.touched.personalData?.phone &&
              formik.errors.personalData?.phone
            }
            required
          />
        </div>
        {formik.errors.personalData?.phone && (
          <div className="alert alert-danger">
            <strong>Nota:</strong> Ingrese solo los 9 dígitos de su número de
            celular sin incluir el código de país.
          </div>
        )}
        {/* <Typography variant="subtitle1">
          ¿Perteneces a algunas instituciones asociadas con HolaLink?
        </Typography>
        <div className="col-md-6 mb-3 mt-2">
          <FormControl fullWidth>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              Instituciones
            </InputLabel>
            <NativeSelect
              id="institution"
              name="institutionalData.institution"
              defaultValue={formik.values.institutionalData.institution}
              onChange={handleInstitutionChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.institutionalData?.institution &&
                Boolean(formik.errors.institutionalData?.institution)
              }
            >
              <option value="0">Ninguno</option>
              {institutions.map((institution, index) => {
                return (
                  <option key={index.toString()} value={institution.id}>
                    {institution.name}
                  </option>
                );
              })}
            </NativeSelect>
          </FormControl>
        </div>
        {formik.values.institutionalData?.institution !== "0" &&
          formik.values.institutionalData?.institution !== "" && (
            <div className="col-md-6 mb-3 mt-2">
              <TextField
                id="identifier"
                type="text"
                name="institutionalData.identifier"
                label="Nº Colegiatura"
                variant="standard"
                fullWidth
                value={formik.values.institutionalData.identifier}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.institutionalData?.identifier &&
                  Boolean(formik.errors.institutionalData?.identifier)
                }
                helperText={
                  formik.touched.institutionalData?.identifier &&
                  formik.errors.institutionalData?.identifier
                }
              />
            </div>
          )} */}
        <div className="col-md-12 mb-3">
          <FormControlLabel
            control={<Checkbox onChange={handleCompanyForm} />}
            label="¿Quieres agregar información de tu marca o empresa?"
          />
        </div>
        {companyForm ? (
          <>
            <div className="col-md-6 mb-3">
              <TextField
                id="brandName"
                type="text"
                name="brandData.brandName"
                label="Nombre de la marca"
                variant="standard"
                fullWidth
                value={formik.values.brandData.brandName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.brandData?.brandName &&
                  Boolean(formik.errors.brandData?.brandName)
                }
                helperText={
                  formik.touched.brandData?.brandName &&
                  formik.errors.brandData?.brandName
                }
              />
            </div>
            <div className="col-md-6 mb-3">
              <TextField
                id="address"
                type="text"
                name="brandData.address"
                label="Dirección"
                variant="standard"
                fullWidth
                value={formik.values.brandData.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.brandData?.address &&
                  Boolean(formik.errors.brandData?.address)
                }
                helperText={
                  formik.touched.brandData?.address &&
                  formik.errors.brandData?.address
                }
              />
            </div>
            <div className="col-md-6 mb-3">
              <TextField
                id="contactEmail"
                type="email"
                name="brandData.contactEmail"
                label="Correo de contacto"
                variant="standard"
                fullWidth
                value={formik.values.brandData.contactEmail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.brandData?.contactEmail &&
                  Boolean(formik.errors.brandData?.contactEmail)
                }
                helperText={
                  formik.touched.brandData?.contactEmail &&
                  formik.errors.brandData?.contactEmail
                }
              />
            </div>
            <div className="col-md-6 mb-3">
              <TextField
                id="contactPhone"
                type="text"
                name="brandData.contactPhone"
                label="Teléfono de contacto"
                variant="standard"
                fullWidth
                value={formik.values.brandData.contactPhone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.brandData?.contactPhone &&
                  Boolean(formik.errors.brandData?.contactPhone)
                }
                helperText={
                  formik.touched.brandData?.contactPhone &&
                  formik.errors.brandData?.contactPhone
                }
              />
            </div>
            <div className="col-md-6 mb-3">
              <TextField
                id="webUrl"
                type="text"
                name="brandData.webUrl"
                label="Página web"
                variant="standard"
                fullWidth
                value={formik.values.brandData.webUrl}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.brandData?.webUrl &&
                  Boolean(formik.errors.brandData?.webUrl)
                }
                helperText={
                  formik.touched.brandData?.webUrl &&
                  formik.errors.brandData?.webUrl
                }
              />
            </div>
            <div className="col-md-6 mb-3 d-flex align-items-end">
              <div className="d-flex justify-space-between">
                <label htmlFor="logo" style={{ cursor: "pointer" }}>
                  <div>
                    <input
                      type="file"
                      accept="image/*"
                      id="logo"
                      name="logo"
                      onChange={(event) => {
                        handleImage(
                          event.currentTarget.files[0],
                          "brandData.logoUrl",
                          formik.setFieldValue
                        );
                      }}
                      style={{ display: "none" }}
                    />
                    <div className="d-flex align-items-center">
                      <FileUploadIcon htmlFor="logo" />
                      {formik.values.brandData.logoUrl ? (
                        <p
                          className="m-0"
                          style={{
                            maxWidth: "15ch",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {formik.values.brandData.logoUrl.name}
                        </p>
                      ) : (
                        <p className="m-0">Sube tu logo (opcional)</p>
                      )}
                    </div>
                  </div>
                </label>
                {formik.values.brandData.logoUrl ? (
                  <DeleteIcon
                    onClick={() => {
                      handleImage(
                        undefined,
                        "brandData.logoUrl",
                        formik.setFieldValue
                      );
                    }}
                    style={{ cursor: "pointer" }}
                    sx={{ color: theme.colors.pastelRed }}
                  />
                ) : null}
              </div>
            </div>
          </>
        ) : null}
        {successMessage ? (
          <div className="alert alert-success">{successMessage}</div>
        ) : null}
        {errorMessage ? (
          <div className="alert alert-danger">{errorMessage}</div>
        ) : null}
        <Button
          variant="primary"
          size="medium"
          type="submit"
          className="col-md-12 mb-3 mt-3"
          onClick={formik.handleSubmit}
        >
          Registrarse
        </Button>
      </div>
    </InitialLayout>
  );
};

export default PersonalRegister;
