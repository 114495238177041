import companiesSagas from "./companies/companiesSaga";
import { all, fork } from "redux-saga/effects";
import businessCardsSaga from "./business-cards/businessCardsSaga";
import accountsSaga from "./accounts/accountsSaga";
import commonSaga from "./common/commonSaga";

export default function* rootSaga() {
  yield all(companiesSagas.map((saga) => fork(saga)));
  yield all(businessCardsSaga.map((saga) => fork(saga)));
  yield all(accountsSaga.map((saga) => fork(saga)));
  yield all(commonSaga.map((saga) => fork(saga)));
}
