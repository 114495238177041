import PropTypes from "prop-types";
import CardTemplateOne from "./CardTemplateOne";
import CardTemplateTwo from "./CardTemplateTwo";
import CardTemplateThree from "./CardTemplateThree";
import { CATEGORIES, COUNTRIES, DEFAULT_COUNTRY } from "./form.utils";

const DEFAULT_TEMPLATE_ID = 1;
function CardPreview({
  cardValues,
  interactive,
  mode,
  fullWidth,
  colors,
  isPreview,
  cardPathKeys,
  openQR,
  handleClose,
}) {
  const phoneExtension =
    cardValues &&
    (cardValues[CATEGORIES.GENERAL_CONTACTS]["phones"][0].extension ||
      COUNTRIES[DEFAULT_COUNTRY].secondary);

  const renderTemplate = () => {
    switch ((cardValues.templateId || DEFAULT_TEMPLATE_ID).toString()) {
      case "1":
        return (
          <CardTemplateOne
            mode={mode}
            interactive={interactive}
            cardInput={cardValues}
            fullWidth={fullWidth}
            colors={colors}
            isPreview={isPreview}
            cardPathKeys={cardPathKeys}
            openQR={openQR}
            handleClose={handleClose}
            phoneExtension={phoneExtension}
          />
        );
      case "2":
        return (
          <CardTemplateTwo
            mode={mode}
            interactive={interactive}
            cardInput={cardValues}
            fullWidth={fullWidth}
            colors={colors}
            isPreview={isPreview}
            cardPathKeys={cardPathKeys}
            openQR={openQR}
            handleClose={handleClose}
            phoneExtension={phoneExtension}
          />
        );
      case "3":
        return (
          <CardTemplateThree
            mode={mode}
            interactive={interactive}
            cardInput={cardValues}
            fullWidth={fullWidth}
            colors={colors}
            isPreview={isPreview}
            cardPathKeys={cardPathKeys}
            openQR={openQR}
            handleClose={handleClose}
            phoneExtension={phoneExtension}
          />
        );
    }
  };

  return <>{renderTemplate()}</>;
}

CardPreview.propTypes = {
  cardValues: PropTypes.object.isRequired,
  interactive: PropTypes.bool,
  mode: PropTypes.oneOf(["static", "dynamic"]),
  fullWidth: PropTypes.bool,
  isPreview: PropTypes.bool,
};

export default CardPreview;
