import styled from "@emotion/styled";
import Button from "../../common/atoms/Button";
import PropTypes from "prop-types";
import EditUserForm from "../views/EditUserForm";
import { useContext, useState } from "react";
import { EmployeeContext } from "../context/EmployeesContext";
import Table from "../../common/atoms/Table";

const CounterStyled = styled.span`
  font-weight: 700;
  font-size: 18px;
  display: block;
  margin: 0 auto;
`;

const StyledEditButton = styled(Button)`
  background-color: #f0f2fa;
  padding: 0.4rem 0.8rem;
  border-radius: 5px;
  font-weight: 500;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.16) 0px 2px 4px -1px;
  &:hover {
    background-color: #eaeaea;
  }
`;

const JobCellContainer = styled.span`
  white-space: break-spaces;
  text-align: center;
`;

const RoleCellContainer = styled.span`
  white-space: break-spaces;
  text-align: center;
`;

function UsersTable({
  data,
  loading,
  page,
  onPageChange,
  handleGetEmployees,
  setPageLoading,
}) {
  const [openEdition, setOpenEdition] = useState(false);
  const [employeeToEdit, setEmployeeToEdit] = useState({});

  const {
    employeesToEditRole,
    setEmployeesToEditRole,
    selectedRows,
    setSelectedRows,
  } = useContext(EmployeeContext);

  const openEditionDialog = (data) => {
    setEmployeeToEdit(data);
    setOpenEdition(true);
  };

  const handlePageLoading = (state) => {
    setPageLoading(state);
  };

  const columns = [
    {
      field: "counter",
      headerName: "#",
      headerAlign: "center",
      width: "40",
      renderCell: ({ value }) => {
        if (value && value > 0) {
          return <CounterStyled>{value}</CounterStyled>;
        } else {
          return <CounterStyled>{"-"}</CounterStyled>;
        }
      },
    },
    {
      field: "names",
      headerName: "Nombre",
      width: "190",
      headerAlign: "center",
      align: "left",
    },
    {
      field: "email",
      headerName: "Correo",
      width: "180",
      headerAlign: "center",
      align: "left",
    },
    {
      field: "phone",
      headerName: "Teléfono",
      width: "140",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "job",
      headerName: "Puesto de trabajo",
      width: "180",
      headerAlign: "center",
      align: "center",
      renderCell: ({ value }) => {
        if (value && value.length > 0) {
          return <JobCellContainer>{value}</JobCellContainer>;
        }
      },
    },
    {
      field: "role",
      headerName: "Rol",
      width: "170",
      headerAlign: "center",
      align: "center",
      renderCell: ({ value }) => {
        if (value && value.length > 0) {
          return <RoleCellContainer>{value}</RoleCellContainer>;
        }
      },
    },
    {
      field: "actions",
      headerName: "+",
      align: "center",
      width: "100",
      sortable: false,
      headerAlign: "center",
      renderCell: ({ row }) => {
        if (row.roleCode !== "ADMIN_FOUNDER") {
          return (
            <StyledEditButton
              onClick={() => {
                openEditionDialog(row);
              }}
            >
              Editar
            </StyledEditButton>
          );
        }
      },
    },
  ];

  const handlePageChange = (newPage) => {
    onPageChange(newPage);
  };

  const closeEditionDialog = () => {
    setOpenEdition(false);
  };

  const getEmployees = async (page) => {
    handleGetEmployees("", page);
  };

  const onSelectionModelChangeFunction = (idArray) => {
    const selectedIds = new Set(idArray);
    let selectedData = [];
    data.data.forEach((row) => {
      if (selectedIds.has(`${row.id.toString()}`)) {
        selectedData.push(row);
      }
    });

    setEmployeesToEditRole({
      ...employeesToEditRole,
      [`${page}`]: selectedData,
    });
    setSelectedRows(idArray);
  };
  return (
    <>
      <div className="border-container">
        <Table
          autoHeight
          rows={data.data || []}
          rowCount={data.rowCount || 0}
          columns={columns}
          pageSize={10}
          page={page}
          disableColumnFilter={true}
          disableColumnMenu={true}
          disableColumnSelector={true}
          rowsPerPageOptions={[10]}
          paginationMode="server"
          disableSelectionOnClick
          loading={loading}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }
          onPageChange={handlePageChange}
          checkboxSelection
          selectionModel={selectedRows}
          onSelectionModelChange={(idArray) => {
            onSelectionModelChangeFunction(idArray);
          }}
          keepNonExistentRowsSelected={true}
          isRowSelectable={(params) => params.row.roleCode !== "ADMIN_FOUNDER"}
        />
      </div>
      <EditUserForm
        open={openEdition}
        employee={employeeToEdit}
        closeDialog={closeEditionDialog}
        getEmployees={getEmployees}
        page={page}
        setPageLoading={handlePageLoading}
      />
    </>
  );
}

UsersTable.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.object,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
};

export default UsersTable;
