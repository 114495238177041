import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, DialogActions, TextField } from "@mui/material";
import { useFeature } from "flagged";
import styled from "@emotion/styled";

import Button from "../../common/atoms/Button";
import { BusinessCardsContext } from "../context/BusinessCardsContext";
import { AuthContext } from "../../auth-management/context/AuthContext";
import { BusinessCardService } from "../services/BusinessCardService";
import CardPreview from "../components/CardPreview";
import ButtonGroup from "../../common/atoms/ButtonGroup";
import BackButton from "../../common/atoms/BackButton";
import CardContainer from "../../common/atoms/CardContainer";
import Dropdown from "../../common/molecules/Dropdown";
import QRDisplay from "../components/QRDisplay";
import { ShareSocialNetworksButtons } from "../components/ShareSocialNetworksButtons";
import CardUrlSection from "../components/CardUrlSection";
import CardStatistics from "../components/CardStatistics";
import { danger, secondaryGray } from "../../common/atoms/variables";
import { EditIcon } from "../../common/atoms/SvgIcons";
import { setPageLoading } from "../../redux/store/companies/actions/generalPageLoadingActions";
import ConfirmationDialog from "../../common/molecules/ConfirmationDialog";
import Typography from "../../common/atoms/Typography";
import theme from "../../common/theme";
import Flex from "../../common/atoms/Flex";
import { isMyCard } from "../../common/helpers/cards.utils";
import ReplaceStatisticsDialog from "../components/ReplaceStatisticsDialog";

const DetailContainer = styled.div`
  display: flex;
  gap: 35px;
  align-items: start;
  margin-top: 40px;
  flex-wrap: wrap;
  @media (min-width: 1920px) {
    gap: 60px;
  }
`;

const CardPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: unset;
  border-radius: 10px;
  overflow: hidden;
  flex: 30%;
  @media (min-width: 1920px) {
    max-width: 500px;
  }
  @media (max-width: 768px) {
    flex: 100%;
  }
  @media screen and (max-width: 480px) {
    border-radius: unset;
  }
`;

const ShareAndStatisticsContainer = styled.div`
  width: 100%;
  position: relative;
  flex: 30%;
  height: 100%;
  @media (min-width: 1920px) {
    max-width: 467px;
  }
`;

const ConfigurationContainer = styled.div`
  width: 100%;
  flex: 25%;
  @media (min-width: 1920px) {
    max-width: 470px;
  }
`;

const UrlContainer = styled.div`
  background-color: white;
  border: 3px solid #0061f7;
  padding: 24px;
  border-radius: 10px;
`;

const UrlBase = styled.span`
  color: #d1d1d1;
  font-size: 1rem;
  margin-right: 8px;
  @media (max-width: 425px) {
    font-size: 14px;
  }
`;

const UrlTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
`;
const UrlBox = styled(Flex)`
  align-items: center;
  @media (max-width: 425px) {
    flex-wrap: wrap;
  }
`;

const UrlField = styled(TextField)`
  display: inline;
`;

const StatisticsButtonContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const ShareLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  height: 100%;
`;

const ShareCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  position: relative;
`;

const WarningMessage = styled(Typography)`
  margin-bottom: 8px;
  margin-top: 8px;
  color: ${danger};
`;

const EditionMessage = styled(Typography)`
  margin-bottom: 8px;
  margin-top: 8px;
  color: ${secondaryGray};
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

const ShareMainContainer = styled.div`
  min-height: 590px;
`;

const TransparentCoverContainer = styled.div`
  position: absolute;
  width: 100%;
  z-index: 30;
`;

const TransparentCover = styled.div`
  background: #fff;
  opacity: 0.85;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 625px;
  max-height: 650px;
  position: absolute;
  z-index: 100;
  border-radius: 0px 0px 10px 10px;
`;

const Subtitle = styled.div`
  font-size: 15px;
  font-weight: 600;
  margin: 0px;
`;

const QRContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 12px 0px;
`;

const EditPendingText = styled.div`
  font-size: 24px;
  font-weight: 700;
  text-align: center;
`;

const PauseCardMessage = styled(Typography)`
  color: ${theme.colors.carnation};
  text-align: left;
`;

const EditButton = styled.div`
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const InactiveUserTitle = styled.h6`
  margin-top: 12px;
  @media (min-width: 950px) {
    margin-left: 30px;
  }
  @media (max-width: 359px) {
    font-size: 12px;
  }
`;

const SocialContainer = styled.div``;
const MAX_INSTANT_UPDATES = 1;
export function BusinessCardDetail() {
  const { setFormMode, isOwnCard, currentCard, setCurrentCard, setIsOwnCard } =
    useContext(BusinessCardsContext);
  const [loading, setLoading] = useState(false);
  const { loggedUser } = useSelector((state) => state.authUser);
  const linkedAccounts = useSelector((state) => state.linkedAccounts);
  const { token } = useContext(AuthContext);
  const [editButtonEnabled, setEditButtonEnabled] = useState(false);
  const navigate = useNavigate();
  const { cardId } = useParams();
  const [showDialogStatistics, setShowDialogStatistics] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [daysLeft, setDaysLeft] = useState(0);
  const dispatch = useDispatch();
  const hasReferrals = useFeature("referrals");
  const [error, setError] = useState(false);
  const [urlEditionDisabled, setUrlEditionDisabled] = useState(true);
  const [urlMessage, setUrlMessage] = useState("");
  const [cardPathKey, setCardPathKey] = useState(
    currentCard && currentCard.businessCardPathKey
  );
  const [url, setUrl] = useState(currentCard && currentCard.employeePathKey);
  const [confirmationNote, setConfirmationNote] = useState("");

  const [inactiveBusinessCardList, setInactiveBusinessCardList] = useState([]);
  const [inactiveCardsOptions, setInactiveCardsOptions] = useState([]);
  const [showDialogTransferEvents, setShowDialogTransferEvents] =
    useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const getBusinessCardType = (type) => {
    return type === "personal" ? "p" : "b";
  };

  const companyPathKey = currentCard && currentCard.companyPathKey;
  const baseCardUrl =
    process.env.REACT_APP_DOMAIN + `/${getBusinessCardType(currentCard?.type)}`;

  const enableEdition = () => {
    if (loggedUser.role !== "EMPLOYEE") {
      if (!urlEditionDisabled) {
        setUrl(currentCard.employeePathKey);
        setUrlMessage("");
        setError(false);
      }
      setUrlEditionDisabled(!urlEditionDisabled);
    }
  };

  const updateUrl = async (e) => {
    dispatch(setPageLoading(true));
    BusinessCardService.editCardEmployeePathKey(
      url,
      currentCard.employeeId,
      currentCard.baseInformation.company.id
    )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.updatedEmployee) {
            setCardPathKey(
              `/${currentCard.companyPathKey}/${response.data.updatedEmployee.pathKey}`
            );
          }
          setDaysLeft(response.data.daysLeft);
          setUrl(response.data.updatedEmployee.pathKey);
          setCurrentCard({
            ...currentCard,
            employee: {
              ...currentCard["employee"],
              pathKey: response.data.updatedEmployee.pathKey,
              pathKeyUpdateCount:
                response.data.updatedEmployee.pathKeyUpdateCount,
            },
            employeePathKey: response.data.updatedEmployee.pathKey,
          });
          setUrlEditionDisabled(true);
          dispatch(setPageLoading(false));
          setShowConfirmationDialog(false);
        }
      })
      .catch((error) => {
        dispatch(setPageLoading(false));
        if (error.response && error.response.message) {
          console.log(error.response.message);
        }
      });
  };

  const handleChange = (e) => {
    setError(false);
    setUrlMessage("");
    setUrl(e.target.value);
  };

  const handleDialogStatistics = () => {
    setShowDialogStatistics(!showDialogStatistics);
  };

  useEffect(() => {
    BusinessCardService.setToken(token);
    fetchCardInfo();
  }, []);

  const fetchCardInfo = () => {
    setLoading(true);
    BusinessCardService.getBusinessCardById(cardId)
      .then((res) => {
        if (res.data.businessCard) {
          const card = res.data.businessCard;
          const ownCards = linkedAccounts.reduce((result, obj) => {
            if (obj.businessCards) {
              result.push(...obj.businessCards);
            }
            return result;
          }, []);
          if (isMyCard(cardId, ownCards)) {
            setIsOwnCard(true);
          } else {
            setIsOwnCard(false);
          }
          setCurrentCard(card);
          setUrl(card.employeePathKey);
          const urlPaths = card.businessCardPathKey.split("/");
          setCardPathKey(
            `/${encodeURIComponent(urlPaths[1])}/${encodeURIComponent(
              urlPaths[2]
            )}`
          );
          setLoading(false);
          setEditButtonEnabled(true);
          setDaysLeft(card.daysLeft);
          getInactiveCards(card.baseInformation.company.id);
        } else {
          navigate("/not-found");
        }
      })
      .catch((error) => {
        if (error.response) {
          const status = error.response.status;
          if (status === 403 || status === 404) {
            navigate("/not-found");
            return;
          }
        }
        console.log(error);
      });
  };

  const getInactiveCards = async (companyId) => {
    BusinessCardService.getInactiveBusinessCards(companyId)
      .then((response) => {
        const inactiveBusinessCardList = response.data || [];
        setInactiveBusinessCardList(inactiveBusinessCardList);
        setInactiveCardsOptions(
          inactiveBusinessCardList.map((item) => ({
            key: item.id,
            value:
              item.baseInformation.name + " " + item.baseInformation.lastname,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDialogTransferEvent = (option) => {
    if (!option) {
      setShowDialogTransferEvents(!showDialogTransferEvents);
      return;
    }
    const selected = inactiveBusinessCardList.find(
      (item) => item.id === option.key
    );
    setSelectedCard(selected);
    setShowDialogTransferEvents(!showDialogTransferEvents);
  };

  const goToEdition = () => {
    if (loggedUser.role === "EMPLOYEE") {
      alert(
        "Debe ser un administrador o editor independiente para editar esta tarjeta"
      );
      return;
    }
    setFormMode("edition");
    navigate(`/cards/${cardId}/edition-form`);
  };

  const goToTemplateSelection = () => {
    if (loggedUser.role === "EMPLOYEE") {
      alert(
        "Debe ser un administrador o editor independiente para editar esta tarjeta"
      );
      return;
    }
    navigate(`/cards/${cardId}/template`);
  };

  const getSimpleName = (name = "", lastname = "") => {
    const names = name.split(" ");
    const lastnames = lastname.split(" ");
    return `${names[0]} ${lastnames[0]}`;
  };

  const renderNoteOnUrl = () => {
    if (loggedUser.role === "EMPLOYEE") {
      return "* No se puede editar el URL teniendo el rol de empleado.";
    } else if (currentCard && currentCard.status === "PENDING") {
      return "No se puede editar aún la URL de la tarjeta.";
    }

    return "";
  };

  const getBackUrl = () => {
    if (!isOwnCard && loggedUser.role.startsWith("ADMIN")) {
      return "/company/cards";
    }

    return "/cards";
  };

  const chooseDialogToOpen = () => {
    if (urlEditionDisabled || loggedUser === "EMPLOYEE") {
      return;
    }

    if (currentCard.employeePathKey === url) {
      setUrlMessage("No hubieron cambios");
      return;
    }

    if (url === "" || url.trim() === "") {
      setError(true);
      setUrlMessage("El URL es requerido");
      return;
    }

    if (currentCard.employee.pathKeyUpdateCount >= MAX_INSTANT_UPDATES) {
      setConfirmationNote("* Podrás editar el URL en 15 días");
    }
    setShowConfirmationDialog(true);
  };

  const closeConfirmationDialog = () => {
    setShowConfirmationDialog(false);
  };

  const goToPublicUrl = () => {
    if (currentCard.status === "PENDING") return;
    const url = `${baseCardUrl}${cardPathKey}?preview=true`;
    window.open(url, "_blank");
  };

  const goToDataRecolection = () => {
    if (currentCard.status === "PENDING") return;
    if (isMyCard(cardId, loggedUser.businessCards)) {
      navigate(`/collection`);
    } else {
      navigate(`/company/collection/${cardId}`);
    }
  };

  return (
    <div>
      <ButtonGroup>
        <BackButton backUrl={getBackUrl()} />
        <Button
          variant={loggedUser.role === "EMPLOYEE" ? "disabled" : "primary"}
          onClick={goToEdition}
          disabled={!editButtonEnabled}
          size="medium"
        >
          Editar
        </Button>
        <Button
          variant={loggedUser.role === "EMPLOYEE" ? "disabled" : "primary"}
          onClick={goToTemplateSelection}
          disabled={!editButtonEnabled}
          size="medium"
        >
          Seleccionar diseño
        </Button>
        {(loggedUser.role === "ADMIN" || loggedUser.role === "ADMIN_FOUNDER") &&
          loggedUser.company.transferEventCount != null && (
            <>
              <InactiveUserTitle>Usuarios inactivos</InactiveUserTitle>
              <Dropdown
                options={inactiveCardsOptions}
                fullWidth={false}
                hasIcon={false}
                onClick={handleDialogTransferEvent}
                loading={loading}
              />
            </>
          )}
        {showDialogTransferEvents && (
          <ReplaceStatisticsDialog
            currentCardId={cardId}
            currentCardData={currentCard}
            selectedCard={selectedCard}
            showDialog={showDialogTransferEvents}
            closeDialog={handleDialogTransferEvent}
          />
        )}
      </ButtonGroup>
      <DetailContainer>
        {loading && (
          <div className="loading-container">
            <CircularProgress />
          </div>
        )}
        {!loading && currentCard && (
          <CardPreviewContainer>
            <CardPreview
              cardValues={currentCard}
              mode="dynamic"
              interactive={false}
              fullWidth={true}
              colors={currentCard.baseInformation.company.colors}
            />
            <Button
              variant={
                currentCard.status === "PENDING" ? "disabled" : "primary"
              }
              size="large"
              fullWidth
              animation={currentCard.status !== "PENDING"}
              onClick={goToPublicUrl}
            >
              Ir a vista previa
            </Button>
            {hasReferrals && (
              <Button
                variant={
                  currentCard.status === "PENDING" ? "disabled" : "primary"
                }
                size="large"
                fullWidth
                animation={currentCard.status !== "PENDING"}
                onClick={goToDataRecolection}
              >
                Ver datos recolectados
              </Button>
            )}
          </CardPreviewContainer>
        )}
        <ShareAndStatisticsContainer>
          <CardContainer
            noGutters
            headerTitle="Compartir tarjeta"
            headerTextAlign="center"
          >
            <TransparentCoverContainer>
              {!loading && currentCard && currentCard.status === "PENDING" && (
                <TransparentCover>
                  <EditPendingText>
                    {loggedUser.role === "EMPLOYEE"
                      ? "Por favor, pida a un administrador que edite su tarjeta."
                      : "Por favor, complete la tarjeta para habilitar las opciones para compartir."}
                  </EditPendingText>
                </TransparentCover>
              )}
            </TransparentCoverContainer>
            <ShareMainContainer>
              {loading && (
                <ShareLoadingContainer>
                  <CircularProgress />
                </ShareLoadingContainer>
              )}
              {!loading && currentCard && (
                <ShareCardInfo>
                  <QRContainer>
                    <QRDisplay
                      title={getSimpleName(
                        currentCard.baseInformation.name,
                        currentCard.baseInformation.lastname
                      )}
                      subtitle={currentCard.baseInformation.job}
                      description={currentCard.baseInformation.company.name}
                      baseUrl={baseCardUrl}
                      cardPathKey={cardPathKey}
                      logoImage={currentCard.baseInformation.company.logoUrl}
                      disabled={currentCard && currentCard.status === "PENDING"}
                      colors={currentCard.baseInformation.company.colors}
                    />
                  </QRContainer>
                  {!(currentCard && currentCard.businessCardPathKey) && (
                    <Subtitle>Cargando enlace...</Subtitle>
                  )}
                  {currentCard && currentCard.businessCardPathKey && (
                    <CardUrlSection
                      baseUrl={baseCardUrl}
                      cardPathKey={cardPathKey}
                      disabled={currentCard && currentCard.status === "PENDING"}
                    />
                  )}
                  <SocialContainer>
                    <Subtitle style={{ marginTop: "16px" }}>
                      Compartir tarjeta por
                    </Subtitle>
                    <ShareSocialNetworksButtons
                      disabled={currentCard && currentCard.status === "PENDING"}
                      url={baseCardUrl + cardPathKey}
                    />
                  </SocialContainer>
                </ShareCardInfo>
              )}
            </ShareMainContainer>
          </CardContainer>
          {!loading && currentCard && (
            <>
              <StatisticsButtonContainer>
                <Button
                  variant={
                    currentCard.status === "PENDING" ? "disabled" : "primary"
                  }
                  onClick={
                    currentCard.status === "PENDING"
                      ? () => {}
                      : handleDialogStatistics
                  }
                  animation={currentCard.status !== "PENDING"}
                  disabled={false}
                  fullWidth
                  size="large"
                >
                  Ver estadísticas
                </Button>
              </StatisticsButtonContainer>
              {showDialogStatistics && (
                <CardStatistics
                  showDialog={showDialogStatistics}
                  closeDialog={handleDialogStatistics}
                  title={`Estadísticas de ${getSimpleName(
                    currentCard.baseInformation.name,
                    currentCard.baseInformation.lastname
                  )}`}
                  cardId={cardId}
                />
              )}
            </>
          )}
        </ShareAndStatisticsContainer>
        <ConfigurationContainer>
          <div>
            <UrlContainer>
              {loading && (
                <ShareLoadingContainer>
                  <CircularProgress />
                </ShareLoadingContainer>
              )}
              {!loading && currentCard && (
                <>
                  <UrlTitle>URL del Perfil</UrlTitle>
                  <form className="d-flex justify-start align-items-center">
                    <UrlBox>
                      <UrlBase>{`https:/hola.link/${companyPathKey}/`}</UrlBase>
                      <Flex className="w-100">
                        <UrlField
                          error={error}
                          helperText={urlMessage !== "" && urlMessage}
                          fullWidth
                          disabled={
                            urlEditionDisabled || loggedUser.role === "EMPLOYEE"
                          }
                          value={url}
                          onChange={(e) => handleChange(e)}
                          variant="standard"
                        />
                        {loggedUser.role !== "EMPLOYEE" &&
                          daysLeft === 0 &&
                          currentCard.status !== "PENDING" && (
                            <EditButton onClick={enableEdition}>
                              <EditIcon />
                            </EditButton>
                          )}
                      </Flex>
                    </UrlBox>
                  </form>
                  {daysLeft !== 0 && (
                    <EditionMessage variant="tiny">
                      Podrás editar luego de{" "}
                      {daysLeft.toString() +
                        (daysLeft > 1 ? " días." : " día.")}
                    </EditionMessage>
                  )}
                  <WarningMessage variant="tiny">
                    {renderNoteOnUrl()}
                  </WarningMessage>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      onClick={chooseDialogToOpen}
                      size="small"
                      type="button"
                      variant={urlEditionDisabled ? "disabled" : "primary"}
                    >
                      Guardar
                    </Button>
                  </div>
                  <ConfirmationDialog
                    open={showConfirmationDialog}
                    headerTitle="Confirmar edición de URL"
                    closeDialog={closeConfirmationDialog}
                    question={
                      "¿Está seguro de completar la edición del URL de tu tarjeta?"
                    }
                    note={confirmationNote}
                  >
                    <div style={{ width: "100%" }}>
                      <DialogActions>
                        <ButtonContainer>
                          <Button
                            variant="cancel"
                            size="medium"
                            onClick={closeConfirmationDialog}
                          >
                            Cancelar
                          </Button>
                          <div style={{ width: "12px" }}></div>
                          <Button
                            variant="primary"
                            size="medium"
                            onClick={updateUrl}
                          >
                            Confirmar
                          </Button>
                        </ButtonContainer>
                      </DialogActions>
                    </div>
                  </ConfirmationDialog>
                </>
              )}
            </UrlContainer>
          </div>
        </ConfigurationContainer>
      </DetailContainer>
    </div>
  );
}
