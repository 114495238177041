import {
  changeAccountRequest,
  getLinkedAccounts,
} from "../requests/linkedAccountsRequests";
import { call, put, select } from "redux-saga/effects";
import { setAccounts } from "../actions/linkedAccountsActions";
import { setAuthUser } from "../actions/authActions";
import { setPageLoading } from "../../companies/actions/generalPageLoadingActions";
import { getCurrentCompanyRequest } from "../../companies/requests/companiesRequests";
import { setCurrentCompany } from "../../companies/actions/currentCompanyActions";
import { setChangeAccountLoaded } from "../actions/changeAccountLoadedActions";

export function* initAccountsHandler(action) {
  try {
    const response = yield call(getLinkedAccounts);
    const users = response.data.employees;
    let authUser = yield select((state) => state.authUser);
    yield put(setAccounts(users));
    yield put(
      setAuthUser({ token: authUser.token, employee: authUser.loggedUser })
    );
  } catch (error) {
    if (error.response) {
      console.log(error.response.data);
      return;
    }
    console.log(error);
  }
}

export function* changeAccountHandler(action) {
  try {
    yield put(setPageLoading(true));
    const accountResponse = yield call(changeAccountRequest, action.payload);
    const { token, employee } = accountResponse.data;
    yield put(setAuthUser({ token, employee }));
    const currentCompanyresponse = yield call(getCurrentCompanyRequest);
    yield put(setCurrentCompany(currentCompanyresponse.data.company));
    yield put(setChangeAccountLoaded(true));
  } catch (error) {
    if (error.response) {
      console.log(error.response.data);
      return;
    }
    console.log(error);
  } finally {
    yield put(setPageLoading(false));
  }
}
