import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { secondaryGray } from "../../common/atoms/variables";
import { MonitoringService } from "../../statistics/services/MonitoringService";
import CardPreview from "../components/CardPreview";
import InactiveCard from "../components/InactiveCard";
import VcfContactButton from "../components/VcfContactButton";
import VcfQrButton from "../components/VcfQrButton";
import { BusinessCardService } from "../services/BusinessCardService";
import theme from "./../../common/theme";
import { setCurrentCompany } from "../../redux/store/companies/actions/currentCompanyActions";
import { CATEGORIES } from "../components/form.utils";
import { VcfService } from "../../common/helpers/VcfService";
import PageLoader from "../../common/atoms/PageLoader";
import ShortcutButton from "../components/public-card-components/ShortcutButton";
import HelpBookmarkButton from "../components/public-card-components/HelpBookmarkButton";
import ModalHelpBookmark from "../components/public-card-components/ModalHelpBookmark";

const enabledContainerStyled = (props) => css`
  background-color: ${!props.disabled && props.color};
  padding-bottom: ${!props.disabled && "150px"};
  @media (min-width: 1920px) {
    background-color: #f0f2fa;
  }
`;
const disabledContainerStyled = (props) => css`
  background-color: ${props.disabled && secondaryGray};
  justify-content: ${props.disabled && "center"};
  @media (min-width: 1920px) {
    background-color: #f0f2fa;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  position: fixed;
  bottom: 20px;
`;

const Container = styled.div`
  margin: 0px auto 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  ${enabledContainerStyled}
  ${disabledContainerStyled}
`;

const InactiveCardImage = styled.img`
  position: relative;
  margin: 0px auto;
  width: 100%;
  height: calc(100vh + 20px);
  @media (max-width: 915px) {
    width: 140%;
  }
  @media (max-width: 415px) {
    width: 247%;
    margin-left: -171px;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  z-index: 100;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

export function BusinessCardPublic() {
  const { token, loggedUser } = useSelector((state) => state.authUser);
  const [pageLoading, setPageLoading] = useState(false);
  const { businessKey, employeeKey } = useParams();
  const [searchParams] = useSearchParams();
  const [businessCard, setBusinessCard] = useState(null);
  const [cardPathKeys, setCardPathKeys] = useState({});

  const [cardPaused, setCardPaused] = useState(false);
  const [cardNotFound, setCardNotFound] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [addContactDisabled, setAddContactDisabled] = useState(true);
  const [openQR, setOpenQR] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const dispatch = useDispatch();

  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };

  useEffect(() => {
    setPageLoading(true);
    BusinessCardService.setToken(token);
    fetchBusinessCard();
  }, []);

  useEffect(() => {
    const isPreviewParam = !!searchParams.get("preview");
    setIsPreview(isPreviewParam);
    const shouldTrackVisit =
      businessCard && businessCard._id && !isPreviewParam;
    if (shouldTrackVisit) {
      trackVisit(businessCard);
    }
  }, [businessCard]);

  const loadVCard = (cardId) => {
    VcfService.loadVcf(cardId).finally(() => {
      setAddContactDisabled(false);
    });
  };

  const fetchBusinessCard = () => {
    BusinessCardService.getBusinessCardByPathKey(businessKey, employeeKey)
      .then((response) => {
        const { currentBusinessKey, currentEmployeeKey } = response.data;
        const card = response.data.businessCard;
        if (!card.paused && card.status === "READY") {
          setCardPathKeys({
            currentBusinessKey: encodeURIComponent(currentBusinessKey),
            currentEmployeeKey: encodeURIComponent(currentEmployeeKey),
          });
          setBusinessCard(card);
          dispatch(setCurrentCompany(card.baseInformation.company));
          setCardPaused(false);
          loadVCard(card._id);
        } else {
          setCardPaused(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setCardNotFound(true);
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  const trackVisit = (businessCard) => {
    MonitoringService.saveEvent("LOAD_CARD", "load", businessCard);
  };

  const onDownload = () => {
    if (isPreview) return;

    MonitoringService.saveEvent(
      "CLICK_ADD_CONTACT_BUTTON",
      "click",
      businessCard,
      CATEGORIES.GENERAL_CONTACTS
    );
  };

  if (pageLoading) {
    return <PageLoader />;
  }

  return (
    <Container
      disabled={cardPaused || cardNotFound}
      color={
        businessCard
          ? businessCard.baseInformation.company.colors.primary
          : theme.colors.mineShaft
      }>
      {businessCard && !cardPaused && !cardNotFound && (
        <>
          <CardPreview
            interactive={true}
            mode="dynamic"
            cardValues={businessCard}
            fullWidth={true}
            colors={businessCard.baseInformation.company.colors}
            isPreview={isPreview}
            cardPathKeys={cardPathKeys}
            openQR={openQR}
            handleClose={() => setOpenQR(false)}
          />
          <ButtonsContainer>
            <VcfContactButton
              businessCardId={businessCard._id}
              onDownload={onDownload}
              disabled={addContactDisabled}
            />
            <VcfQrButton setOpenQR={setOpenQR} />
            {!isPreview && !isIos() && <ShortcutButton />}
            {!isPreview && isIos() && (
              <>
                <HelpBookmarkButton setOpenModal={setOpenModal} />
                <ModalHelpBookmark
                  open={openModal}
                  handleClose={() => setOpenModal(false)}
                />
              </>
            )}
          </ButtonsContainer>
        </>
      )}
      {(cardPaused || cardNotFound) && (
        <div style={{ width: "100%", position: "relative" }}>
          <InactiveCard loggedUser={loggedUser} token={token} />
          <ImageContainer>
            <InactiveCardImage
              src={
                require("../../common/assets/img/InactiveCardBackground.svg")
                  .default
              }
            />
          </ImageContainer>
        </div>
      )}
    </Container>
  );
}
