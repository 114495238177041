import { BusinessCardService } from "../../business-card-management/services/BusinessCardService";
import { LocalStorageService } from "../services/LocalStorageService";

const blobToBase64 = (blob) => {
  return new Promise((resolve, _) => {
    const fileReader = new FileReader();

    fileReader.readAsDataURL(blob);

    fileReader.onloadend = function () {
      resolve(fileReader.result);
    };
  });
};

export const VcfService = {
  downloadVcf: async () => {
    const base64 = LocalStorageService.get("vcf-file");
    const filename = LocalStorageService.get("vcf-filename");
    const link = document.createElement("a");
    const blob = await fetch(base64).then((res) => res.blob());

    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  },
  loadVcf: async (businessCardId) => {
    const currentCardId = LocalStorageService.get("vcf-current-card-id");
    if (currentCardId === businessCardId) return;
    return BusinessCardService.getVCard(businessCardId).then((response) => {
      LocalStorageService.set("vcf-current-card-id", businessCardId);
      const type = response.headers["content-type"];
      const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
      blobToBase64(blob).then((base64) => {
        LocalStorageService.set("vcf-file", base64);
      });
      const contentDisposition =
        response.headers["content-disposition"].split("; ");
      const filename = contentDisposition[1].split("=")[1].replace(/\"/g, "");
      LocalStorageService.set("vcf-filename", filename);
    });
  },
};
