import { useEffect, useState } from "react";
import "../assets/css/styles.css";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "@emotion/styled";
import AuthService from "../services/AuthService";
import { ReactComponent as Logo } from "./../assets/img/logo-login.svg";
import Button from "../../common/atoms/Button";
import TextInputPassword from "../../common/atoms/TextInputPassword";
import Alert from "../../common/atoms/Alert";
import PageLoader from "../../common/atoms/PageLoader";
import PasswordChecklist from "react-password-checklist";
import { HorizontalRule, Check, Close } from "@mui/icons-material";
import { black_main, danger, middle_green } from "../../common/atoms/variables";
import passwordValidator from "password-validator";
import { setAuthUser } from "../../redux/store/accounts/actions/authActions";
import { useDispatch } from "react-redux";
import Flex from "../../common/atoms/Flex";
import Link from "../../common/atoms/Link";

const RowStyled = styled.div`
  margin: 0px;
  min-height: 100vh !important;
  height: 100vh;
`;

const ImageContainer = styled.div`
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #ededed;
  background-image: url(/greetings.jpg);
`;

const LeftWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  min-height: 100%;
  padding: 0 90px;
  color: #fff;
  text-align: center;
  position: relative;
  width: 100%;
  height: 100%;
  position: relative;
  left: 0;
  top: 0;
  z-index: 2;
  mix-blend-mode: multiply;
  background-color: rgba(0, 122, 255, 0.8);
  @media (max-width: 991px) {
    height: auto;
    padding: 95px 30px 60px 30px;
  }
  @media (max-width: 767px) {
    padding: 95px 15px 60px 15px;
  }
`;

const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: center;
  margin-bottom: 20px;
`;

const ResetContainer = styled.div`
  p {
    font-size: 18px;
    font-family: Lato;
  }
`;

const WelcomeStyled = styled.span`
  font-size: 24px;
  font-family: Lato;
  font-weight: 400;
`;
const NameStyled = styled.span`
  font-size: 30px;
  font-family: Lato;
  font-weight: 700;
`;
const CompanyNameStyled = styled.p`
  margin: 0;
  font-size: 18px;
  font-family: Lato;
  font-weight: 500;
  font-style: italic;
  text-align: center;
`;

const LogoIcon = styled.div`
  max-width: 72.44px;
  max-height: 72.44px;
  border-radius: 50%;
  border: 5px solid white;
  overflow: hidden;
  > img {
    object-fit: cover;
    border-radius: 50%;
    width: 100%;
    height: 100%;
  }
`;

const CrossIcon = styled(Close)`
  color: ${danger};
`;

const CheckIcon = styled(Check)`
  color: ${middle_green};
`;

const LineIcon = styled(HorizontalRule)`
  color: ${black_main};
`;

const IconContainer = styled.div`
  width: 14px;
  height: 14px;
  margin-right: 12px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
`;

const schema = new passwordValidator();
schema.is().min(8).has().uppercase().has().digits(1).has().symbols(1);

export function ChangePassword() {
  const dispatch = useDispatch();
  const { code } = useParams();
  const [searchParams] = useSearchParams();
  const [pageLoading, setPageLoading] = useState(false);
  const [form, setForm] = useState({ password: "", repeatPassword: "" });
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [hideForm, setHideForm] = useState(false);
  const [userToken, setUserToken] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (code) {
      setPageLoading(true);
      AuthService.verifyToken(code)
        .then((data) => {
          if (!data.company || !data.employee) {
            setErrorMessage("El link no es válido o ya expiró");
            setHideForm(true);
            return;
          }
          setUserToken(data);
        })
        .catch((error) => {
          const message = error.response?.data?.message;
          setErrorMessage(message);
          setHideForm(true);
        })
        .finally(() => {
          setPageLoading(false);
        });
    }
  }, [code]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors({
      ...errors,
      [name]: "",
    });
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleValidation = () => {
    const errors = {};
    let isValid = true;

    if (!form.password) {
      errors.password = "Ingrese una contraseña";
      isValid = false;
    } else if (!schema.validate(form.password)) {
      errors.password = "Debe ingresar una contraseña válida";
      isValid = false;
    }
    if (!form.repeatPassword) {
      errors.repeatPassword = "Repita la contraseña";
      isValid = false;
    } else if (form.password !== form.repeatPassword) {
      errors.repeatPassword = "Las contraseñas no coinciden";
      isValid = false;
    }
    setErrors(errors);

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    const isValid = handleValidation();
    if (!isValid) return;
    setPageLoading(true);
    AuthService.changePassword(code, form.password)
      .then((response) => {
        const employee = response.data.employee;

        const token = response.data.token;
        dispatch(setAuthUser({ token, employee }));
        navigate(`/`);
      })
      .catch((error) => {
        if (error.response && error.response.status === 409) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(
            "Ocurrió un error, intente de nuevo en unos minutos."
          );
        }
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  const goToLogin = () => {
    navigate(`/login`);
  };

  const renderInvalidIcon = () => {
    return (
      <IconContainer>
        {form.password !== "" ? <CrossIcon /> : <LineIcon />}
      </IconContainer>
    );
  };

  const renderValidIcon = () => {
    return (
      <IconContainer>
        <CheckIcon />
      </IconContainer>
    );
  };

  return (
    <>
      {pageLoading && <PageLoader />}
      <div className="container-fluid p-0" style={{ height: "100%" }}>
        <RowStyled className="row">
          <ImageContainer className="col-lg-6 p-0">
            <LeftWrapper></LeftWrapper>
          </ImageContainer>
          <div className="col-lg-6 d-flex flex-column content-right">
            <div className="container my-auto py-5">
              <div className="row">
                <div className="col-lg-9 col-xl-7 mx-auto">
                  <div className="text-center">
                    <Logo width="218px" />
                  </div>
                  {userToken && searchParams.get("invitation") && (
                    <WelcomeContainer>
                      <div>
                        <WelcomeStyled>Bienvenido</WelcomeStyled> <br />
                        <NameStyled>{userToken.employee.name}</NameStyled>
                        <br />
                        {userToken.employee.accountType &&
                          userToken.employee.accountType !== "personal" && (
                            <CompanyNameStyled>
                              {userToken.company.name} te invitó
                            </CompanyNameStyled>
                          )}
                      </div>
                      <LogoIcon>
                        <img src={userToken.company.logoUrl} />
                      </LogoIcon>
                    </WelcomeContainer>
                  )}
                  {userToken && searchParams.get("reset") && (
                    <ResetContainer>
                      <p>
                        Hola <strong>{userToken.employee.name}</strong>, ingresa
                        tu nueva contraseña.
                      </p>
                    </ResetContainer>
                  )}
                  {errorMessage && <Alert type={"error"}>{errorMessage}</Alert>}
                  {!hideForm && (
                    <form
                      onSubmit={handleSubmit}
                      className="input_style_2 fl-form"
                      method="post"
                    >
                      <div className="form-group">
                        <TextInputPassword
                          fullWidth
                          type="password"
                          color="primary"
                          margin="normal"
                          variant="standard"
                          label="Ingresa una Contraseña"
                          name="password"
                          error={!!errors.password}
                          helperText={errors.password}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="form-group">
                        <div
                          className="hideShowPassword-wrapper"
                          style={{
                            position: "relative",
                            display: "block",
                            verticalAlign: "baseline",
                            margin: "0px",
                          }}
                        >
                          <TextInputPassword
                            fullWidth
                            type="password"
                            color="primary"
                            margin="normal"
                            variant="standard"
                            label="Repetir Contraseña"
                            name="repeatPassword"
                            error={!!errors.repeatPassword}
                            helperText={errors.repeatPassword}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="mt-5">
                        <PasswordChecklist
                          rules={[
                            "minLength",
                            "specialChar",
                            "number",
                            "capital",
                          ]}
                          minLength={8}
                          value={form.password}
                          valueAgain={form.repeatPassword}
                          onChange={() => {}}
                          messages={{
                            minLength:
                              "La contraseña tiene 8 caracteres o más.",
                            specialChar:
                              "La contraseña tiene caracteres especiales.",
                            number: "La contraseña tiene un número.",
                            capital: "La contraseña tiene una letra mayúscula.",
                          }}
                          iconComponents={{
                            ValidIcon: renderValidIcon(),
                            InvalidIcon: renderInvalidIcon(),
                          }}
                        />
                      </div>
                      <div className="mt-5">
                        <Button
                          variant="primary"
                          size="medium"
                          fullWidth={true}
                          type="submit"
                        >
                          Continuar
                        </Button>
                      </div>
                    </form>
                  )}
                  {hideForm && (
                    <Flex justifyContent="center" mt="3">
                      <Button
                        variant="primary"
                        size="medium"
                        onClick={goToLogin}
                      >
                        Ir a Inicio de sesión
                      </Button>
                    </Flex>
                  )}
                </div>
              </div>
            </div>
            <div className="container social-networks">
              <div className="row">
                <div className="col-12">
                  <div className="d-flex gap-2 justify-content-center">
                    <Link
                      className="social-networks__link"
                      href="https://www.facebook.com/Holalinklatam"
                      target="_blank"
                    >
                      <i className="bi bi-facebook"></i>
                    </Link>
                    <Link
                      className="social-networks__link"
                      href="https://www.instagram.com/holalinklatam/"
                      target="_blank"
                    >
                      <i className="bi bi-instagram"></i>
                    </Link>
                    <Link
                      className="social-networks__link"
                      href="https://www.linkedin.com/company/holalink/"
                      target="_blank"
                    >
                      <i className="bi bi-linkedin"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="container pb-3 copy text-center">
              © {new Date().getFullYear()} HolaLink - All Rights Reserved.
            </div>
          </div>
        </RowStyled>
      </div>
    </>
  );
}
