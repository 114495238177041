export const LocalStorageService = {
  set: (name, value) => {
    const body = JSON.stringify(value);
    window.localStorage.setItem(name, body);
  },

  get: (name) => {
    try {
      const object = JSON.parse(window.localStorage.getItem(name));
      if (!object) return null;
      return object;
    } catch (error) {
      console.log(error);
    }
  },

  remove: (name) => {
    window.localStorage.removeItem(name);
  },
};
