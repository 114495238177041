import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import SimpleCard from "../../common/atoms/SimpleCard";
import Button from "../../common/atoms/Button";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import PhotoSection from "./card-form/PhotoSection";
import BasicInfoSection from "./card-form/BasicInfoSection";
import GeneralContactSection from "./card-form/GeneralContactSection";
import SocialNetworkSection from "./card-form/SocialNetworkSection";
import { CATEGORIES } from "./form.utils";

const SimpleCardStyled = styled(SimpleCard)`
  max-width: 500px;
  @media (min-width: 1920px) {
    max-width: 533px;
    width: 533px;
    gap: 60px;
  }
  @media (max-width: 425px) {
    padding: 15px;
    border-radius: unset;
  }
  .form-subtitle {
    font-size: 16px;
  }
`;

const CardForm = forwardRef(
  (
    {
      submitCard,
      mode,
      isOwnCard,
      initialCardData,
      setCardValues,
      hasInstitution,
      currentCompany,
    },
    ref
  ) => {
    const [errors, setErrors] = useState({});
    const [isValid, setIsValid] = useState(true);
    const { loggedUser } = useSelector((state) => state.authUser);

    const photoRef = useRef(null);
    const basicInfoRef = useRef(null);
    const generalContactRef = useRef(null);
    const socialNetworkRef = useRef(null);

    const handlePhotoSectionChange = (data) => {
      setCardValues((prevState) => ({
        ...prevState,
        baseInformation: {
          ...prevState.baseInformation,
          photo: data,
        },
      }));
    };

    const handleBasicInfoSectionChange = (data) => {
      setCardValues((prevState) => ({
        ...prevState,
        baseInformation: {
          ...prevState.baseInformation,
          ...data,
        },
      }));
    };

    const handleGeneralContactSectionChange = (data) => {
      setCardValues((prevState) => ({
        ...prevState,
        generalContacts: data,
      }));
    };

    const handleSocialNetworkSectionChange = (data) => {
      setCardValues((prevState) => ({
        ...prevState,
        socialNetworks: data,
      }));
    };

    const handleErrors = (errors) => {
      setErrors((prevState) => ({ ...prevState, ...errors }));
    };

    const handleSubmission = () => {
      const hasError = validateSections();
      if (hasError) return;

      submitCard();
    };

    const validateSections = () => {
      let hasError = false;
      const sections = [
        photoRef,
        basicInfoRef,
        generalContactRef,
        socialNetworkRef,
      ];

      for (const section of sections) {
        if (!section?.current) continue;

        const isSectionValid = section.current.validateAll();
        if (!isSectionValid) hasError = true;
      }

      return hasError;
    };

    const addField = (name, category, field) => {
      if (category === CATEGORIES.SOCIAL_NETWORKS) {
        socialNetworkRef.current.addField(name, category, field);
      }

      if (category === CATEGORIES.GENERAL_CONTACTS) {
        generalContactRef.current.addField(name, category, field);
      }

      // Using setTimeout to wait for div rendering
      setTimeout(() => {
        if (category === CATEGORIES.GENERAL_CONTACTS) {
          document.getElementById("datosContacto").scrollIntoView();
        } else if (category === CATEGORIES.SOCIAL_NETWORKS) {
          document.getElementById("redesSociales").scrollIntoView();
        }
      });
    };

    useImperativeHandle(ref, () => ({
      addField,
    }));

    return (
      <SimpleCardStyled>
        <form noValidate>
          <>
            <PhotoSection
              ref={photoRef}
              initialData={initialCardData.baseInformation.photo}
              templateId={initialCardData.templateId}
              readOnly={false}
              mode={mode}
              onChange={handlePhotoSectionChange}
              onError={handleErrors}
            />
            <BasicInfoSection
              ref={basicInfoRef}
              loggedUser={loggedUser}
              hasInstitution={hasInstitution}
              initialData={initialCardData.baseInformation}
              readOnly={false}
              mode={mode}
              onChange={handleBasicInfoSectionChange}
              onError={handleErrors}
            />
            <GeneralContactSection
              ref={generalContactRef}
              currentCompany={currentCompany}
              initialData={initialCardData.generalContacts}
              isOwnCard={isOwnCard}
              readOnly={false}
              mode={mode}
              onChange={handleGeneralContactSectionChange}
              onError={handleErrors}
            />
            <SocialNetworkSection
              ref={socialNetworkRef}
              initialData={initialCardData.socialNetworks}
              onChange={handleSocialNetworkSectionChange}
              onError={handleErrors}
            />
          </>
          {!isValid && (
            <div className="alert alert-danger mt-3" role="alert">
              <span>
                <b>Error:</b> Debe completar todos los campos obligatorios.
              </span>
            </div>
          )}
          <hr />
          <div className="text-center">
            <Button
              type="button"
              size="medium"
              onClick={handleSubmission}
              variant="primary"
            >
              Guardar
            </Button>
          </div>
        </form>
      </SimpleCardStyled>
    );
  }
);

CardForm.propTypes = {
  submitCard: PropTypes.func,
  mode: PropTypes.oneOf(["edition", "creation"]),
  isOwnCard: PropTypes.bool,
  initialCardData: PropTypes.object,
  setCardValues: PropTypes.func,
  hasInstitution: PropTypes.bool,
  currentCompany: PropTypes.object,
};

export default CardForm;
