import generalPageLoadingActionTypes from "../action-types/generalPageLoadingActionTypes";
const { SET_PAGE_LOADING } = generalPageLoadingActionTypes;
const initialState = false;

const generalPageLoadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAGE_LOADING:
      return action.payload;

    default:
      return state;
  }
};

export default generalPageLoadingReducer;
