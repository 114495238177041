import axiosInstance from "../../common/helpers/axios";

const InstitutionService = {
  getInstitutions: async () => {
    const response = await axiosInstance.get("/institutions");
    return response.data;
  },
};

export default InstitutionService;
