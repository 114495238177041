import { createContext, useState } from "react";

export const EmployeeContext = createContext({});

export const EmployeeProvider = ({ children }) => {
  const [employeesToEditRole, setEmployeesToEditRole] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);

  return (
    <EmployeeContext.Provider
      value={{
        employeesToEditRole,
        setEmployeesToEditRole,
        selectedRows,
        setSelectedRows,
      }}
    >
      {children}
    </EmployeeContext.Provider>
  );
};
