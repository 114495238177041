import React from "react";
import PropTypes from "prop-types";
import { Card, CardContent, Typography } from "@mui/material";
import { css } from "@emotion/react";

const SelectedPackageCard = ({
  title,
  price,
  currency,
  duration,
  quantity,
}) => {
  const rootStyles = css`
    background-color: #f5f5f5;
    padding: 16px;
    border-radius: 8px;
  `;

  const titleStyles = css`
    font-weight: bold;
    margin-bottom: 8px;
  `;

  const priceStyles = css`
    font-size: 24px;
    margin-bottom: 16px;
  `;

  const fieldStyles = css`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  `;

  const fieldIconStyles = css`
    margin-right: 8px;
  `;

  const fieldTextStyles = css`
    font-size: 14px;
  `;

  return (
    <Card css={rootStyles}>
      <CardContent>
        <Typography variant="h6" css={titleStyles}>
          {title}
        </Typography>
        <Typography variant="h4" css={priceStyles}>
          {price * quantity} {currency}
        </Typography>
        <div css={fieldStyles}>
          <span css={fieldIconStyles}>
            <i className="fas fa-clock"></i>
          </span>
          <span css={fieldTextStyles}>
            {duration} días de acceso a la plataforma
          </span>
        </div>
      </CardContent>
    </Card>
  );
};

SelectedPackageCard.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
};

export default SelectedPackageCard;
