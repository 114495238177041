import { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import "../assets/css/styles.css";
import { IconsContext } from "../context/IconsContext";
import { MonitoringService } from "../../statistics/services/MonitoringService";
import { CATEGORIES, getEventNameByKey } from "./form.utils";
import {
  getContactLabel,
  getContactValue,
  getSocialValue,
} from "./template.utils";
import Flex from "../../common/atoms/Flex";
import {
  CardPhotoArea,
  CardTemplateBase,
  Photo,
  LogoIcon,
  CardContent,
  ContactButtonWrapper,
  ContactButton,
  ContactText,
  StyledIcon,
  StyledTypography,
  SocialButtonWrapper,
  SocialButton,
} from "./CardTemplateBase";
import ModalQR from "./ModalQr";

import {
  Language as LanguageIcon,
  Email as EmailIcon,
  Phone as PhoneIcon,
  PictureAsPdf as PictureAsPdfIcon,
  LocationOn as LocationOnIcon,
  LiveTv as LiveTvIcon,
  Link as LinkIcon,
} from "@mui/icons-material";

const cardDefaultInfo = {
  baseInformation: {
    company: {
      name: "Empresa",
      logoUrl: require("../assets/img/default-company-logo.png"),
    },
    photo: {
      url: "",
      filename: "",
    },
    name: "Nombre",
    lastname: "",
    job: "Puesto de trabajo",
    introduction:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In lacus dui, blandit pulvinar aliquam vel, euismod ac nisi. Vivamus vitae.",
  },
  generalContacts: {
    phones: [{ value: "999999999", tag: "Teléfono" }],
    email: "test@gmail.com",
    webpage: "Página web",
    pdf: {
      url: "",
      filename: "PDF Empresa",
    },
  },
  socialNetworks: {
    facebook: "test",
    twitter: "test",
    instagram: "test",
    linkedin: "test",
  },
  templateId: 1,
};

const CardPhotoAreaThree = styled(CardPhotoArea)`
  height: 230px;
  @media (min-width: 1920px) {
    height: 250px;
  }
  @media (max-width: 375px) {
    height: 197px;
  }
`;

const CustomShapeDividerBottom = styled.div`
  .shape-fill {
    fill: ${(props) => props.color};
  }
`;

const PhotoThree = styled(Photo)`
  > div:first-of-type {
    border-radius: 15px;
    overflow: hidden;
    > img {
      z-index: 0;
    }
  }
  @media (max-width: 375px) {
    height: 160px;
  }
`;

const CardContentThree = styled(CardContent)`
  padding-top: 24px;
`;

const ContactButtonThree = styled(ContactButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  font-weight: 700;
  font-size: 14px;
  line-height: 19.6px;
  &:hover {
    color: white;
  }
`;

const SocialButtonThree = styled(SocialButton)`
  border-radius: 50%;
`;

const StyledGeneralIcon = styled.svg`
  color: white;
  font-size: 100%;
  margin-right: 3px;
`;

function CardTemplateThree({
  mode,
  interactive,
  selectable,
  selected,
  cardInput,
  fullWidth,
  colors,
  isPreview,
  cardPathKeys,
  openQR,
  handleClose,
  phoneExtension,
}) {
  const { icons } = useContext(IconsContext);
  const [cardInfo, setCardInfo] = useState(
    mode === "static" ? cardDefaultInfo : cardInput
  );

  const getBusinessCardType = (type) => {
    return type === "personal" ? "p" : "b";
  };

  const [pathKey, setPathKey] = useState("");

  const handlePathKey = () => {
    if (!cardPathKeys) return;
    setPathKey(
      cardPathKeys.currentBusinessKey &&
        cardPathKeys.currentEmployeeKey &&
        `/${cardPathKeys.currentBusinessKey}/${cardPathKeys.currentEmployeeKey}`
    );
  };

  const baseUrl =
    process.env.REACT_APP_DOMAIN + `/${getBusinessCardType(cardInfo?.type)}`;

  useEffect(() => {
    if (mode === "dynamic") {
      setCardInfo(cardInput);
    }
    handlePathKey();
  }, [cardInput, mode, setCardInfo]);

  const getSimpleName = (name = "", lastname = "") => {
    const names = name.split(" ");
    const lastnames = lastname.split(" ");
    return `${names[0]} ${lastnames[0]}`;
  };

  const handleSocialButtonClick = (key) => {
    if (isPreview) return;
    const eventName = getEventNameByKey(key);
    MonitoringService.saveEvent(
      eventName,
      "click",
      cardInfo,
      CATEGORIES.SOCIAL_NETWORKS
    );
  };
  const handleContactButtonClick = (key) => {
    if (isPreview) return;
    const eventName = getEventNameByKey(key);
    MonitoringService.saveEvent(
      eventName,
      "click",
      cardInfo,
      CATEGORIES.GENERAL_CONTACTS
    );
  };

  return (
    <CardTemplateBase
      selectable={selectable}
      selected={selected}
      fullWidth={fullWidth}
      background={colors.primary}
    >
      <CardPhotoAreaThree fullWidth={fullWidth} background={colors.primary}>
        <PhotoThree fullWidth={fullWidth}>
          <Flex>
            {cardInfo.baseInformation.photo.url ? (
              <img src={cardInfo.baseInformation.photo.url} />
            ) : (
              <img style={{ backgroundColor: "rgb(167, 167, 167)" }} />
            )}
          </Flex>
          <LogoIcon>
            <img src={cardInfo.baseInformation.company.logoUrl} />
          </LogoIcon>
        </PhotoThree>
      </CardPhotoAreaThree>
      <CustomShapeDividerBottom
        className="custom-shape-divider-bottom-black"
        color={colors.primary}
      >
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
            className="shape-fill"
          ></path>
        </svg>
      </CustomShapeDividerBottom>
      <div className="custom-shape-divider-bottom-white">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>
      <CardContentThree fullWidth={fullWidth}>
        <div className="template-main-content">
          <StyledTypography variant="h5" color={colors.primaryText}>
            {cardInfo.baseInformation.name +
              " " +
              cardInfo.baseInformation.lastname}
          </StyledTypography>
          <StyledTypography
            variant="small"
            weight="bold"
            color={colors.primaryText}
          >
            {cardInfo.baseInformation.job}
          </StyledTypography>
          <StyledTypography variant="small" color={colors.primaryText}>
            <i>{cardInfo.baseInformation.company.name}</i>
          </StyledTypography>
        </div>
        <div className="template-introduction" color={colors.primaryText}>
          <StyledTypography variant="tiny" mb="2" color={colors.primaryText}>
            {cardInfo.baseInformation.introduction}
          </StyledTypography>
        </div>
        {cardInfo.institution !== undefined && cardInfo.institution !== "" && (
          <div className="template-introduction">
            <StyledTypography variant="tiny" mb="2" color={colors.primaryText}>
              {cardInfo.institutionalData.identifier && (
                <>
                  <em>
                    <strong>Nº COLEGIATURA</strong>{" "}
                  </em>
                  {cardInfo.institutionalData.identifier
                    .toString()
                    .padStart(6, "0")}
                </>
              )}
            </StyledTypography>
          </div>
        )}
        <ModalQR
          open={openQR}
          handleClose={handleClose}
          title={getSimpleName(
            cardInfo.baseInformation.name,
            cardInfo.baseInformation.lastname
          )}
          subtitle={cardInfo.baseInformation.job}
          description={cardInfo.baseInformation.company.name}
          baseUrl={baseUrl}
          cardPathKey={pathKey}
          logoImage={cardInfo.baseInformation.company.logoUrl}
          disabled={cardInfo && cardInfo.status === "PENDING"}
          colors={cardInfo.baseInformation.company.colors}
        />
        <ContactButtonWrapper>
          {Object.keys(cardInfo.generalContacts).map((key) => {
            const field = cardInfo.generalContacts[key];
            if (!field) return;
            if (!Array.isArray(field)) {
              const label = getContactLabel(field, key);
              return (
                label &&
                (interactive ? (
                  <ContactButtonThree
                    background={colors.generalContactButton}
                    target="_blank"
                    key={key}
                    href={getContactValue(field, key)}
                    onClick={() => handleContactButtonClick(key)}
                  >
                    {key === "email" && <StyledGeneralIcon as={EmailIcon} />}
                    {key === "webpage" && (
                      <StyledGeneralIcon as={LanguageIcon} />
                    )}
                    {key === "pdf" && (
                      <StyledGeneralIcon as={PictureAsPdfIcon} />
                    )}
                    {key === "address" && (
                      <StyledGeneralIcon as={LocationOnIcon} />
                    )}
                    <ContactText
                      variant="tiny"
                      weight="bold"
                      color={colors.generalContactText}
                    >
                      {label}
                    </ContactText>
                  </ContactButtonThree>
                ) : (
                  <ContactButtonThree
                    key={key}
                    background={colors.generalContactButton}
                    color={colors.generalContactText}
                  >
                    {key === "email" && <StyledGeneralIcon as={EmailIcon} />}
                    {key === "webpage" && (
                      <StyledGeneralIcon as={LanguageIcon} />
                    )}
                    {key === "pdf" && (
                      <StyledGeneralIcon as={PictureAsPdfIcon} />
                    )}
                    {key === "address" && (
                      <StyledGeneralIcon as={LocationOnIcon} />
                    )}
                    <ContactText
                      variant="tiny"
                      weight="bold"
                      color={colors.generalContactText}
                    >
                      {label}
                    </ContactText>
                  </ContactButtonThree>
                ))
              );
            } else {
              {
                return field.map((element, index) => {
                  const label = getContactLabel(element, key);
                  return (
                    label &&
                    (interactive ? (
                      <ContactButtonThree
                        background={colors.generalContactButton}
                        target="_blank"
                        key={`${key}${index}`}
                        href={getContactValue(element, key)}
                        onClick={() => handleContactButtonClick(key)}
                      >
                        {key === "businessPresentations" && (
                          <StyledGeneralIcon as={LiveTvIcon} />
                        )}
                        {key === "phones" && (
                          <StyledGeneralIcon as={PhoneIcon} />
                        )}
                        {key === "webpages" && (
                          <StyledGeneralIcon as={LinkIcon} />
                        )}
                        <ContactText
                          variant="tiny"
                          weight="bold"
                          color={colors.generalContactText}
                        >
                          {label}
                        </ContactText>
                      </ContactButtonThree>
                    ) : (
                      <ContactButtonThree
                        key={`${key}${index}`}
                        background={colors.generalContactButton}
                        color={colors.generalContactText}
                      >
                        {key === "businessPresentations" && (
                          <StyledGeneralIcon as={LiveTvIcon} />
                        )}
                        {key === "phones" && (
                          <StyledGeneralIcon as={PhoneIcon} />
                        )}
                        {key === "webpages" && (
                          <StyledGeneralIcon as={LinkIcon} />
                        )}
                        <ContactText
                          variant="tiny"
                          weight="bold"
                          color={colors.generalContactText}
                        >
                          {label}
                        </ContactText>
                      </ContactButtonThree>
                    ))
                  );
                });
              }
            }
          })}
        </ContactButtonWrapper>
        <SocialButtonWrapper>
          {cardInfo.socialNetworks &&
            Object.keys(cardInfo.socialNetworks).map((key, index) => {
              const field = cardInfo.socialNetworks[key];

              if (field !== "") {
                let socialValue = "";
                if (key === "whatsapp") {
                  socialValue = getSocialValue(
                    !field.includes("+") ? `${phoneExtension}${field}` : field,
                    key
                  );
                } else {
                  socialValue = getSocialValue(field, key);
                }
                return interactive ? (
                  <SocialButtonThree
                    background={colors.socialNetworkButton}
                    target="_blank"
                    key={key}
                    href={socialValue}
                    onClick={() => handleSocialButtonClick(key)}
                  >
                    <StyledIcon
                      className={icons[key]}
                      color={colors.socialNetworkText}
                    ></StyledIcon>
                  </SocialButtonThree>
                ) : (
                  <SocialButtonThree
                    key={key}
                    background={colors.socialNetworkButton}
                  >
                    <StyledIcon
                      className={icons[key]}
                      color={colors.socialNetworkText}
                    ></StyledIcon>
                  </SocialButtonThree>
                );
              }
            })}
        </SocialButtonWrapper>
      </CardContentThree>
    </CardTemplateBase>
  );
}

CardTemplateThree.propTypes = {
  mode: PropTypes.oneOf(["static", "dynamic"]),
  interactive: PropTypes.bool,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  cardInput: PropTypes.object,
  fullWidth: PropTypes.bool,
  isPreview: PropTypes.bool,
};

CardTemplateThree.defaultProps = {
  mode: "static",
  interactive: false,
  cardInput: null,
  fullWidth: false,
  isPreview: false,
};
export default CardTemplateThree;
