import styled from "@emotion/styled";
import Button from "../../../common/atoms/Button";
import theme from "../../../common/theme";

const ButtonStyled = styled(Button)`
  display: flex;
  gap: 10px;
  align-items: center;
  border-radius: 50%;
  border: 3px solid white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 8px;
  background-color: white;
  color: ${theme.colors.white};

  padding-inline: 15px;
  transform: perspective(1px) translateZ(0);
  transition-duration: 0.3s;
  transition-property: transform;
  &:hover,
  &:active,
  &:focus {
    transform: scale(0.9);
  }
`;

const HelpBookmarkButton = ({ setOpenModal }) => {
  const handleClick = () => {
    setOpenModal(true);
  };

  return (
    <ButtonStyled onClick={handleClick} size="large">
      <i
        className="bi bi-house-add"
        style={{
          fontSize: "1.5rem",
          color: "#000",
        }}
      />
    </ButtonStyled>
  );
};

export default HelpBookmarkButton;
