import styled from "@emotion/styled";
import { DialogActions, DialogContent } from "@mui/material";
import { useEffect, useState } from "react";
import Button from "../../common/atoms/Button";
import PropTypes from "prop-types";
import EmployeeService from "../services/EmployeeService";
import DialogWithHeader from "../../common/molecules/DialogWithHeader";
import RoleSelector from "../components/RoleSelector";
import InputFull from "../../common/atoms/InputFull";

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
  > div {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const SelectorContainer = styled.div``;

const SaveButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 12px;
`;

const DialogContentStyled = styled(DialogContent)`
  @media (max-width: 425px) {
    padding: 20px 0px;
  }
`;

const EditUserForm = ({
  open,
  employee,
  closeDialog,
  getEmployees,
  page,
  setPageLoading,
}) => {
  const initialFormValues = {
    name: {
      value: employee.name,
      placeholder: "Nombre",
      validation: (v) => {
        if (!v || v.trim() === "") {
          return "El nombre es requerido";
        } else {
          return /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '.'-]+$/.test(
            v
          )
            ? ""
            : "El nombre es inválido";
        }
      },
      disabled: false,
    },
    lastname: {
      value: employee.lastname,
      placeholder: "Apellido",
      validation: (v) => {
        if (!v || v.trim() === "") {
          return "El apellido es requerido";
        } else {
          return /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/.test(
            v
          )
            ? ""
            : "El apellido es inválido";
        }
      },
      disabled: false,
    },
    email: {
      value: employee.email,
      placeholder: "Correo",
      validation: (v) => {
        if (!v || v.trim() === "") return "Debe ingresar el email";
        return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/.test(v)
          ? ""
          : "El correo es inválido";
      },
      disabled: true,
    },
    phone: {
      value: employee.phone,
      placeholder: "Teléfono",
      validation: (v) => {
        if (!v || v.trim() === "") return "El teléfono es requerido";

        let phoneText = v.toString();
        if (!phoneText.startsWith("9"))
          return "El teléfono debe comenzar con 9";

        return phoneText.length !== 9
          ? "El teléfono debe contener 9 dígitos"
          : "";
      },
      disabled: false,
    },
    job: {
      value: employee.job,
      placeholder: "Puesto de trabajo",
      validation: (v) => {
        if (!v || v.trim() === "") return "El puesto es requerido";
        return "";
      },
      disabled: false,
    },
  };

  const initialErrors = {
    name: "",
    lastname: "",
    email: "",
    phone: "",
    job: "",
    role: "",
  };
  const [formValues, setFormValues] = useState(initialFormValues);
  const [roleSelected, setRoleSelected] = useState("");
  const [errors, setErrors] = useState(initialErrors);

  const formFieldNames = Object.keys(formValues);

  const buildInitialFormValues = () => {
    let newFormValues = initialFormValues;
    formFieldNames.forEach((fieldName) => {
      newFormValues[fieldName].value = employee[fieldName];
    });
    setFormValues(newFormValues);
    setRoleSelected(`${employee.roleCode}`);
  };

  useEffect(() => {
    if (employee && employee.roleCode) {
      buildInitialFormValues();
    }
  }, [employee]);

  const handleSelection = (roleName) => {
    setErrors((prevState) => ({
      ...prevState,
      role: "",
    }));
    setRoleSelected(roleName);
  };

  const handleChange = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));

    setFormValues((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        value: value,
      },
    }));
  };

  const validateFormBeforeSubmit = () => {
    let errorsObject = initialErrors;
    let formValid = true;
    formFieldNames.forEach((fieldName) => {
      let field = formValues[fieldName];
      let error = field.validation(field.value);
      if (error !== "") {
        formValid = formValid && false;
        errorsObject[fieldName] = error;
      }
    });

    if (!roleSelected) {
      errorsObject.role = "Debe seleccionar un rol";
      formValid = formValid && false;
    }

    setErrors(errorsObject);
    return formValid;
  };

  const formatEmployeeValues = () => {
    let newEmployee = {};
    formFieldNames.forEach((name) => {
      newEmployee[name] = formValues[name].value;
    });
    newEmployee.role = roleSelected;
    return newEmployee;
  };

  const submit = async () => {
    if (validateFormBeforeSubmit()) {
      setPageLoading(true);
      const newEmployee = formatEmployeeValues();
      if (employee.id) {
        let result = await EmployeeService.editEmployeeByAdmin(
          employee.id,
          newEmployee
        );
        if (result.status === 200 && result.data.updatedEmployee) {
          await getEmployees(page);
          setErrors(initialErrors);
          closeDialog(false);
          setPageLoading(false);
        }
      }
    }
  };

  return (
    <DialogWithHeader
      open={open}
      headerTitle="Editar usuario"
      closeDialog={closeDialog}
    >
      <DialogContentStyled>
        <InputContainer>
          {formFieldNames.map((fieldName, index) => {
            const field = formValues[fieldName];
            if (fieldName === "name" || fieldName === "lastname") {
              return (
                <InputFull
                  key={index}
                  name={fieldName}
                  value={field.value}
                  label={field.placeholder}
                  error={!!errors[fieldName]}
                  errorMessage={errors[fieldName]}
                  fullWidth
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  variant="outlined"
                  isDisabled={field.disabled}
                />
              );
            }
          })}
        </InputContainer>
        <InputContainer>
          {formFieldNames.map((fieldName, index) => {
            const field = formValues[fieldName];
            if (fieldName === "email" || fieldName === "phone") {
              return (
                <InputFull
                  key={index}
                  name={fieldName}
                  value={field.value}
                  label={field.placeholder}
                  error={!!errors[fieldName]}
                  errorMessage={errors[fieldName]}
                  fullWidth
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  variant="outlined"
                  isDisabled={field.disabled}
                />
              );
            }
          })}
        </InputContainer>
        <div>
          {formFieldNames.map((fieldName, index) => {
            const field = formValues[fieldName];
            if (fieldName === "job") {
              return (
                <InputFull
                  key={index}
                  name={fieldName}
                  fullWidth
                  value={field.value}
                  label={field.placeholder}
                  error={!!errors[fieldName]}
                  errorMessage={errors[fieldName]}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  variant="outlined"
                  isDisabled={field.disabled}
                />
              );
            }
          })}
        </div>
        <SelectorContainer>
          <RoleSelector
            hideMainLabel={true}
            currentValue={roleSelected}
            customError={errors.role}
            onChange={handleSelection}
          />
        </SelectorContainer>
      </DialogContentStyled>
      <DialogActions>
        <SaveButtonContainer>
          <Button
            variant="cancel"
            size="small"
            onClick={() => {
              closeDialog();
            }}
          >
            Cancelar
          </Button>
          <Button variant="primary" size="small" onClick={submit}>
            Guardar
          </Button>
        </SaveButtonContainer>
      </DialogActions>
    </DialogWithHeader>
  );
};

EditUserForm.propTypes = {
  open: PropTypes.bool,
  employee: PropTypes.object,
};

export default EditUserForm;
