import React, { useState } from "react";
import { useSelector } from "react-redux";
import { COUNTRIES, DEFAULT_COUNTRY } from "../components/form.utils";

export const BusinessCardsContext = React.createContext({});

export const BusinessCardsProvider = ({ children }) => {
  const [formMode, setFormMode] = useState("creation");
  const [currentTemplateId, setCurrentTemplateId] = useState(1);
  const currentCompany = useSelector((state) => state.currentCompany);
  const initialCardData = {
    templateId: currentTemplateId,
    baseInformation: {
      photo: {
        url: require("../assets/img/no-avatar-img.jpg"),
        filename: "",
      },
      name: "",
      lastname: "",
      company: {
        id: currentCompany.id,
        name: currentCompany.name,
        logoUrl: currentCompany.logoUrl,
      },
      job: "",
      introduction: "",
    },
    generalContacts: {
      phones: [
        {
          value: "",
          tag: "",
          country: DEFAULT_COUNTRY,
          extension: COUNTRIES[DEFAULT_COUNTRY].secondary,
        },
      ],
      email: "",
      webpage: currentCompany.webUrl,
    },
    socialNetworks: {},
  };
  const [initialCard, _setInitialCard] = useState(initialCardData);
  const [currentCard, setCurrentCard] = useState();
  const [isOwnCard, setIsOwnCard] = useState(true);
  const [isEditionMode, setIsEditionMode] = useState(false);

  return (
    <BusinessCardsContext.Provider
      value={{
        initialCard,
        currentCard,
        setCurrentCard,
        isOwnCard,
        setIsOwnCard,
        isEditionMode,
        setIsEditionMode,
        formMode,
        setFormMode,
        currentTemplateId,
        setCurrentTemplateId,
      }}
    >
      {children}
    </BusinessCardsContext.Provider>
  );
};
