import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { format, parseISO } from "date-fns";
import { initMercadoPago } from "@mercadopago/sdk-react";

import CardContainer from "../../common/atoms/CardContainer";
import Button from "../../common/atoms/Button";
import theme from "../../common/theme";
import UpgradePlanDialog from "../components/UpgradePlanDialog";
import PersonalPaymentHistory from "../components/PersonalPaymentHistory";
import BusinessPaymentHistory from "../components/BusinessPaymentHistory";
import { PaymentService } from "../services/PaymentService";
import { PricingService } from "../services/PricingService";
import { useSelector } from "react-redux";
import { ACCOUNT_TYPE_PERSONAL } from "../../common/helpers/constants";
initMercadoPago(process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY);

const PaymentContainer = styled.div`
  > div:first-of-type {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-bottom: 20px;
  }
  @media (max-width: 992px) {
    > div:first-of-type {
      grid-template-columns: 1fr;
    }
  }
`;

const CardContainerUpgrade = styled.div`
  display: grid;
  grid-template-rows: 1fr 2fr 1fr;
  text-align: center;
  padding: 40px;
  border-radius: 10px;
  background-color: #ffffff;
  width: 100%;
  h5 {
    font-size: 20px;
    font-weight: 600;
    color: ${theme.colors.azureRadiance};
  }
  @media (max-width: 992px) {
    padding: 24px;
  }
`;

const TablePayment = styled.table`
  width: 100%;
  tr {
    th,
    td {
      padding: 0.5rem 0px;
      text-align: left;
    }
  }
`;

const RemainingDaysText = styled.span`
  color: ${(props) => (props.needPayment || props.lessThan30Days) && "red"};
`;

const NO_PAYMENT_TEXT = "Se mostrará cuando se registre el pago";
const LOADING_TEXT = "Cargando...";
const EXPIRED_PLAN = "Su plan ha expirado";
const Payment = () => {
  const [showDialogUpgrade, setShowDialogUpgrade] = React.useState(false);
  const [paymentInfo, setPaymentInfo] = React.useState({});
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [pricingArray, setPricingArray] = React.useState([]);
  const { loggedUser } = useSelector((state) => state.authUser);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    fetchInfo();
    getPricing();
  }, []);

  const handleDialogUpgrade = () => {
    setShowDialogUpgrade(!showDialogUpgrade);
  };

  const fetchInfo = () => {
    setLoading(true);
    if (loggedUser.accountType === ACCOUNT_TYPE_PERSONAL) {
      PaymentService.getInfo(timezone)
        .then((response) => {
          const data = response.data;
          setPaymentInfo({
            ...data,
            createdAt:
              data.createdAt && format(parseISO(data.createdAt), "dd/MM/yyyy"),
            endDate:
              data.endDate && format(parseISO(data.endDate), "dd/MM/yyyy"),
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      PaymentService.getBusinessInfo(timezone)
        .then((response) => {
          const data = response.data;
          setPaymentInfo({
            ...data,
            createdAt:
              data.createdAt && format(parseISO(data.createdAt), "dd/MM/yyyy"),
            endDate:
              data.endDate && format(parseISO(data.endDate), "dd/MM/yyyy"),
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getPricing = () => {
    PricingService.getPricing().then((response) => {
      setPricingArray(response.data.pricingArray);
    });
  };

  const showRemainingDaysText = (paymentInfo) => {
    if (loading) {
      return <i>{LOADING_TEXT}</i>;
    }
    if (
      paymentInfo.remainingDays === null ||
      paymentInfo.remainingDays === undefined
    ) {
      return NO_PAYMENT_TEXT;
    }
    if (paymentInfo.remainingDays === 0) {
      return EXPIRED_PLAN;
    }

    return paymentInfo.remainingDays;
  };

  const createdAtText = loading ? (
    <i>{LOADING_TEXT}</i>
  ) : (
    paymentInfo.createdAt || NO_PAYMENT_TEXT
  );

  const endDateText = loading ? (
    <i>{LOADING_TEXT}</i>
  ) : (
    paymentInfo.endDate || NO_PAYMENT_TEXT
  );

  return (
    <>
      <PaymentContainer>
        <div>
          <CardContainer
            headerTitle="Información de Pagos"
            headerTextAlign="start"
            bodyTextAlign="left"
          >
            <TablePayment className="table table-borderless">
              <tbody>
                <tr>
                  <th>Fecha de registro</th>

                  <td>{createdAtText}</td>
                </tr>
                <tr>
                  <th>Fecha de fin</th>
                  <td>{endDateText}</td>
                </tr>
                <tr>
                  <th>Días Restantes</th>
                  <td>
                    <RemainingDaysText
                      needPayment={
                        paymentInfo.remainingDays &&
                        paymentInfo.remainingDays === 0
                      }
                      lessThan30Days={
                        paymentInfo.remainingDays &&
                        paymentInfo.remainingDays < 30
                      }
                    >
                      {showRemainingDaysText(paymentInfo)}
                    </RemainingDaysText>
                  </td>
                </tr>
                {/* <tr>
                  <th>Transferencias estadísticas</th>
                  <td>
                    {loggedUser.company.transferEventCount
                      ? loggedUser.company.transferEventCount
                      : "No dispone"}
                  </td>
                </tr> */}
              </tbody>
            </TablePayment>
          </CardContainer>
          <CardContainerUpgrade>
            <h5>Actualiza tu plan</h5>
            <p>
              Actualiza tu plan para obtener más beneficios y funcionalidades
              adicionales. El tiempo restante de tu plan actual se sumará al
              nuevo plan.
            </p>
            <Button
              variant="primary"
              size="extraSmall"
              onClick={handleDialogUpgrade}
            >
              Actualizar
            </Button>
          </CardContainerUpgrade>
        </div>
        {loggedUser.accountType === ACCOUNT_TYPE_PERSONAL ? (
          <PersonalPaymentHistory />
        ) : (
          <BusinessPaymentHistory user={loggedUser} />
        )}
      </PaymentContainer>
      <UpgradePlanDialog
        showDialog={showDialogUpgrade}
        closeDialog={handleDialogUpgrade}
        pricingArray={pricingArray}
        accountType={loggedUser.accountType}
      />
    </>
  );
};

export default Payment;
