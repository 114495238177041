import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import "../assets/css/styles.css";
import { IconsContext } from "../context/IconsContext";
import { MonitoringService } from "../../statistics/services/MonitoringService";
import { CATEGORIES, getEventNameByKey } from "./form.utils";
import {
  getContactStaticValue,
  getContactValue,
  getSocialValue,
} from "./template.utils";
import theme from "../../common/theme";
import Flex from "../../common/atoms/Flex";
import {
  CardPhotoArea,
  CardTemplateBase,
  Photo,
  LogoIcon,
  CardContent,
  ContactButtonWrapper,
  StyledIcon,
  StyledTypography,
} from "./CardTemplateBase";
import ModalQR from "./ModalQr";

const cardDefaultInfo = {
  baseInformation: {
    company: {
      name: "Empresa",
      logoUrl: require("../assets/img/default-company-logo.png"),
    },
    photo: {
      url: "",
      filename: "",
    },
    name: "Nombre",
    lastname: "",
    job: "Puesto de trabajo",
    introduction:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. In lacus dui, blandit pulvinar aliquam vel, euismod ac nisi. Vivamus vitae.",
  },
  generalContacts: {
    phones: [{ value: "999999999", tag: "Teléfono", extension: "+51" }],
    email: "test@gmail.com",

    webpage: "empresa.com",
  },
  socialNetworks: {
    facebook: "facebook.com/empresa",
    linkedin: "linkedin.com/empresa",
  },
  templateId: 1,
};

const CardPhotoAreaTwo = styled(CardPhotoArea)`
  @media (max-width: 375px) {
    height: 124px;
  }
`;

const PhotoTwo = styled(Photo)`
  max-width: 200px;
  @media (min-width: 768px) {
    max-width: ${(props) => (props.fullWidth ? "272px" : "200px")};
    margin-top: ${(props) => (props.fullWidth ? "-40px" : "24px")};
    height: ${(props) => (props.fullWidth ? "272px" : "200px")};
  }
  @media (min-width: 1920px) {
    max-width: ${(props) => (props.fullWidth ? "272px" : "220px")};
    margin-top: ${(props) => (props.fullWidth ? "-40px" : "24px")};
    height: ${(props) => (props.fullWidth ? "272px" : "220px")};
  }
  > div:first-of-type {
    border-radius: 50%;
    overflow: hidden;
  }
`;

const IconContainer = styled.div`
  background-color: ${(props) => props.background};
`;

const ContactItem = styled.div`
  max-width: 238px;
  width: 100%;
  a {
    color: ${theme.colors.mineShaft};
    text-decoration: none;
  }
`;

function CardTemplateTwo({
  mode,
  interactive,
  selectable,
  selected,
  cardInput,
  fullWidth,
  colors,
  isPreview,
  cardPathKeys,
  openQR,
  handleClose,
  phoneExtension,
}) {
  const { icons } = useContext(IconsContext);
  const [cardInfo, setCardInfo] = useState(
    mode === "static" ? cardDefaultInfo : cardInput
  );

  const getBusinessCardType = (type) => {
    return type === "personal" ? "p" : "b";
  };

  const [pathKey, setPathKey] = useState("");

  const handlePathKey = () => {
    if (!cardPathKeys) return;
    setPathKey(
      cardPathKeys.currentBusinessKey &&
        cardPathKeys.currentEmployeeKey &&
        `/${cardPathKeys.currentBusinessKey}/${cardPathKeys.currentEmployeeKey}`
    );
  };

  const baseUrl =
    process.env.REACT_APP_DOMAIN + `/${getBusinessCardType(cardInfo?.type)}`;

  useEffect(() => {
    if (mode === "dynamic") {
      setCardInfo(cardInput);
    }
    handlePathKey();
  }, [cardInput, mode, setCardInfo]);

  const getSimpleName = (name = "", lastname = "") => {
    const names = name.split(" ");
    const lastnames = lastname.split(" ");
    return `${names[0]} ${lastnames[0]}`;
  };

  const handleSocialButtonClick = (key) => {
    if (isPreview) return;

    const eventName = getEventNameByKey(key);
    MonitoringService.saveEvent(
      eventName,
      "click",
      cardInfo,
      CATEGORIES.SOCIAL_NETWORKS
    );
  };
  const handleContactButtonClick = (key) => {
    if (isPreview) return;
    const eventName = getEventNameByKey(key);
    MonitoringService.saveEvent(
      eventName,
      "click",
      cardInfo,
      CATEGORIES.GENERAL_CONTACTS
    );
  };

  return (
    <CardTemplateBase
      selectable={selectable}
      selected={selected}
      fullWidth={fullWidth}
      background={colors.primary}
      className="template-two"
    >
      <CardPhotoAreaTwo fullWidth={fullWidth} background={colors.primary}>
        <PhotoTwo fullWidth={fullWidth}>
          <Flex>
            {cardInfo.baseInformation.photo.url ? (
              <img src={cardInfo.baseInformation.photo.url} />
            ) : (
              <img style={{ backgroundColor: "rgb(167, 167, 167)" }} />
            )}
          </Flex>
          <LogoIcon>
            <img src={cardInfo.baseInformation.company.logoUrl} />
          </LogoIcon>
        </PhotoTwo>
      </CardPhotoAreaTwo>
      <CardContent fullWidth={fullWidth}>
        <div className="template-main-content">
          <StyledTypography variant="h5" color={colors.primaryText}>
            {cardInfo.baseInformation.name +
              " " +
              cardInfo.baseInformation.lastname}
          </StyledTypography>
          <StyledTypography
            variant="small"
            weight="bold"
            color={colors.primaryText}
          >
            {cardInfo.baseInformation.job}
          </StyledTypography>
          <StyledTypography variant="small" color={colors.primaryText}>
            <i>{cardInfo.baseInformation.company.name}</i>
          </StyledTypography>
        </div>
        <div className="template-introduction">
          <StyledTypography variant="tiny" mb="2" color={colors.primaryText}>
            {cardInfo.baseInformation.introduction}
          </StyledTypography>
        </div>
        {cardInfo.institution !== undefined && cardInfo.institution !== "" && (
          <div className="template-introduction">
            <StyledTypography variant="tiny" mb="2" color={colors.primaryText}>
              {cardInfo.institutionalData.identifier && (
                <>
                  <em>
                    <strong>Nº COLEGIATURA</strong>{" "}
                  </em>
                  {cardInfo.institutionalData.identifier
                    .toString()
                    .padStart(6, "0")}
                </>
              )}
            </StyledTypography>
          </div>
        )}
        <ModalQR
          open={openQR}
          handleClose={handleClose}
          title={getSimpleName(
            cardInfo.baseInformation.name,
            cardInfo.baseInformation.lastname
          )}
          subtitle={cardInfo.baseInformation.job}
          description={cardInfo.baseInformation.company.name}
          baseUrl={baseUrl}
          cardPathKey={pathKey}
          logoImage={cardInfo.baseInformation.company.logoUrl}
          disabled={cardInfo && cardInfo.status === "PENDING"}
          colors={cardInfo.baseInformation.company.colors}
        />
        <ContactButtonWrapper>
          {Object.keys(cardInfo.generalContacts).map((key) => {
            const field = cardInfo.generalContacts[key];
            if (!field) return;
            if (!Array.isArray(field)) {
              const value = getContactStaticValue(field, key);
              const interactiveValue = getContactValue(field, key);
              return (
                value &&
                (interactive ? (
                  <ContactItem
                    className="d-flex flex-row align-items-center"
                    key={key}
                  >
                    <IconContainer
                      className="social-square-icon"
                      background={colors.socialNetworkButton}
                    >
                      <StyledIcon
                        className={icons[key]}
                        color={colors.socialNetworkText}
                      ></StyledIcon>
                    </IconContainer>
                    <StyledTypography variant="tiny" color={colors.primaryText}>
                      <a
                        href={interactiveValue}
                        target="_blank"
                        onClick={() => handleContactButtonClick(key)}
                      >
                        {value}
                      </a>
                    </StyledTypography>
                  </ContactItem>
                ) : (
                  <ContactItem
                    className="d-flex flex-row align-items-center"
                    key={key}
                  >
                    <IconContainer
                      className="social-square-icon"
                      background={colors.socialNetworkButton}
                    >
                      <StyledIcon
                        className={icons[key]}
                        color={colors.socialNetworkText}
                      ></StyledIcon>
                    </IconContainer>
                    <StyledTypography variant="tiny" color={colors.primaryText}>
                      {value}
                    </StyledTypography>
                  </ContactItem>
                ))
              );
            } else {
              {
                return field.map((element, index) => {
                  const value = getContactStaticValue(element, key);
                  const interactiveValue = getContactValue(element, key);
                  return (
                    value &&
                    (interactive ? (
                      <ContactItem
                        className="d-flex flex-row align-items-center"
                        key={`${key}${index}`}
                      >
                        <IconContainer
                          className="social-square-icon"
                          background={colors.socialNetworkButton}
                        >
                          <StyledIcon
                            className={icons[key]}
                            color={colors.socialNetworkText}
                          ></StyledIcon>
                        </IconContainer>
                        <StyledTypography
                          variant="tiny"
                          color={colors.primaryText}
                        >
                          <a
                            href={interactiveValue}
                            target="_blank"
                            onClick={() => handleContactButtonClick(key)}
                          >
                            {value}
                          </a>
                        </StyledTypography>
                      </ContactItem>
                    ) : (
                      <ContactItem
                        className="d-flex flex-row align-items-center"
                        key={`${key}${index}`}
                      >
                        <IconContainer
                          className="social-square-icon"
                          background={colors.socialNetworkButton}
                        >
                          <StyledIcon
                            className={icons[key]}
                            color={colors.socialNetworkText}
                          ></StyledIcon>
                        </IconContainer>
                        <StyledTypography
                          variant="tiny"
                          color={colors.primaryText}
                        >
                          {value}
                        </StyledTypography>
                      </ContactItem>
                    ))
                  );
                });
              }
            }
          })}
          {cardInfo.socialNetworks &&
            Object.keys(cardInfo.socialNetworks).map((key, index) => {
              const field = cardInfo.socialNetworks[key];
              if (field !== "") {
                let socialValue = "";
                if (key === "whatsapp") {
                  socialValue = getSocialValue(
                    !field.includes("+") ? `${phoneExtension}${field}` : field,
                    key
                  );
                } else {
                  socialValue = getSocialValue(field, key);
                }
                return interactive ? (
                  <ContactItem
                    className="d-flex flex-row align-items-center"
                    key={key}
                  >
                    <IconContainer
                      className="social-square-icon"
                      background={colors.socialNetworkButton}
                    >
                      <StyledIcon
                        className={icons[key]}
                        color={colors.socialNetworkText}
                      ></StyledIcon>
                    </IconContainer>
                    <StyledTypography variant="tiny" color={colors.primaryText}>
                      <a
                        href={socialValue}
                        target="_blank"
                        onClick={() => handleSocialButtonClick(key)}
                      >
                        {field}
                      </a>
                    </StyledTypography>
                  </ContactItem>
                ) : (
                  <ContactItem
                    className="d-flex flex-row align-items-center"
                    key={key}
                  >
                    <IconContainer
                      className="social-square-icon"
                      background={colors.socialNetworkButton}
                    >
                      <StyledIcon
                        className={icons[key]}
                        color={colors.socialNetworkText}
                      ></StyledIcon>
                    </IconContainer>
                    <StyledTypography variant="tiny" color={colors.primaryText}>
                      {field}
                    </StyledTypography>
                  </ContactItem>
                );
              }
            })}
        </ContactButtonWrapper>
      </CardContent>
    </CardTemplateBase>
  );
}

CardTemplateTwo.propTypes = {
  mode: PropTypes.oneOf(["static", "dynamic"]),
  interactive: PropTypes.bool,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  cardInput: PropTypes.object,
  fullWidth: PropTypes.bool,
  isPreview: PropTypes.bool,
};

CardTemplateTwo.defaultProps = {
  mode: "static",
  interactive: false,
  selectable: false,
  selected: false,
  cardInput: null,
  fullWidth: false,
  isPreview: false,
};
export default CardTemplateTwo;
