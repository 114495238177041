import { useCallback, useState } from "react";
import Cropper from "react-easy-crop";
import styled from "@emotion/styled";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Slider,
  LinearProgress,
} from "@mui/material";
import getCroppedImage from "../helpers/getCroppedImage";
import Button from "../atoms/Button";
import { flexbox } from "@mui/system";

const CropContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 100px;
`;

const RoundImageCropper = ({
  imageSrc,
  openCropper,
  closeCropper,
  setCroppedImage,
  cropType,
  loading = false,
  withLoading = false,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [aspect, setAspect] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onZoomChange = (zoom) => {
    setZoom(zoom);
  };

  const cropImage = async () => {
    try {
      const { file, url } = await getCroppedImage(imageSrc, croppedAreaPixels);
      await setCroppedImage(file, url);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog fullWidth open={openCropper}>
      <DialogContent
        sx={{
          background: "#444",

          position: "relative",
          height: 450,
          width: "100%",
          minWidth: { sm: 500 },
        }}
      >
        <div
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={aspect}
            cropShape={cropType}
            //restrictPosition={false}
            showGrid={false}
            onCropChange={onCropChange}
            onCropComplete={onCropComplete}
            onZoomChange={onZoomChange}
          />
        </div>
      </DialogContent>
      <DialogActions
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "auto",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "16px",
          }}
        >
          <p
            style={{
              fontSize: "16px",
              fontWeight: "600",
              marginRight: "16px",
              marginBottom: "0px",
            }}
          >
            Zoom
          </p>
          <Slider
            size="small"
            aria-label="slider"
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e) => {
              setZoom(e.target.value);
            }}
          />
        </div>
        <div style={{ width: "100%", textAlign: "center", marginLeft: "0px" }}>
          <Button
            variant="primary"
            size="small"
            onClick={cropImage}
            style={{ margin: "0px 12px" }}
          >
            Confirmar
          </Button>
          <Button
            variant="primary"
            size="small"
            onClick={() => {
              closeCropper();
            }}
            style={{ margin: "0px 12px" }}
          >
            Cerrar
          </Button>
        </div>
      </DialogActions>
      {withLoading && loading && <LinearProgress />}
    </Dialog>
  );
};

export default RoundImageCropper;
