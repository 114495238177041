import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css, keyframes } from "@emotion/react";
import theme from "../theme";
const clickAnimation = keyframes`
    to {
        transform: scale(4);
        opacity: 0;
    }
`;

const primaryStyle = css`
  color: ${theme.colors.white};
  background-color: ${theme.colors.azureRadiance};
  border: 2px solid ${theme.colors.azureRadiance};
  &:hover {
    background-color: ${theme.colors.scienceBlue};
    border-color: ${theme.colors.scienceBlue};
  }
  &:active {
    background-color: ${theme.colors.azureRadiance};
    border-color: ${theme.colors.scienceBlue};
  }
`;

const secondaryStyle = css`
  color: ${theme.colors.white};
  background-color: ${theme.colors.mineShaft};
  border: 2px solid ${theme.colors.mineShaft};
  &:hover {
    color: ${theme.colors.mineShaft};
    background-color: ${theme.colors.white};
  }
  span.ripple {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

const tertiaryStyle = css`
  color: ${theme.colors.mineShaft};
  background-color: ${theme.colors.springGreen};
  border: 2px solid ${theme.colors.springGreen};
  &:hover {
    background-color: ${theme.colors.springGreenHover};
  }
  &:active {
    color: ${theme.colors.mineShaft};
    background-color: ${theme.colors.springGreen};
  }
`;

const primaryOutlinedStyle = css`
  color: ${theme.colors.azureRadiance};
  background-color: ${theme.colors.white};
  border: 2px solid ${theme.colors.azureRadiance};
  &:hover {
    background-color: ${theme.colors.azureRadiance};
    color: ${theme.colors.white};
  }
  &:active {
    background-color: ${theme.colors.scienceBlue};
  }
`;

const secondaryOutlinedStyle = css`
  color: ${theme.colors.mineShaft};
  background-color: ${theme.colors.white};
  border: 2px solid ${theme.colors.mineShaft};
  &:hover {
    color: ${theme.colors.white};
    background-color: ${theme.colors.mineShaft};
  }
  span.ripple {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

const tertiaryOutlinedStyle = css`
  color: ${theme.colors.mineShaft};
  background-color: ${theme.colors.white};
  border: 2px solid ${theme.colors.springGreen};
  &:hover {
    background-color: ${theme.colors.springGreen};
  }
  &:active {
    color: ${theme.colors.mineShaft};
    background-color: ${theme.colors.springGreen};
  }
  span.ripple {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

const cancelStyle = css`
  color: ${theme.colors.silverChalice};
  background-color: ${theme.colors.white};
  border: 2px solid ${theme.colors.silverChalice};
  &:hover {
    background-color: ${theme.colors.whiteLilac};
  }
`;

const extraSmallStyle = css`
  padding: 8px 18px;
  line-height: 14px;
`;
const smallStyle = css`
  padding: 10px 22px;
  line-height: 16px;
`;
const mediumStyle = css`
  padding: 13px 28px;
  line-height: 19px;
`;
const largeStyle = css`
  font-size: 18px;
  padding: 18px 38px;
  line-height: 24px;
  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;
const extraLargeStyle = css`
  font-size: 18px;
  padding: 23px 48px;
  line-height: 26px;
  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;

const shrinkEffect = css`
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  &:hover {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
`;

const fullWidthStyle = css`
  width: 100%;
`;

const disabledStyle = css`
  color: rgb(43, 43, 43, 0.6);
  border: 2px solid #f0f2fa;
  box-shadow: none;
`;

const StyledButton = styled.button`
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  font-size: 16px;
  transition: background 400ms;
  cursor: pointer;

  span.ripple {
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    animation: ${clickAnimation} 600ms linear;
    background-color: rgba(255, 255, 255, 0.3);
  }

  ${(props) => props.variant === "primary" && primaryStyle};
  ${(props) => props.variant === "secondary" && secondaryStyle};
  ${(props) => props.variant === "tertiary" && tertiaryStyle};
  ${(props) => props.variant === "primaryOutlined" && primaryOutlinedStyle};
  ${(props) => props.variant === "secondaryOutlined" && secondaryOutlinedStyle};
  ${(props) => props.variant === "tertiaryOutlined" && tertiaryOutlinedStyle};
  ${(props) => props.variant === "disabled" && disabledStyle};
  ${(props) => props.variant === "cancel" && cancelStyle};
  ${(props) => props.size === "extraSmall" && extraSmallStyle};
  ${(props) => props.size === "small" && smallStyle};
  ${(props) => props.size === "medium" && mediumStyle};
  ${(props) => props.size === "large" && largeStyle};
  ${(props) => props.size === "extraLarge" && extraLargeStyle};
  ${(props) => props.fullWidth && fullWidthStyle};
  ${(props) => props.animation && shrinkEffect};
`;

const handleClick = (e) => {
  const button = e.currentTarget;

  const circle = document.createElement("span");
  const diameter = Math.max(button.clientWidth, button.clientHeight);
  const radius = diameter / 2;

  circle.style.width = circle.style.height = `${diameter}px`;
  circle.style.left = `${e.clientX - button.offsetLeft - radius}px`;
  circle.style.top = `${e.clientY - button.offsetTop - radius}px`;
  circle.classList.add("ripple");

  const ripple = button.getElementsByClassName("ripple")[0];

  if (ripple) {
    ripple.remove();
  }

  button.appendChild(circle);
};

const Button = (props) => {
  return (
    <StyledButton onClick={handleClick} {...props}>
      {props.children}
    </StyledButton>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  size: PropTypes.oneOf([
    "extraSmall",
    "small",
    "medium",
    "large",
    "extraLarge",
  ]),
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "tertiary",
    "primaryOutline",
    "secondaryOutline",
    "tertiaryOutline",
    "cancel",
    "disabled",
  ]),
  animation: PropTypes.bool,
  title: PropTypes.string,
};

export default Button;
