import styled from "@emotion/styled";
import PropTypes from "prop-types";

const StyledButton = styled.a`
  display: block;
  border: 1px solid black;
  border-style: dashed;
  padding: 12px 20px;
  background: white;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DashedButton = ({ text, onClick, href, download, disabled }) => {
  const handleClick = (e) => {
    if (disabled) {
      return;
    }
    onClick(e);
  };
  return (
    <StyledButton
      onClick={handleClick}
      href={href}
      download={download}
      disabled={disabled}
    >
      {text}
    </StyledButton>
  );
};

DashedButton.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default DashedButton;
