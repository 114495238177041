import styled from "@emotion/styled";
import { useEffect, useState, lazy, Suspense } from "react";
import { useFeature } from "flagged";

import {
  CATEGORIES,
  getFieldSchema,
} from "../../business-card-management/components/form.utils";
import Flex from "../../common/atoms/Flex";
import BaseFiltersSelector from "../components/BaseFiltersSelector";
import OwnBusinessCardSelector from "../components/OwnBusinessCardSelector";
import TopCTA from "../components/TopCTA";
import TopSocialMedia from "../components/TopSocialMedia";
import { MonitoringService } from "../services/MonitoringService";
import CardContainer from "../../common/atoms/CardContainer";
import DniRatingTable from "../components/DniRatingTable";
import MyRating from "../components/MyRating";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../../common/atoms/Loader";

const ChartReact = lazy(() => import("../components/ChartReact"));

const PersonalStatisticsWrapper = styled.div`
  width: 100%;
  margin: 12px 0px;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 24px 0px;
  > div:first-of-type {
    flex: 70%;
  }
  > div:last-of-type {
    flex: 20%;
  }
  @media (max-width: 525px) {
    gap: 10px;
    flex-direction: column;
  }
`;

const StatisticsContainer = styled.div`
  max-width: 1100px;
  width: 100%;
`;

const RatingTableContainer = styled.div`
  display: flex;
  gap: 25px;
  margin: 24px 0px;
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 5px;
  }
`;

const PersonalStatistics = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const { loggedUser } = useSelector((state) => state.authUser);
  const [generalContactData, setGeneralContactData] = useState([]);
  const [socialMediaData, setSocialMediaData] = useState([]);
  const [visitsRatingData, setVisitsRatingData] = useState([]);
  const [socialMediaLoading, setSocialMediaLoading] = useState(false);
  const [generalContactLoading, setGeneralContactLoading] = useState(false);
  const [currentBusinessCard, setCurrentBusinessCard] = useState(null);
  const [ratingValue, setRatingValue] = useState("");
  const [filter, setFilter] = useState("THIS_WEEK");
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const hasReferrals = useFeature("referrals");
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const getPersonalVisits = (businessCardId) => {
    setLoading(true);
    MonitoringService.getVisitsInDateRange(businessCardId, timezone, filter)
      .then((res) => {
        if (res.status === 200) {
          setData(res.data.visitsData);
        } else {
          console.log(res.data.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getSocialMediaEvents = (businessCardId) => {
    setSocialMediaLoading(true);
    MonitoringService.getPersonalEventsByCategory(
      CATEGORIES.SOCIAL_NETWORKS,
      businessCardId,
      filter
    ).then((res) => {
      if (res.status === 200) {
        const events = res.data.events.map((item) => {
          const fieldSchema = getFieldSchema(item.category, item.key);
          return {
            ...item,
            placeholder: fieldSchema
              ? fieldSchema.placeholder || fieldSchema.valuePlaceholder
              : item.key,
          };
        });
        setSocialMediaData(events);
      } else {
        console.log(res.data.message);
      }
      setSocialMediaLoading(false);
    });
  };

  const getGeneralContactEvents = (businessCardId) => {
    setGeneralContactLoading(true);
    MonitoringService.getPersonalEventsByCategory(
      CATEGORIES.GENERAL_CONTACTS,
      businessCardId,
      filter
    ).then((res) => {
      if (res.status === 200) {
        const events = res.data.events.map((item) => {
          const fieldSchema = getFieldSchema(item.category, item.key);
          return {
            ...item,
            placeholder: fieldSchema
              ? fieldSchema.placeholder || fieldSchema.valuePlaceholder
              : item.key,
          };
        });
        setGeneralContactData(events);
      } else {
        console.log(res.data.message);
      }
      setGeneralContactLoading(false);
    });
  };

  const getPersonalVisitsWithRatingInformation = (businessCardId) => {
    MonitoringService.getPersonalVisitsWithRatingSummary(businessCardId, filter)
      .then((res) => {
        if (res.status === 200 && res.data.visits) {
          setVisitsRatingData(res.data.visits);
          setRatingValue(
            (res.data.visits &&
              res.data.visits.length &&
              res.data.visits[0].averageRating) ||
              "0"
          );
        } else {
          console.log(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleBusinessCardChange = (businessCard) => {
    setCurrentBusinessCard(businessCard);
    getPersonalVisits(businessCard.id);
    getSocialMediaEvents(businessCard.id);
    getGeneralContactEvents(businessCard.id);
    if (hasReferrals) {
      getPersonalVisitsWithRatingInformation(businessCard.id);
    }
  };

  const onChangeFilter = (option) => {
    setFilter(option);
  };

  useEffect(() => {
    const cardIdFromParam = searchParams.get("cardId");
    if (cardIdFromParam) {
      if (!loggedUser.businessCards.includes(cardIdFromParam)) {
        navigate("/not-found");
        return;
      }
    }
  }, []);

  useEffect(() => {
    if (currentBusinessCard && currentBusinessCard.id) {
      handleBusinessCardChange(currentBusinessCard);
    }
  }, [filter]);

  return (
    <StatisticsContainer>
      <OptionsContainer className="step-1-statistics">
        <OwnBusinessCardSelector onChange={handleBusinessCardChange} />
        <BaseFiltersSelector onChange={onChangeFilter} />
      </OptionsContainer>
      <PersonalStatisticsWrapper className="step-2-statistics">
        <CardContainer statistic headerTitle="Visitas de mi tarjeta">
          <Suspense fallback={<Loader />}>
            <ChartReact data={data} filter={filter} loading={loading} />
          </Suspense>
        </CardContainer>
      </PersonalStatisticsWrapper>
      {hasReferrals && (
        <RatingTableContainer className="step-3-statistics">
          <DniRatingTable
            type="personal"
            data={visitsRatingData}
            tableTitle="Mis referidos por tarjeta"
          />
          {/* <MyRating value={ratingValue} /> */}
        </RatingTableContainer>
      )}
      <Flex className="step-4-statistics" gap="25px" flexWrap="wrap">
        <TopCTA
          loading={generalContactLoading}
          data={generalContactData}
        ></TopCTA>
        <TopSocialMedia
          loading={socialMediaLoading}
          data={socialMediaData}
        ></TopSocialMedia>
      </Flex>
    </StatisticsContainer>
  );
};

export default PersonalStatistics;
