import axiosInstance from "../../common/helpers/axios";
let tokenString = null;

export var BusinessCardService = {
  setToken: (token) => {
    tokenString = `Bearer ${token}`;
  },

  getBusinessCards: async (companyId, page, search) => {
    const config = {
      params: {
        page: page || 0,
        search: search || "",
      },
    };
    let result = {};
    try {
      result = await axiosInstance.get(
        `/business-card/team/${companyId}`,
        config
      );
      return result.data;
    } catch (error) {
      console.log(error);
    }
  },

  getInactiveBusinessCards: async (companyId) => {
    let result = {};
    try {
      result = await axiosInstance.get(
        `/business-card/team/${companyId}/inactive`
      );
      return result.data;
    } catch (error) {
      console.log(error);
    }
  },

  createBusinessCard: async (card) => {
    return axiosInstance.post(`/business-card/other-employee`, {
      cardBody: card,
    });
  },

  getBusinessCardsQuantityByCompany: async (companyId) => {
    let result = {};
    try {
      result = await axiosInstance.get(
        `/business-card/company/${companyId}/quantity`
      );
    } catch (error) {
      console.log(error);
    }

    return result;
  },
  createOwnBusinessCard: async (card) => {
    return axiosInstance.post(`/business-card/own`, { cardBody: card });
  },
  getOwnBusinessCards: async (page, search) => {
    const config = {
      params: {
        page: page || 0,
        search: search || "",
      },
    };
    return axiosInstance.get(`/business-card/own`, config);
  },

  getBusinessCardById: async (cardId) => {
    const today = new Date();
    return axiosInstance.get(
      `/business-card/${cardId}/detail?date=${today.toString()}`
    );
  },

  editBusinessCard: async (cardBody, cardId) => {
    return axiosInstance.put(`/business-card/${cardId}`, cardBody);
  },

  getBusinessCardByPathKey: async (businessKey, employeeKey) => {
    const config = {
      params: {
        businessKey,
        employeeKey,
      },
    };

    return axiosInstance.get(`/business-card/public/`, config);
  },

  updateTemplate: async (cardId, templateId) => {
    return axiosInstance.put(`/business-card/${cardId}/template`, {
      templateId,
    });
  },

  getVCard: async (businessCardId) => {
    return axiosInstance.get(`/business-card/public/vcard/${businessCardId}`, {
      responseType: "arraybuffer",
    });
  },
  editCardEmployeePathKey: async (newUrl, employeeId, companyId) => {
    return axiosInstance.put(
      `/employee/${employeeId}/edit-path-key/company/${companyId}`,
      {
        newUrl,
      }
    );
  },

  setCardPaused: async (cardId, body) => {
    return axiosInstance.put(`/business-card/${cardId}/pause-card`, body);
  },
};
