export function downloadResponseBase64File(response) {
  const type = response.headers["content-type"];
  const blob = convertBase64ToBlob(response.data, type);
  const contentDisposition =
    response.headers["content-disposition"].split("; ");
  const filename = contentDisposition[1].split("=")[1].replace(/\"/g, "");

  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;
  link.click();
}

function convertBase64ToBlob(base64, type) {
  const decodedData = window.atob(base64);
  const uInt8Array = new Uint8Array(decodedData.length);
  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i);
  }

  return new Blob([uInt8Array], { type });
}
