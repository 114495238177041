import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "../../common/atoms/Button";
import Typography from "../../common/atoms/Typography";
import { Card, CardContent } from "@mui/material";
import styled from "@emotion/styled";
import { ACCOUNT_TYPE_PERSONAL } from "../../common/helpers/constants";

const CardStyle = styled(Card)`
  max-width: 280px;
`;

const CardContentStyled = styled(CardContent)`
  min-height: 320px;
  display: flex;
  flex-direction: column;
  > ul {
    padding-left: 1rem;
    margin-bottom: auto;
    li {
      padding-left: none;
    }
    @media screen and (max-width: 768px) {
      font-size: 0.8rem;
      min-height: auto;
    }
  }
`;

const ButtonStyled = styled(Button)`
  margin-top: 12px;
`;

const PlanCard = ({
  title,
  price,
  currency,
  benefits,
  businessBenefits,
  buttonText,
  quantity,
  accountType,
  onClick = () => {},
  hasButton = true,
}) => {
  const [cardsQuantity, setCardsQuantity] = useState(quantity);

  const handleChange = (e) => {
    const { value } = e.target;
    const cleanValue = value.replace(/[^0-9]/g, "");
    setCardsQuantity(cleanValue);
  };
  const handleClick = () => {
    if (cardsQuantity <= 0) {
      return;
    }
    onClick(cardsQuantity);
  };

  return (
    <CardStyle>
      <CardContentStyled>
        <Typography variant='h5' component='h2'>
          {title}
        </Typography>
        <Typography color='text.secondary' gutterBottom>
          {currency} {price}
        </Typography>
        <ul>
          {benefits.map((benefit, index) => (
            <li key={index}>
              <Typography variant='tiny'>{benefit}</Typography>
            </li>
          ))}

          {accountType !== ACCOUNT_TYPE_PERSONAL && (
            <>
              <li key='business-benefit-1'>
                <Typography variant='tiny'>
                  {businessBenefits.mainBenefit}
                </Typography>
              </li>
              <ul>
                {businessBenefits.infoList.map((benefit, index) => (
                  <li key={index}>
                    <Typography variant='tiny'>
                      <b>{benefit}</b>
                    </Typography>
                  </li>
                ))}
              </ul>
            </>
          )}
        </ul>
        {accountType !== ACCOUNT_TYPE_PERSONAL && (
          <>
            <Typography mt='2' variant='tiny'>
              Cantidad de Tarjetas:
            </Typography>
            <input
              type='text'
              name='quantity'
              maxLength={4}
              value={cardsQuantity}
              onChange={handleChange}
            />
            <Typography variant='tiny'>
              En total pagarás:{" "}
              <strong>
                {currency} {(cardsQuantity * price).toFixed(2)}
              </strong>
            </Typography>
          </>
        )}
        {hasButton && (
          <ButtonStyled
            disabled={cardsQuantity <= 0}
            variant={cardsQuantity <= 0 ? "disabled" : "primary"}
            size='small'
            fullWidth
            onClick={handleClick}
          >
            {buttonText}
          </ButtonStyled>
        )}
      </CardContentStyled>
    </CardStyle>
  );
};

PlanCard.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  benefits: PropTypes.array.isRequired,
  buttonText: PropTypes.string.isRequired,
  quantity: PropTypes.number,
  accountType: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default PlanCard;
