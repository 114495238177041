import styled from "@emotion/styled";
import { BusinessUrlForm } from "../components/BusinessUrlForm";
import { CompanyProfileForm } from "../components/CompanyProfileForm";
import { GlobalColors } from "../components/GlobalColors";
import { useSelector } from "react-redux";
const PageLayout = styled.div`
  width: 100%;
  @media (max-width: 480px) {
    padding: 0;
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  & > div:first-of-type {
    max-width: 550px;
  }
  @media screen and (max-width: 480px) {
    justify-content: center;
  }
`;

const UrlContainer = styled.div`
  margin-top: 24px;
`;

export const CompanyConfiguration = () => {
  const { loggedUser, token } = useSelector((state) => state.authUser);

  return (
    <PageLayout>
      <MainContainer>
        <div>
          <CompanyProfileForm loggedUser={loggedUser} token={token} />
          <UrlContainer>
            <BusinessUrlForm loggedUser={loggedUser} token={token} />
          </UrlContainer>
        </div>
        <div style={{ maxWidth: "350px", width: "100%" }}>
          <GlobalColors loggedUser={loggedUser} />
        </div>
      </MainContainer>
    </PageLayout>
  );
};
