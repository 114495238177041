import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import { getSocialNetworkInfoByName } from "../form.utils";
import SocialInput from "../../../common/atoms/SocialInput";

const sanitizers = {
  whatsapp: (value) => value.replace(/\s/g, ""),
  whatsappBusiness: (value) => value.replace(/\s/g, ""),
};

const SocialNetworkSection = forwardRef(
  ({ initialData, readOnly, onChange, onError }, ref) => {
    const [formData, setFormData] = useState(initialData || {});
    const [errors, setErrors] = useState({});

    const validate = (name, value) => {
      const error = !value ? "El enlace es requerido" : "";
      setErrors((prevState) => ({
        ...prevState,
        [name]: error,
      }));

      return error;
    };

    const handleInputChange = (e, type) => {
      let { name, value } = e.target;

      const sanitizedValue = sanitizers[name] ? sanitizers[name](value) : value;

      setFormData((prevState) => ({
        ...prevState,
        [name]: sanitizedValue,
      }));

      validate(name, value);
    };

    const handleDeleteInput = (e, type, name) => {
      setErrors((prevState) => ({
        ...prevState,
        [name]: "",
      }));

      setFormData((prevState) => {
        const { [name]: _, ...rest } = prevState;
        return rest;
      });
    };

    const handleTouched = (name, value, type) => {
      validate(name, value);
    };

    const validateAll = () => {
      let isValid = true;
      Object.keys(formData).forEach((name) => {
        const error = validate(name, formData[name]);

        if (error) {
          isValid = false;
        }
      });
      return isValid;
    };

    const addField = (name, category, field) => {
      setFormData((prevState) => ({ ...prevState, [name]: field.object }));
    };

    useEffect(() => {
      onError(errors);
    }, [errors]);

    useEffect(() => {
      onChange(formData);
    }, [formData]);

    useImperativeHandle(ref, () => ({
      validateAll,
      addField,
    }));

    return (
      <>
        {formData && Object.keys(formData).length > 0 && (
          <>
            <p id="redesSociales" className="form-subtitle">
              Redes sociales
            </p>
            {Object.keys(formData).map((key, index) => {
              const { baseUrl, placeholder } = getSocialNetworkInfoByName(key);
              const field = formData[key];
              return (
                <SocialInput
                  autoFocus={!field}
                  key={`${key}-${index}`}
                  error={errors[key]}
                  helperText={errors[key]}
                  social={baseUrl}
                  placeholder={placeholder}
                  deleteInputField={handleDeleteInput}
                  name={key}
                  type="socialNetworks"
                  onChange={handleInputChange}
                  value={field}
                  onBlur={handleTouched}
                  extra={true}
                />
              );
            })}
          </>
        )}
      </>
    );
  }
);

export default SocialNetworkSection;
