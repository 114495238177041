import React, { useState } from "react";

export const IconsContext = React.createContext("");

export const IconsProvider = ({ children }) => {
  const [icons, setIcons] = useState({
    linkedin: "bi-linkedin",
    whatsapp: "bi-whatsapp",
    linkedinBusiness: "linkedin-business-icon",
    whatsappBusiness: "whatsapp-business-icon",
    facebook: "bi-facebook",
    twitter: "bi-twitter-x",
    instagram: "bi-instagram",
    twitch: "bi-twitch",
    github: "bi-github",
    behance: "bi-behance",
    snapchat: "bi-snapchat",
    youtube: "bi-youtube",
    vimeo: "bi-vimeo",
    tiktok: "bi-tiktok",
    telegram: "bi-telegram",
    wechat: "bi-wechat",
    signal: "bi-signal",
    paypal: "bi-paypal",
    phones: "bi-telephone-fill",
    email: "bi-envelope-fill",
    pdf: "bi-file-earmark-pdf-fill",
    businessPresentations: "bi-file-slides-fill",
    businesspresentations: "bi-file-slides-fill",
    address: "bi-geo-alt-fill",
    webpage: "bi-globe",
    webpages: "bi-link",
    file: "",
    addcontact: "bi-person-fill-add",
  });

  return (
    <IconsContext.Provider
      value={{
        icons,
        setIcons,
      }}
    >
      {children}
    </IconsContext.Provider>
  );
};
