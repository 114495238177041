import AuthService from "../../../../auth-management/services/AuthService";
import EmployeeService from "../../../../user-profile/services/EmployeeService";

export const changeAccountRequest = (relatedEmployeeId) => {
  return AuthService.changeAccount(relatedEmployeeId);
};

export const getLinkedAccounts = async () => {
  return await EmployeeService.getLinkedAccounts();
};
