import DialogWithHeader from "../../common/molecules/DialogWithHeader";
import DataTable from "react-data-table-component";
import CounterTextStyled from "../../common/atoms/CounterTextStyled";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, DialogActions } from "@mui/material";
import { EmployeeContext } from "../context/EmployeesContext";
import { useContext, useEffect, useState } from "react";
import Button from "../../common/atoms/Button";
import EmployeeService from "../services/EmployeeService";
import Alert from "../../common/atoms/Alert";
import RoleSelector from "../components/RoleSelector";

const EditManyUsers = ({
  open,
  closeDialog,
  closeDialogByCompletion,
  getEmployees,
  setLoading,
  batchAction,
}) => {
  const { employeesToEditRole } = useContext(EmployeeContext);
  const [employees, setEmployees] = useState([]);
  const [editLoading, setEditLoading] = useState(false);
  useEffect(() => {
    if (employeesToEditRole && open) {
      setEditLoading(true);
      let array = [];
      Object.keys(employeesToEditRole).forEach((key) => {
        array = [...array, ...employeesToEditRole[key]];
      });

      const newEmployees = array;
      if (newEmployees) {
        setEditLoading(false);
      }
      setEmployees([...newEmployees]);
    }
  }, [open]);

  const onDeleteRow = (data) => {
    let newEmployeesArray = [...employees];
    let indexToDelete = 0;
    employees.forEach((employee, index) => {
      if (employee.id === data.id) {
        indexToDelete = index;
      }
    });

    newEmployeesArray.splice(indexToDelete, 1);
    setEmployees(newEmployeesArray);
  };

  const handleCloseDialog = async () => {
    await closeDialogByCompletion();
    setSelectedRole("");
    setSelectorError("");
  };
  const simpleCloseDialog = async () => {
    closeDialog();
    setSelectedRole("");
    setSelectorError("");
  };
  const columns = [
    {
      name: "#",
      width: "60px",
      selector: (row) => row.counter,
      sortable: true,
      cell: (row) => <CounterTextStyled>{row.counter}</CounterTextStyled>,
    },

    {
      name: "Nombre",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Apellido",
      selector: (row) => row.lastname,
      sortable: true,
    },
    {
      name: "Correo",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Telefono",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Rol",
      selector: (row) => row.role,
      sortable: true,
    },
    {
      name: "",
      button: true,
      cell: (row) => {
        return (
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              onDeleteRow(row);
            }}
          />
        );
      },
    },
  ];

  const [selectedRole, setSelectedRole] = useState("");
  const [selectorError, setSelectorError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSelection = (value) => {
    setSelectorError("");
    setSelectedRole(value);
  };

  const validateRole = () => {
    if (selectedRole !== "") {
      return true;
    }
    setSelectorError("Debe seleccionar un rol.");
    return false;
  };

  const handleUpdate = async () => {
    setSelectorError("");
    if (validateRole()) {
      setLoading(true);
      const newEmployees = employees.map((employee) => {
        return {
          id: employee.id,
          role: selectedRole,
        };
      });
      try {
        let result = await EmployeeService.editManyEmployeesByAdmin(
          newEmployees
        );
        if (result.status === 200 && result.data.updatedEmployees) {
          getEmployees();
          setEmployees([]);
          handleCloseDialog();
          setLoading(false);
        }
      } catch (error) {
        console.log(JSON.stringify(error.response));
        setLoading(false);
        setErrorMessage("Ocurrió un error. Intente de nuevo en unos minutos.");
      }
    }
  };

  const handleDelete = async () => {
    setSelectorError("");
    setLoading(true);
    const employeesWithId = employees.map((employee) => {
      return { id: employee.id };
    });
    try {
      let result = await EmployeeService.deleteEmployees(employeesWithId);
      if (result.status === 200 && result.data.deletedEmployees) {
        getEmployees();
        setEmployees([]);
        handleCloseDialog();
        setLoading(false);
      }
    } catch (error) {
      console.log(JSON.stringify(error.response));
      setLoading(false);
      setErrorMessage("Ocurrió un error. Intente de nuevo en unos minutos.");
    }
  };

  const handleBatchAction = async () => {
    switch (batchAction) {
      case "EDIT":
        handleUpdate();
        break;
      case "DELETE":
        handleDelete();
        break;
    }
  };

  return (
    <DialogWithHeader
      open={open}
      headerTitle={batchAction === "EDIT" ? "Editar roles" : "Eliminar"}
      closeDialog={simpleCloseDialog}
    >
      <div style={{ margin: "12px" }}>
        {editLoading && <CircularProgress />}
        {!editLoading && (
          <DataTable
            noDataComponent="No se han encontrado empleados."
            columns={columns}
            data={employees}
          />
        )}
      </div>
      {batchAction === "EDIT" && (
        <div style={{ marginTop: "16px" }}>
          <RoleSelector
            currentValue={selectedRole}
            customError={selectorError}
            onChange={handleSelection}
          />
        </div>
      )}
      {errorMessage !== "" && <Alert type="error">{errorMessage}</Alert>}
      <DialogActions>
        <div style={{ width: "100%", textAlign: "center" }}>
          <Button
            variant="primary"
            size="medium"
            onClick={() => {
              handleBatchAction();
            }}
          >
            {batchAction === "EDIT" ? "Actualizar" : "Eliminar"}
          </Button>
        </div>
      </DialogActions>
    </DialogWithHeader>
  );
};

export default EditManyUsers;
