import { CircularProgress } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CardItem } from "../components/CardItem";
import { BusinessCardsContext } from "../context/BusinessCardsContext";
import { BusinessCardService } from "../services/BusinessCardService";
import CreateCardInvitation from "../components/CreateCardInvitation";
import { useSelector } from "react-redux";
import Pagination from "../../common/atoms/Pagination";
import styled from "@emotion/styled";
import TextSearch from "../../common/atoms/TextSearch";
import { debounce } from "../../common/helpers/functions";
import CardsLegend from "../../common/molecules/CardsLegend";

const PAGE_SIZE = 15;

const ListContainer = styled.div`
  display: flex;
  gap: 22px;
  flex-wrap: wrap;
  @media (min-width: 1920px) {
    gap: 40px;
  }
  @media (max-width: 516px) {
    justify-content: center;
  }
`;

export default function BusinessCardTeamList() {
  const [cards, setCards] = useState([]);
  const { loggedUser } = useSelector((state) => state.authUser);
  const [loading, setLoading] = useState(false);
  const currentCompany = useSelector((state) => state.currentCompany);
  const [pagination, setPagination] = useState({
    total: 0,
    pages: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const { setFormMode, setIsOwnCard } = useContext(BusinessCardsContext);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  // const showAddButton =
  //   loggedUser &&
  //   (loggedUser.role === "ADMIN" || loggedUser.role === "ADMIN_FOUNDER") &&
  //   location.pathname === "/company/cards";
  const showInvitationButton =
    loggedUser &&
    !loggedUser.haveCard &&
    (location.pathname === "/" || location.pathname === "/cards") &&
    loggedUser.role !== "EMPLOYEE";

  useEffect(() => {
    if (loggedUser.role.startsWith("ADMIN")) {
      setCards([]);
      if (!showInvitationButton) {
        setLoading(true);
      }

      setIsOwnCard(false);
      getCards(search, currentPage);
    }
  }, [location.pathname, currentPage, search]);

  const getCards = async (search, currentPage) => {
    try {
      const businessCards = await BusinessCardService.getBusinessCards(
        currentCompany.id,
        currentPage - 1,
        search
      );
      if (businessCards.data && businessCards.data.length > 0) {
        setCards(businessCards.data);
      }
      setPagination(businessCards.paging);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const goToTemplateSelection = () => {
    setFormMode("creation");
    navigate("/cards/templates");
  };

  const goToCardDetail = (cardId) => {
    setFormMode("edition");
    navigate(`/cards/${cardId}`);
  };

  // const renderCardAddButtons = () => {
  //   if (showAddButton) {
  //     return <AddCardButton onClick={goToTemplateSelection} />;
  //   } else if (showInvitationButton) {
  //     return <CreateCardInvitation onClick={goToTemplateSelection} />;
  //   }
  // };

  const renderCardAddButtons = () => {
    if (
      loggedUser &&
      (loggedUser.role === "ADMIN" || loggedUser.role === "ADMIN_FOUNDER")
    ) {
      return;
    } else if (showInvitationButton) {
      return <CreateCardInvitation onClick={goToTemplateSelection} />;
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearch = (event) => {
    const value = event.nativeEvent.target.value;
    setCurrentPage(1);
    setSearch(value);
  };

  const debouncedChangeHandler = useCallback(debounce(handleSearch, 500), []);

  return (
    <div className="container-fluid p-0">
      <div style={{ marginBottom: "20px" }}>
        <TextSearch onSearch={debouncedChangeHandler} />
      </div>
      <CardsLegend />
      <ListContainer>
        {renderCardAddButtons()}
        {loading && (
          <div className="loading-container">
            <CircularProgress />
          </div>
        )}
        {cards &&
          cards.map((card, index) => {
            return (
              <CardItem
                key={index}
                card={card}
                goToCardDetail={goToCardDetail}
              />
            );
          })}
      </ListContainer>
      <Pagination
        total={pagination.total}
        pageSize={PAGE_SIZE}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
}
