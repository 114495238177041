import React from "react";
import InitialLayout from "../components/InitialLayout";
import AccountSelector from "../components/AccountSelector";

const SelectAcount = () => {
  return (
    <InitialLayout errorMessage={""} successMessage={""} pageLoading={false}>
      <AccountSelector />
    </InitialLayout>
  );
};

export default SelectAcount;
