import { CLEAR_STORE } from "../action-types/commonActionTypes";
import noPaddingActionTypes from "../action-types/noPaddingActionTypes";

const { SET_NO_PADDING } = noPaddingActionTypes;

export const clearStore = () => {
  return {
    type: CLEAR_STORE,
  };
};

export const setNoPadding = (state) => {
  return {
    type: SET_NO_PADDING,
    payload: state,
  };
};
