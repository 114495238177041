import axiosInstance from "../../common/helpers/axios";

export const MonitoringService = {
  getVisitsInDateRange: async (businessCardId, timezone, filter) => {
    try {
      return await axiosInstance.get(`/statistics/personal/visits`, {
        params: {
          businessCardId,
          timezone,
          filter,
        },
      });
    } catch (error) {
      return error.response;
    }
  },
  getGeneralVisitsByCompany: async (companyId, timezone, filter) => {
    try {
      return await axiosInstance.get(
        `/statistics/general/company/${companyId}/visits?timezone=${timezone}&filter=${filter}`
      );
    } catch (error) {
      return error.response;
    }
  },

  getVisitsByCardId: async (cardId, timezone, filter) => {
    try {
      return await axiosInstance.get(
        `/statistics/business-card/${cardId}/visits`,
        {
          params: {
            timezone,
            filter,
          },
        }
      );
    } catch (error) {
      return error.response;
    }
  },
  getVisitsByEmployee: async (companyId, filter) => {
    try {
      return await axiosInstance.get(
        `/statistics/general/company/${companyId}/top-users`,
        {
          params: {
            filter,
          },
        }
      );
    } catch (error) {
      return error.response;
    }
  },
  getEventsByCategory: async (companyId, category, filter) => {
    try {
      return await axiosInstance.get(
        `/statistics/general/company/${companyId}/category`,
        {
          params: {
            name: category,
            filter,
          },
        }
      );
    } catch (error) {
      return error.response;
    }
  },
  getPersonalEventsByCategory: async (category, businessCardId, filter) => {
    try {
      return await axiosInstance.get(`/statistics/personal/category`, {
        params: {
          name: category,
          businessCardId,
          filter,
        },
      });
    } catch (error) {
      return error.response;
    }
  },
  getEventsByCategoryAndBusinessCardId: async (cardId, category, filter) => {
    try {
      return await axiosInstance.get(
        `/statistics/business-card/${cardId}/category`,
        {
          params: {
            name: category,
            filter,
          },
        }
      );
    } catch (error) {
      return error.response;
    }
  },
  getVisitsWithRatingSummary: async (companyId, filter) => {
    try {
      return await axiosInstance.get(
        `/statistics/general/company/${companyId}/rating-information`,
        {
          params: {
            filter,
          },
        }
      );
    } catch (error) {
      return error.response && error.response;
    }
  },
  getPersonalVisitsWithRatingSummary: async (cardId, filter) => {
    try {
      return await axiosInstance.get(
        `/statistics/personal/business-card/${cardId}/rating-information`,
        {
          params: {
            filter,
          },
        }
      );
    } catch (error) {
      return error.response && error.response;
    }
  },
  downloadReport: (companyId, format, filter) => {
    return axiosInstance.get(
      `/statistics/general/company/${companyId}/report`,
      {
        params: {
          filter,
          format,
        },
        responseType: "text",
      }
    );
  },
  saveEvent: (name, type, businessCard, category) => {
    return axiosInstance.post("/statistics/event", {
      name,
      type,
      businessCard,
      category: category || "",
    });
  },
  transferEvents: (companyId, fromBusinessCardId, toBusinessCardId) => {
    return axiosInstance.post(
      `/statistics/general/company/${companyId}/transfer-statistical-data`,
      {
        fromBusinessCardId,
        toBusinessCardId,
      }
    );
  },
};
