import * as React from "react";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import styled from "@emotion/styled";

const ModalBox = styled.div`
  position: absolute;
  top: 81%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #fff;
  box-shadow: 24px;
  border-radius: 15px;
  padding: 16px;
  outline: none;
  text-align: center;
  display: grid;
  place-items: center;
  @media screen and (max-width: 768px) {
    width: 90%;
  }
  @media screen and (max-width: 430px) {
    top: 83%;
  }
  @media screen and (max-width: 390px) {
    top: 80%;
  }
  @media screen and (max-width: 375px) {
    top: 75%;
  }
`;

const ArrowIndicator = styled.div`
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 20px solid #fff;
  @media screen and (max-width: 768px) {
    bottom: -10px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
  width: 80%;
  @media screen and (max-width: 430px) {
    width: 90%;
  }
`;

const ModalHelpBookmark = ({ open = false, handleClose }) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title">
        <Fade in={open}>
          <ModalBox>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="mb-3">
              Añadir a pantalla de inicio
            </Typography>
            <FlexContainer>
              <Typography
                id="modal-modal-description"
                variant="body1"
                component="p">
                1. Presiona el icono
              </Typography>
              <img
                src="/ios-share.png"
                alt="Add to Home Screen Icon"
                style={{ width: "45px", height: "45px", marginLeft: "8px" }}
                className="mt-1"
              />
            </FlexContainer>
            <FlexContainer>
              <Typography
                id="modal-modal-description"
                variant="body1"
                component="p"
                className="mb-1">
                2. Selecciona "Add To Home Screen".
              </Typography>
            </FlexContainer>
            <img
              src="/AddToHomeScreen.png"
              alt="Add to Home Screen Icon"
              style={{ width: "300px", height: "110px" }}
              className="mb-1"
            />
            <ArrowIndicator />
          </ModalBox>
        </Fade>
      </Modal>
    </div>
  );
};

export default ModalHelpBookmark;
