import styled from "@emotion/styled";
import { css } from "@emotion/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

import PageLoader from "../common/atoms/PageLoader";
import { initAccounts } from "../redux/store/accounts/actions/linkedAccountsActions";
import { initCurrentCompany } from "../redux/store/companies/actions/currentCompanyActions";
import NavigationDrawer from "./NavigationDrawer";
import { Sidebar } from "./Sidebar";
import { TopNavBar } from "./TopNavBar";
//import { FirstLoginModal } from "./ui/FirstLoginModal";
import { setLoggedUser } from "../redux/store/accounts/actions/authActions";
//import { ACCOUNT_TYPE_PERSONAL } from "../common/helpers/constants";
import NotificationService from "../common/services/NotificationService";
import { PaymentService } from "../payments/services/PaymentService";
const noPadding = css`
  padding: 15px 0px;
`;

const PageContainer = styled.div`
  width: 100%;
  padding: 30px;
  display: flex;
  height: 100%;
  gap: 34px;
  @media (min-width: 1920px) {
    padding: 53px 60px;
    gap: 53px;
  }
  @media (max-width: 768px) {
    padding: 15px;
    ${(props) => props.noPadding && noPadding}
  }
`;

const MainContent = styled.div`
  width: 100%;
  @media (max-width: 768px) {
    max-width: none;
  }
`;

const Msg = ({ notification, navigate }) => {
  const handleClick = () => {
    if (notification.targetPath) navigate(notification.targetPath);
  };
  return (
    <div
      onClick={handleClick}
      dangerouslySetInnerHTML={{ __html: notification.message }}
    ></div>
  );
};

const toggleSideBar = () => {
  const segments = location.pathname.split("/");
  const isADetailCardPage = segments[1] === "cards" && segments.length > 2;
  return !isADetailCardPage;
};

function Layout({ isAuth, loggedUser }) {
  const location = useLocation();
  const {
    companies,
    currentCompany,
    generalPageLoading,
    noPadding,
    changeAccount,
  } = useSelector((state) => state);
  const showSidebar = toggleSideBar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const initProcess = () => {
    dispatch(initAccounts());
    dispatch(initCurrentCompany());
    fetchNotifications();
  };

  useEffect(() => {
    if (isAuth) {
      // TODO: mejorar lógica de validación de estado de pago
      checkUnpaidStatus();
    }
  }, []);

  useEffect(() => {
    if (changeAccount) {
      checkUnpaidStatus();
    }
  }, [changeAccount]);

  const checkUnpaidStatus = () => {
    PaymentService.checkUnpaidStatus().then((response) => {
      const unpaid = response.data.unpaid;
      dispatch(
        setLoggedUser({
          ...loggedUser,
          unpaid,
        })
      );
      initProcess();
      if (unpaid) {
        navigate("/payments");
      } else {
        if (
          !loggedUser.role.startsWith("ADMIN") &&
          location.pathname.startsWith("/company")
        ) {
          navigate("/not-found");
        }
      }
    });
  };

  /*const onCloseFirstLoginModal = () => {
    dispatch(setLoggedUser({ ...loggedUser, firstLogin: false }));
  };*/

  const fetchNotifications = () => {
    NotificationService.getUserNotifications(timezone).then((response) => {
      const notifications = response.data;
      const toastConfig = {
        position: "bottom-right",
        autoClose: 30000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      };
      notifications.forEach((item) =>
        toast(<Msg notification={item} navigate={navigate} />, {
          ...toastConfig,
          type: item.type || "info",
        })
      );
    });
  };

  return (
    <>
      {/* {generalPageLoading && <PageLoader />} */}
      <div style={{ display: "flex" }}>
        {isAuth && (
          <NavigationDrawer
            role={loggedUser.role}
            companies={companies}
            currentCompany={currentCompany}
            loggedUser={loggedUser}
          />
        )}
        {!isAuth ? (
          <Navigate to="/login" replace state={{ from: location }} />
        ) : (
          <div style={{ width: "100%" }}>
            <TopNavBar user={loggedUser} />
            <PageContainer noPadding={noPadding}>
              <MainContent>
                <Outlet />
              </MainContent>
              {showSidebar && <Sidebar user={loggedUser} />}
              {/* <FirstLoginModal
                firstLogin={
                  loggedUser?.firstLogin &&
                  loggedUser?.accountType === ACCOUNT_TYPE_PERSONAL
                }
                onClose={onCloseFirstLoginModal}
              /> */}
            </PageContainer>
          </div>
        )}
      </div>
    </>
  );
}

export default Layout;
