import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { Avatar, Popper } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Box } from "@mui/system";
import { useState } from "react";

const MainContainer = styled(Box)`
  max-width: 200px;
`;

const Selector = styled(Popper)`
  max-width: 200px;
  padding: 12px;
  border-radius: ${(props) => (props.open ? "0px 0px 12px 12px" : "12px")};
  background: #eaeaea;
  box-shadow: 5px 5px 5px rgba(121, 121, 121, 0.25);
  @media (max-width: 768px) {
    border-radius: ${(props) => props.open && "12px"};
  }
`;

const Container = styled.div`
  width: 100%;
  border-radius: ${(props) => (props.open ? "25px 25px 0px 0px" : "25px")};
  background: #eaeaea;
  display: flex;
  gap: 12px;
  padding: 8px 10px;
  cursor: pointer;
  box-shadow: 5px 5px 5px rgba(121, 121, 121, 0.25);
`;

const AvatarStyled = styled(Avatar)`
  width: 35px;
  height: 35px;
`;

const Option = styled.p`
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  margin: 6px 0px;
  cursor: pointer;
  &:hover {
    font-weight: 700;
  }
`;

const TextContainer = styled.div`
  margin-left: 12px;
  margin-right: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 119px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const UserName = styled.p`
  font-family: Lato;
  font-weight: 700;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 18px;
`;

const UserPlan = styled.p`
  font-family: Lato;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 0;
  text-transform: capitalize;
`;

const ProfileSelector = ({ logout, goToProfile, user }) => {
  const [anchor, setAnchor] = useState(null);

  const handleClick = (e) => {
    setAnchor(anchor !== null ? null : e.currentTarget);
  };

  const closeOptions = () => {
    setAnchor(null);
  };

  const handleLogout = () => {
    logout();
  };

  const handleGoToProfile = () => {
    goToProfile();
  };

  const getName = () => {
    if (!user || !user.name || !user.lastname) return "";

    const firstName = user.name.split(" ");
    const lastName = user.lastname.split(" ");

    return firstName[0] + " " + lastName[0];
  };

  const getImageProfile = () => {
    return user.profileImage
      ? user.profileImage
      : require("../../common/assets/img/no-avatar-img.jpg");
  };

  return (
    <ClickAwayListener onClickAway={closeOptions}>
      <MainContainer className='step-1'>
        <Container onClick={handleClick} open={Boolean(anchor)}>
          <AvatarStyled src={getImageProfile()} />
          <TextContainer>
            <UserName>{getName()}</UserName>
            <UserPlan>{user.accountType} Plan</UserPlan>
          </TextContainer>
        </Container>
        <Selector
          open={Boolean(anchor)}
          anchorEl={anchor}
          placement='bottom'
          style={{ width: "100%" }}
        >
          {!user.unpaid && (
            <Option onClick={handleGoToProfile}>Mi perfil</Option>
          )}
          <Option onClick={handleLogout}>Desconectar</Option>
        </Selector>
      </MainContainer>
    </ClickAwayListener>
  );
};
ProfileSelector.propTypes = {
  user: PropTypes.object,
  logout: PropTypes.func,
  goToProfile: PropTypes.func,
};

export default ProfileSelector;
