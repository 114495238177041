import styled from "@emotion/styled";
import { css } from "@emotion/react";
import theme from "../theme";
import propTypes from "prop-types";
import "bootstrap-icons/font/bootstrap-icons.css";
import { useState } from "react";

const autoFocus = css`
  border: 2px solid ${theme.colors.azureRadiance};
`;

const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  width: 100%;
`;

const SocialUrl = styled.div`
  flex: 50%;
  width: 100%;
  max-width: 100%;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  background: ${(props) =>
    props.urlColorChanged
      ? theme.colors.azureRadiance
      : theme.colors.mineShaft};
  color: white;
  border-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0.7rem 1.2rem 0.7rem 1.2rem;
  border: 1px solid
    ${(props) =>
      props.urlColorChanged
        ? theme.colors.azureRadiance
        : theme.colors.mineShaft};
  @media screen and (max-width: 768px) {
    max-width: 200px;
    font-size: 14px;
    flex: 55%;
  }
`;

const SocialInputContainer = styled.div`
  position: relative;
  flex: 50%;
  width: 100%;
  input {
    font-family: "Lato", sans-serif;
    font-size: 16px;
    color: ${theme.colors.mineShaft};
    background: transparent;
    padding: 0.7rem 1.8rem 0.7rem 0.8rem;
    border-radius: 10px;
    border: 1px solid ${theme.colors.mineShaft};
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    max-width: 100%;
    width: 100%;
    @media screen and (max-width: 768px) {
      max-width: 200px;
      font-size: 14px;
      flex: 45%;
      padding-left: 0.7rem;
    }
    &:focus {
      border: 1px solid ${theme.colors.azureRadiance};
      ${(props) => props.autoFocus && autoFocus}
      border-radius: 10px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 1px solid ${theme.colors.azureRadiance};
    }
    &:focus-visible {
      outline: none;
    }
  }
  > i {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 35px;
    border-radius: 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: transparent;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.5s;
  }
  .icon-red {
    color: ${theme.colors.pastelRed};
    transition: color 0.5s;
    &:hover {
      color: #b20101;
    }
  }
`;

const ErrorMessage = styled.div`
  color: ${theme.colors.pastelRed};
  font-weight: 600;
  font-size: 0.9rem;
  padding-left: 0.5rem;
  margin: 0;
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const SocialInput = (props) => {
  const handleChange = (e) => {
    props.onChange(e, props.type);
  };

  const handleTouched = (value) => {
    props.onBlur(props.name, value, props.type);
  };

  const deleteField = (e) => {
    props.deleteInputField(e, props.type, props.name);
  };

  const [urlColorChanged, setUrlColorChanged] = useState(false);

  const handleOnBlur = (e) => {
    setUrlColorChanged(!urlColorChanged);
    handleTouched(e.target.value);
  };

  return (
    <>
      <SocialContainer id={props.id}>
        <SocialUrl urlColorChanged={urlColorChanged}>{props.social}</SocialUrl>
        <SocialInputContainer>
          <input
            type="text"
            name={props.name}
            error={props.error}
            placeholder={props.placeholder}
            value={props.value}
            onChange={(e) => handleChange(e)}
            onBlur={handleOnBlur}
            onFocus={() => setUrlColorChanged(true)}
            autoFocus={props.autoFocus}
          />
          {props.extra && (
            <i
              className="bi bi-trash icon-red"
              onClick={(e) => deleteField(e)}
            ></i>
          )}
        </SocialInputContainer>
      </SocialContainer>
      {props.error && <ErrorMessage>{props.helperText}</ErrorMessage>}
    </>
  );
};

SocialInput.propTypes = {
  social: propTypes.string,
  placeholder: propTypes.string,
  value: propTypes.string,
  onChange: propTypes.func,
  type: propTypes.string,
  name: propTypes.string,
  extra: propTypes.bool,
  autoFocus: propTypes.bool,
};

export default SocialInput;
