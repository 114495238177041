import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Box, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const TextFieldStyled = styled(TextField)`
  margin: 0 10px;
  margin-left: 0;
  width: 100%;
  @media (max-width: 1393px) {
    margin-left: 0;
  }
  @media (max-width: 1280px) {
    margin-left: 0;
  }
  .MuiOutlinedInput-root {
    background-color: white;
    border-radius: 10px;
    font-family: Lato;
    font-size: 16px;
    width: 100%;
    height: 50px;
  }
  input {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  @media (max-width: 480px) {
    input{
      font-size: 14px;
    }
  }
  input:placeholder-shown {
    text-overflow: ellipsis;
  }
  fieldset {
    border: none;
    legend {
      width: unset;
    }
  }
`;

const TextSearch = (props) => {
  return (
    <Box sx={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
      <TextFieldStyled
        id="filled-search"
        label={null}
        type="search"
        variant="outlined"
        placeholder="Buscar y filtrar"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon edge="end"></SearchIcon>
            </InputAdornment>
          ),
        }}
        onChange={props.onSearch}
      />
    </Box>
  );
};

TextSearch.propTypes = {
  children: PropTypes.node,
  placeholder: PropTypes.string
};

export default TextSearch;
