import React, { useState } from "react";

export const useDialog = () => {
  const [showDialog, setShowDialog] = useState(false);

  const openDialog = () => {
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  return {
    showDialog,
    openDialog,
    closeDialog,
  };
};
