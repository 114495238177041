import styled from "@emotion/styled";
import React, { useContext } from "react";
import "../assets/css/styles.css";
import SimpleCard from "../../common/atoms/SimpleCard";
import { IconsContext } from "../context/IconsContext";
import Typography from "../../common/atoms/Typography";

const SimpleCardStyled = styled(SimpleCard)`
  max-width: 320px;
  @media (min-width: 1920px) {
    max-width: 426px;
    width: 426px;
    padding: 33px;
  }
  @media screen and (max-width: 768px) {
    max-width: 100%;
    width: 100%;
  }
  @media screen and (max-width: 480px) {
    border-radius: unset;
  }
`;

const SectionTitle = styled.h3`
  font-family: Lato;
  font-size: 16px;
  font-style: italic;
  font-weight: 700;
  margin-top: 15px;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-flow: wrap;
  margin: 8px 0px 10px 0px;
  justify-content: start;
  gap: 17px;
  @media (min-width: 1920px) {
    gap: 30px;
  }
  @media (max-width: 432px) {
    gap: 0;
    justify-content: space-between;
  }
`;

const FieldItem = styled.div`
  width: 53px;
  cursor: pointer;
  text-align: center;
  @media (min-width: 1920px) {
    width: 64px;
  }
  @media screen and (max-width: 480px) {
    flex: 25%;
    margin-bottom: 10px;
    > p {
      font-size: 14px;
      margin-top: 5px;
    }
  }
`;

const FieldIcon = styled.div`
  border-radius: 10px;
  background-color: black;
  margin: auto;
  height: 45px;
  width: 45px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  > i {
    font-size: 20px;
  }
  transform: scale(1);
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
  @media (min-width: 1920px) {
    width: 53px;
    height: 53px;
    font-size: 24px;
  }
`;

function FieldOption(props) {
  const { field, category, icon, name } = props;

  const addField = () => {
    props.handleAddField(name, category, field);
  };

  return (
    <FieldItem onClick={addField}>
      <FieldIcon>
        <i className={icon}></i>
      </FieldIcon>
      {field.placeholder && (
        <Typography variant="xtiny">{field.placeholder}</Typography>
      )}
    </FieldItem>
  );
}

export default function CardFieldsList({ addField }) {
  const { icons } = useContext(IconsContext);

  const fieldList = {
    generalContacts: {
      phones: {
        object: {
          value: "",
          tag: "",
          extension: "",
          country: "",
        },
        type: "array",
        placeholder: "Teléfono",
      },
      address: {
        object: "",
        type: "simple",
        placeholder: "Dirección",
      },
      webpage: {
        object: "",
        type: "simple",
        placeholder: "Main web",
      },
      pdf: {
        object: {
          filename: "",
          url: "",
        },
        type: "simple",
        placeholder: "PDF",
      },
      webpages: {
        object: {
          tag: "",
          value: "",
        },
        type: "array",
        placeholder: "+ Webs",
      },
      businessPresentations: {
        object: {
          filename: "",
          url: "",
        },
        type: "array",
        placeholder: "Video",
      },
    },
    socialNetworks: {
      whatsapp: {
        object: "",
        type: "simple",
        placeholder: "WhatsApp",
      },
      whatsappBusiness: {
        object: "",
        type: "simple",
        placeholder: "WhatsApp Business",
      },
      linkedin: {
        object: "",
        type: "simple",
        placeholder: "LinkedIn",
      },
      linkedinBusiness: {
        object: "",
        type: "simple",
        placeholder: "LinkedIn Business",
      },
      facebook: {
        object: "",
        type: "simple",
        placeholder: "Facebook",
      },
      instagram: {
        object: "",
        type: "simple",
        placeholder: "Instagram",
      },
      twitter: {
        object: "",
        type: "simple",
        placeholder: "Twitter",
      },
      behance: {
        object: "",
        type: "simple",
        placeholder: "Behance",
      },
      snapchat: {
        object: "",
        type: "simple",
        placeholder: "Snapchat",
      },
      youtube: {
        object: "",
        type: "simple",
        placeholder: "Youtube",
      },
      vimeo: {
        object: "",
        type: "simple",
        placeholder: "Vimeo",
      },
      tiktok: {
        object: "",
        type: "simple",
        placeholder: "Tik tok",
      },
      twitch: {
        object: "",
        type: "simple",
        placeholder: "Twitch",
      },
      telegram: {
        object: "",
        type: "simple",
        placeholder: "Telegram",
      },
      wechat: {
        object: "",
        type: "simple",
        placeholder: "Wechat",
      },
      signal: {
        object: "",
        type: "simple",
        placeholder: "Signal",
      },
      github: {
        object: "",
        type: "simple",
        placeholder: "Github",
      },
      paypal: {
        object: "",
        type: "simple",
        placeholder: "Paypal",
      },
    },
  };

  const handleClick = (name, category, field) => {
    const newField = fieldList[category][name];
    const object = newField.object;
    addField(name, category, field, object);
  };

  const contactKeys = Object.keys(fieldList.generalContacts);
  const socialKeys = Object.keys(fieldList.socialNetworks);

  return (
    <SimpleCardStyled className="card-field-list">
      <Typography variant="xs">Añadir más información</Typography>
      <Typography variant="xs" weight="bold" mt="2">
        Botones generales
      </Typography>
      <FieldWrapper>
        {contactKeys.map((key, index) => {
          const field = fieldList.generalContacts[key];
          return (
            <FieldOption
              key={index.toString()}
              name={key}
              field={field}
              category="generalContacts"
              handleAddField={handleClick}
              icon={icons[key]}
            />
          );
        })}
      </FieldWrapper>
      <SectionTitle>Botones sociales</SectionTitle>
      <FieldWrapper>
        {socialKeys.map((key, index) => {
          const field = fieldList.socialNetworks[key];
          return (
            <FieldOption
              key={index.toString()}
              name={key}
              field={field}
              category="socialNetworks"
              handleAddField={handleClick}
              icon={icons[key]}
            />
          );
        })}
      </FieldWrapper>
    </SimpleCardStyled>
  );
}
