import { useEffect, useState } from "react";
import styled from "@emotion/styled";

import Link from "../../../common/atoms/Link";
import theme from "../../../common/theme";
import Typography from "../../../common/atoms/Typography";
import Flex from "../../../common/atoms/Flex";

const BoldLabel = styled.label`
  font-size: 15px;
  margin-top: 12px;
  font-weight: bold;
`;

const SimpleInputContainer = styled.div`
  font-family: "Lato", sans-serif;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: white;
  margin-top: 10px;
  input[type="file"] {
    height: 0;
    opacity: 0;
    border: none;
  }
  .input-link {
    width: 100%;
    input[type="text"] {
      font-family: "Lato", sans-serif;
      font-size: 14px;
      color: ${theme.colors.mineShaft};
      background: transparent;
      padding: 0.7rem 1.2rem 0.7rem 1.2rem;
      border-radius: 10px;
      border: 1px solid ${theme.colors.mineShaft};
      transition: border-color 0.5s;
      width: 100%;
      &:focus {
        border: 1px solid ${theme.colors.azureRadiance};
        border-radius: 10px;
      }
      &:not(:focus) {
        & + p {
          color: #7a7a7a56;
        }
      }
    }
    > i {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 35px;
      border-radius: 10px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background: transparent;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: background-color 0.5s;
    }
    .icon-red {
      color: ${theme.colors.pastelRed};
      transition: color 0.5s;
      &:hover {
        color: #b20101;
      }
    }
  }
  .file-input-val {
    flex: 50%;
    width: 100%;
    top: 0;
    left: 0;
    font-size: 13px;
    line-height: 25px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    pointer-events: none;
    padding: 10px 20px;
    border: 1px solid ${theme.colors.mineShaft};
    font-size: 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    @media screen and (max-width: 768px) {
      font-size: 0.8rem;
      padding: 10px 15px;
    }
  }
  .file-input-button {
    flex: 40%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${theme.colors.azureRadiance};
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 1px solid ${theme.colors.azureRadiance};
    color: white;
    right: 0;
    top: 0;
    font-size: 11px;
    line-height: 25px;
    text-align: center;
    -webkit-transition: 500ms all;
    -moz-transition: 500ms all;
    transition: 500ms all;
    padding: 10px 20px;
    font-size: 1rem;
    &:hover {
      background-color: ${theme.colors.scienceBlue};
      border: 1px solid ${theme.colors.scienceBlue};
    }
    > i {
      font-size: 0.8rem;
    }
    @media screen and (max-width: 768px) {
      font-size: 0.8rem;
      padding: 10px 15px;
    }
    @media screen and (max-width: 380px) {
      > i {
        display: none;
      }
    }
  }
  > i {
    height: 100%;
    width: 35px;
    background: transparent;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.5s;
  }
  .icon-red {
    color: ${theme.colors.pastelRed};
    transition: color 0.5s;
    &:hover {
      color: #b20101;
    }
  }
`;

export function FileInput({
  error,
  helperText,
  label,
  deleteInputField,
  acceptedType,
  name,
  type,
  onChange,
  onBlur,
  value,
  extra,
}) {
  const [selectedFile, setSelectedFile] = useState();
  const [fileType, setFileType] = useState(value.externalUrl ? "link" : "file");
  const [unique, setUnique] = useState("");
  const [currentName, setCurrentName] = useState("");

  useEffect(() => {
    const randomValue = Math.random().toString(36).substring(7);
    setUnique(randomValue);
  }, []);

  const handleTouch = (e) => {
    onBlur(name, e.target.value, type);
  };

  const handleFileName = (e) => {
    // const val = document.getElementById("val");
    setCurrentName(e.target.value.replace(/C:\\fakepath\\/i, ""));
  };

  const handleFileTypeChange = (event) => {
    setFileType(event.target.value);
  };

  const handleLinkChange = (e) => {
    onChange(e, type);
  };

  const handleChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      handleFileName(e);
      setSelectedFile(file);
    } else {
      setSelectedFile(undefined);
    }
    onChange(e, type);
  };

  const deleteField = (e) => {
    deleteInputField(e, type, name);
  };

  const handleFileButtonClick = () => {
    const file = document.getElementById(`inputFile-${unique}`);
    file.click();
  };

  return (
    <div>
      <BoldLabel htmlFor={name}>{label}</BoldLabel>
      <Flex gap="15px">
        <Flex>
          <input
            id={`linkType-${unique}`}
            type="radio"
            name={`fileType-${unique}`}
            value="link"
            checked={fileType === "link"}
            onChange={handleFileTypeChange}
          />
          <label htmlFor={`linkType-${unique}`}>
            <Typography variant="xs" weight="regular" ml="2">
              Link
            </Typography>
          </label>
        </Flex>
        <Flex>
          <input
            id={`fileType-${unique}`}
            type="radio"
            name={`fileType-${unique}`}
            value="file"
            checked={fileType === "file"}
            onChange={handleFileTypeChange}
          />
          <label htmlFor={`fileType-${unique}`}>
            <Typography variant="xs" weight="regular" ml="2">
              Archivo
            </Typography>
          </label>
        </Flex>
      </Flex>
      {value && value.url && !selectedFile && fileType === "file" && (
        <div className="d-flex gap-1">
          <i className="bi bi-file-pdf"></i>
          <label>
            <Link target="_blank" href={value.url}>
              {value.filename}
            </Link>
          </label>
        </div>
      )}
      <SimpleInputContainer className="input-group">
        {fileType === "link" ? (
          <div className="input-link">
            <input
              key={`inputLink-${unique}`}
              placeholder={label}
              name={name}
              type="text"
              width="100%"
              value={value.externalUrl}
              onChange={handleLinkChange}
              onBlur={handleTouch}
            />
            {extra && (
              <i
                className="bi bi-trash icon-red"
                onClick={(e) => deleteField(e)}
              ></i>
            )}
          </div>
        ) : (
          <>
            <input
              id={`inputFile-${unique}`}
              style={{ display: "inline-block" }}
              placeholder={label}
              name={name}
              type="file"
              title="Sin archivo seleccionado"
              onChange={handleChange}
              onBlur={handleTouch}
              accept={acceptedType}
            />
            <span className="file-input-val">
              {currentName || "Sin archivo seleccionado"}
            </span>
            <span className="file-input-button" onClick={handleFileButtonClick}>
              Adjuntar Archivo
              <i className="bi bi-upload"></i>
            </span>
            {extra && (
              <i
                className="bi bi-trash icon-red"
                onClick={(e) => deleteField(e)}
              ></i>
            )}
          </>
        )}
      </SimpleInputContainer>
      {error && <span className="input-error-message">{helperText}</span>}
    </div>
  );
}
