export const medicalTerms = {
  cmp: "CMP",
  rne: "RNE",
};

export const ACCOUNT_TYPE_PERSONAL = "personal";

export const CURRENCY_SYMBOLS = {
  USD: '$',
  PEN: 'S/'
};