import Typography from "../atoms/Typography";
import styled from "@emotion/styled";
import { blue_main, danger } from "../atoms/variables";

const LegendContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
  @media screen and (max-width: 480px) {
    justify-content: flex-start;
  }
`;
const LegendText = styled(Typography)`
  margin-left: 6px;
`;

const LegendCircle = styled.div`
  width: 16px;
  height: 16px;
  background-color: ${(props) => props.color};
  border-radius: 20px;
`;
const LegendItemContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CardsLegend = () => {
  return (
    <LegendContainer>
      <LegendItemContainer>
        <LegendCircle color={danger}></LegendCircle>
        <LegendText variant="tiny">Pendientes de edición</LegendText>
      </LegendItemContainer>
      <div style={{ width: "24px" }}></div>
      <LegendItemContainer>
        <LegendCircle color={blue_main}></LegendCircle>
        <LegendText variant="tiny">Completadas</LegendText>
      </LegendItemContainer>
    </LegendContainer>
  );
};

export default CardsLegend;
