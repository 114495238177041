import PropTypes from "prop-types";
import { default as AlertMui } from "@mui/material/Alert";

const Alert = ({ children, type }) => {
  return <AlertMui severity={type}>{children}</AlertMui>;
};

Alert.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(["error", "warning", "info", "success"]),
};

export default Alert;
