import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";

import styled from "@emotion/styled";
import TabPanel from "../components/TabPanel";

import SwipeableViews from "react-swipeable-views";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { increaseCardQuantity } from "../../redux/store/business-cards/actions/cardQuantityActions";

import ImportExcelComponent from "../components/ImportExcelComponent";
import ManualAddUserForm from "../components/ManualAddUserForm";
import EmployeeService from "../services/EmployeeService";
import { defaultCardPhoto } from "../../common/atoms/variables";
import Typography from "../../common/atoms/Typography";
import theme from "../../common/theme";

const DialogStyled = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 1020px;
  }
`;

const IconButtonStyled = styled(IconButton)`
  &.MuiButtonBase-root {
    position: absolute;
    right: 12px;
    top: 6px;
    color: ${theme.colors.mineShaft};
  }
`;

function allyProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const StyledTab = styled(Tab)`
  &.MuiButtonBase-root {
    color: ${theme.colors.azureRadiance};
    &.Mui-selected {
      color: ${theme.colors.white};
      background-color: ${theme.colors.azureRadiance};
    }
  }
`;

const CustomDialogContent = styled(DialogContent)`
  padding: 0;
`;

const CustomDialogTitle = styled(DialogTitle)`
  padding: 0;
  background-color: ${theme.colors.gallery};
`;

export const AddUserForm = ({
  companyId,
  open,
  closeDialog,
  roleNames,
  setPageLoading,
  getEmployees,
  onPageChange,
  currentTab,
  setCurrentTab,
}) => {
  const handleTabChange = (_event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleChangeIndex = (index) => {
    setCurrentTab(index);
  };

  const [serverErrorMessage, setServerErrorMessage] = useState("");
  const [massiveServerError, setMassiveServerError] = useState("");
  const [successUserCreation, setSuccessUserCreation] = useState(false);
  const dispatch = useDispatch();

  const defaultPhoto = {
    url: defaultCardPhoto,
    filename: "no-avatar-img.jpg",
  };

  const refreshServerError = () => {
    setServerErrorMessage("");
  };

  const refreshUserCreationState = () => {
    setSuccessUserCreation(false);
  };

  const handleCloseDialog = () => {
    refreshServerError();
    closeDialog();
  };

  const saveEmployees = async (employeesArray, type) => {
    setPageLoading(true);
    const data = await EmployeeService.createManyEmployees(
      employeesArray,
      defaultPhoto
    );
    setPageLoading(false);
    if (data.message) {
      switch (type) {
        case "manual":
          setServerErrorMessage(data.message);
          break;
        case "massive":
          setMassiveServerError(data.message);
      }
      setSuccessUserCreation(false);
      return;
    }
    if (data.employees) {
      dispatch(increaseCardQuantity());
      closeDialog();
      getEmployees("", 0);
      onPageChange(0);
      setSuccessUserCreation(true);
    }
  };

  return (
    <DialogStyled open={open} maxWidth="sm" fullWidth scroll="paper">
      <CustomDialogTitle textAlign="center">
        <div style={{ padding: "12px" }}>
          <IconButtonStyled aria-label="close" onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButtonStyled>

          <Typography variant="small" weight="bold">
            Invitar a personas
          </Typography>
        </div>
        <AppBar
          position="static"
          sx={{ flexGrow: 1, margin: 0, background: "white" }}
        >
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            variant="fullWidth"
          >
            <StyledTab label="Modo Manual" {...allyProps(0)} />
            <StyledTab label="Modo Masivo" {...allyProps(1)} />
          </Tabs>
        </AppBar>
      </CustomDialogTitle>
      <CustomDialogContent>
        <SwipeableViews index={currentTab} onChangeIndex={handleChangeIndex}>
          <TabPanel value={currentTab} index={0}>
            <ManualAddUserForm
              open={open}
              companyId={companyId}
              closeDialog={handleCloseDialog}
              saveEmployees={saveEmployees}
              serverErrorMessage={serverErrorMessage}
              refreshServerError={refreshServerError}
              roleNames={roleNames}
              refreshUsersCreated={refreshUserCreationState}
              usersCreated={successUserCreation}
            />
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <ImportExcelComponent
              getEmployees={getEmployees}
              closeDialog={handleCloseDialog}
              refreshUsersCreated={refreshUserCreationState}
            />
          </TabPanel>
        </SwipeableViews>
      </CustomDialogContent>
    </DialogStyled>
  );
};
