import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { useEffect, useState, lazy, Suspense } from "react";
import { useFeature } from "flagged";
import { MonitoringService } from "../services/MonitoringService";
import TopUsersTable from "../components/TopUsersTable";
import {
  CATEGORIES,
  getFieldSchema,
} from "../../business-card-management/components/form.utils";
import TopCTA from "../components/TopCTA";
import TopSocialMedia from "../components/TopSocialMedia";
import { THIS_MONTH, THIS_WEEK, THIS_YEAR } from "../../common/atoms/variables";
import BaseFiltersSelector from "../components/BaseFiltersSelector";
import Flex from "../../common/atoms/Flex";
import CardContainer from "../../common/atoms/CardContainer";
import DniRatingTable from "../components/DniRatingTable";
import Loader from "../../common/atoms/Loader";
import DownloadReportButton from "../components/DownloadReportButton";

const ChartReact = lazy(() => import("../components/ChartReact"));

const renderLoader = () => <Loader />;

const GlobalStatisticsWrapper = styled.div`
  width: 100%;
  margin: 20px 0;
  & > div > div:last-child {
    padding: 5px;
  }
`;

const CompanyStatisticsContainer = styled.div`
  max-width: 1100px;
  width: 100%;
`;

const CompanyStatistics = () => {
  const currentCompany = useSelector((state) => state.currentCompany);
  const hasReferrals = useFeature("referrals");
  const [data, setData] = useState();
  const [generalContactData, setGeneralContactData] = useState([]);
  const [socialMediaData, setSocialMediaData] = useState([]);
  const [visitsRatingData, setVisitsRatingData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [socialMediaLoading, setSocialMediaLoading] = useState(false);
  const [generalContactLoading, setGeneralContactLoading] = useState(false);
  const [filter, setFilter] = useState(THIS_WEEK);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const getGeneralVisits = () => {
    if (currentCompany && currentCompany.id) {
      setLoading(true);
      MonitoringService.getGeneralVisitsByCompany(
        currentCompany.id,
        timezone,
        filter
      )
        .then((res) => {
          if (res.status === 200) {
            setData(res.data.visitsData);
          } else {
            console.log(res.data.message);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getSocialMediaEvents = () => {
    if (currentCompany && currentCompany.id) {
      setSocialMediaLoading(true);
      MonitoringService.getEventsByCategory(
        currentCompany.id,
        CATEGORIES.SOCIAL_NETWORKS,
        filter
      ).then((res) => {
        if (res.status === 200) {
          const events = res.data.events.map((item) => {
            const fieldSchema = getFieldSchema(item.category, item.key);
            return {
              ...item,
              placeholder: fieldSchema
                ? fieldSchema.placeholder || fieldSchema.valuePlaceholder
                : item.key,
            };
          });

          setSocialMediaData(events);
        } else {
          console.log(res.data.message);
        }
        setSocialMediaLoading(false);
      });
    }
  };

  const getGeneralContactEvents = () => {
    if (currentCompany && currentCompany.id) {
      setGeneralContactLoading(true);
      MonitoringService.getEventsByCategory(
        currentCompany.id,
        CATEGORIES.GENERAL_CONTACTS,
        filter
      ).then((res) => {
        if (res.status === 200) {
          const events = res.data.events.map((item) => {
            const fieldSchema = getFieldSchema(item.category, item.key);
            return {
              ...item,
              placeholder: fieldSchema
                ? fieldSchema.placeholder || fieldSchema.valuePlaceholder
                : item.key,
            };
          });
          setGeneralContactData(events);
        } else {
          console.log(res.data.message);
        }
        setGeneralContactLoading(false);
      });
    }
  };

  const getVisitsWithRatingInformation = () => {
    if (currentCompany && currentCompany.id) {
      setGeneralContactLoading(true);
      MonitoringService.getVisitsWithRatingSummary(currentCompany.id, filter)
        .then((res) => {
          if (res.status === 200 && res.data.visits) {
            setVisitsRatingData(res.data.visits);
          } else {
            console.log(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const onChange = (selectedOption) => {
    setFilter(selectedOption);
  };

  useEffect(() => {
    getGeneralVisits();
    getSocialMediaEvents();
    getGeneralContactEvents();
    if (hasReferrals) {
      getVisitsWithRatingInformation();
    }
  }, [filter]);

  return (
    <CompanyStatisticsContainer>
      <Flex gap="10px">
        <BaseFiltersSelector onChange={onChange} />
        <DownloadReportButton companyId={currentCompany.id} filter={filter} />
      </Flex>
      <GlobalStatisticsWrapper>
        <CardContainer headerTitle="Estadísticas Globales">
          <Suspense fallback={renderLoader()}>
            <ChartReact data={data} filter={filter} loading={loading} />
          </Suspense>
        </CardContainer>
      </GlobalStatisticsWrapper>
      <TopUsersTable filter={filter}></TopUsersTable>
      {hasReferrals && (
        <DniRatingTable
          filter={filter}
          type="general"
          data={visitsRatingData}
          tableTitle="Top de referidos"
        />
      )}
      <Flex gap="25px" flexWrap="wrap">
        <TopCTA
          loading={generalContactLoading}
          data={generalContactData}
        ></TopCTA>
        <TopSocialMedia
          loading={socialMediaLoading}
          data={socialMediaData}
        ></TopSocialMedia>
      </Flex>
    </CompanyStatisticsContainer>
  );
};

export default CompanyStatistics;
