import { useCallback, useState } from "react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import { debounce } from "../../common/helpers/functions";
import TextSearch from "../../common/atoms/TextSearch";
import { ExportExcelIcon } from "../../common/atoms/SvgIcons";
import GlobalCollectionTable from "../components/GlobalCollectionTable";
import { RatingService } from "../../business-card-management/services/RatingService";
import { downloadResponseBase64File } from "../../common/helpers/files.utils";
import ReferralsMultiSelect from "../components/ReferralsMultiSelect";
import Button from "../../common/atoms/Button";

const PageLayout = styled.div`
  width: 100%;
  @media (max-width: 480px) {
    padding: 0;
  }
`;

const MainContainer = styled.div`
  flex-wrap: wrap;
  gap: 30px;
`;

const HeaderContainer = styled.div`
  display: flex;
`;

const ExportExcelButtonContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  min-width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const TableContainer = styled.div`
  width: 100%;
  margin-top: 30px;
`;

const InputDate = styled.input`
  border-radius: 10px;
  border: none;
  padding: 5px 12px;
  min-width: 140px;
  flex-grow: 1;
  min-height: 50px;
`;

const FilterButton = styled(Button)`
  min-width: 120px;
  flex-grow: 1;
`;

const FiltersContainer = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
  gap: 20px;
  margin: 12px 0px;
`;
const initialDateObj = { startDate: "", endDate: "" };

export default function GlobalCollection() {
  const [search, setSearch] = useState("");
  const [selectedReferralCards, setSelectedReferralCards] = useState([]);
  const [referralCardsToFilter, setReferralCardsToFilter] = useState([]);
  const [dateSelected, setDateSelected] = useState(initialDateObj);
  const [dateToFilter, setDateToFilter] = useState(initialDateObj);
  const currentCompany = useSelector((state) => state.currentCompany);

  const handleSearch = (event) => {
    const value = event.nativeEvent.target.value;
    setSearch(value);
  };

  const handleDateChange = (event) => {
    const { name, value } = event.target;
    if (!name) return;

    setDateSelected((prevState) => ({
      ...prevState,
      [name]: value || "",
    }));
  };

  const handleDownload = () => {
    if (!currentCompany) return;
    RatingService.getGeneralCollectionByCompanyReport(
      currentCompany.id,
      search,
      referralCardsToFilter,
      dateSelected.startDate,
      dateSelected.endDate
    ).then((response) => {
      downloadResponseBase64File(response);
    });
  };

  const filterByReferrals = () => {
    setReferralCardsToFilter(
      selectedReferralCards.map((referralCard) => referralCard.value)
    );
    setDateToFilter(dateSelected);
  };

  const debouncedChangeHandler = useCallback(debounce(handleSearch, 300), []);
  return (
    <PageLayout>
      <MainContainer>
        <HeaderContainer>
          <TextSearch onSearch={debouncedChangeHandler} />
          <ExportExcelButtonContainer onClick={handleDownload}>
            <ExportExcelIcon />
          </ExportExcelButtonContainer>
        </HeaderContainer>
        <FiltersContainer>
          <ReferralsMultiSelect
            companyId={currentCompany.id}
            setSelectedReferralCards={setSelectedReferralCards}
          />
          <InputDate name="startDate" type="date" onChange={handleDateChange} />
          <InputDate name="endDate" type="date" onChange={handleDateChange} />
          <FilterButton
            size="small"
            variant="primary"
            onClick={filterByReferrals}
          >
            <FilterAltIcon />
            Filtrar
          </FilterButton>
        </FiltersContainer>
        <TableContainer>
          <GlobalCollectionTable
            search={search}
            referralCardsToFilter={referralCardsToFilter}
            dateToFilter={dateToFilter}
          />
        </TableContainer>
      </MainContainer>
    </PageLayout>
  );
}
