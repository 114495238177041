import styled from "@emotion/styled";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initCardQuantity } from "../redux/store/business-cards/actions/cardQuantityActions";
import {
  PlanTypeCard,
  UpdatePlanCard,
  WelcomeCard,
  FirstStepsCard,
} from "./ui/SidebarCards";

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 20%;
  min-width: 220px;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Sidebar = ({ user }) => {
  const { currentCompany, cardQuantity } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentCompany.id && user.role.startsWith("ADMIN")) {
      const companyId = currentCompany.id;
      dispatch(initCardQuantity(companyId));
    }
  }, [currentCompany]);

  return (
    <>
      <SidebarContainer>
        <WelcomeCard />
        {user.accountType === "business" && (
          <PlanTypeCard cardsQuantity={cardQuantity} />
        )}
        {location.pathname === "/company/configuration" && <UpdatePlanCard />}
        {!user.unpaid && <FirstStepsCard />}
      </SidebarContainer>
    </>
  );
};
