import styled from "@emotion/styled";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { useState } from "react";
import PropTypes from "prop-types";
import Typography from "../../common/atoms/Typography";
import theme from "../../common/theme";

const ErrorText = styled(FormHelperText)`
  color: #d32f2f;
  font-size: 13px;
  margin-left: 12px;
`;

const SelectRenderValue = styled.p`
  margin-bottom: 0px;
`;

const StyledRoleItem = styled(MenuItem)`
  &.MuiButtonBase-root {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    border: 1px solid ${theme.colors.gallery};
    padding: 15px 25px;
    p {
      white-space: normal;
    }

    @media (max-width: 768px) {
      width: auto;
    }
  }
`;

const selectorRoles = {
  ["ADMIN"]: {
    name: "ADMIN",
    text: "Administrador",
    description:
      "Acceso global del negocio. Puede editar cada una de las tarjetas, así como ver las estadísticas globales e individuales del personal.",
  },
  ["EDITOR"]: {
    name: "EDITOR",
    text: "Editor independiente",
    description:
      "Puede acceder a su cuenta HolaLink y puede editar los datos de contacto de su tarjeta.",
  },
  ["EMPLOYEE"]: {
    name: "EMPLOYEE",
    text: "Acceso de empleado",
    description:
      "Puede acceder a su cuenta HolaLink pero no puede editar los datos de contacto de su tarjeta.",
  },
};

const roleKeys = Object.keys(selectorRoles);

const RoleSelector = ({
  hideMainLabel,
  currentValue,
  customError,
  onChange,
}) => {
  const [selectorError, setSelectorError] = useState("");

  const handleSelection = (event) => {
    if (selectorError !== "") {
      setSelectorError("");
    }

    const value = event.target.value;
    onChange(value);
  };
  return (
    <>
      {!hideMainLabel && (
        <Typography variant="small" weight="bold">
          Asignar Rol
        </Typography>
      )}
      <FormControl
        fullWidth
        sx={{ marginTop: "10px", maxWidth: "100%" }}
        error={selectorError !== ""}
      >
        <InputLabel id="demo-multiple-name-label">Seleccionar rol</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          value={currentValue}
          onChange={handleSelection}
          input={<OutlinedInput label="Name-Selector" />}
          renderValue={(selected) => (
            <SelectRenderValue>
              {selectorRoles[selected].text}
            </SelectRenderValue>
          )}
          native={false}
        >
          {roleKeys.map((roleName) => {
            const role = selectorRoles[roleName];
            return (
              <StyledRoleItem dense key={role.name} value={role.name}>
                <Typography variant="small" weight="bold" mb="2">
                  {role.text}
                </Typography>
                <Typography variant="tiny" weight="light" mb="2">
                  {role.description}
                </Typography>
              </StyledRoleItem>
            );
          })}
        </Select>
      </FormControl>
      {selectorError ||
        (customError && <ErrorText>{selectorError || customError}</ErrorText>)}
    </>
  );
};

RoleSelector.propTypes = {
  onChange: PropTypes.func,
  hideMainLabel: PropTypes.bool,
  currentValue: PropTypes.string,
  customError: PropTypes.string,
};

export default RoleSelector;
