import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import "../assets/css/styles.css";
import Button from "../../common/atoms/Button";
import PageLoader from "../../common/atoms/PageLoader";
import UsersTable from "../components/UsersTable";

import EmployeeService from "../services/EmployeeService";

import { AddUserForm } from "./AddUserForm";
import TextSearch from "../../common/atoms/TextSearch";
import EditManyUsers from "./EditManyUsers";
import { EmployeeContext } from "../context/EmployeesContext";
import SelectorWithButton from "../../common/molecules/SelectorWithButton";
import Alert from "../../common/atoms/Alert";
import { ImportExcelIcon } from "../../common/atoms/SvgIcons";
import { ROLE_NAMES } from "../../common/helpers/roles.utils";
import { debounce } from "../../common/helpers/functions";

const FilterContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

const AddAndSearchContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;

const BatchActionsContainer = styled.div`
  display: flex;
  justify-content: start;
  flex-grow: 1;
  @media (min-width: 1092px) {
    max-width: 300px;
  }
  @media (max-width: 480px) {
    p {
      font-size: 14px;
    }
  }
`;

const AddUserButton = styled(Button)`
  display: block;
  width: 150px;
  height: 50px;
  @media (max-width: 425px) {
    display: none;
  }
`;

const AddUserButtonMobile = styled(AddUserButton)`
  display: none;
  @media (max-width: 425px) {
    display: block;
  }
`;

const ImportExcelButtonContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  margin-right: 15px;
  min-width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export function UsersManagementPage() {
  const currentCompany = useSelector((state) => state.currentCompany);
  const [employeesData, setEmployeesData] = useState({
    data: [],
    rowCount: 0,
  });
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [openCreation, setOpenCreation] = useState(false);
  const [openRoleEdition, setOpenRoleEdition] = useState(false);
  const [usersPage, setUsersPage] = useState(0);
  const [search, setSearch] = useState("");
  const [currentTab, setCurrentTab] = useState(0);

  const { employeesToEditRole, setSelectedRows, setEmployeesToEditRole } =
    useContext(EmployeeContext);
  const [batchAction, setBatchAction] = useState("");
  const [infoMessage, setInfoMessage] = useState("");

  const getEmployees = async (search, page) => {
    setLoading(true);
    EmployeeService.getEmployeesByCompany(currentCompany.id, search, page)
      .then((response) => {
        if (response) {
          let formattedData = response.data.map((employee, index) => {
            let newEmployee = { ...employee };
            newEmployee.role = ROLE_NAMES[employee.role];
            newEmployee.roleCode = employee.role;
            return newEmployee;
          });
          response.data = formattedData;
          setEmployeesData(response);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSearch = (event) => {
    const value = event.nativeEvent.target.value;
    setUsersPage(0);
    setSearch(value);
  };

  const debouncedChangeHandler = useCallback(debounce(handleSearch, 300), []);

  const handlePageChange = (newPage) => {
    setUsersPage(newPage);
  };

  useEffect(() => {
    getEmployees();
  }, []);

  useEffect(() => {
    getEmployees(search, usersPage);
  }, [search, usersPage]);

  const openDialogFromAddButton = () => {
    setCurrentTab(0);
    openDialog();
  };

  const openDialogFromImportIcon = () => {
    setCurrentTab(1);
    openDialog();
  };

  const openDialog = () => {
    setOpenCreation(true);
  };

  const closeDialog = () => {
    setOpenCreation(false);
  };

  const closeRoleEditionByCompletion = async () => {
    setSelectedRows([]);
    setEmployeesToEditRole({});
    closeRoleEditionDialog();
  };

  const closeRoleEditionDialog = () => {
    setOpenRoleEdition(false);
  };

  const openRoleEditionDialog = () => {
    if (batchAction === "") {
      setInfoMessage("Debe seleccionar una acción.");
      return;
    }
    if (Object.keys(employeesToEditRole).length <= 0) {
      setInfoMessage(
        "Debe seleccionar al menos a un empleado para poder realizar esta acción."
      );
      return;
    }
    setInfoMessage("");
    setOpenRoleEdition(true);
  };

  const options = {
    ["EDIT"]: "Editar",
    ["DELETE"]: "Borrar",
  };

  return (
    <>
      {pageLoading && <PageLoader />}
      <div>
        <FilterContainer>
          <BatchActionsContainer>
            <SelectorWithButton
              options={options}
              setParentValue={setBatchAction}
              buttonAction={openRoleEditionDialog}
              textDescription="Acciones en lote"
              textDescriptionMobile="Acciones"
            />
          </BatchActionsContainer>

          <AddAndSearchContainer>
            <div style={{ marginRight: "8px", width: "100%" }}>
              <TextSearch onSearch={debouncedChangeHandler} />
            </div>
            <ImportExcelButtonContainer onClick={openDialogFromImportIcon}>
              <ImportExcelIcon />
            </ImportExcelButtonContainer>
            <AddUserButton
              variant="primary"
              size="medium"
              onClick={openDialogFromAddButton}
            >
              Añadir
            </AddUserButton>
            <AddUserButtonMobile
              variant="primary"
              onClick={openDialogFromAddButton}
            >
              <PersonAddIcon />
            </AddUserButtonMobile>
          </AddAndSearchContainer>
        </FilterContainer>

        <div>
          <div style={{ height: "12px" }}></div>
          {infoMessage !== "" && <Alert type="info">{infoMessage}</Alert>}
          <UsersTable
            data={employeesData}
            loading={loading}
            page={usersPage}
            onPageChange={handlePageChange}
            handleGetEmployees={getEmployees}
            setPageLoading={setPageLoading}
          />
        </div>
        <AddUserForm
          companyId={currentCompany.id}
          open={openCreation}
          closeDialog={closeDialog}
          setPageLoading={setPageLoading}
          roleNames={ROLE_NAMES}
          onPageChange={handlePageChange}
          getEmployees={getEmployees}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
        <EditManyUsers
          open={openRoleEdition}
          closeDialogByCompletion={closeRoleEditionByCompletion}
          closeDialog={closeRoleEditionDialog}
          getEmployees={() => {
            getEmployees("", usersPage);
          }}
          onPageChange={handlePageChange}
          setLoading={setPageLoading}
          batchAction={batchAction}
        />
      </div>
    </>
  );
}
