import styled from "@emotion/styled";
import PropTypes from "prop-types";
import { CircularProgress } from "@mui/material";
import CardContainer from "../../common/atoms/CardContainer";
import Typography from "../../common/atoms/Typography";

const ContactCard = styled.div`
  min-width: 220px;
  @media (max-width: 612px) {
    width: 100%;
  }
`;

const MyRating = ({ loading, value }) => {
  return (
    <ContactCard>
      <CardContainer headerTitle="Mi Calificación" headerTextAlign="center">
        <div>
          {loading && <CircularProgress />}
          {!loading && (
            <Typography variant="h4" weight="bold" my="1">
              {value || 0}
            </Typography>
          )}
        </div>
      </CardContainer>
    </ContactCard>
  );
};

MyRating.propTypes = {
  loading: PropTypes.bool,
  value: PropTypes.string,
};

export default MyRating;
