import { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import Flex from "../../../common/atoms/Flex";
import { ImageInput } from "../inputs/ImageInput";

const PhotoSection = forwardRef(
  (
    { initialData, templateId, readOnly, mode, onChange, onTouch, onError },
    ref
  ) => {
    const [formData, setFormData] = useState({
      photo: {
        filename: initialData?.filename,
        url: initialData?.url,
        value: initialData?.value,
      },
    });
    const { filename, url, value } = initialData;
    const [cropType, setCropType] = useState("rect");
    const [errors, setErrors] = useState({});

    useEffect(() => {
      if (templateId) {
        switch (templateId.toString()) {
          case "1":
            setCropType("rect");
            break;
          case "2":
            setCropType("round");
            break;
          case "3":
            setCropType("rect");
            break;
        }
      }
    }, [templateId]);

    const validate = (v, url) => {
      if (!v) {
        return !url ? "La foto es requerida" : null;
      } else {
        return !v.type.startsWith("image")
          ? "El archivo debe ser una imagen"
          : null;
      }
    };

    const handleImageChange = (type, name, image, url) => {
      const data = {
        url,
        value: image,
        filename: `${new Date().getTime()}${image.name}`,
      };
      setFormData({ photo: data });
      validateInput(name, data);
      onChange(data);
    };

    const handleFileTouched = (name, value, type) => {
      const error = validate(value, url);
      setErrors({
        [name]: error,
      });
    };

    const validateInput = (name, value) => {
      const error = validate(value?.value, value.url);
      setErrors({
        [name]: error,
      });
      return error;
    };

    const validateAll = () => {
      let isValid = false;
      Object.keys(formData).forEach((name) => {
        const error = validateInput(name, formData[name]);
        isValid = !error;
      });
      return isValid;
    };

    useEffect(() => {
      onError(errors);
    }, [errors]);

    useImperativeHandle(ref, () => ({
      validateAll,
    }));

    return (
      <Flex justifyContent="center" alignItems="center" my="20px">
        <ImageInput
          field={formData.photo}
          error={errors.photo}
          helperText={errors.photo}
          handleImage={handleImageChange}
          handleTouched={handleFileTouched}
          formMode={mode}
          name="photo"
          cropType={cropType}
        />
      </Flex>
    );
  }
);

export default PhotoSection;
