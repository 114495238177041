import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import PageLoader from "../../common/atoms/PageLoader";
import { useEffect } from "react";
import AuthService from "../services/AuthService";
import { useDispatch } from "react-redux";
import { setAuthUser } from "../../redux/store/accounts/actions/authActions";
import { clearStore } from "../../redux/store/common/actions/commonActions";

export function Redirect() {
  const { token } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!token || !searchParams.get("path")) {
      navigate("/login");
      return;
    }
    dispatch(setAuthUser({ employee: null, token }));
    AuthService.getUser().then(
      (response) => {
        dispatch(setAuthUser({ employee: response.data, token }));
        navigate(searchParams.get("path"));
      },
      (err) => {
        dispatch(clearStore());
        localStorage.clear();
        navigate("/login");
      }
    );
  }, []);

  return <>{/* <PageLoader /> */}</>;
}
