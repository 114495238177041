import styled from "@emotion/styled";
import { Dialog, LinearProgress, TextField } from "@mui/material";
import { useState } from "react";
import Alert from "../atoms/Alert";
import Button from "../atoms/Button";
import CardContainer from "../atoms/CardContainer";

const Content = styled.div`
  padding: 10px 24px;
  position: relative;
`;
const Description = styled.p`
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 16px;
`;
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0px 8px 0px;
  gap: 8px;
`;

const ButtonContent = styled.p`
  margin-bottom: 0px;
`;

const LoadingEdition = styled(LinearProgress)``;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: 10px;
  }
`;

const EditFieldDialog = ({
  fieldName,
  fieldType,
  initialValue,
  fieldLabel,
  headerTitle,
  validationFunction,
  descriptionText,
  open,
  onClose,
  save,
  serverErrorMessage = "",
}) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    const value = e.target.value;
    let message = validationFunction(value);
    setErrorMessage(message);
    setValue(value);
  };

  const handleSave = async () => {
    if (errorMessage !== "") {
      return;
    }
    setLoading(true);
    await save(fieldName, value);

    setLoading(false);
  };

  const closeDialog = () => {
    onClose(fieldName);
  };

  return (
    <StyledDialog open={open} maxWidth="xs" fullWidth>
      <CardContainer noGutters headerTitle={headerTitle}>
        <Content>
          <Description>{descriptionText}</Description>
          <TextField
            autoFocus={true}
            fullWidth
            variant="standard"
            value={value}
            name={fieldName}
            type={fieldType}
            label={fieldLabel}
            error={errorMessage !== ""}
            helperText={errorMessage !== "" && errorMessage}
            onChange={handleChange}
          />
          <ButtonsContainer>
            <Button size="small" variant="cancel" onClick={closeDialog}>
              <ButtonContent>Cancelar</ButtonContent>
            </Button>
            <Button size="small" variant="primary" onClick={handleSave}>
              <ButtonContent>Confirmar</ButtonContent>
            </Button>
          </ButtonsContainer>
        </Content>
        {serverErrorMessage !== "" && (
          <Alert type="error">{serverErrorMessage}</Alert>
        )}
        {loading && <LoadingEdition />}
      </CardContainer>
    </StyledDialog>
  );
};

export default EditFieldDialog;
