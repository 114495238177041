import {
  SET_ACCOUNTS,
  CLEAR_ACCOUNTS,
} from "../action-types/linkedAccountsActionTypes";
const initialState = [];

const linkedAccountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCOUNTS:
      const accounts = action.payload;
      return accounts;
    case CLEAR_ACCOUNTS:
      return initialState;
    default:
      return state;
  }
};

export default linkedAccountsReducer;
