import { takeLatest } from "redux-saga/effects";
import currentCompanyActionTypes from "./action-types/currentCompanyActionTypes";
import {
  handleInitCurrentCompany,
  handleUpdateCurrentCompany,
} from "./handlers/currentCompanyHandler";

const { UPDATE_CURRENT_COMPANY, INIT_CURRENT_COMPANY } =
  currentCompanyActionTypes;

function* watchUpdateCurrentCompany() {
  yield takeLatest(UPDATE_CURRENT_COMPANY, handleUpdateCurrentCompany);
}

function* watchInitCurrentCompany() {
  yield takeLatest(INIT_CURRENT_COMPANY, handleInitCurrentCompany);
}

export default [watchUpdateCurrentCompany, watchInitCurrentCompany];
