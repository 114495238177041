import currentCompanyActionTypes from "../action-types/currentCompanyActionTypes";
const { SET_CURRENT_COMPANY, CLEAR_CURRENT_COMPANY } =
  currentCompanyActionTypes;

const initialState = {
  id: "",
  name: "",
  mainEmail: "",
  phone: "",
  address: "",
  businessPathKey: "",
  logoUrl: "",
  urlUpdateCount: "",
  transferEventCount: "",
};

const currentCompanyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_COMPANY:
      return action.payload;
    case CLEAR_CURRENT_COMPANY:
      return initialState;
    default:
      return state;
  }
};

export default currentCompanyReducer;
