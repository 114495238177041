import React from "react";
import Button from "../../common/atoms/Button";
import PersonIcon from "@mui/icons-material/Person";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import { Alert } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

const AccountSelector = () => {
  const navigate = useNavigate();
  return (
    <>
      <span className="d-flex justify-content-center align-items-center gap-1 mb-3">
        Selecciona tu tipo de cuenta
      </span>
      <Button
        className="d-flex justify-content-center align-items-center gap-1 mb-4"
        variant="primary"
        fullWidth
        size="extraLarge"
        onClick={() => navigate("/register")}
      >
        Personal
        <PersonIcon />
      </Button>
      <Button
        onClick={() =>
          window.open("https://hola.link/solicitar-demo/", "_blank")
        }
        className="d-flex justify-content-center align-items-center gap-1 mb-4"
        variant="primary"
        fullWidth
        size="extraLarge"
      >
        Corporativa
        <CorporateFareIcon />
      </Button>
      <Alert severity="info">
        <span className="d-flex justify-content-center align-items-center gap-1">
          La cuenta corporativa es para representantes de empresas que tengan la
          autorización de registrar su empresa en la plataforma.
        </span>
      </Alert>
      <span className="d-flex justify-content-center align-items-center gap-1 mt-3">
        ¿Ya tienes una cuenta? <Link to="/login">Inicia sesión</Link>
      </span>
    </>
  );
};

export default AccountSelector;
