import styled from "@emotion/styled";
import TextSearch from "../../common/atoms/TextSearch";
import { ExportExcelIcon } from "../../common/atoms/SvgIcons";
import PersonalCollectionTable from "../components/PersonalCollectionTable";
import { useCallback, useEffect, useState } from "react";
import { debounce } from "../../common/helpers/functions";
import { RatingService } from "../../business-card-management/services/RatingService";
import { downloadResponseBase64File } from "../../common/helpers/files.utils";
import { BusinessCardService } from "../../business-card-management/services/BusinessCardService";

const PageLayout = styled.div`
  width: 100%;
  @media (max-width: 480px) {
    padding: 0;
  }
`;

const MainContainer = styled.div`
  flex-wrap: wrap;
  gap: 30px;
`;

const HeaderContainer = styled.div`
  display: flex;
`;

const ExportExcelButtonContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  min-width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const TableContainer = styled.div`
  width: 100%;
  margin-top: 30px;
`;

export default function PersonalCollection() {
  const [search, setSearch] = useState("");
  const [businessCardId, setBusinessCardId] = useState(null);

  useEffect(() => {
    getOwnCards();
  }, []);

  const handleSearch = (event) => {
    const value = event.nativeEvent.target.value;
    setSearch(value);
  };
  const debouncedChangeHandler = useCallback(debounce(handleSearch, 300), []);

  const handleDownload = () => {
    if (!businessCardId) return;
    RatingService.getPersonalCollectionReport(businessCardId, search).then(
      (response) => {
        downloadResponseBase64File(response);
      }
    );
  };

  const getOwnCards = async () => {
    BusinessCardService.getOwnBusinessCards()
      .then((response) => {
        const businessCardList = response.data.businessCards || [];
        if (businessCardList.length > 0) {
          setBusinessCardId(businessCardList[0].id);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <PageLayout>
      <MainContainer>
        <HeaderContainer>
          <TextSearch onSearch={debouncedChangeHandler} />
          <ExportExcelButtonContainer onClick={handleDownload}>
            <ExportExcelIcon />
          </ExportExcelButtonContainer>
        </HeaderContainer>
        <TableContainer>
          <PersonalCollectionTable
            businessCardId={businessCardId}
            search={search}
          />
        </TableContainer>
      </MainContainer>
    </PageLayout>
  );
}
