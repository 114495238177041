import axiosInstance from "../../common/helpers/axios";

export const PaymentService = {
  getInfo: async (timezone) => {
    try {
      return await axiosInstance.get(`/payments/info`, {
        params: {
          timezone,
        },
      });
    } catch (error) {
      return error.response;
    }
  },
  getBusinessInfo: async (timezone) => {
    try {
      return await axiosInstance.get(`/payments/company-info`, {
        params: {
          timezone,
        },
      });
    } catch (error) {
      return error.response;
    }
  },
  getPayments: () => {
    return axiosInstance.get(`/payments`);
  },
  getBusinessPayments: () => {
    return axiosInstance.get(`/payments/company`);
  },
  createPreference: async (pricingId, quantity) => {
    try {
      return await axiosInstance.get("/payments/create-preference", {
        params: {
          pricingId,
          quantity,
        },
      });
    } catch (error) {
      return error.response;
    }
  },
  checkUnpaidStatus: async () => {
    try {
      return await axiosInstance.get("/payments/unpaid-status");
    } catch (error) {
      return error.response;
    }
  },
};
