import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import TemplateCardList from "../components/TemplateCardList";
import { BusinessCardsContext } from "../context/BusinessCardsContext";
import Button from "../../common/atoms/Button";
import BackButton from "../../common/atoms/BackButton";
import Typography from "../../common/atoms/Typography";
import { useSelector } from "react-redux";
import Flex from "../../common/atoms/Flex";

export default function TemplateSelection() {
  const navigate = useNavigate();
  const { currentTemplateId, setCurrentTemplateId, isOwnCard } =
    useContext(BusinessCardsContext);
  const currentCompany = useSelector((state) => state.currentCompany);
  const selectTemplate = (id) => {
    setCurrentTemplateId(id);
  };

  const goToCreationForm = () => {
    if (currentTemplateId) {
      if (isOwnCard) {
        navigate(`/cards/own-creation-form`);
      } else {
        navigate(`/cards/creation-form`);
      }
    }
  };

  return (
    <div className="container-fluid p-0 .step-1-create-card">
      <BackButton />
      <Typography
        variant="h5"
        weight="bold"
        textAlign="center"
        mt="24px"
        mb="40px"
      >
        Selecciona tu tarjeta
      </Typography>
      <TemplateCardList
        defaultSelected={currentTemplateId}
        selectTemplate={selectTemplate}
        colors={currentCompany.colors}
      ></TemplateCardList>
      <Flex m="4" justifyContent="center">
        <Button variant="primary" size="medium" onClick={goToCreationForm}>
          Seleccionar
        </Button>
      </Flex>
    </div>
  );
}
