import { takeLatest } from "redux-saga/effects";
import { UPDATE_LOGGED_USER } from "./action-types/authActionTypes";
import {
  CHANGE_ACCOUNT,
  INIT_ACCOUNTS,
} from "./action-types/linkedAccountsActionTypes";
import { updateLoggedUserHandler } from "./handlers/authHandler";
import {
  changeAccountHandler,
  initAccountsHandler,
} from "./handlers/linkedAccountsHandlers";

function* handleInitAccounts() {
  yield takeLatest(INIT_ACCOUNTS, initAccountsHandler);
}

function* handleChangeAccount() {
  yield takeLatest(CHANGE_ACCOUNT, changeAccountHandler);
}

function* handleUpdateLoggedUser() {
  yield takeLatest(UPDATE_LOGGED_USER, updateLoggedUserHandler);
}

export default [
  handleInitAccounts,
  handleChangeAccount,
  handleUpdateLoggedUser,
];
