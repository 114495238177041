import styled from "@emotion/styled";
import { toast } from "react-toastify";
import CopyToClipboard from "react-copy-to-clipboard";
import UrlBackground from "../../common/atoms/UrlBackground";
import Button from "../../common/atoms/Button";

const UrlContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 0px;
  width: 100%;
`;

const CardUrlSection = ({ baseUrl, cardPathKey, disabled }) => {
  const handleCopyText = () => {
    if (disabled) {
      return;
    }
    toast.success(`Enlace copiado`, {
      position: "bottom-center",
      autoClose: 2500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  return (
    <UrlContainer>
      <UrlBackground
        url={baseUrl.replace(/^https?\:\/\//i, "") + cardPathKey}
      />
      <CopyToClipboard text={baseUrl + cardPathKey} onCopy={handleCopyText}>
        <Button variant="secondary" size="small">
          Copiar enlace <i className="bi bi-clipboard"></i>
        </Button>
      </CopyToClipboard>
    </UrlContainer>
  );
};

export default CardUrlSection;
