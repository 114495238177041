import styled from "@emotion/styled";
import { QRCode } from "react-qrcode-logo";
import * as htmlToImage from "html-to-image";

import DashedButton from "../../common/atoms/DashedButton";
import { clearString } from "../../common/helpers/strings.utils";

const DEFAULT_COLOR = "#2b2b2b";
const HiddenContainer = styled.div`
  height: 1px;
  width: 1px;
  overflow: hidden;
`;

const Container = styled.div`
  display: flex;
  border-radius: ${(props) => (props.scaled ? "75px" : "15px")};
  border-width: ${(props) => (props.scaled ? "50px" : "10px")};
  border-style: solid;
  border-color: ${(props) =>
    (props.colors && props.colors.primary) || DEFAULT_COLOR};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: ${(props) => (props.scaled ? "60px" : "12px")};
  background-color: ${(props) =>
    (props.colors && props.colors.primary) || DEFAULT_COLOR};
  max-width: ${(props) => (props.scaled ? "1125px" : "225px")};
  width: ${(props) => props.scaled && "1045px"};
  canvas {
    border-radius: ${(props) => (props.scaled ? "50px" : "10px")};
    @media (max-width: 425px) {
      width: ${(props) => !props.scaled && "168px !important"};
      height: ${(props) => !props.scaled && "auto !important"};
    }
  }
`;

const Header = styled.div`
  margin-bottom: ${(props) => (props.scaled ? "35px" : "7px")};
  display: flex;
  flex-direction: column;
`;
const Title = styled.span`
  color: white;
  font-weight: bold;
  font-family: Lato;
  word-break: break-all;
  font-size: ${(props) => (props.scaled ? "80px" : "16px")};
  line-height: ${(props) => (props.scaled ? "90px" : "18px")};
`;

const Subtitle = styled.span`
  color: white;
  font-family: Lato;
  font-size: ${(props) => (props.scaled ? "60px" : "12px")};
  word-break: break-all;
  line-height: ${(props) => (props.scaled ? "80px" : "16px")};
`;

const Description = styled.span`
  color: white;
  font-family: Lato;
  font-size: ${(props) => (props.scaled ? "60px" : "12px")};
  font-style: italic;
  word-break: break-all;
  line-height: ${(props) => (props.scaled ? "80px" : "16px")};
`;

const Footer = styled.span`
  color: white;
  font-weight: 400;
  font-size: ${(props) => (props.scaled ? "55px" : "11px")};
  margin-top: ${(props) => (props.scaled ? "30px" : "6px")};
  a {
    color: white;
  }
`;

const QRDisplay = ({
  baseUrl,
  cardPathKey,
  logoImage,
  title,
  subtitle,
  description,
  disabled,
  colors,
}) => {
  const url = baseUrl + cardPathKey;
  const qrImageName =
    "HolaLink-BusinessCard-" +
    clearString(cardPathKey.replace("/", "")) +
    ".jpeg";

  const handleQrDownload = () => {
    if (disabled) {
      return;
    }
    const element = document.querySelector("#qr-image-container-scaled");
    htmlToImage.toPng(element).then((dataURL) => {
      htmlToImage.toPng(element).then((dataURL) => {
        htmlToImage.toPng(element).then((dataURL) => {
          const link = document.createElement("a");
          link.download = qrImageName;
          link.href = dataURL;
          link.click();
        });
      });
    });
  };

  return (
    <>
      <Container id="qr-image-container" scaled={false} colors={colors}>
        <Header scaled={false}>
          <Title scaled={false}>{title}</Title>
          <Subtitle scaled={false}>{subtitle}</Subtitle>
          <Description scaled={false}>{description}</Description>
        </Header>
        <QRCode
          value={url}
          enableCORS={true}
          removeQrCodeBehindLogo={true}
          qrStyle="dots"
          fgColor={DEFAULT_COLOR}
          eyeRadius={[
            {
              outer: 8,
              inner: 2,
            },
            {
              outer: 8,
              inner: 2,
            },
            {
              outer: 8,
              inner: 2,
            },
          ]}
          quietZone={10}
          size={185}
          bgColor="white"
          logoImage={logoImage}
          logoWidth={45}
          ecLevel="Q"
        />
        <Footer scaled={false}>
          tarjeta generada por{" "}
          <strong>
            <a href="https://hola.link" target="_blank">
              Hola.link
            </a>
          </strong>
        </Footer>
      </Container>
      <HiddenContainer>
        <Container id="qr-image-container-scaled" scaled={true} colors={colors}>
          <Header scaled={true}>
            <Title scaled={true}>{title}</Title>
            <Subtitle scaled={true}>{subtitle}</Subtitle>
            <Description scaled={true}>{description}</Description>
          </Header>
          <QRCode
            value={url}
            enableCORS={true}
            removeQrCodeBehindLogo={true}
            qrStyle="dots"
            fgColor={DEFAULT_COLOR}
            eyeRadius={[
              {
                outer: 40,
                inner: 10,
              },
              {
                outer: 40,
                inner: 10,
              },
              {
                outer: 40,
                inner: 10,
              },
            ]}
            quietZone={50}
            size={885}
            bgColor="white"
            logoImage={logoImage}
            logoWidth={225}
            ecLevel="Q"
          />
          <Footer scaled={true}>
            tarjeta generada por{" "}
            <strong>
              <a href="https://hola.link" target="_blank">
                Hola.link
              </a>
            </strong>
          </Footer>
        </Container>
      </HiddenContainer>
      <DashedButton
        disabled={disabled}
        text={
          <p
            style={{
              marginBottom: "0px",
              padding: "0px 0px",
              fontSize: "14px",
            }}
          >
            Descargar imagen QR
          </p>
        }
        onClick={handleQrDownload}
      />
    </>
  );
};

export default QRDisplay;
