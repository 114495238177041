import styled from "@emotion/styled";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import theme from "../../common/theme";

const ShareButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const IconContainer = styled.div`
  margin: 12px 8px;
  width: 43px;
  height: 43px;
  background: ${theme.colors.azureRadiance};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  transform: scale(1);
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.2);
  }
`;

const returnIconButton = (icon, disabled) => (
  <IconContainer disabled={disabled}>
    <i className={`bi bi-${icon}`} style={{ fontSize: "22px" }}></i>
  </IconContainer>
);

export const ShareSocialNetworksButtons = ({ url, disabled }) => {
  return (
    <ShareButtons>
      <FacebookShareButton
        url={url}
        quote={"Hola!!! Esta es mi tarjeta de HolaLink."}
        hashtag={"#HolaLink"}
        disabled={disabled}
      >
        {returnIconButton("facebook", disabled)}
      </FacebookShareButton>
      <TwitterShareButton
        url={url}
        title={"Hola!!! Esta es mi tarjeta de HolaLink."}
        hashtags={["HolaLink"]}
        source={"HolaLink"}
        disabled={disabled}
      >
        {returnIconButton("twitter", disabled)}
      </TwitterShareButton>
      <LinkedinShareButton
        url={url}
        title={"Tarjeta de HolaLink"}
        summary={"Hola!!! Esta es mi tarjeta de HolaLink."}
        source={"HolaLink"}
        disabled={disabled}
      >
        {returnIconButton("linkedin", disabled)}
      </LinkedinShareButton>
      <EmailShareButton
        url={url}
        subject={"Mi tarjeta de HolaLink"}
        body={"Hola!!! Esta es mi tarjeta de HolaLink."}
        disabled={disabled}
      >
        {returnIconButton("envelope-fill", disabled)}
      </EmailShareButton>
      <WhatsappShareButton
        url={url}
        title={"Hola!!! Esta es mi tarjeta de HolaLink."}
        disabled={disabled}
      >
        {returnIconButton("whatsapp", disabled)}
      </WhatsappShareButton>
    </ShareButtons>
  );
};
