import React, { useState } from "react";
import { CustomDialog } from "../../common/atoms/Dialog";
import PlanCard from "./Plans";
import SelectedPackageCard from "./SelectedPackagePrice";
import styled from "@emotion/styled";
import { PaymentService } from "../services/PaymentService";
import { Wallet } from "@mercadopago/sdk-react";
import { ArrowBack } from "@mui/icons-material";
import { ACCOUNT_TYPE_PERSONAL } from "../../common/helpers/constants";
import Typography from "../../common/atoms/Typography";
import theme from "../../common/theme";

const PlansContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  min-width: 800px;
`;

const PlanCardContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const DividingLine = styled.div`
  min-height: 450px;
  margin: 0 20px;
  width: 1px;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.38);
`;

const BuyingInfoText = styled(Typography)`
  color: ${theme.colors.mineShaft};
  margin: 12px 12px;
`;

const BankAccountSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 12px;
`;

const BankAccountContainer = styled.div`
  max-width: 460px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const benefitsSemester = [
  "Acceso por 180 días a la plataforma",
  "Analíticas y seguimiento de tu tarjeta",
  "Acceso a plantillas de tarjeta",
  "Presentación de tu empresa/marca a través de un PDF o video empresarial",
];

const benefitsAnnual = [
  "Acceso por 12 meses (365 días) a la plataforma",
  "Analíticas y seguimiento de tu tarjeta",
  "Acceso a plantillas de tarjeta",
  "Presentación de tu empresa/marca a través de un PDF o video empresarial",
];

const businessBenefits = {
  mainBenefit: "Beneficio de vigencia adicional",
  infoList: ["13 meses", "14 meses", "15 meses"],
};

const UpgradePlanDialog = ({
  showDialog,
  closeDialog,
  pricingArray,
  accountType,
}) => {
  const [selectedPricing, setSelectedPricing] = useState(undefined);
  const [step, setStep] = useState(1);
  const [currentPreferenceId, setCurrentPreferenceId] = useState("");

  const handleGetPricing = (item, quantity) => {
    createPreference(item.id, quantity).then(() => {
      setSelectedPricing({ ...item, quantity });
      setStep(2);
    });
  };

  const createPreference = (pricingId, quantity) => {
    return PaymentService.createPreference(pricingId, quantity).then(
      (response) => {
        setCurrentPreferenceId(response.data.id);
      }
    );
  };

  return (
    <CustomDialog
      title={step === 1 ? "Elige tu plan" : "Pagar"}
      showDialog={showDialog}
      closeDialog={closeDialog}
      maxWidth={"800px"}
    >
      {step === 1 && (
        <PlansContainer>
          <div>
            <BuyingInfoText variant='tiny'>
              <b>Opción 1:</b> Paga a través del portal de Mercado pago
            </BuyingInfoText>

            <PlanCardContainer>
              {step === 1 &&
                pricingArray.map((pricingItem) => {
                  return (
                    <PlanCard
                      key={pricingItem.id}
                      title={pricingItem.name}
                      price={pricingItem.price.toString()}
                      currency={pricingItem.currency}
                      benefits={
                        pricingItem.duration === 180
                          ? benefitsSemester
                          : benefitsAnnual
                      }
                      businessBenefits={businessBenefits}
                      buttonText='Obtener'
                      quantity={1}
                      accountType={accountType}
                      onClick={(quantity) =>
                        handleGetPricing(pricingItem, quantity)
                      }
                    />
                  );
                })}
            </PlanCardContainer>
          </div>
          <DividingLine />
          <BankAccountContainer>
            <BuyingInfoText variant='tiny'>
              <b>Opción 2:</b> Paga realizando un abono o transferencia a nuestras cuentas bancarias BBVA
            </BuyingInfoText>
            <BankAccountSection>
              <div>
                <Typography variant='tiny' mt='1'>
                  <b>Titular de la cuenta:</b> UNICORNS PLATFORMS S.A.C.
                </Typography>
              </div>

              <div>
                <Typography variant='tiny' mt='3' weight={"bold"}>
                  CUENTA AHORROS SOLES
                </Typography>
                <Typography variant='tiny' mt='1'>
                  CC: {process.env.REACT_APP_BANK_ACCOUNT_PEN}
                </Typography>
                <Typography variant='tiny' mt='1'>
                  CCI: {process.env.REACT_APP_BANK_ACCOUNT_CCI_PEN}
                </Typography>
              </div>
              <div>
                <Typography variant='tiny' mt='2' weight={"bold"}>
                  CUENTA AHORROS DÓLARES
                </Typography>
                <Typography variant='tiny' mt='1'>
                  CC: {process.env.REACT_APP_BANK_ACCOUNT_DOLLARS}
                </Typography>
                <Typography variant='tiny' mt='1'>
                  CCI: {process.env.REACT_APP_BANK_ACCOUNT_CCI_DOLLARS}
                </Typography>
              </div>

              <div>
                <Typography variant='tiny' mt='3' weight={"bold"}>
                  CÓDIGO SWIFT
                </Typography>
                <Typography variant='tiny' mt='1'>
                  BCONPEPL
                </Typography>
              </div>

              <div>
                <Typography variant='tiny' mt='3'>
                  También puedes llamarnos o escribirnos al <b>{process.env.REACT_APP_HOLALINK_PHONE}</b>
                </Typography>
              </div>
            </BankAccountSection>
          </BankAccountContainer>
        </PlansContainer>
      )}
      {step === 2 && selectedPricing && (
        <div style={{ minWidth: "450px" }}>
          <div
            className='mb-2 col-md-2 col-sm-12'
            onClick={() => setStep(1)}
            style={{
              cursor: "pointer",
            }}
          >
            <ArrowBack />
            Volver
          </div>
          <SelectedPackageCard
            title={selectedPricing.name}
            price={selectedPricing.price.toString()}
            quantity={selectedPricing.quantity}
            currency={selectedPricing.currency}
            duration={selectedPricing.duration}
          />
          <Wallet
            initialization={{ preferenceId: currentPreferenceId }}
            customization={{
              visual: {
                buttonBackground: "default",
              },
            }}
          />
        </div>
      )}
    </CustomDialog>
  );
};

export default UpgradePlanDialog;
