import styled from "@emotion/styled";
import React from "react";
import Typography from "../../common/atoms/Typography";
import { blue_main } from "../../common/atoms/variables";

const PendingCardText = styled(Typography)`
  color: ${blue_main};
  line-height: 24px;
  font-size: 15px;
  margin-bottom: 8px;
  font-style: italic;
`;

const PendingTextAndCompanyContainer = styled.div`
  width: 55%;
  margin: auto;
  @media (max-width: 1919px) {
    width: 85%;
  }
`;
export default function CreateCardInvitation({
  onClick,
  currentCompany,
  cardType,
}) {
  const handleClick = () => {
    onClick();
  };
  return (
    <div className="card-list-item create-card" onClick={handleClick}>
      <div className="create-body">
        <PendingTextAndCompanyContainer>
          <div style={{ margin: "0px auto 12px auto" }}>
            <PendingCardText variant="xtiny" style={{ fontSize: "15px" }}>
              Tarjeta pendiente de configuración
            </PendingCardText>
          </div>
          <img
            style={{ width: "50px", height: "50px", marginBottom: "12px" }}
            src={currentCompany.logoUrl}
          />
          {cardType && cardType === "business" && (
            <Typography
              variant="tiny"
              style={{
                lineHeight: "12px",
                fontSize: "15px",
                marginBottom: "8px",
              }}
            >
              Empresa:
            </Typography>
          )}
          <Typography
            variant="tiny"
            style={{
              lineHeight: "12px",
              fontSize: "15px",
              marginBottom: "8px",
            }}
          >
            {currentCompany.name}
          </Typography>
        </PendingTextAndCompanyContainer>
        <div style={{ width: "90%", margin: "auto" }}>
          <Typography
            style={{
              color: blue_main,
              lineHeight: "12px",
              fontSize: "15px",
              marginTop: "20px",
            }}
          >
            Click aquí para comenzar
          </Typography>
        </div>
      </div>
    </div>
  );
}
