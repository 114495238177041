import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import { Menu } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ArticleIcon from "@mui/icons-material/Article";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import Typography from "../../common/atoms/Typography";
import Button from "../../common/atoms/Button";
import styled from "@emotion/styled";
import { MonitoringService } from "../services/MonitoringService";
import { downloadResponseBase64File } from "../../common/helpers/files.utils";

const StyledButton = styled(Button)`
  min-width: 150px;
  span {
    margin-right: 4px;
  }
  @media (max-width: 425px) {
    min-width: 72px;
    span {
      display: none;
    }
  }
`;

const StyledItem = styled(MenuItem)`
  &.MuiButtonBase-root {
    display: flex;
    padding: 6px 16px;
    align-items: start;
    justify-content: start;
  }
`;

export default function DownloadReportButton({ companyId, filter }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (format) => {
    setAnchorEl(null);
    if (!format) return;
    MonitoringService.downloadReport(companyId, format, filter).then(
      (response) => {
        downloadResponseBase64File(response);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  return (
    <>
      <StyledButton variant="primary" size="small" onClick={handleClick}>
        <span>Descargar</span>
        <FileDownloadIcon />
      </StyledButton>
      <Menu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
      >
        <StyledItem onClick={() => handleClose("pdf")} disableRipple>
          <PictureAsPdfIcon />
          <Typography ml="2" variant="tiny">
            Pdf
          </Typography>
        </StyledItem>
        <StyledItem onClick={() => handleClose("xlsx")} disableRipple>
          <ArticleIcon />
          <Typography ml="2" variant="tiny">
            Excel
          </Typography>
        </StyledItem>
      </Menu>
    </>
  );
}
