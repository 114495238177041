import { EMAIL_REGEX } from "../../common/helpers/form.utils";
import { useState } from "react";
import AuthService from "../services/AuthService";
import Link from "../../common/atoms/Link";
import Button from "../../common/atoms/Button";
import styled from "@emotion/styled";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { TextField } from "@material-ui/core";
import InitialLayout from "../components/InitialLayout";
import { useNavigate } from "react-router-dom";

const TextStyled = styled.p`
  font-size: 16px;
  font-family: Lato;
`;

const EmailToChangePasswordForm = () => {
  const [pageLoading, setPageLoading] = useState(false);
  const [forgotForm, setForgotForm] = useState({ email: "" });
  const [forgotErrors, setForgotErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [sendingEmailTimer, setSendingEmailTimer] = useState(0);

  const navigate = useNavigate();

  const handleBack = () => {
    setErrorMessage("");
    setSuccessMessage("");
    navigate("/login");
  };

  const handleForgotFormChange = (e) => {
    const { name, value } = e.target;

    setForgotForm((prevState) => ({
      ...prevState,
      [name]: value.replace(/\s/g, ""),
    }));
  };

  const handleForgotFormValidation = () => {
    const errors = {};
    let isValid = true;

    if (!forgotForm.email) {
      errors.email = "Ingrese un correo";
      isValid = false;
    } else if (!EMAIL_REGEX.test(forgotForm.email)) {
      errors.email = "Ingrese un correo válido";
      isValid = false;
    }

    setForgotErrors(errors);

    return isValid;
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    const isValid = handleForgotFormValidation();
    if (!isValid) return;
    setPageLoading(true);
    AuthService.resetPassword(forgotForm)
      .then((response) => {
        setIsSubmitted(true);
        setSuccessMessage(
          "El envío se realizó satisfactoriamente. Revisa la bandeja de Spam si no encuentras el correo."
        );
        setSendingEmailTimer(30);
        const interval = setInterval(() => {
          setSendingEmailTimer((value) => {
            if (value <= 0) {
              clearInterval(interval);
            } else {
              return value - 1;
            }
          });
        }, 1000);
      })
      .catch((error) => {
        setErrorMessage(
          error.response?.data?.message ||
            "Ocurrió un error al resetear su contraseña."
        );
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  return (
    <InitialLayout
      errorMessage={errorMessage}
      successMessage={successMessage}
      pageLoading={pageLoading}
    >
      <form method="post" onSubmit={handleResetPassword}>
        <Link onClick={handleBack}>
          <ArrowBackIcon /> Volver
        </Link>
        <div id="forgot_pw">
          <div className="form-group">
            <TextField
              fullWidth
              type="text"
              color="primary"
              margin="normal"
              variant="standard"
              label="Correo"
              name="email"
              error={!!forgotErrors.email}
              helperText={forgotErrors.email}
              onChange={handleForgotFormChange}
            />
          </div>
          <TextStyled>
            Si la información ingresada es válida recibirás un correo con el
            link que te permitirá restablecer tu contraseña.
          </TextStyled>
          <div className="mt-5">
            {isSubmitted && sendingEmailTimer > 0 ? (
              <Button
                disabled
                variant="disabled"
                size="medium"
                fullWidth={true}
              >
                Volver a enviar correo en {sendingEmailTimer}...
              </Button>
            ) : (
              <Button
                variant="primary"
                size="medium"
                fullWidth={true}
                type="submit"
              >
                Enviar correo
              </Button>
            )}
          </div>
        </div>
      </form>
    </InitialLayout>
  );
};

export default EmailToChangePasswordForm;
