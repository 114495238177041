import mobileMenuOpenActionTypes from "../action-types/mobileMenuOpenActionTypes";

const { SET_MOBILE_MENU_OPEN } = mobileMenuOpenActionTypes;

export const setMobileMenuOpen = (state) => {
  return {
    type: SET_MOBILE_MENU_OPEN,
    payload: state,
  };
};
